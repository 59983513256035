import { useEffect, useState } from "react";
import Select from "@atlaskit/select";
import { placeholderService } from "../../../Service/PlaceholderService";
import { useBoardStore } from "./board-store";

export const ColumnPicker = ({ selectedGroup, onUpdate, initialValues }) => {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [availableColumns, setAvailableColumns] = useState([]);

  const { setSelectedProperties } = useBoardStore((state) => ({
    setSelectedProperties: state.setSelectedProperties,
  }));

  useEffect(() => {
    const fetchAvailableColumns = async () => {
      const { data } = await placeholderService.getAllPlaceholdersOfSet(selectedGroup.id);
      setAvailableColumns(
        data.filter(({ kind }) => kind !== "CONFLUENCEPAGE").map(({ data: { name }, id, kind }) => ({ label: name, value: id, kind }))
      );
    };
    fetchAvailableColumns();
  }, [selectedGroup.id]);

  useEffect(() => setSelectedProperties(selectedColumns), [selectedColumns, setSelectedProperties]);

  useEffect(
    () => setSelectedColumns(initialValues?.length && availableColumns?.length ? initialValues : []),
    [initialValues, availableColumns]
  );

  const handleSelectionChange = (newSelectedColumns) => {
    onUpdate(newSelectedColumns || []);
    setSelectedColumns(newSelectedColumns || []);
  };

  return (
    <Select
      options={availableColumns}
      isClearable
      isMulti
      maxMenuHeight={100}
      isSearchable
      onChange={handleSelectionChange}
      placeholder="Select properties to show"
      value={selectedColumns}
    />
  );
};
