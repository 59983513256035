import { useState } from "react";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import Button from "@atlaskit/button";
import { AsyncSelect } from "@atlaskit/select";

import { usePropertyVisualizationStore } from "../property-visualization-store";

function mapSpace(space) {
  return {
    icon: space.icon,
    name: space.name,
    spaceKey: space.key,
    type: "space",
    id: space.key,
    label: space.name,
    value: space.key,
  };
}

const SystemPropertySpacePicker = ({ onUpdate }) => {
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const { config } = usePropertyVisualizationStore((state) => ({
    config: state.config.args,
  }));

  const loadOptions = async (query) => {
    if (query.trim().length === 0) {
      return [];
    }
    return (await atlassianRestService.searchForSpace(query)).results.map(({ space }) => mapSpace(space));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <div style={{ flex: 1 }}>
        <AsyncSelect
          placeholder="Search spaces..."
          isClearable={true}
          isSearchable={true}
          isMulti
          onChange={(updatedSpaces) => {
            setSelectedSpaces(updatedSpaces);
          }}
          value={selectedSpaces}
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          styles={{
            control: (styles) => ({
              ...styles,
              backgroundColor: "#FFFFFF",
              fontSize: "14px",
              width: "inherit",
              fontWeight: "normal",
            }),
            menuPortal: (styles) => ({
              ...styles,
              position: "initial",
            }),
            menu: (styles) => ({
              ...styles,
              width: "100%",
              marginTop: "0px",
            }),
            menuList: (styles) => ({
              ...styles,
              textAlign: "left",
              fontSize: "14px",
              fontWeight: "normal",
            }),
          }}
        />
      </div>

      <div style={{ marginLeft: "5px" }}>
        <Button
          appearance="primary"
          onClick={() => {
            if (!selectedSpaces || selectedSpaces.length === 0) {
              return;
            }
            const existingSpaces = config?.data?.args?.spaces ?? [];
            const filteredSpaces = selectedSpaces.filter((u) => !existingSpaces.includes(u));
            onUpdate(existingSpaces, filteredSpaces);
            setSelectedSpaces([]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default SystemPropertySpacePicker;
