import Spinner from '@atlaskit/spinner';
import React from "react";
export const LoadingState = props => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size={"large"} />
        <p>Loading...</p>
      </div>
    );
}