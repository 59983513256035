import React, { useState } from "react";
import { HeadingItem } from "@atlaskit/side-navigation";
import { Field } from "@atlaskit/form";
import { LabelFilter, SpaceFilter } from "./";

export function PageFiltersField({ placeholder, onLabelsChange, onSpacesChange, uniqueId }) {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div style={{ transform: "translateY(10px)" }}>
      <HeadingItem onClick={toggleExpanded} cssFn={() => ({ paddingLeft: 0, cursor: "pointer" })}>
        Filters
        <span style={{ fontSize: "14px" }}>{expanded ? " -" : " +"}</span>
      </HeadingItem>
      {expanded && (
        <div style={{ padding: "10px 20px", backgroundColor: "#f3f7ff" }}>
          <Field label="Label(s)" name="filter labels" defaultValue="">
            {() => <LabelFilter placeholder={placeholder} onLabelsChange={onLabelsChange} uniqueId={uniqueId} />}
          </Field>
          <Field label="Space(s)" name="filter spaces" defaultValue="">
            {() => <SpaceFilter placeholder={placeholder} onSpacesChange={onSpacesChange} uniqueId={uniqueId} />}
          </Field>
        </div>
      )}
    </div>
  );
}
