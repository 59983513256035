import React from "react";

const ConfirmCancelButtons = ({ idx, rowsInfo, handleSaveToQueue, onDeleteEntry, onStopSpinner, CheckCircleIcon, CrossIcon, Spinner }) => {
  return (
    <div className="row-icons">
      {rowsInfo[idx]?.loading ? (
        <>
          <Spinner size={"small"} style={{ cursor: "not-allowed" }} />
          <div onClick={() => onStopSpinner(idx)}>
            <CrossIcon size="small" primaryColor="red" />
          </div>
        </>
      ) : (
        <>
          <div onClick={() => handleSaveToQueue(idx)}>
            <CheckCircleIcon size="small" primaryColor="green" />
          </div>
          <div onClick={() => onDeleteEntry(idx)}>
            <CrossIcon size="small" primaryColor="red" />
          </div>
        </>
      )}
    </div>
  );
};

export default ConfirmCancelButtons;
