import React from "react";
import PropertyGroupFormWrapper from "../../../v2/Components/Shared/PropertyGroupFormWrapper";
import { getPropertiesOfGroup } from "./../PropertyGroupMacro/api";
import { useQuery } from "react-query";
import Textfield from "@atlaskit/textfield";
import Spinner from "@atlaskit/spinner";
import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import AddSaveEntry from "./AddSaveEntry";
import ConfirmCancelButtons from "./ConfirmCancelButtons";
import useCreateEntryMethods from "./useCreateEntryMethods";
import "./CreateEntry.scss";

const CreateEntry = ({ groupId, onRecordsUpdate }) => {
  const { data: groupData } = useQuery(["properties-of-group", { group: groupId }], getPropertiesOfGroup);
  const {
    onAddEntry,
    createCardInputs,
    rowsInfo,
    handleInputChange,
    onStopSpinner,
    onDeleteEntry,
    valuesPerOrdinalNumber,
    updateValuesPerOrdinalNumber,
    onSaveAll,
    handeSaveToQueue,
    keySwitcher,
  } = useCreateEntryMethods({ groupId, onRecordsUpdate });

  const renderEntry = (idx) => {
    const initialValues = valuesPerOrdinalNumber?.[idx] || false;

    return (
      <div className={`input-group ${rowsInfo[idx]?.isNameEmpty ? "isNameEmpty" : ""}`} key={idx}>
        <Textfield
          className="input-group-text-field"
          onChange={(evt) => handleInputChange(evt, idx)}
          placeholder="Enter a Page Name"
          value={createCardInputs[idx] || ""}
        />
        {groupData && (
          <PropertyGroupFormWrapper
            reportView
            groupData={groupData}
            updateValuesPerOrdinalNumber={updateValuesPerOrdinalNumber}
            ordinalNumber={idx}
            initialValues={initialValues}
          />
        )}
        <ConfirmCancelButtons
          idx={idx}
          rowsInfo={rowsInfo}
          handleSaveToQueue={handeSaveToQueue}
          onDeleteEntry={onDeleteEntry}
          onStopSpinner={onStopSpinner}
          CheckCircleIcon={CheckCircleIcon}
          CrossIcon={CrossIcon}
          Spinner={Spinner}
        />
      </div>
    );
  };

  const renderEntries = () => {
    return rowsInfo?.map((_, idx) => <div key={`${idx}` + keySwitcher}>{renderEntry(idx)}</div>);
  };

  return (
    <>
      <div>{renderEntries()}</div>
      <AddSaveEntry rowsInfo={rowsInfo} onAddEntry={onAddEntry} onSaveAll={onSaveAll} />
    </>
  );
};

export default CreateEntry;
