import styled from "styled-components";
import SelectOptions from "./SelectOptions";

const EmptySelect = styled.span`
  color: rgb(107, 119, 140);
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
`;
const DEFAULT_COLOR = "#DFE1E6";

const SelectPropertyView = ({ property, initialValue, name }) => {
  const optionsSource = property?.data?.options || [property?.value];
  const getCorrectInitialValue = () => {
    const defaultOption = optionsSource?.find((o) => o?.isDefault);
    if (!initialValue) {
      if (defaultOption) {
        return defaultOption;
      }
      return;
    }
    if (typeof initialValue === "string") {
      const foundOption = optionsSource?.find((p) => p.value === initialValue);
      if (foundOption) {
        return { label: initialValue, value: initialValue, color: foundOption?.color || DEFAULT_COLOR };
      }
    } else {
      const optionExists = optionsSource?.some((p) => p.value === initialValue?.value);
      if (optionExists) {
        optionsSource?.map((p) => {
          if (p?.value === initialValue?.value) {
            initialValue.color = p?.color;
            return p;
          }
        });
        return initialValue;
      }
    }

    return undefined;
  };

  const correctValue = getCorrectInitialValue();

  if (correctValue) {
    return <SelectOptions name={name} values={[correctValue]} property={property} single />;
  }

  if (property?.data?.description) {
    return (
      <EmptySelect data-sortable={true} data-raw-value="">
        {property?.data?.description}
      </EmptySelect>
    );
  }
  return (
    <EmptySelect data-sortable={true} data-raw-value="">
      Select a value
    </EmptySelect>
  );
};

export default SelectPropertyView;
