import { useEffect } from "react";
import shallow from "zustand/shallow";
import { usePropertyVisualizationStore } from "../property-visualization-store";
import BoardVisualizationContainer from "./BoardVisualizationContainer";
import PropertyVisualizationSidebarBoardView from "./PropertyVisualizationSidebarBoardView";
import VisualizationEmptyState from "./VisualizationEmptyState";
import Spinner from "@atlaskit/spinner";
import { ViewType } from "./BoardViewType";

const BoardVisualizationEditWrapper = (props) => {
  const { rootConfig, config, updateConfig } = usePropertyVisualizationStore(
    (state) => ({
      rootConfig: state.config,
      config: state.config.args,
      updateConfig: state.updateConfig,
    }),
    shallow
  );
  const isIdDefined = config?.data?.args?.id !== undefined;

  useEffect(() => {
    if (!rootConfig.args) {
      updateConfig({
        strategy: "Board",
        data: {
          group: undefined,
          args: {},
        },
      });
    }
  }, []);

  if (!rootConfig.args) {
    <div style={{ textAlign: "center" }}>
      <Spinner />
    </div>;
  }

  return (
    <>
      <PropertyVisualizationSidebarBoardView />
      <div
        style={{
          width: "100%",
          overflow: "overlay",
          paddingBottom: "1rem",
          borderBottom: "1px solid #f5f5f5",
          boxSizing: "borderBox",
        }}
      >
        {isIdDefined && <BoardVisualizationContainer shouldDisableDragDrop={true} viewType={ViewType.editView} />}
        {!isIdDefined && (
          <VisualizationEmptyState description="Make sure to select a property group on the left side and choose select property." />
        )}
      </div>
    </>
  );
};

export default BoardVisualizationEditWrapper;
