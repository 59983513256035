import styled from "styled-components";

const Cover = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  position: absolute;
`;

const CreateCardClosed = styled.div`
  display: ${(props) => props.isEntity && "none"} !important;
  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  font-family: inherit;
  font-weight: 600;
  max-width: 100%;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  height: 36px;
  line-height: 2.28571em;
  vertical-align: middle;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  border-width: 0px;
  border-radius: 3px;
  text-decoration: none;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  background: var(--ds-background-neutral-subtle, none);
  padding: 0px 10px;
  outline: none;
`;

const CreateCardOpened = styled.dialog`
  border-radius: 8px;
  border: 1px solid #888;
  z-index: 100000;
  top: 12.5%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: flex-start;
  padding: 20px 22px 20px 15px;
  outline: none;
  box-shadow: inset 0 0 0 2px var(--ds-border-focused, #4c9aff);
  border-radius: 3px;
  height: fit-content !important;
  min-height: 40px;
  transition: box-shadow 200ms ease-in-out 0s;
  > div {
    padding: 4px 6px 10px 5px;
    overflow: visible;
    height: fit-content;
    > * {
      align-items: flex-start !important;
      > * {
        padding: 5px 0 5px 0;
        overflow: visible;
      }
    }
  }
  > :nth-child(4) {
    margin-bottom: 70px;
    z-index: 100;
  }
  > :nth-child(2) {
    z-index: 1000 !important;
  }
  > :first-child {
    margin: 10px 0;
  }
  > :last-child {
    padding: 15px 0 15px 0;
    position: absolute;
    bottom: 10px;
    left: 20px;
    width: calc(100% - 40px);
  }
`;

export { Cover, CreateCardClosed, CreateCardOpened };
