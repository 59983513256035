import { Field, HelperMessage } from "@atlaskit/form";
import SystemPropertyUserPicker from "./SystemPropertyUserPicker";
import { useSystemPropertiesUIStore } from "./chart-visualization-store";
import SystemPropertySpacePicker from "./SystemPropertySpacePicker";
import TextDivider from "./TextDivider";
import { usePropertyVisualizationStore } from "../property-visualization-store";

const SystemPropertiesConfiguration = (props) => {
  const { selected, setSelected } = useSystemPropertiesUIStore((state) => ({
    selected: state.selected,
    setSelected: state.setSelected,
  }));

  const { config, updateConfig } = usePropertyVisualizationStore((state) => ({
    config: state.config.args,
    updateConfig: state.updateConfig,
  }));

  const UserOrSpace = () => {
    return selected.type === "user" ? "user" : "space";
  };

  return (
    <div>
      <Field label="User" name="select user" defaultValue="">
        {({}) => (
          <>
            <SystemPropertyUserPicker
              isMulti={false}
              onUpdate={(existing, newUsers) => {
                updateConfig({
                  data: {
                    ...config.data,
                    args: {
                      ...config.data.args,
                      people: [...existing, ...newUsers.map((u) => u.id)],
                      assignments: [...config.data.args.assignments, ...newUsers.map((u) => ({ id: u.id, parent: "0", type: "user" }))],
                    },
                  },
                });
              }}
            />
            <HelperMessage>This user will be added as root user</HelperMessage>
            <div style={{ marginTop: "10px", marginBottom: "10px", color: "#6B778C", fontSize: "12px" }}>
              <TextDivider text="or" />
            </div>
            <SystemPropertySpacePicker
              onUpdate={(existing, newSpaces) => {
                updateConfig({
                  data: {
                    ...config.data,
                    args: {
                      ...config.data.args,
                      spaces: [...existing, ...newSpaces.map((u) => u.id)],
                      assignments: [...config.data.args.assignments, ...newSpaces.map((u) => ({ id: u.id, parent: "0", type: "space" }))],
                    },
                  },
                });
              }}
            />
            <HelperMessage>This space will be added as root space</HelperMessage>
          </>
        )}
      </Field>

      {selected && (
        <Field label="Configure selected" name="configure selected" defaultValue="">
          {({}) => (
            <div
              style={{
                padding: "10px 20px",
                backgroundColor: "#f3f7ff",
              }}
            >
              <div>
                <div style={{ marginBottom: "10px" }}>
                  {selected.type === "space" && (
                    <HelperMessage>You can assign multiple users or spaces to the selected space</HelperMessage>
                  )}
                  {selected.type === "user" && (
                    <HelperMessage>You can assign multiple users or spaces the selected user is responsible for</HelperMessage>
                  )}
                </div>
                <Field label="User(s)" name="select users" defaultValue="">
                  {({}) => (
                    <>
                      <SystemPropertyUserPicker
                        isMulti
                        placeholder="Search users..."
                        onUpdate={(existing, newUsers) => {
                          updateConfig({
                            data: {
                              ...config.data,
                              args: {
                                ...config.data.args,
                                people: [...existing, ...newUsers.map((u) => u.id)],
                                assignments: [
                                  ...config.data.args.assignments,
                                  ...newUsers.map((u) => ({ id: u.id, parent: selected.id, type: "user" })),
                                ],
                              },
                            },
                          });
                        }}
                      />
                      <HelperMessage>
                        Users will be added below selected <UserOrSpace />
                      </HelperMessage>
                    </>
                  )}
                </Field>
                <Field label="Space(s)" name="select spaces" defaultValue="">
                  {({}) => (
                    <>
                      <SystemPropertySpacePicker
                        onUpdate={(existing, newSpaces) => {
                          updateConfig({
                            data: {
                              ...config.data,
                              args: {
                                ...config.data.args,
                                spaces: [...existing, ...newSpaces.map((u) => u.id)],
                                assignments: [
                                  ...config.data.args.assignments,
                                  ...newSpaces.map((u) => ({ id: u.id, parent: selected.id, type: "space" })),
                                ],
                              },
                            },
                          });
                        }}
                      />
                      <HelperMessage>
                        Spaces will be added below selected <UserOrSpace />
                      </HelperMessage>
                    </>
                  )}
                </Field>
                <div style={{ display: "flex", marginTop: "10px", justifyContent: "space-between" }}>
                  <div
                    onClick={() => {
                      const updatedAssignments = JSON.parse(JSON.stringify(config.data.args.assignments));
                      const userToUpdate = updatedAssignments.find((a) => a.id === selected.id);
                      userToUpdate.parent = "0";
                      updateConfig({
                        data: {
                          ...config.data,
                          args: {
                            ...config.data.args,
                            assignments: updatedAssignments,
                          },
                        },
                      });
                    }}
                    style={{ cursor: "pointer", width: "fit-content", fontSize: "14px" }}
                  >
                    Set as root
                  </div>
                  <div
                    onClick={() => {
                      if (selected.type === "user" || selected.type === "space") {
                        const currentAssignments = JSON.parse(JSON.stringify(config.data.args.assignments));
                        const userToDelete = currentAssignments.find((a) => a.id === selected.id);
                        const updatedAssignments = currentAssignments
                          .filter((a) => a.id !== selected.id)
                          .map((a) => {
                            if (a.parent === selected.id) {
                              return { ...a, parent: userToDelete.parent };
                            }
                            return a;
                          });
                        const updatedPeople = JSON.parse(JSON.stringify(config.data.args.people)).filter((p) => p !== selected.id);

                        updateConfig({
                          data: {
                            ...config.data,
                            args: {
                              ...config.data.args,
                              people: updatedPeople,
                              assignments: updatedAssignments,
                            },
                          },
                        });
                        setSelected(undefined);
                      }
                    }}
                    style={{ cursor: "pointer", color: "red", width: "fit-content", fontSize: "14px" }}
                  >
                    Delete
                  </div>
                </div>
              </div>
            </div>
          )}
        </Field>
      )}
    </div>
  );
};

export default SystemPropertiesConfiguration;
