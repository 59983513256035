import React from "react";
import { PlaceholderSetHeader } from "./PlaceholderSetHeader/PlaceholderSetHeader";
import { PlaceholderList } from "./PlaceholderList/PlaceholderList";
import { PortalActions } from "./PortalActions/PortalActions";
import "./SelectedPlaceholderSet.scss";

export const SelectedPlaceholderSet = ({ allowedAccess, groupIsChanged }) => {
  return (
    <div className="selectedPlaceholderSet" style={{ maxHeight: "90vh" }}>
      <div className="selectedPlaceholderSet-container header">
        <div className="selectedPlaceholderSet-element">
          <PlaceholderSetHeader />
        </div>
      </div>
      <div className="selectedPlaceholderSet-container list">
        <div className="selectedPlaceholderSet-element">
          <PlaceholderList />
        </div>
      </div>
      <div className="selectedPlaceholderSet-container actions">
        <div className="selectedPlaceholderSet-save-buttons">{allowedAccess && <PortalActions groupIsChanged={groupIsChanged} />}</div>
      </div>
    </div>
  );
};
