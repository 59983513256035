export const loadDataFromMacro = async () => {
  return new Promise((resolve, reject) => {
    window.AP.confluence.getMacroData((macroData) => {
      if (Object.entries(macroData).length > 0) {
        try {
          resolve(macroData);
        } catch (e) {
          console.log("erorr loading data", e);
        }
      }
      resolve(undefined);
    });
  });
};

export const saveDataInMacro = (data) => {
  window.AP.dialog.disableCloseOnSubmit();
  window.AP.confluence.saveMacro({
    data: JSON.stringify(data),
  });
  window.AP.dialog.close();
};
