import { useState } from "react";
import Select from "@atlaskit/select";
import { SelectFieldStyles } from "../../Shared/SelectFieldStyles";

export const ParentPicker = ({ initialValues, onUpdate }) => {
  const [defaultState, setDefaultState] = useState(initialValues);

  const options = [
    { label: "As child page of the board", value: "disabled" },
    { label: "Choose individually on the create dialogue ", value: "enabled" },
  ];

  return (
    <Select
      options={options}
      isClearable={false}
      value={defaultState && options.find((o) => o.value === defaultState)}
      maxMenuHeight={205}
      styles={SelectFieldStyles}
      onChange={(newOption) => {
        setDefaultState(newOption?.value);
        onUpdate(newOption?.value);
      }}
      placeholder="select default state"
    />
  );
};
