import React from "react";
import { Sidebar } from "./Sidebar";
import PromotionBanner from "../PromotionBanner";
import { EMPTY_PORTAL } from "../../../empty-states-resources";
import { EmptyPortalPlaceholder, EmptyPortalSidebarContainer } from "../PlaceholderPortalStyles";

const EmptyPortal = ({ setAddPlaceholderSetModalVisible }) => {
  return (
    <EmptyPortalSidebarContainer>
      <Sidebar onAddNewPlaceholder={() => setAddPlaceholderSetModalVisible(true)} />
      <div style={{ flex: 1 }}>
        <PromotionBanner />
        <EmptyPortalPlaceholder>
          <div style={{ fontWeight: 500 }}>Welcome to the Properties Portal!</div>
          <img alt="empty_portal" width="40%" src={EMPTY_PORTAL}></img>
          <div style={{ fontWeight: 500 }}>
            There is nothing here yet. Learn more about properties or{" "}
            <a
              onClick={(e) => {
                e.preventDefault();
                setAddPlaceholderSetModalVisible(true);
              }}
              href="#"
            >
              create a new property group to start.
            </a>
          </div>
        </EmptyPortalPlaceholder>
      </div>
    </EmptyPortalSidebarContainer>
  );
};

export default EmptyPortal;
