import { useEffect, useState } from "react";
import Select from "@atlaskit/select";
import { SelectFieldStyles } from "../../Shared/SelectFieldStyles";
import { usePropertyGroupReportUIStore } from "../../PropertyReportMacro/store";
import { onSelectMenuClosed } from "../../../resize-service";
import { token } from "@atlaskit/tokens";

const DEFAULT_COLOR = "#DFE1E6";

const SelectPropertyEdit = ({ property, initialValue, autoFocus, onUpdate }) => {
  const [selected, setSelected] = useState();
  const { loadedLiveFilter } = usePropertyGroupReportUIStore((state) => ({
    loadedLiveFilter: state.loadedLiveFilter,
  }));
  useEffect(() => {
    if (initialValue) {
      if (typeof initialValue === "string") {
        const foundOption = property.data.options.find((p) => p.value === initialValue);
        if (foundOption) {
          setSelected({ label: initialValue, initialValue, color: foundOption?.color || DEFAULT_COLOR });
          return;
        }
      } else {
        const optionExists = property.data.options.some((p) => p.value === initialValue.value);
        if (optionExists) {
          setSelected(initialValue);
          return;
        }
      }
      const defaultOption = property.data.options.find((o) => o.isDefault);
      if (defaultOption) {
        setSelected(defaultOption);
      }
    } else {
      const defaultOption = property.data.options.find((o) => o.isDefault);
      if (defaultOption) {
        setSelected(toSelectField(defaultOption));
      }
    }
  }, [initialValue]);

  return (
    <Select
      className="select-edit-field"
      autoFocus={autoFocus}
      openMenuOnFocus={loadedLiveFilter}
      isClearable
      maxMenuHeight={205}
      styles={
        SelectFieldStyles && {
          control: (base) => ({
            ...base,
            backgroundColor: `${token("utility.elevation.surface.current")}`,
            borderColor: `${token("color.border.input")}`,
            boxShadow: `${token("elevation.shadow.overflow")}`,
            fontSize: "14px",
          }),
          menuList: (base) => ({ ...base, fontSize: "14px", fontWeight: "400", paddingTop: "0px", textAlign: "left" }),
        }
      }
      value={selected}
      options={property.data.options.map(toSelectField)}
      onChange={(newOption) => {
        setSelected(newOption);
        onUpdate(newOption);
      }}
      onMenuOpen={() => {
        if (loadedLiveFilter) {
          window.AP.events.emit("trigger_resize_livefilter", [".select-edit-field"]);
        }
      }}
      onMenuClose={() => {
        if (loadedLiveFilter) {
          onSelectMenuClosed();
        }
      }}
      placeholder={property?.data?.description || "Select a value"}
    ></Select>
  );
};

const toSelectField = ({ value, color }) => {
  return {
    label: value,
    value,
    color,
  };
};

export default SelectPropertyEdit;
