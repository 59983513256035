import { placeholderCloudRunUrl, placeholderSetRunUrl } from "../../../cloud-run-config";
import httpClient from "../../http-client";

export async function getAllPropertyGroups({ queryKey }) {
  const [_key, {}] = queryKey;
  const url = placeholderSetRunUrl + "/api/sets";
  return httpClient.get(url);
}

export async function getPropertiesOfGroup({ queryKey }) {
  const [_key, { group }] = queryKey;
  const url = `${placeholderCloudRunUrl}/api/placeholder/${group}`;
  return httpClient.get(url);
}

export async function getReport({ queryKey, pageParam }) {
  const [_key, { group, labels = {}, pagesUnder = undefined, spaces = [], filters = {}, limit, labelsLogic = [false] }] = queryKey;
  const url = placeholderCloudRunUrl + "/api/reports";
  const allLabels = [];
  const labelsAsList = Array.isArray(labels);
  if (labelsAsList) {
    labels.forEach((labelGroup) => {
      allLabels.push(labelGroup.join(","));
    });
  } else {
    const labelGroups = Object.values(labels).filter((x) => x.length > 0);
    allLabels.push(...labelGroups.map((group) => group.join(",")));
  }
  return httpClient.post(url, {
    groupId: group,
    labels: allLabels.join(";"),
    labelsLogic,
    spaces: spaces.join(","),
    filters: filters,
    pagesUnder: pagesUnder,
    cursor: pageParam?.cursor,
    offset: pageParam?.offset ?? 0,
    limit,
  });
}

export async function getPaginatedReport(group, resolvedProperties, entityIds) {
  const url = placeholderCloudRunUrl + "/api/reports/paginated-report";
  return httpClient.post(url, {
    groupId: group,
    entityIds,
    resolvedProperties,
  });
}
export async function getSpaceByKey(spaceKey) {
  return window.AP.request(`/rest/api/space/${spaceKey}?expand=icon`);
}
export async function getPagesInSpace(id) {
  return window.AP.request(`/api/v2/spaces/${id}/pages`);
}
