import { useState } from "react";
import { usePropertyGroupReportMacroStore, usePropertyLiveFilterStore } from "./store";
import { Field } from "@atlaskit/form";
import { SelectFieldStyles } from "../Shared/SelectFieldStyles";
import Select from "@atlaskit/select";
import { PropertyType } from "../../property-type";
import DatePropertyEdit from "../PropertyGroupMacro/editor/DatePropertyEdit";
import TextPropertyEdit from "../PropertyGroupMacro/editor/TextPropertyEdit";
import UrlPropertyEdit from "../PropertyGroupMacro/editor/UrlPropertyEdit";
import ConfluencePagePropertyEdit from "../PropertyGroupMacro/editor/ConfluencePagePropertyEdit";
import SelectPropertyEdit from "../PropertyGroupMacro/editor/SelectPropertyEdit";
import MultiSelectPropertyEdit from "../PropertyGroupMacro/editor/MultiSelectPropertyEdit";
import EmailPropertyEdit from "../PropertyGroupMacro/editor/EmailPropertyEdit";
import UserPropertyEdit from "../PropertyGroupMacro/editor/UserPropertyEdit";
import PhonePropertyEdit from "../PropertyGroupMacro/editor/PhonePropertyEdit";
import NumberPropertyEdit from "../PropertyGroupMacro/editor/NumberPropertyEdit";
import shallow from "zustand/shallow";
import { ValuesForLiveFilter } from "./ValuesForLiveFilter";
import { token } from "@atlaskit/tokens";


export const NewPropertyFilter = ({ targetIdx, availableProperties, isEditing }) => {
  const { setPropertyFiltersWithIndex } = usePropertyGroupReportMacroStore((state) => ({
    setPropertyFiltersWithIndex: state.setPropertyFiltersWithIndex,
  }));
  const [selected, setSelected] = useState([]);

  return (
    <Select
      isClearable
      closeMenuOnSelect
      isSearchable
      styles={SelectFieldStyles && { control: (base) => ({ ...base, backgroundColor: token("elevation.surface.overlay"), fontSize: "14px" }) }}
      options={availableProperties}
      maxMenuHeight={200}
      onChange={(newProperty) => {
        setSelected(newProperty);
        setPropertyFiltersWithIndex({ [newProperty.value]: undefined }, targetIdx);
      }}
      placeholder="Select properties to filter"
      value={selected}
    />
  );
};
export const ExistingPropertyFilter = ({ targetIdx, availableProperties, isEditing }) => {
  const { propertyFilters, setPropertyFiltersWithIndex } = usePropertyGroupReportMacroStore(
    (state) => ({
      propertyFilters: state.propertyFilters,
      setPropertyFiltersWithIndex: state.setPropertyFiltersWithIndex,
    }),
    shallow
  );

  const propertyFilter = propertyFilters[targetIdx];
  const propertyId = Object.keys(propertyFilter)[0];
  const currentProperty = availableProperties.find((p) => p.id === propertyId);
  const onUpdate = (newValue) => {
    setPropertyFiltersWithIndex({ [currentProperty.id]: newValue }, targetIdx);
  };
  return (
    <Field label={currentProperty?.label} name="single property filter" defaultValue="">
      {() => (
        <FieldsForFiltering
          currentProperty={currentProperty}
          propertyFilter={propertyFilter}
          propertyId={propertyId}
          onUpdate={onUpdate}
          isEditing={isEditing}
        />
      )}
    </Field>
  );
};
export const ExistingPropertyLiveFilter = ({ currentProperty, setNewFilter, reportMacroId }) => {
  const { propertyLiveFilters } = usePropertyLiveFilterStore((state) => ({
    propertyLiveFilters: state.propertyLiveFilters,
  }));
  let filteredProperty = {};
  try {
    const propertyLiveFiltersJson = reportMacroId
      ? localStorage.getItem(`property-report-live-filter-${reportMacroId}`)
      : localStorage.getItem("property-report-live-filter");
    const propertyLiveFilterValues = JSON.parse(propertyLiveFiltersJson);
    const entriesOfProperties = propertyLiveFilterValues ? Object.entries(propertyLiveFilterValues) : [];
    filteredProperty = entriesOfProperties.filter((filterEntry) => filterEntry.find((p) => p === currentProperty.id));
    filteredProperty = filteredProperty.reduce(
      (currentPropertyId, filteredValue) => ({ ...currentPropertyId, [filteredValue[0]]: filteredValue[1] }),
      {}
    );
  } catch (e) {
    filteredProperty = Object.values(propertyLiveFilters)
      .map((filter) => {
        const liveFilteredProperty = Object.entries(filter)
          .filter((filterEntry) => filterEntry.find((p) => p === currentProperty.id))
          .map((entry) => {
            if (entry.length > 1) {
              const id = entry.find((entryId) => entryId === currentProperty.id);
              const value = entry[1];
              return { [id]: value };
            }
          })[0];
        return liveFilteredProperty;
      })
      .filter((filterEntry) => filterEntry !== undefined)[0];
  }
  const addFilter = (newValue) => {
    if (currentProperty.kind === PropertyType.CONFLUENCEPAGE) {
      setNewFilter({ [currentProperty.id]: newValue?.contentId });
    } else {
      setNewFilter({ [currentProperty.id]: newValue });
    }
  };
  return (
    <ValuesForLiveFilter
      currentProperty={currentProperty}
      propertyFilter={filteredProperty && Object.keys(filteredProperty).length > 0 ? filteredProperty : { [currentProperty.id]: undefined }}
      propertyId={filteredProperty && Object.keys(filteredProperty).length > 0 ? Object.keys(filteredProperty)[0] : currentProperty.id}
      onUpdate={(newValue) => {
        addFilter(!newValue || newValue.length === 0 ? undefined : newValue);
      }}
    />
  );
};
export const FieldsForFiltering = ({ currentProperty, propertyFilter, propertyId, onUpdate, liveFilter = false, isEditing = false }) => {
  return (
    <div style={{ width: liveFilter ? "235px" : "100%" }}>
      {currentProperty?.kind === PropertyType.TEXT && (
        <TextPropertyEdit property={currentProperty} initialValue={propertyFilter[propertyId] ?? undefined} onUpdate={onUpdate} />
      )}
      {currentProperty?.kind === PropertyType.URL && (
        <UrlPropertyEdit property={currentProperty} initialValue={propertyFilter[propertyId] ?? undefined} onUpdate={onUpdate} autoFocus />
      )}
      {currentProperty?.kind === PropertyType.DATE && (
        <DatePropertyEdit property={currentProperty} initialValue={propertyFilter[propertyId] ?? undefined} onUpdate={onUpdate} autoFocus />
      )}
      {currentProperty?.kind === PropertyType.CONFLUENCEPAGE && (
        <ConfluencePagePropertyEdit property={currentProperty} initialValue={propertyFilter[propertyId] ?? undefined} onUpdate={onUpdate} />
      )}
      {currentProperty?.kind === PropertyType.SELECT && (
        <SelectPropertyEdit
          property={currentProperty}
          initialValue={propertyFilter[propertyId] ?? undefined}
          onUpdate={(newValue) => {
            if (liveFilter) {
              onUpdate(newValue === null ? undefined : newValue);
            } else {
              onUpdate(newValue?.value?.length === 0 ? undefined : newValue);
            }
          }}
          autoFocus
        />
      )}
      {currentProperty?.kind === PropertyType.MULTISELECT && (
        <MultiSelectPropertyEdit
          property={currentProperty}
          initialValue={propertyFilter[propertyId] ?? undefined}
          onUpdate={(newValue) => {
            onUpdate(newValue.length === 0 ? undefined : newValue);
          }}
          autoFocus
        />
      )}
      {currentProperty?.kind === PropertyType.EMAIL && (
        <EmailPropertyEdit
          property={currentProperty}
          initialValue={propertyFilter[propertyId] ?? undefined}
          onUpdate={onUpdate}
          autoFocus={liveFilter}
        />
      )}
      {currentProperty?.kind === PropertyType.USER && (
        <UserPropertyEdit
          isEditing={isEditing}
          property={currentProperty}
          initialValue={propertyFilter[propertyId] ?? undefined}
          onUpdate={(newValue) => {
            if (liveFilter) {
              onUpdate({ value: newValue, type: "user" });
            }
            onUpdate(newValue.length === 0 ? undefined : newValue);
          }}
          autoFocus
        />
      )}
      {currentProperty?.kind === PropertyType.PHONE && (
        <PhonePropertyEdit property={currentProperty} initialValue={propertyFilter[propertyId] ?? undefined} onUpdate={onUpdate} />
      )}
      {currentProperty?.kind === PropertyType.NUMBER && (
        <NumberPropertyEdit
          property={currentProperty}
          initialValue={propertyFilter[propertyId] ?? undefined}
          onUpdate={onUpdate}
          autoFocus
        />
      )}
    </div>
  );
};
