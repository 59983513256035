import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { usePropertyGroupReportMacroStore } from "./store";
import Select from "@atlaskit/select";
import Spinner from "@atlaskit/spinner";
import { getPropertiesOfGroup } from "./api";
import ChevronUpIcon from "@atlaskit/icon/glyph/chevron-up";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import Tooltip from "@atlaskit/tooltip";
import { ReportSortOrder } from "./report-sort-order";
import shallow from "zustand/shallow";
import { SelectFieldStyles } from "../Shared/SelectFieldStyles";
import SortOrderPicker from "./SortOrderPicker";

const DefaultSortPicker = (props) => {
  const { group, selectedColumns, defaultSortColumn, setDefaultSortColumn, defaultSortOrder, setDefaultSortOrder } =
    usePropertyGroupReportMacroStore(
      (state) => ({
        group: state.selectedGroup,
        selectedColumns: state.selectedColumns,
        defaultSortColumn: state.defaultSortColumn,
        defaultSortOrder: state.defaultSortOrder,
        setDefaultSortColumn: state.setDefaultSortColumn,
        setDefaultSortOrder: state.setDefaultSortOrder,
      }),
      shallow
    );

  const [selected, setSelected] = useState();
  const [options, setOptions] = useState([]);

  const { isLoading, data: availableProperties } = useQuery(["properties-of-group", { group }], getPropertiesOfGroup, {
    select(response) {
      return response.data.map((d) => ({ label: d.data.name, value: d.id }));
    },
  });

  const setLoadOptions = () => {
    if (selectedColumns.length === 0) {
      setOptions(availableProperties);
    } else {
      setOptions(selectedColumns.map((c) => ({ value: c, label: availableProperties.find((p) => p.value === c).label })));
    }
  };

  const handleDefaultSortColumnStillInColumnSelection = () => {
    if (defaultSortColumn) {
      let defaultSortColumnStillExists;
      if (selectedColumns.length === 0) {
        defaultSortColumnStillExists = availableProperties.some((c) => c.value === defaultSortColumn);
      } else {
        defaultSortColumnStillExists = selectedColumns.includes(defaultSortColumn);
      }
      if (!defaultSortColumnStillExists) {
        setSelected(null);
        setDefaultSortColumn(undefined);
        setDefaultSortOrder(undefined);
        return false;
      }
      return true;
    }
    return false;
  };

  const initializeSelectedValue = () => {
    if (!defaultSortColumn) {
      return;
    }
    const defaultSortProperty = availableProperties.find((p) => p.value === defaultSortColumn);
    if (defaultSortProperty) {
      setSelected({ value: defaultSortColumn, label: defaultSortProperty.label });
    } else {
      setSelected(null);
      setDefaultSortColumn(undefined);
      setDefaultSortOrder(undefined);
    }
  };

  useEffect(() => {
    if (!availableProperties) {
      return;
    }
    setLoadOptions();
    const sortColumnInSelection = handleDefaultSortColumnStillInColumnSelection();
    if (sortColumnInSelection) {
      initializeSelectedValue();
    }
    if (!defaultSortOrder) {
      setDefaultSortOrder(ReportSortOrder.ASC);
    }
  }, [selectedColumns, availableProperties]);

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        <Select
          options={options}
          isClearable
          maxMenuHeight={100}
          styles={SelectFieldStyles}
          onChange={(newSelectedColumn) => {
            setSelected(newSelectedColumn);
            if (!newSelectedColumn) {
              setDefaultSortOrder(undefined);
              setDefaultSortColumn(undefined);
              return;
            }
            if (!defaultSortOrder) {
              setDefaultSortOrder(ReportSortOrder.ASC);
            }
            setDefaultSortColumn(newSelectedColumn.value);
          }}
          placeholder="Select property"
          value={selected}
        />
      </div>
      <div>
        <Tooltip content="Ascending">
          <SortOrderPicker
            selected={defaultSortColumn && defaultSortOrder === ReportSortOrder.ASC}
            onClick={() => {
              setDefaultSortOrder(ReportSortOrder.ASC);
            }}
          >
            <ChevronUpIcon size="medium" />
          </SortOrderPicker>
        </Tooltip>
        <Tooltip content="Descending">
          <SortOrderPicker
            selected={defaultSortColumn && defaultSortOrder === ReportSortOrder.DESC}
            onClick={() => {
              setDefaultSortOrder(ReportSortOrder.DESC);
            }}
          >
            <ChevronDownIcon size="medium" />
          </SortOrderPicker>
        </Tooltip>
      </div>
    </div>
  );
};
export default DefaultSortPicker;
