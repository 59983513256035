import { useEffect, useState } from "react";
import { LazyPageLink } from "./LazyPageLink";
import Button from "@atlaskit/button";
import { generateId } from "../../../v2/id-generator";
import { usePropertyGroupReportFormStore } from "./property-group-report-form-store";
import { usePropertyGroupReportMacroStore, usePropertyLiveFilterStore } from "./store";
import shallow from "zustand/shallow";
import { useQuery } from "react-query";
import Spinner from "@atlaskit/spinner";
import { getPaginatedReport, getReport } from "./api";
import PropertyBasedOnKind from "./PropertyBasedOnKind";
import TableRow from "./TableRow";
import { ReportSortOrder } from "./report-sort-order";
import ChevronUpIcon from "@atlaskit/icon/glyph/chevron-up";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import "./table.css";
import { FieldIcons } from "../FieldIcons";
import EmptyState from "@atlaskit/empty-state";
import NoReportsForGroup from "./NoReportsForGroup.png";
import "./PropertyGroup.scss";
import { placeholderService } from "../../Service/PlaceholderService";
import { v4 as uuidv4 } from "uuid";
import { getRecordsForGroup } from "../PropertyVisualizationMacro/api";
import CreateCard from "../Shared/CreateCard";
import CreateEntry from "./CreateEntry";
import { addEntriesTypes } from "./addEntriesTypes";
import Tooltip from "@atlaskit/tooltip";
import styled from "styled-components";
import { token } from "@atlaskit/tokens";

const TableHover = styled.tr`
  > tr:hover {
    background-color: ${token("elevation.surface.hover")} !important;
    > td:hover {
      background-color: ${token("elevation.surface.pressed")} !important;
    }
  }
`;

const PropertyGroupReportTable = ({ disableEdit = false, groupId }) => {
  const { setInitialValues, setValues } = usePropertyGroupReportFormStore((state) => ({
    values: state.values,
    initialValues: state.initialValues,
    setValues: state.setValues,
    setInitialValues: state.setInitialValues,
  }));

  const { propertyLiveFilters } = usePropertyLiveFilterStore((state) => ({
    propertyLiveFilters: state.propertyLiveFilters,
  }));

  const {
    group,
    selectedColumns,
    propertyFilters,
    labels,
    spaces,
    pagesUnder,
    currentPageId,
    numberOfItems,
    defaultSortOrder,
    defaultSortColumn,
    addEntriesType,
    labelsLogic,
  } = usePropertyGroupReportMacroStore(
    (state) => ({
      group: state.selectedGroup,
      selectedColumns: state.selectedColumns,
      defaultSortOrder: state.defaultSortOrder,
      defaultSortColumn: state.defaultSortColumn,
      labels: state.labels,
      spaces: state.selectedSpaces,
      pagesUnder: state.pagesUnder,
      currentPageId: state.currentPageId,
      numberOfItems: state.numberOfItems,
      propertyFilters: state.propertyFilters,
      addEntriesType: state.addEntriesType,
      labelsLogic: state.labelsLogic,
    }),
    shallow
  );

  const [records, setRecords] = useState([]);
  const [resolvedProperties, setResolvedProperties] = useState([]);
  const [pages, setPages] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);
  const [reportLength, setReportLength] = useState(numberOfItems);
  const [columnToSortBy, setColumnToSortBy] = useState(defaultSortColumn);
  const [rowsRendered, setRowsRendered] = useState(false);
  const [tableAvailable, setTableAvailable] = useState(false);
  const [headerOfReport, setHeader] = useState("");
  const [descriptionOfReport, setDescription] = useState("");
  const allPropertyFilters = { ...propertyFilters, ...propertyLiveFilters };
  const [tableHeight, setTableHeight] = useState(0);
  const { isLoading, isFetchingNextPage, refetch, isFetching, data } = useQuery(
    [
      "group-report",
      {
        group,
        labels,
        spaces,
        pagesUnder: pagesUnder === "current-page" ? currentPageId : pagesUnder,
        filters: allPropertyFilters,
        limit: numberOfItems,
        labelsLogic,
      },
    ],
    getReport,
    {
      retry: 0,
      select: (response) => {
        const pages = [response];
        return { pages, pageParams: [response?.pageParams] };
      },
    }
  );

  const { data: groupRecords } = useQuery(["records-of-group", { group: groupId }], getRecordsForGroup, {
    select(response) {
      return response;
    },
  });

  const insertNewReportToData = ({ newReport, values, placeholders, newKey }) => {
    const modifiedValues = Object.values(values ?? {}).map((value, idx) => {
      const key = Object.keys(values)[idx];
      const placeholder = placeholders.find((placeholder) => {
        return placeholder.id == key;
      });
      return {
        value: value,
        data: placeholder.data,
        kind: placeholder.kind,
        id: key,
      };
    });

    Object.keys(values ?? {}).forEach((key, idx) => {
      newReport.records[newKey][key] = modifiedValues[idx];
    });
    data?.pages[0].data.report.push(newReport);
  };

  const onComputedHeight = (computedHeight) => {
    setTableHeight(computedHeight);
  };

  const onRecordsUpdate = async (groupId, values, pageResponse, recordId) => {
    const newKey = recordId ?? uuidv4();
    const newReport = {
      entityId: String(pageResponse.id),
      records: { [newKey]: {} },
    };

    let placeholders;
    try {
      const { data } = await placeholderService.getAllPlaceholdersOfSet(groupId);
      placeholders = data;
    } catch (error) {
      console.log(error);
    }

    insertNewReportToData({ newReport, values, placeholders, newKey });

    const newGroupReport = {
      resolvedProperties: data.pages[0].data.resolvedProperties,
      report: data.pages.flatMap((p) => p.data.report),
    };
    const newRecords = buildRecords(newGroupReport, true, recordId);

    setRecords(newRecords);
  };

  useEffect(() => {
    refetch();
  }, [group, labels, spaces]);

  useEffect(() => {
    if (rowsRendered) {
      sortRowsByProperty(defaultSortColumn);
    }
  }, [rowsRendered]);

  useEffect(() => {
    if (anyFiltersApplied()) {
      setHeader("We couldn't find any matches for your search.");
      setDescription("Try different keywords, fewer of them, or ones that are more general.");
    } else {
      setHeader("There are no records to display yet");
      setDescription("Select a different Property Group to generate a report on");
    }
  }, [group, labels, spaces, propertyFilters, propertyLiveFilters]);

  useEffect(() => {
    window.AP.theming.initializeTheming();
    const mutationObserver = new MutationObserver((entries) => {
      const tableElement = document.querySelector(".report-table");
      if (tableElement) {
        setTableAvailable(true);
        mutationObserver.disconnect();
      }
    });
    mutationObserver.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }, []);

  useEffect(() => {
    if (!defaultSortColumn || isLoading || isFetching || records?.length === 0) {
      return;
    }

    const tableElement = document.querySelector(".report-table");
    if (!tableElement) {
      return;
    }
    const rowElements = Array.from(tableElement.rows);

    const mutationObserver = new MutationObserver((entries) => {
      if (records?.length === rowElements?.length) {
        if (!rowsRendered) {
          setRowsRendered(true);
          mutationObserver.disconnect();
        }
      }
    });
    if (!rowsRendered) {
      mutationObserver.observe(tableElement, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }
  }, [isLoading, isFetching, records, tableAvailable]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const newRecords = buildRecords({
      resolvedProperties: data?.pages[0]?.data?.resolvedProperties,
      report: data?.pages?.map((p) => p.data.report).flat(),
    });
    setResolvedProperties(data?.pages[0]?.data?.resolvedProperties);
    setPages(data?.pages[0]?.data?.allEntitiesMatchingFilters);
    setHasNextPage(data?.pages[0]?.data?.allEntitiesMatchingFilters?.length > newRecords?.length);
    if (!isLoading && !isFetching) {
      setRecords(newRecords);
    }
  }, [isLoading, isFetching, selectedColumns]);

  useEffect(() => {
    if (!tableAvailable) {
      return;
    }
    setColumnToSortBy(defaultSortColumn);
    setSortOrder(defaultSortOrder);
    if (defaultSortColumn) {
      sortRowsByProperty(defaultSortColumn, defaultSortOrder);
    }
  }, [defaultSortColumn, defaultSortOrder, tableAvailable]);

  const getMoreEntriesForReport = async () => {
    if (pages?.length >= reportLength) {
      setReportLength(parseInt(reportLength) + parseInt(numberOfItems));
      const morePagesForReport = pages.slice(0, parseInt(reportLength) + parseInt(numberOfItems));
      const reportWithMoreEntries = await getPaginatedReport(group, resolvedProperties, morePagesForReport, numberOfItems);
      const moreRecords = buildRecords({
        resolvedProperties: reportWithMoreEntries?.data?.resolvedProperties,
        report: reportWithMoreEntries?.data?.report,
      });
      if (!isLoading && !isFetching) {
        setRecords(moreRecords);
      }
    }
  };

  const anyFiltersApplied = () => {
    if (propertyLiveFilters || propertyFilters || labels || spaces) {
      if (
        Object.values(propertyLiveFilters)?.length > 0 ||
        Object.values(propertyFilters)?.length > 0 ||
        Object.values(labels)?.length > 0 ||
        spaces?.length > 0
      ) {
        const propertyFilterValues = Object.values(propertyFilters);
        const propertyLiveFilterValues = Object.values(propertyLiveFilters);
        const labelValues = Object.values(labels);
        const hasAnyPropertyFilters = propertyFilterValues.some((input) => Object.keys(input)?.length > 0);
        const hasAnyPropertyLiveFilters = propertyLiveFilterValues.some((input) => Object.keys(input)?.length > 0);
        const hasAnyLabelFilters = labelValues.some((input) => input?.length > 0);
        return hasAnyPropertyFilters || hasAnyPropertyLiveFilters || hasAnyLabelFilters || spaces?.length > 0;
      }
    }
    return false;
  };

  const sortRowsByProperty = (propertyKey, newSortOrder = ReportSortOrder.DESC) => {
    const getVal = (rowElement) => {
      const sortableElement = rowElement.querySelector(`[data-property-key='${propertyKey}']`).querySelector(`[data-sortable=true]`);
      return sortableElement.dataset.rawValue;
    };
    const tableElement = document.querySelector(".report-table");
    if (!tableElement) {
      return;
    }
    const rowElements = Array.from(tableElement.rows);
    rowElements.shift();
    rowElements.sort((a, b) => (getVal(a) || "").localeCompare(getVal(b) || ""));
    if (newSortOrder === ReportSortOrder.DESC) {
      rowElements.reverse();
    }
    const sortedRows = [];
    rowElements.forEach((row) => {
      const sortID = row.dataset.sortIdentifier;
      sortedRows.push(records.find((r) => r.sortIdentifier === sortID));
    });
    setColumnToSortBy(propertyKey);
    setRecords(sortedRows);
  };

  const fillEmptyFieldsInEntries = ({ groupReport, isRecordUpdating, newRecordId }) => {
    const data = groupRecords?.data?.reverse() ?? [];
    const entriesWithEmptyFileds = [];
    const fakeRecordWithEmptyValues = {};
    const dummyKey = "dummyKey";
    const emptyRecordIndexes = [];
    let isEmptyRecordcreated = false;

    groupReport.report.forEach((recordsForEntity, index) => {
      if (JSON.stringify(recordsForEntity.records) === "{}") {
        emptyRecordIndexes.push(index);
      }
      if ((!isEmptyRecordcreated && JSON.stringify(recordsForEntity.records) !== "{}") || (!isEmptyRecordcreated && isRecordUpdating)) {
        const valuesData = Object.values(recordsForEntity.records)[0];
        fakeRecordWithEmptyValues[dummyKey] = Object.fromEntries(
          Object.entries(valuesData).map(([key, valueObject]) => [key, { ...valueObject, value: null }])
        );
        isEmptyRecordcreated = true;
      }

      entriesWithEmptyFileds.push(fakeRecordWithEmptyValues);
    });
    let createdRecord;
    const lastRecord = groupReport.report[groupReport.report?.length - 1];
    if (isRecordUpdating) {
      const valuesData = Object.values(lastRecord.records)[0];
      createdRecord = { ...fakeRecordWithEmptyValues[dummyKey], ...valuesData };
    }

    const filledEmptyEntries = entriesWithEmptyFileds.map((recordsForEntity, idx) => {
      const recordId = newRecordId ?? data?.[idx]?.recordId;
      if (emptyRecordIndexes.includes(idx)) {
        return { [recordId]: recordsForEntity[dummyKey] };
      }
      if (isRecordUpdating) {
        return { [recordId]: createdRecord ?? recordsForEntity[dummyKey] };
      }
      return {};
    });

    return filledEmptyEntries;
  };

  const buildRecords = (groupReport, isRecordUpdating = false, newRecordId) => {
    const valuesGroupedByRecordId = {};

    const modifiedEmptyEntries = fillEmptyFieldsInEntries({ groupReport, isRecordUpdating, newRecordId });

    const propertyValues = [];
    groupReport.report.forEach((recordsForEntity, idx) => {
      const entityId = recordsForEntity.entityId;
      const areEntryFieldsEmpty = Object.keys(recordsForEntity.records)?.length === 0;
      const isLastRecord = idx === groupReport.report?.length - 1;
      const pageElement = {
        columnname: "Page",
        info: {
          name: "Page",
          kind: "PAGE",
          id: entityId,
        },
        key: "page",
        content: <LazyPageLink key={entityId} pageId={entityId}></LazyPageLink>,
      };

      valuesGroupedByRecordId[entityId] = {};

      if (areEntryFieldsEmpty || (isLastRecord && isRecordUpdating)) {
        recordsForEntity.records = modifiedEmptyEntries[idx];
      }
      Object.keys(recordsForEntity.records).map((recordId) => {
        const recordForEntity = recordsForEntity.records[recordId] ?? {};
        const recordWithResolvedValues = {
          entityId,
          entries: [],
          sortIdentifier: generateId(),
        };

        const keys = selectedColumns?.length > 0 ? selectedColumns : Object.keys(recordForEntity);

        recordWithResolvedValues.entries = keys.map((propertyKey, index) => {
          const currentProperty = recordForEntity[propertyKey] || {};

          return {
            key: propertyKey,
            columnname: groupReport.resolvedProperties[propertyKey].data.name,
            info: groupReport.resolvedProperties[propertyKey],
            content: (
              <PropertyBasedOnKind
                key={currentProperty.id}
                currentProperty={currentProperty}
                pageId={entityId}
                recordId={recordId}
                viewStyles={{
                  marginLeft: "5px",
                }}
                disableEdit={disableEdit}
              />
            ),
            value: currentProperty?.value,
          };
        });

        recordWithResolvedValues.entries.forEach((entry) => {
          if (!(recordId in valuesGroupedByRecordId[entityId])) {
            valuesGroupedByRecordId[entityId][recordId] = {};
          }
          valuesGroupedByRecordId[entityId][recordId][entry.key] = entry.value;
        });

        recordWithResolvedValues.entries = [pageElement, ...recordWithResolvedValues.entries];
        propertyValues.push(recordWithResolvedValues);
      });
    });

    setValues(valuesGroupedByRecordId);
    setInitialValues(valuesGroupedByRecordId);

    const filteredDuplicateValues = propertyValues.filter(
      (propertyEntry, index, self) => index === self.findIndex((t) => t.entityId === propertyEntry.entityId)
    );

    return filteredDuplicateValues;
  };

  if (((isLoading || isFetching) && !isFetchingNextPage) || !data) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size={"large"} />
      </div>
    );
  }

  if (data.pages?.length === 0 || data.pages[0].data.report?.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        <EmptyState header={headerOfReport} description={descriptionOfReport} imageUrl={NoReportsForGroup} />
      </div>
    );
  }

  return (
    <div
      style={{
        minHeight: `${tableHeight ? tableHeight + "px" : "unset"}`,
        backgroundColor: token("utility.elevation.surface.current"),
      }}
    >
      <table className={`table-layout report-table ${disableEdit ? "table-preview" : ""}`}>
        <thead className="table-header">
          <tr>
            {records[0] &&
              records[0].entries.map((record) => {
                return (
                  <th
                    key={record.key}
                    onClick={(e) => {
                      const newSortOrder = sortOrder === ReportSortOrder.DESC ? ReportSortOrder.ASC : ReportSortOrder.DESC;
                      sortRowsByProperty(record.key, newSortOrder);
                      setSortOrder(newSortOrder);
                    }}
                  >
                    <Tooltip content={record?.info?.data?.description}>
                      <div>
                        <span>{FieldIcons(record.info.kind)}</span>
                        <span className="table-header-name">{record.columnname}</span>
                        {columnToSortBy && sortOrder === ReportSortOrder.ASC && (
                          <span
                            style={{
                              visibility: columnToSortBy === record.key ? "visible" : "hidden",
                            }}
                          >
                            <ChevronUpIcon />
                          </span>
                        )}
                        {columnToSortBy && sortOrder === ReportSortOrder.DESC && (
                          <span
                            style={{
                              visibility: columnToSortBy === record.key ? "visible" : "hidden",
                            }}
                          >
                            <ChevronDownIcon />
                          </span>
                        )}
                      </div>
                    </Tooltip>
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody className="table-body">
          {records.map((record, index) => {
            return (
              <TableRow
                key={`${record.entityId}-${index}`}
                entityId={record.entityId}
                entries={record.entries}
                sortIdentifier={record.sortIdentifier}
              />
            );
          })}
        </tbody>
      </table>

      {!disableEdit && addEntriesType !== addEntriesTypes.DISABLED && (
        <>
          {addEntriesType === addEntriesTypes.DIALOG ? (
            <CreateCard groupId={groupId} onCreateNewPage={onRecordsUpdate} onComputedHeight={onComputedHeight} name="New Entry" isEntry />
          ) : (
            <CreateEntry groupId={groupId} onRecordsUpdate={onRecordsUpdate} />
          )}
        </>
      )}
      {isFetchingNextPage && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size={"small"} />
        </div>
      )}
      {hasNextPage && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Button appearance="link" onClick={() => getMoreEntriesForReport()}>
            Show more
          </Button>
        </div>
      )}
    </div>
  );
};

export default PropertyGroupReportTable;
