export const PLACEHOLDER_KIND_STRING = "STRING";
export const PLACEHOLDER_KIND_SELECT = "SELECT";
export const PLACEHOLDER_KIND_MULTI_SELECT = "MULTI-SELECT";
export const PLACEHOLDER_KIND_DATE = "DATE";
export const PLACEHOLDER_KIND_TEXT_AREA = "TEXT-AREA";
export const PLACEHOLDER_KIND_USER_MENTION = "USER-MENTION";
export const PLACEHOLDER_KIND_CONFLUENCE_PAGE = "CONFLUENCEPAGE";
export const PLACEHOLDER_KIND_EMAIL = "EMAIL";
export const PLACEHOLDER_KIND_URL = "URL";
export const PLACEHOLDER_KIND_PHONE = "PHONE";
export const PLACEHOLDER_KIND_NUMBER = "NUMBER";
