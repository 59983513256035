import { PropertyType } from "../property-type";
import PageIcon from "@atlaskit/icon/glyph/page";
import PageFilledIcon from "@atlaskit/icon/glyph/page-filled";
import CalendarIcon from "@atlaskit/icon/glyph/calendar";
import CheckboxIcon from "@atlaskit/icon/glyph/checkbox";
import ArrowDownIcon from "@atlaskit/icon/glyph/arrow-down";
import EmojiKeyboardIcon from "@atlaskit/icon/glyph/emoji/keyboard";
import PersonCircleIcon from "@atlaskit/icon/glyph/person-circle";
import EmailIcon from "@atlaskit/icon/glyph/email";
import LinkIcon from "@atlaskit/icon/glyph/link";
import HipchatDialOutIcon from "@atlaskit/icon/glyph/hipchat/dial-out";
import { NumberIcon } from "./Icon/NumberIcon";
export const FieldIcons = (type) => {
  const size = "medium";
  return (
    <>
      {type === PropertyType.DATE && <CalendarIcon size={size} />}
      {type === PropertyType.MULTISELECT && <CheckboxIcon size={size} />}
      {type === PropertyType.SELECT && <ArrowDownIcon size={size} />}
      {type === PropertyType.TEXT && <EmojiKeyboardIcon size={size} />}
      {type === PropertyType.USER && <PersonCircleIcon size={size} />}
      {type === PropertyType.CONFLUENCEPAGE && <PageFilledIcon size={size} />}
      {type === PropertyType.EMAIL && <EmailIcon size={size} />}
      {type === PropertyType.URL && <LinkIcon size={size} />}
      {type === PropertyType.PHONE && <HipchatDialOutIcon size={size} />}
      {type === PropertyType.NUMBER && <NumberIcon />}
      {type === "PAGE" && <PageIcon size={size} />}
    </>
  );
};
