import { css } from "@emotion/react";
import { NavigationContent, SideNavigation, HeadingItem } from "@atlaskit/side-navigation";
import { Field, HelperMessage } from "@atlaskit/form";
import PropertyGroupPicker from "../Shared/PropertyGroupPicker";
import BoardVariantWrapper from "./BoardVariantWrapper";
import { usePropertyVisualizationStore } from "../property-visualization-store";
import { useBoardStore } from "./board-store";
import { ColumnPicker } from "./ColumnPicker";
import { ExpandState } from "./ExpandState";
import styled from "styled-components";
import { ParentPicker } from "./ParentPicker";
import { token } from "@atlaskit/tokens";

const HyperLinkContainer = styled.div`
  font-size: 0.8571428571428571em;
  font-style: inherit;
  line-height: 1.3333333333333333;
  font-weight: 600;
  margin-top: 16px;
  display: flex;
  display: inline-block;
  margin-top: 4px;
  justify-content: baseline;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: normal;
  color: #6b778c;
  & > :first-child {
    color: #0070f3;
    text-decoration: none;
  }
`;

const PropertyVisualizationSidebarBoardView = (props) => {
  const { config, updateConfig } = usePropertyVisualizationStore((s) => ({
    config: s.config.args,
    updateConfig: s.updateConfig,
  }));
  const { selectedGroup, setSelectedGroup } = useBoardStore((s) => ({
    selectedGroup: s.selectedGroup,
    setSelectedGroup: s.setSelectedGroup,
  }));

  const onPropertyGroupUpdate = (group) => {
    if (group) {
      updateConfig({ ...config, data: { ...config.data, group: group.id } });
      setSelectedGroup(group);
    }
  };

  const onSelectedPropertiesUpdate = (updateData) => {
    if (updateData) {
      updateConfig({ ...config, data: { ...config.data, selectedProperties: updateData } });
    }
  };
  const onExpandStateUpdate = (updateData) => {
    if (updateData) {
      updateConfig({ ...config, data: { ...config.data, expandState: updateData } });
    }
  };
  const onParentPickerUpdate = (updateData) => {
    if (updateData) {
      updateConfig({ ...config, data: { ...config.data, isPickable: updateData } });
    }
  };

  return (
    <div className="content-sidebar" style={{ width: "28em", borderRight: "1px solid #ccc" }}>
      <SideNavigation>
        <div style={{ display: "flex", flexDirection: "column", backgroundColor: token("utility.elevation.surface.current") }}>
          <NavigationContent
            css={css`
              margin: 0;
              margin-left: 20px;
              margin-right: 20px;
            `}
          >
            <div style={{ margin: 0, marginLeft: 15, marginRight: 20, height: "80vh" }}>
              <Field label="Property Group" name="select group" defaultValue="">
                {() => (
                  <>
                    <PropertyGroupPicker initialValue={config?.data?.group} onUpdate={onPropertyGroupUpdate} />
                    <HelperMessage>Select the property group you want to generate visualizations for</HelperMessage>
                  </>
                )}
              </Field>

              {config?.data?.group ? (
                <>
                  <HeadingItem cssFn={() => ({ paddingLeft: 0 })}>Configuration</HeadingItem>
                  <HelperMessage>Choose a select property to be the base of the board</HelperMessage>
                  {(!config.data?.variant || config.data.variant === "board") && <BoardVariantWrapper />}
                </>
              ) : null}

              {config?.data?.group ? (
                <Field label="Displayed properties" name="select properties" defaultValue="">
                  {() => (
                    <>
                      <ColumnPicker
                        selectedGroup={selectedGroup}
                        onUpdate={onSelectedPropertiesUpdate}
                        initialValues={config?.data?.selectedProperties}
                      />
                      <HelperMessage>Select which properties are to be displayed in the board</HelperMessage>
                    </>
                  )}
                </Field>
              ) : null}
              {config?.data?.group ? (
                <Field label="Default state" name="select default state" defaultValue="">
                  {() => (
                    <>
                      <ExpandState onUpdate={onExpandStateUpdate} initialValues={config?.data?.expandState} />

                      <HyperLinkContainer>
                        <a target="_blank" rel="noopener noreferrer" href="https://junovi.atlassian.net/l/cp/wGctiQcc">
                          Learn more
                        </a>
                        <span> about the different display options</span>
                      </HyperLinkContainer>
                    </>
                  )}
                </Field>
              ) : null}
              {config?.data?.group ? (
                <Field label="Choose creation mode" name="select parent page" defaultValue="">
                  {() => (
                    <>
                      <ParentPicker onUpdate={onParentPickerUpdate} initialValues={config?.data?.isPickable} />
                      <HelperMessage>Select how new page will be created</HelperMessage>
                    </>
                  )}
                </Field>
              ) : null}
            </div>
          </NavigationContent>
        </div>
      </SideNavigation>
    </div>
  );
};

export default PropertyVisualizationSidebarBoardView;
