import { useEffect, useState } from "react";
import { DatePicker } from "@atlaskit/datetime-picker";
import { usePropertyGroupReportUIStore } from "../../PropertyReportMacro/store";

const DatePropertyEdit = ({ property, initialValue, autoFocus, onUpdate }) => {
  const [date, setDate] = useState(initialValue);
  const { loadedLiveFilter } = usePropertyGroupReportUIStore((state) => ({
    loadedLiveFilter: state.loadedLiveFilter,
  }));
  useEffect(() => {
    if (initialValue) {
      setDate(initialValue);
    }
  }, [initialValue]);

  if (property.data?.displayLastModified) {
    return <p style={{ fontSize: "14px", marginLeft: "2px" }}>Displays the last modified date</p>;
  } else if (property.data?.displayCreationDate) {
    return <p style={{ fontSize: "14px", marginLeft: "2px" }}>Displays the page creation date</p>;
  }
  return (
    <div className="property-date-picker" style={{ fontSize: "14px", fontWeight: "400" }}>
      <DatePicker
        autoFocus={autoFocus}
        locale={navigator.language}
        value={date}
        dateFormat="MMM DD, YYYY"
        onChange={(newDate) => {
          setDate(newDate);
          onUpdate(newDate);
        }}
        onFocus={() => {
          if (loadedLiveFilter) {
            window.AP.events.emit("trigger_resize_livefilter", [".property-inline-dialog"]);
          } else {
            window.AP.events.emit("trigger_resize", [".property-date-picker>div>div>div:last-child"]);
          }
        }}
        placeholder={property?.data?.description}
      />
    </div>
  );
};

export default DatePropertyEdit;
