export const PropertyType = {
  TEXT: "STRING",
  DATE: "DATE",
  SELECT: "SELECT",
  MULTISELECT: "MULTI-SELECT",
  USER: "USER-MENTION",
  CONFLUENCEPAGE: "CONFLUENCEPAGE",
  EMAIL: "EMAIL",
  URL: "URL",
  PHONE: "PHONE",
  NUMBER: "NUMBER",
};
