import React from "react";
import PropTypes from "prop-types";
import Form from "@atlaskit/form";
import { FormFooter } from "@atlaskit/form/FormFooter";
import { PanelTitle } from "../Fields/PanelTitle";
import { NameField } from "../Fields/NameField";
import { DescriptionField } from "../Fields/DescriptionField";
import { usePlaceholderIcon } from "../usePlaceholderIcon";
import { PLACEHOLDER_KIND_STRING } from "../PlaceholderKinds";
import { CollapsibleContainer } from "../CollapsibleContainer";
import { DeleteProperty } from "../DeletePlaceholder";
import { useLifecycleSetCollapsed } from "./useLifecycleSetCollapsed";

StringPlaceholderInPortal.propTypes = {
  placeholder: PropTypes.object.isRequired,
};

export function StringPlaceholderInPortal({ placeholder }) {
  const { collapsed, setCollapsed } = useLifecycleSetCollapsed({ placeholder });

  return (
    <CollapsibleContainer
      Title={<PanelTitle placeholder={placeholder} />}
      Icon={usePlaceholderIcon(PLACEHOLDER_KIND_STRING, "small")}
      collapsed={collapsed}
      onCollapseClick={setCollapsed}
    >
      <Form>
        {({ formProps }) => (
          <form {...formProps} name="string-placeholder-from-example">
            <NameField placeholder={placeholder} />
            <DescriptionField placeholder={placeholder} />
            <FormFooter>
              <DeleteProperty property={placeholder} />
            </FormFooter>
          </form>
        )}
      </Form>
    </CollapsibleContainer>
  );
}
