import VidFullScreenOnIcon from "@atlaskit/icon/glyph/vid-full-screen-on";
import EditorAlignImageCenterIcon from "@atlaskit/icon/glyph/editor/align-image-center";
import MediaServicesZoomInIcon from "@atlaskit/icon/glyph/media-services/zoom-in";
import MediaServicesZoomOutIcon from "@atlaskit/icon/glyph/media-services/zoom-out";
import styled from "styled-components";

const IconWrapper = styled.div`
  cursor: pointer;
  color: #7c7b7b;
  display: flex;
  &:hover {
    color: #000;
  }
`;

const TopBar = ({ onZoomIn, onZoomOut, onFitContent, onlyFullScreen = false, customData }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        border: "1px solid #cecece",
        borderBottom: "none",
      }}
    >
      {!onlyFullScreen ? (
        <>
          <IconWrapper onClick={onZoomIn}>
            <MediaServicesZoomInIcon size="medium" />
          </IconWrapper>
          <IconWrapper onClick={onZoomOut}>
            <MediaServicesZoomOutIcon size="medium" />
          </IconWrapper>
          <IconWrapper onClick={onFitContent}>
            <EditorAlignImageCenterIcon size="medium" />
          </IconWrapper>
        </>
      ) : null}
      <IconWrapper
        onClick={() => {
          window.AP.require("dialog", function (dialog) {
            dialog
              .create({
                header: "Property Group Visualization",
                chrome: true,
                size: "fullscreen",
                key: "junovi-viewer",
                customData,
              })
              .on("close", () => {
                window.AP.events.emit(`isFullScreenClosed`);
              });
            window.AP.dialog.getButton("submit").hide();
          });
        }}
      >
        <VidFullScreenOnIcon size="medium" />
      </IconWrapper>
    </div>
  );
};

export default TopBar;
