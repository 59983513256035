import { useEffect, useState, useRef } from "react";
import { Route, Router, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { initAtlassianScript } from "./v2/confluence";
import { NotFound } from "./v2/Components/PropertyPlaceholderPortal/NotFound";
import { PlaceHolderPortal } from "./v2/Components/PropertyPlaceholderPortal/PlaceholderPortal";
import { QueryClient, QueryClientProvider } from "react-query";
import PropertyGroupLog from "./v2/Components/PropertyGroupLog/PropertyGroupLog";
import PropertyGroupMacroEditor from "./v2/Components/PropertyGroupMacro/PropertyGroupMacroEditor";
import PropertyGroupMacro from "./v2/Components/PropertyGroupMacro/PropertyGroupMacro";
import PropertyGroupReportMacroEditor from "./v2/Components/PropertyReportMacro/PropertyGroupReportMacroEditor";
import PropertyGroupReportMacro from "./v2/Components/PropertyReportMacro/PropertyGroupReportMacro";
import { InvalidLicenseEditor, InvalidLicenseView } from "./v2/Components/InvalidLicense";
import { FreeVersionInvalidEditor, FreeVersionInvalidView } from "./v2/Components/FreeVersionInvalid";
import PropertyVisualizationMacro from "./v2/Components/PropertyVisualizationMacro/PropertyVisualizationMacro";
import PropertyVisualizationMacroEditor from "./v2/Components/PropertyVisualizationMacro/PropertyVisualizationMacroEditor";
import Viewer from "./v2/Components/Viewer";

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function css(element, style) {
  for (const property in style) {
    element.style[property] = style[property];
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [apLoaded, setApLoaded] = useState(false);

  const macro = useRef("");

  const isViewMode = (context) => {
    return (
      context.confluence &&
      context.confluence.macro &&
      (context.confluence.macro.outputType === "display" || context.confluence.macro.outputType === "preview")
    );
  };

  useEffect(() => {
    (async () => {
      const isAtlassianScriptInitialized = await initAtlassianScript();
      const atlassianConnectContentElement = document.getElementsByClassName("ac-content")[0];
      const context = await window.AP.context.getContext();

      if (isViewMode(context)) {
        atlassianConnectContentElement.classList.remove("hide");
        setApLoaded(isAtlassianScriptInitialized);
        return;
      }

      macro.current = getParameterByName("macro");
      const mode = getParameterByName("mode");
      if (mode === "custom-macro-editor") {
        atlassianConnectContentElement.classList.remove("hide");
        if (atlassianConnectContentElement) {
          css(atlassianConnectContentElement, {
            height: macro.current === "property-group" ? "80vh" : "95vh",
            width: macro.current === "property-group" ? "70%" : "95%",
            margin: macro.current === "property-group" ? "7vh auto" : "2.5vh auto",
            background: "white",
          });
        }
      }
      if (mode === "editor") {
        window.AP.dialog
          .create({
            key: "custom-macro-editor",
            width: "100%",
            height: "100%",
            customData: {
              macro: macro.current,
            },
          })
          .on("close", () => {
            window.AP.confluence.closeMacroEditor();
          });
        setApLoaded(isAtlassianScriptInitialized);
      } else {
        setApLoaded(isAtlassianScriptInitialized);
        atlassianConnectContentElement.classList.remove("hide");
      }
    })();
  }, []);

  return apLoaded === false ? (
    <div>Loading AP</div>
  ) : (
    <Router history={createBrowserHistory()}>
      <Switch>
        <Route path="/invalid-license-view">
          <InvalidLicenseView />
        </Route>
        <Route path="/invalid-license-editor">
          <InvalidLicenseEditor />
        </Route>
        <Route path="/invalid-version-view">
          <FreeVersionInvalidView />
        </Route>
        <Route path="/invalid-version-editor">
          <FreeVersionInvalidEditor />
        </Route>
        <Route path="/generalPages/properties-overview">
          <PlaceHolderPortal />
        </Route>
        <Route path="/dynamicContentMacros/property-group-form-macro">
          <QueryClientProvider client={queryClient}>
            <PropertyGroupMacro />
          </QueryClientProvider>
        </Route>
        <Route path="/dynamicContentMacros/property-group-form/editor">
          <PropertyGroupMacroEditor />
        </Route>
        <Route path="/dynamicContentMacros/property-report-macro">
          <PropertyGroupReportMacro />
        </Route>
        <Route path="/dynamicContentMacros/property-report/editor">
          <PropertyGroupReportMacroEditor />
        </Route>
        <Route path="/property-group-log-dialog">
          <PropertyGroupLog />
        </Route>
        <Route path="/dynamicContentMacros/property-visualization-macro">
          <PropertyVisualizationMacro />
        </Route>
        <Route path="/dynamicContentMacros/property-visualization/editor">
          <PropertyVisualizationMacroEditor />
        </Route>
        <Route path="/viewer">
          <Viewer />
        </Route>
        <Route path="/">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
