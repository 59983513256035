import styled from "styled-components";
import { fontColorAccordingToHex } from "../font-color-according-to-hex";

const SelectTag = styled.span`
  background-color: ${(props) => (props.color ? `${props.color};` : `rgb(244, 245, 247);`)};
  color: rgb(37, 56, 88);
  border-radius: 3px;
  cursor: default;
  display: flex;
  line-height: 1;
  margin: 4px;
  padding: 0px;
  overflow: initial;
  margin: 3px;
`;

const SelectTagText = styled.span`
  font-size: ${(props) => (props?.property?.data?.isUppercase ? `12px` : `14px`)}
  font-weight: 700;
  text-transform: ${(props) => (props?.property?.data?.isUppercase ? `uppercase` : `initial`)};
  line-height: 1.1;
  margin: 0 4px;
  padding: 2px 0;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  width: fit-content;
  white-space: nowrap;
`;

const SelectOptions = ({ values, property, single = false, name }) => {
  return (
    <div
      className={single ? "single-select-property-value" : "multiselect-property-value"}
      data-sortable={true}
      data-raw-value={values.map((o, idx) => o.value).join()}
      style={{ display: "flex", flexFlow: "row wrap", marginLeft: "3px" }}
    >
      {values.map((o, idx) => {
        const textColor = fontColorAccordingToHex(o.color);
        return (
          <SelectTag key={idx} color={o.color}>
            <SelectTagText style={{ color: textColor }} property={property}>
              {name || o.value}
            </SelectTagText>
          </SelectTag>
        );
      })}
    </div>
  );
};

export default SelectOptions;
