import React from "react";

import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { BPCIcon } from "./BPCIcon";

export const IconPicker = (props) => {
  const { onClose, onSubmit, visible } = props;

  const handleSubmit = (id, e) => {
    onSubmit(id);
    onClose(e);
  };

  return (
    <ModalTransition>
      {visible && (
        <Modal
          css={{
            position: "relative",
          }}
          height="385px"
          width="300px"
          onClose={onClose}
        >
          <div style={{ padding: "20px 0 20px 0", overflow: "auto" }}>
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
                justifyItems: "center",
                gridTemplateColumns: "repeat(3, 1fr)",
                gridGap: "10px",
              }}
            >
              {[...Array(25)].map((_, i) => (
                <BPCIcon onClick={(e) => handleSubmit(`ava${i + 1}`, e)} key={i} icon={`ava${i + 1}`} size="48" />
              ))}
            </div>
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};
