import { LazyPageLink } from "../../PropertyReportMacro/LazyPageLink";

export const MultiPageSelect = ({ pageIds }) => {
  return (
    <div style={{ display: "flex", flexFlow: "row wrap", rowGap: "5px" }}>
      {pageIds?.map((pageId) => {
        return <LazyPageLink key={pageId} pageId={pageId}></LazyPageLink>;
      })}
    </div>
  );
};
