import { useEffect, useState } from "react";
import { usePropertyVisualizationStore } from "../property-visualization-store";
import { propertyRecordsService } from "../../../Service/PropertyRecordsService";
import BoardVisualization from "./BoardVisualization";
import useBoardData from "./useBoardData";
import { useBoardStore } from "./board-store";

const BoardVisualizationContainer = ({ shouldDisableDragDrop = false, predefinedStructure = undefined, viewType }) => {
  const [storedData, setStoredData] = useState([]);

  let config = usePropertyVisualizationStore((state) => state.config);
  if (predefinedStructure) {
    config = predefinedStructure;
  }
  const { group: groupId, args, selectedProperties: initialProperties, expandState, isPickable } = config?.args?.data || {};
  const { id: fieldId, label: selectLabel } = args || {};

  const { selectedProperties } = useBoardStore((state) => ({
    selectedProperties: state.selectedProperties,
  }));

  const { isLoading, selectFieldLoading, data, selectedField, refetch } = useBoardData({
    groupId,
    fieldId,
    selectedProperties: initialProperties || selectedProperties,
    storedData,
    setStoredData,
  });

  const onSave = async ({ pageId, groupId, recordId, valuesDestination }) => {
    const context = await window.AP.context.getContext();
    return await propertyRecordsService.createOrUpdateRecord(
      pageId,
      groupId,
      recordId,
      valuesDestination,
      context.confluence.content.version
    );
  };

  const onCreateNewPage = () => {
    refetch();
  };

  useEffect(() => {
    if ((storedData?.length === 0 || typeof storedData === "undefined") && data?.length > 0) {
      setStoredData(data);
    }

    window.AP.events.on(`isFullScreenClosed`, function () {
      refetch();
    });
  }, [data]);

  return (
    <BoardVisualization
      viewType={viewType}
      groupId={groupId}
      isLoading={isLoading}
      onSave={onSave}
      selectFieldLoading={selectFieldLoading}
      selectedField={selectedField}
      storedData={storedData}
      shouldDisableDragDrop={shouldDisableDragDrop}
      expandState={expandState}
      isPickable={isPickable}
      selectLabel={selectLabel}
      onCreateNewPage={onCreateNewPage}
    />
  );
};

export default BoardVisualizationContainer;
