import styled from "styled-components";
import React, { useEffect, useState } from "react";
import MoreIcon from "@atlaskit/icon/glyph/more";
import LockIcon from "@atlaskit/icon/glyph/lock";
import UnlockIcon from "@atlaskit/icon/glyph/unlock";
import Button, { ButtonGroup } from "@atlaskit/button";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import UsernameWithOptionalAvatar from "../UsernameWithOptionalAvatar";
import { EditPlaceholderSetModal } from "../../../PlaceholderSetModal/EditPlaceholderSetModal";
import { DeletePlaceholderSetModal } from "../../../PlaceholderSetModal/DeletePlaceholderSetModal";
import PermissionDialog from "./PermissionDialog";
import { BPCIcon } from "../../../../Icon/BPCIcon";
import { FormattedDate } from "../../../../FormattedDate";
import { usePlaceholderPortalStore } from "../../../Store";
import { IconPicker } from "../../../../Icon/IconPicker";
import { placeholderSetService } from "../../../../../Service/PlaceholderSetService";

const DescriptionContainer = styled.div`#
  margin-left: 5px;
  margin-top: 10px;
  max-height: 60px;
  overflow-y: hidden;
  &:hover {
    overflow-y: overlay;
  }
`;

PlaceholderSetHeader.MainHeader = ({
  selectedPlaceholderSet,
  restricted,
  setEditPlaceholderSetModalIsVisible,
  setDeletePlaceholderSetModalIsVisible,
  editPlaceholderSetModalIsVisible,
  deletePlaceholderSetModalIsVisible,
  setOpenPermissionDialog,
  openPermissionDialog,
  isContentDisplayAllowed,
  icon,
  iconPickerModalVisible,
  setIconPickerModalVisible,
  handleOnIconPickerSubmit,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <EditPlaceholderSetModal
        {...selectedPlaceholderSet}
        onClose={() => setEditPlaceholderSetModalIsVisible(false)}
        visible={editPlaceholderSetModalIsVisible}
      />
      <DeletePlaceholderSetModal
        {...selectedPlaceholderSet}
        onClose={() => setDeletePlaceholderSetModalIsVisible(false)}
        visible={deletePlaceholderSetModalIsVisible}
      />
      <div style={{ flex: "1" }}>
        <div style={{ display: "flex", alignItems: "center" }} onClick={() => setIconPickerModalVisible(true)}>
          <BPCIcon icon={icon} />
          <div>
            <IconPicker
              onClose={(e) => {
                e?.stopPropagation();
                setIconPickerModalVisible(false);
              }}
              onSubmit={handleOnIconPickerSubmit}
              visible={iconPickerModalVisible}
            />
          </div>
          <h2 style={{ marginLeft: "10px", marginTop: 0, marginBottom: 0 }}>{selectedPlaceholderSet?.name}</h2>
        </div>
      </div>
      {isContentDisplayAllowed && (
        <div style={{ display: "contents" }}>
          {!restricted ? (
            <div>
              <ButtonGroup>
                <Button iconBefore={<UnlockIcon />} onClick={() => setOpenPermissionDialog(true)} />
              </ButtonGroup>
            </div>
          ) : (
            <div>
              <ButtonGroup>
                <Button iconBefore={<LockIcon primaryColor={"red"} />} onClick={() => setOpenPermissionDialog(false)} />
              </ButtonGroup>
            </div>
          )}

          {!restricted && (
            <DropdownMenu triggerButtonProps={{ iconBefore: <MoreIcon label="more" /> }} triggerType="button">
              <DropdownItemGroup>
                <DropdownItem onClick={() => setEditPlaceholderSetModalIsVisible(true)}>Edit</DropdownItem>
                <DropdownItem onClick={() => setDeletePlaceholderSetModalIsVisible(true)}>Delete</DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          )}
        </div>
      )}
      <PermissionDialog
        {...selectedPlaceholderSet}
        isUserRestricted={restricted}
        onClose={() => setOpenPermissionDialog(false)}
        openDrawer={() => setEditPlaceholderSetModalIsVisible(true)}
        visible={openPermissionDialog}
      />
    </div>
  );
};
PlaceholderSetHeader.SubHeader = ({ selectedPlaceholderSet }) => {
  const isModified = selectedPlaceholderSet?.modified && selectedPlaceholderSet?.modifiedBy;
  const accountID = isModified ? selectedPlaceholderSet?.modifiedBy : selectedPlaceholderSet?.createdBy;
  const prefix = isModified ? "Last modified by" : "Created by";
  const after = isModified ? (
    <span>
      &nbsp;on&nbsp;
      <FormattedDate seconds={selectedPlaceholderSet.modified?._seconds} nanoseconds={selectedPlaceholderSet.modified?._nanoseconds} />
    </span>
  ) : (
    <span>
      &nbsp;on&nbsp;
      <FormattedDate seconds={selectedPlaceholderSet.created?._seconds} nanoseconds={selectedPlaceholderSet.created?._nanoseconds} />
    </span>
  );
  const renderAvatar = <UsernameWithOptionalAvatar withAvatar accountID={accountID} prefix={prefix} after={after} />;

  return (
    <div style={{ marginLeft: "10px", marginTop: "10px" }}>
      <div style={{ lineHeight: "1.5", fontSize: "12px", color: "rgb(107, 119, 140)" }}>
        <span style={{ display: "flex" }}>{renderAvatar}</span>
      </div>
      <DescriptionContainer>{selectedPlaceholderSet?.description}</DescriptionContainer>
    </div>
  );
};

export function PlaceholderSetHeader() {
  const [editPlaceholderSetModalIsVisible, setEditPlaceholderSetModalIsVisible] = useState(false);
  const [deletePlaceholderSetModalIsVisible, setDeletePlaceholderSetModalIsVisible] = useState(false);
  const [openPermissionDialog, setOpenPermissionDialog] = useState(false);
  const [iconPickerModalVisible, setIconPickerModalVisible] = useState(false);
  const { selectedPlaceholderSet, restricted, isContentDisplayAllowed, updatePlaceholderSet, icon, setIcon } = usePlaceholderPortalStore(
    (state) => ({
      selectedPlaceholderSet: state.selectedPlaceholderSet,
      restricted: state.restricted,
      isContentDisplayAllowed: state.isContentDisplayAllowed,
      updatePlaceholderSet: state.updatePlaceholderSet,
      icon: state.icon || state.selectedPlaceholderSet?.icon,
      setIcon: state.setIcon,
    })
  );
  const handleOnIconPickerSubmit = async (iconId) => {
    setIcon(iconId);
    setIconPickerModalVisible(false);
    const placeholderSet = {
      name: selectedPlaceholderSet?.name,
      description: selectedPlaceholderSet?.description,
      icon: iconId,
      groupOwners: selectedPlaceholderSet?.groupOwners,
      userOwners: selectedPlaceholderSet?.userOwners,
    };
    const { data, status } = await placeholderSetService.editPlaceholderSet(selectedPlaceholderSet.id, placeholderSet);
    if (status !== 200) {
      window.AP.flag.create({
        title: "Error",
        body: ` an error occurred updating ${selectedPlaceholderSet.name}`,
        type: "error",
      });
    } else {
      updatePlaceholderSet(selectedPlaceholderSet.id, placeholderSet);
      window.AP.flag.create({
        title: "Success",
        body: `${selectedPlaceholderSet.name} updated`,
        type: "success",
        close: "auto",
      });
    }
  };

  if (!selectedPlaceholderSet) {
    return <div></div>;
  }
  return (
    <>
      <PlaceholderSetHeader.MainHeader
        selectedPlaceholderSet={selectedPlaceholderSet}
        restricted={restricted}
        isContentDisplayAllowed={isContentDisplayAllowed}
        setEditPlaceholderSetModalIsVisible={setEditPlaceholderSetModalIsVisible}
        setDeletePlaceholderSetModalIsVisible={setDeletePlaceholderSetModalIsVisible}
        editPlaceholderSetModalIsVisible={editPlaceholderSetModalIsVisible}
        deletePlaceholderSetModalIsVisible={deletePlaceholderSetModalIsVisible}
        setOpenPermissionDialog={setOpenPermissionDialog}
        openPermissionDialog={openPermissionDialog}
        icon={icon}
        iconPickerModalVisible={iconPickerModalVisible}
        setIconPickerModalVisible={setIconPickerModalVisible}
        handleOnIconPickerSubmit={handleOnIconPickerSubmit}
      />
      <PlaceholderSetHeader.SubHeader selectedPlaceholderSet={selectedPlaceholderSet} />
    </>
  );
}
