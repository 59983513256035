import {
  PLACEHOLDER_KIND_DATE,
  PLACEHOLDER_KIND_MULTI_SELECT,
  PLACEHOLDER_KIND_CONFLUENCE_PAGE,
  PLACEHOLDER_KIND_SELECT,
  PLACEHOLDER_KIND_STRING,
  PLACEHOLDER_KIND_TEXT_AREA,
  PLACEHOLDER_KIND_USER_MENTION,
  PLACEHOLDER_KIND_EMAIL,
  PLACEHOLDER_KIND_URL,
  PLACEHOLDER_KIND_PHONE,
  PLACEHOLDER_KIND_NUMBER,
} from "./PlaceholderKinds";
import CalendarFilledIcon from "@atlaskit/icon/glyph/calendar-filled";
import React from "react";
import CheckboxIcon from "@atlaskit/icon/glyph/checkbox";
import ArrowDownIcon from "@atlaskit/icon/glyph/arrow-down";
import EmojiKeyboardIcon from "@atlaskit/icon/glyph/emoji/keyboard";
import EditorAlignLeftIcon from "@atlaskit/icon/glyph/editor/align-left";
import PersonCircleIcon from "@atlaskit/icon/glyph/person-circle";
import PageFilledIcon from "@atlaskit/icon/glyph/page-filled";
import EmailIcon from "@atlaskit/icon/glyph/email";
import LinkIcon from "@atlaskit/icon/glyph/link";
import HipchatDialOutIcon from "@atlaskit/icon/glyph/hipchat/dial-out";
import { NumberPlaceholderIcon } from "./NumberPlaceholderIcon";

export const usePlaceholderIcon = (kind, size) => {
  return (
    <>
      {kind === PLACEHOLDER_KIND_DATE && <CalendarFilledIcon size={size} />}
      {kind === PLACEHOLDER_KIND_MULTI_SELECT && <CheckboxIcon size={size} />}
      {kind === PLACEHOLDER_KIND_SELECT && <ArrowDownIcon size={size} />}
      {kind === PLACEHOLDER_KIND_STRING && <EmojiKeyboardIcon size={size} />}
      {kind === PLACEHOLDER_KIND_TEXT_AREA && <EditorAlignLeftIcon size={size} />}
      {kind === PLACEHOLDER_KIND_USER_MENTION && <PersonCircleIcon size={size} />}
      {kind === PLACEHOLDER_KIND_CONFLUENCE_PAGE && <PageFilledIcon size={size} />}
      {kind === PLACEHOLDER_KIND_EMAIL && <EmailIcon size={size} />}
      {kind === PLACEHOLDER_KIND_URL && <LinkIcon size={size} />}
      {kind === PLACEHOLDER_KIND_PHONE && <HipchatDialOutIcon size={size} />}
      {kind === PLACEHOLDER_KIND_NUMBER && <NumberPlaceholderIcon />}
    </>
  );
};
