import { useRef } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import FlatToNested from "flat-to-nested";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrop } from "react-dnd";

import { useSystemPropertiesUIStore } from "./chart-visualization-store";

import styled from "styled-components";
import LazyLoadingUser, { DraggableDroppableLazyLoadingUser } from "./LazyLoadingUser";
import LazyLoadingSpace, { DraggableDroppableLazyLoadingSpace } from "./LazyLoadingSpace";
import { useEffect } from "react";
import { usePropertyVisualizationStore } from "../property-visualization-store";

const TreeWrapper = styled.div`
  > ul > li > ul {
    &::before {
      visibility: ${({ isDragging }) => (isDragging ? "show" : "hidden")};
    }
  }
`;
const PseudoRootNode = ({ visible, isDraggingRef }) => {
  const [, drop] = useDrop(() => ({
    accept: ["user"],
    drop: () => ({ id: "0" }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging,
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  if (!visible) {
    return <span className="pseudo-root" style={{ display: "block", margin: "0 auto", width: "1px" }}></span>;
  }

  return <span ref={drop}>ROOT</span>;
};

const NodeBasedOnType = ({ parent, element, isEdit }) => {
  const { selected, setSelected } = useSystemPropertiesUIStore((state) => ({
    selected: state.selected,
    setSelected: state.setSelected,
    isDragging: state.isDragging,
    setDragging: state.setIsDragging,
  }));

  const { config, updateConfig } = usePropertyVisualizationStore((state) => ({
    config: state.config.args,
    updateConfig: state.updateConfig,
  }));

  const isDraggingRef = useRef(false);

  if (parent === undefined) {
    return <PseudoRootNode />;
  }

  if (element.type === "user") {
    if (isEdit) {
      return (
        <DraggableDroppableLazyLoadingUser
          accountId={element?.id}
          selected={selected?.id === element?.id}
          onClick={() => {
            if (isEdit) {
              setSelected(selected?.id === element.id ? undefined : element);
            }
          }}
          onDragStart={() => {}}
          onDrop={(source, target) => {
            isDraggingRef.current = false;
            if (source === target) {
              return;
            }
            const updatedAssignments = JSON.parse(JSON.stringify(config.data.args.assignments));
            const userToMove = updatedAssignments.find((a) => a.id === source);
            userToMove.parent = target;

            updateConfig({
              data: {
                ...config.data,
                args: {
                  ...config.data.args,
                  assignments: updatedAssignments,
                },
              },
            });
          }}
        />
      );
    }
    return (
      <>
        <LazyLoadingUser
          accountId={element?.id}
          selected={selected?.id === element?.id}
          onClick={() => {
            window.open(`${window.AP._hostOrigin}/wiki/people/${element.id}`, "_blank");
          }}
        />
      </>
    );
  }
  if (element.type === "space") {
    if (isEdit) {
      return (
        <DraggableDroppableLazyLoadingSpace
          spaceKey={element?.id}
          selected={selected?.id === element?.id}
          onClick={() => {
            setSelected(selected?.id === element.id ? undefined : element);
          }}
          onDrop={(source, target) => {
            if (source === target) {
              return;
            }
            const updatedAssignments = JSON.parse(JSON.stringify(config.data.args.assignments));
            const spaceToMove = updatedAssignments.find((a) => a.id === source);
            spaceToMove.parent = target;

            updateConfig({
              data: {
                ...config.data,
                args: {
                  ...config.data.args,
                  assignments: updatedAssignments,
                },
              },
            });
          }}
        />
      );
    }
    return (
      <>
        <LazyLoadingSpace
          spaceKey={element.id}
          onClick={() => {
            window.open(`${window.AP._hostOrigin}/wiki/spaces/${element.id}/overview`, "_blank");
          }}
        />
      </>
    );
  }
};

const RootTree = (props) => {
  return (
    <TreeWrapper className="tree-wrapper">
      <Tree {...props} lineWidth={"2px"} lineColor={"#bbc"} lineBorderRadius={"12px"}>
        {props.children}
      </Tree>
    </TreeWrapper>
  );
};

function Node({ o, parent, isEdit, children }) {
  const T = parent ? TreeNode : RootTree;
  return (
    <T label={<NodeBasedOnType parent={parent} element={o} isEdit={isEdit} />}>
      {(o.children ?? []).map((c) => (
        <Node o={c} parent={o} isEdit={isEdit} key={c.id}>
          {children}
        </Node>
      ))}
    </T>
  );
}

const SystemPropertiesStructureVisualization = ({ isEdit = false, predefinedStructure = undefined, onLoaded = () => {} }) => {
  const config = usePropertyVisualizationStore((state) => state.config.args) ?? predefinedStructure;

  useEffect(() => {
    let counter = 0;
    const mutationObserver = new MutationObserver((entries) => {
      counter += 1;
      if (counter >= config.data?.args?.assignments?.length - 1) {
        const pseudoRoot = document.querySelector(".tree-wrapper .pseudo-root");
        if (pseudoRoot) {
          onLoaded();
          mutationObserver.disconnect();
        }
      }
    });
    mutationObserver.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }, []);

  if (!config?.data?.args?.assignments) {
    return <></>;
  }

  const structure = new FlatToNested().convert(JSON.parse(JSON.stringify(config.data.args.assignments)));

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Node isEdit={isEdit} o={structure} />
      </DndProvider>
    </>
  );
};

export default SystemPropertiesStructureVisualization;
