import React, { useState } from "react";
import Button from "@atlaskit/button";
import EmptyState from "@atlaskit/empty-state";
import { NO_PROPERTY_GROUP_SELECTED_OR_EMPTY } from "../../../v2/empty-states-resources";
import { PropertyType } from "../../property-type";
import TextPropertyEdit from "../PropertyGroupMacro/editor/TextPropertyEdit";
import DatePropertyEdit from "../PropertyGroupMacro/editor/DatePropertyEdit";
import ConfluencePagePropertyEdit from "../PropertyGroupMacro/editor/ConfluencePagePropertyEdit";
import SelectPropertyEdit from "../PropertyGroupMacro/editor/SelectPropertyEdit";
import MultiSelectPropertyEdit from "../PropertyGroupMacro/editor/MultiSelectPropertyEdit";
import UserPropertyEdit from "../PropertyGroupMacro/editor/UserPropertyEdit";
import EditIcon from "@atlaskit/icon/glyph/edit";
import EmailPropertyEdit from "../PropertyGroupMacro/editor/EmailPropertyEdit";
import UrlPropertyEdit from "../PropertyGroupMacro/editor/UrlPropertyEdit";
import PhonePropertyEdit from "../PropertyGroupMacro/editor/PhonePropertyEdit";
import NumberPropertyEdit from "../PropertyGroupMacro/editor/NumberPropertyEdit";
import { FieldIcons } from "../FieldIcons";
import useResizeObserver from "use-resize-observer";
import "./PropertyGroupForm.scss";

const PropertyGroupForm = ({
  shortVersion = false,
  selectPropertyName = null,
  reportView = false,
  updateValue,
  data,
  values,
  pageId,
  recordId,
  group,
  ordinalNumber = -1,
}) => {
  const [responsiveVariant, setResponsiveVariant] = useState(false);

  const { ref: propertyFormRef } = useResizeObserver({
    onResize: ({ width }) => {
      if (width < 500 && !responsiveVariant) {
        setResponsiveVariant(true);
      } else if (width >= 500 && responsiveVariant) {
        setResponsiveVariant(false);
      }
    },
  });

  if (data.data.length === 0) {
    return (
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <EmptyState
          header="You haven't created any properties yet"
          description="Properties act as form fields that capture information. You can create and assign them to your property groups."
          imageUrl={NO_PROPERTY_GROUP_SELECTED_OR_EMPTY}
          primaryAction={
            <Button
              appearance="primary"
              href={`${window.AP._hostOrigin}/wiki/plugins/servlet/ac/net.junovi.confluence.properties/properties-overview`}
              target="_blank"
              rel="nofollow noopener"
            >
              Go to portal
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div key={ordinalNumber} ref={propertyFormRef} className={reportView ? "property-form-wrapper-report" : "property-form-wrapper"}>
      <div>
        {!shortVersion && <div style={{ fontWeight: 700 }}>Properties</div>}
        {!shortVersion && (
          <p style={{ marginBottom: "30px" }}>You can edit the property values here or anytime in the view mode of this page.</p>
        )}
      </div>
      <div
        className={
          reportView
            ? "property-row-wrapper-report" + `${data.data.length < 5 ? " short-version-" + data.data.length : ""}`
            : "property-row-wrapper"
        }
      >
        {Object.keys(data.data).map((key, index) => {
          let currentProperty = data.data[key];
          let initialValue = values?.[pageId]?.[recordId]?.[currentProperty.id] ?? values?.[currentProperty.id] ?? undefined;
          let initialValueSelect =
            values?.[pageId]?.[recordId]?.[currentProperty.id] ?? values?.[currentProperty.id] ?? selectPropertyName ?? undefined;
          const properties = (isSelectProperty = false) => {
            return {
              property: currentProperty,
              initialValue: isSelectProperty ? initialValueSelect : initialValue,
              onUpdate: (newValue) => {
                updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
              },
            };
          };
          const PropertiesEdit = {
            [PropertyType.TEXT]: <TextPropertyEdit {...properties()} />,
            [PropertyType.DATE]: <DatePropertyEdit {...properties()} />,
            [PropertyType.SELECT]: <SelectPropertyEdit {...properties(true)} />,
            [PropertyType.MULTISELECT]: <MultiSelectPropertyEdit {...properties()} />,
            [PropertyType.USER]: <UserPropertyEdit {...properties()} />,
            [PropertyType.CONFLUENCEPAGE]: <ConfluencePagePropertyEdit {...properties()} />,
            [PropertyType.EMAIL]: <EmailPropertyEdit {...properties()} />,
            [PropertyType.URL]: <UrlPropertyEdit {...properties()} />,
            [PropertyType.PHONE]: <PhonePropertyEdit {...properties()} />,
            [PropertyType.NUMBER]: <NumberPropertyEdit {...properties()} />,
          };
          return (
            <div
              key={key + index + ordinalNumber}
              style={{
                display: responsiveVariant ? "block" : "flex",
                marginBottom: "16px",
                width: "100%",
                maxWidth: "700px",
              }}
            >
              {!reportView && (
                <span
                  style={{
                    flex: "0 1 250px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight: "1.3333",
                    fontWeight: "600",
                    marginTop: "2px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    width: "100%",
                  }}
                >
                  <span style={{ marginRight: "12px", color: "darkslategray", top: "1px" }}>{FieldIcons(currentProperty.kind)}</span>
                  {currentProperty.data.name}
                </span>
              )}
              <div key={"property-row-" + key + index} className={`property-row ${reportView && "property-row-report-view"}`}>
                {currentProperty.kind ? PropertiesEdit?.[currentProperty.kind] : null}
              </div>
            </div>
          );
        })}
        {!shortVersion && (
          <Button
            appearance="link"
            href={`${window.AP._hostOrigin}/wiki/plugins/servlet/ac/net.junovi.confluence.properties/properties-overview?ac.selectedGroup=${group}`}
            target="_blank"
            rel="nofollow noopener"
            iconBefore={<EditIcon size="small" />}
            style={{
              alignSelf: "flex-start",
              textDecoration: "none",
              paddingLeft: "0",
              fontSize: "14px",
            }}
          >
            Edit in portal
          </Button>
        )}
      </div>
    </div>
  );
};

export default PropertyGroupForm;
