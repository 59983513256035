import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "@atlaskit/select";
import Field from "@atlaskit/form/Field";
import { HelperMessage } from "@atlaskit/form";
import { CurrencySelectionField } from "./CurrencySelectionField";
import NumberType from "../../../../NumberType";
import { SelectFieldStyles } from "../../../../SelectFieldStyles";
import { usePlaceholderStore } from "../../../../Store";

const formatSelection = [
  { label: "Number", value: "number" },
  { label: "Number with comma", value: "comma" },
  { label: "Percent", value: "percent" },
  { label: "Currency", value: "currency" },
];
FormatSelectionField.propTypes = {
  placeholder: PropTypes.object.isRequired,
};
export function FormatSelectionField({ placeholder }) {
  const [format, setFormat] = useState();
  const [currencySelected, isCurrencySelected] = useState(false);
  const { changeOptions, changeNumberType } = usePlaceholderStore((state) => ({
    changeOptions: state.changeOptions,
    changeNumberType: state.changeNumberType,
  }));
  const handleFormatChange = (selectedFormat) => {
    selectedFormat.value === "currency" ? isCurrencySelected(true) : isCurrencySelected(false);
    setFormat(selectedFormat || undefined);
    if (placeholder?.data?.currency && selectedFormat.value !== "currency") {
      delete placeholder.data.currency;
    }
    changeNumberType(placeholder, selectedFormat.value);
  };
  const initializeFormat = () => {
    if (placeholder.data) {
      const value = placeholder.data.type;
      const defaultOption = formatSelection.find((selection) => selection.value === value);
      setFormat(defaultOption);
    }
    if (placeholder.data.currency && placeholder.data.type !== "currency") {
      changeOptions(placeholder, []);
    }
  };
  const handleCurrencySelection = () => {
    if (placeholder.data && placeholder.data.currency) {
      isCurrencySelected(true);
    }
  };

  useEffect(() => {
    initializeFormat();
    handleCurrencySelection();
  }, []);

  return (
    <Field label="Format" name="format" defaultValue="" isRequired>
      {({ fieldProps }) => (
        <div>
          <Select
            {...fieldProps}
            maxMenuHeight={100}
            placeholder="Select a format"
            styles={SelectFieldStyles}
            menuPlacement="auto"
            options={formatSelection.map((format) => format)}
            value={format}
            onChange={handleFormatChange}
          />
          {currencySelected ? (
            <CurrencySelectionField placeholder={placeholder} />
          ) : (
            format && (
              <HelperMessage>
                Example:{" "}
                {format.value === NumberType.NUMBER
                  ? "10"
                  : format.value === NumberType.COMMA
                  ? "10,00"
                  : format.value === NumberType.PERCENT
                  ? "10.00%"
                  : ""}
              </HelperMessage>
            )
          )}
        </div>
      )}
    </Field>
  );
}
