import { placeholderCloudRunUrl, placeholderSetRunUrl } from "../../../cloud-run-config";
import httpClient from "../../http-client";

export async function getAllPropertyGroups({ queryKey }) {
  const [_key] = queryKey;
  const url = placeholderSetRunUrl + "/api/sets";
  return httpClient.get(url);
}

export async function getPropertiesOfGroup({ queryKey }) {
  const [_key, { group }] = queryKey;
  const url = `${placeholderCloudRunUrl}/api/placeholder/${group}`;
  return httpClient.get(url);
}

export async function getRecordsForGroup({ queryKey }) {
  const [_key, { group }] = queryKey;
  const url = `${placeholderCloudRunUrl}/api/records/${group}`;
  return httpClient.get(url);
}
export async function getFieldFromGroup({ queryKey }) {
  const [_key, { groupId, fieldId }] = queryKey;
  const url = `${placeholderCloudRunUrl}/api/placeholder/${groupId}/${fieldId}`;
  return httpClient.get(url);
}

export async function getSpaceByKey(spaceKey) {
  return window.AP.request(`/rest/api/space/${spaceKey}?expand=icon`);
}

export async function createNewPage(spaceId, parentId, recordId, groupId, title, values, spaceKey) {
  const url = `${placeholderCloudRunUrl}/api/atlassian-connect/confluence-cloud/createNewPage`;
  const data = {
    spaceId,
    parentId,
    recordId,
    groupId,
    title,
    values,
    spaceKey,
  };
  const response = await httpClient.post(url, data);

  return response.data;
}

