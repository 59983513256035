import { useEffect, useState } from "react";
import { AsyncSelect } from "@atlaskit/select";
import { HelperMessage } from "@atlaskit/form";
import { getSpaceByKey } from "../../../../../api";
import { analytics } from "../../../../../../../../analytics";
import { AnalyticsEvent } from "../../../../../../../../analytics-events";
import { SelectFieldStyles } from "../../../../../../Shared/SelectFieldStyles";
import { atlassianRestService } from "../../../../../../../Service/AtlassianRestService";
import { usePlaceholderStore } from "../../../../../Store";

function mapSpace(space) {
  return {
    icon: space.icon,
    name: space.name,
    spaceKey: space.key,
    type: "space",
    label: space.name,
    value: space.key,
    url: `${window.AP._hostOrigin}/wiki/spaces/${space.key}/overview`,
  };
}

export const SpaceFilter = ({ placeholder, onSpacesChange, uniqueId }) => {
  const { spaces, setSpacesWithIndex, setSpaces } = usePlaceholderStore((state) => ({
    spaces: placeholder.data.spaces?.[uniqueId]?.length ? placeholder.data.spaces : state.spaces,
    setSpacesWithIndex: state.setSpacesWithIndex,
    setSpaces: state.setSpaces,
  }));
  const [selectedSpaces, setSelectedSpaces] = useState([]);

  const loadSpaces = async (query) => {
    if (!query || query.trim().length === 0) return [];
    const results = await atlassianRestService.searchForSpace(query);
    return results.results.map(({ space }) => ({
      label: space.name,
      value: space.key,
    }));
  };

  useEffect(() => {
    if (placeholder && placeholder.data && placeholder.data.spaces) {
      setSpaces(placeholder.data.spaces);
    }
  }, [placeholder]);

  useEffect(() => {
    if (spaces?.[uniqueId]?.length > 0) {
      const promises = spaces[uniqueId]?.map((spaceKey) => getSpaceByKey(spaceKey));
      Promise.all(promises)
        .then((results) => {
          setSelectedSpaces(results.map((r) => mapSpace(JSON.parse(r.body))));
        })
        .catch(console.log);
    } else {
      setSelectedSpaces((prevSelectedSpaces) => prevSelectedSpaces);
    }
  }, [uniqueId]);

  return (
    <>
      <AsyncSelect
        placeholder="Filter by space"
        isClearable
        isSearchable
        isMulti
        styles={
          SelectFieldStyles && {
            control: (base) => ({
              ...base,
              backgroundColor: "#FFFFFF",
              fontSize: "14px",
            }),
          }
        }
        onChange={(updatedSpaces) => {
          onSpacesChange();
          setSelectedSpaces(updatedSpaces);
          const spaceKeys = updatedSpaces.map((space) => space.value);
          setSpacesWithIndex(spaceKeys, uniqueId);
          analytics.logEvent(AnalyticsEvent.SPACE_FILTER_USED, {
            LiveFilterUsed: true,
          });
        }}
        value={selectedSpaces}
        cacheOptions
        defaultOptions
        loadOptions={loadSpaces}
      />
      <HelperMessage>Returns items from these spaces</HelperMessage>
    </>
  );
};
