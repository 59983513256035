import { useEffect, useState } from "react";
import styled from "styled-components";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import UserMention from "./UserMention";
import Spinner from "@atlaskit/spinner";

const EmptyUser = styled.span`
  color: rgb(107, 119, 140);
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
`;

function mapUser(user) {
  return {
    avatarUrl: atlassianRestService.getProfilePictureUrl(user.profilePicture.path),
    id: user.accountId,
    name: user.publicName,
    type: "user",
    label: user.publicName,
    value: user.accountId,
  };
}

const UserPropertyView = ({ property, initialValue, displayMode, pageId }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCreator, setPageCreator] = useState({});
  const [lastModifier, setLastModifier] = useState({});
  const [textPlaceholder, setTextPlaceholder] = useState("");
  useEffect(() => {
    if ((initialValue ?? []).length > 0) {
      const promises = [];
      for (const accountId of initialValue) {
        promises.push(atlassianRestService.getUserByAccountId(accountId));
      }
      Promise.all(promises)
        .then((results) => {
          setSelectedUsers(results.map((r) => mapUser(r)));
        })
        .catch((e) => {})
        .finally(() => {
          setLoading(false);
        });
    }
    setLoading(false);
  }, [initialValue]);

  useEffect(async () => {
    const pageById = await atlassianRestService.getPageById(pageId);
    if (property.data?.displayPageCreator) {
      setPageCreator(pageById?.history?.createdBy);
      setTextPlaceholder("Property will display the page creator");
    }
    if (property.data?.displayLastModifier) {
      setLastModifier(pageById?.version?.by);
      setTextPlaceholder("Displays the latest modifier of the page");
    }
  }, []);

  const renderUserWithNameOnly = (users) => {
    return (
      <div style={{ display: "flex" }}>
        {users.map((p, index) => {
          return (
            <span key={p.id || p.accountId} style={{ display: "flex", alignItems: "center", whiteSpace: "pre-line" }}>
              <div
                style={{
                  display: "inline",
                  border: "1px solid transparent",
                  background: "rgba(9,30,66,0.08)",
                  color: "#42526E",
                  borderRadius: "20px",
                  cursor: "pointer",
                  padding: "0px 0.3em 1px 0.23em",
                  lineHeight: "1.714",
                  fontSize: "14px",
                  fontWeight: "normal",
                  wordBreak: "break-word",
                  marginRight: "3px",
                }}
              >
                <span style={{ fontSize: "14px" }}>@{p.name || p.publicName}</span>
              </div>
            </span>
          );
        })}
      </div>
    );
  };
  if (loading) {
    return <Spinner />;
  }
  if (property?.data?.displayPageCreator || property.data?.displayLastModifier) {
    let userAccount;
    if (property?.data?.displayPageCreator) {
      userAccount = pageCreator;
    } else if (property.data?.displayLastModifier) {
      userAccount = lastModifier;
    }
    if (!displayMode) {
      return <p style={{ color: "rgb(107, 119, 140)", display: "inline-block", marginLeft: "5px", fontSize: "14px" }}>{textPlaceholder}</p>;
    }
    if (!property?.data?.displayAvatar) {
      return renderUserWithNameOnly([userAccount]);
    }
    return (
      <div
        style={{ display: "flex" }}
        onClick={() => {
          window.open(`${window.AP._hostOrigin}/wiki/people/${userAccount.accountId}`, "_blank");
        }}
      >
        <UserMention profiles={[userAccount]} displayUserProfilePicture />
      </div>
    );
  }
  if (selectedUsers && selectedUsers?.length > 0 && (property?.data?.displayAvatar || property?.data?.displayAvatar === undefined)) {
    return (
      <div style={{ display: "flex" }}>
        <UserMention profiles={selectedUsers} />
      </div>
    );
  }
  if (selectedUsers && selectedUsers?.length > 0 && !property?.data?.displayAvatar) {
    return renderUserWithNameOnly([selectedUsers]);
  }
  if (property?.data?.description) {
    return (
      <EmptyUser data-sortable={true} data-raw-value="">
        {property?.data?.description}
      </EmptyUser>
    );
  }
  return (
    <EmptyUser data-sortable={true} data-raw-value="">
      Select a value
    </EmptyUser>
  );
};

export default UserPropertyView;
