import Field from "@atlaskit/form/Field";
import React from "react";
import Textfield from "@atlaskit/textfield";
import { NameField } from "./NameField";
import PropTypes from "prop-types";
import { usePlaceholderStore } from "../../../../Store";

NameField.propTypes = {
  placeholder: PropTypes.object.isRequired,
};
export function DescriptionField({ placeholder }) {
  const { placeholders, changeDescription } = usePlaceholderStore((state) => ({
    placeholders: state.placeholders,
    changeDescription: state.changeDescription,
  }));
  const p = placeholders.find((p) => p === placeholder) || "";
  const onChange = (e) => {
    changeDescription(placeholder, e.target.value);
  };
  return (
    <Field label="Description" name="description" defaultValue="">
      {({ fieldProps }) => (
        <Textfield {...fieldProps} value={p.data.description} onChange={onChange} placeholder="Describe how people should use this field" />
      )}
    </Field>
  );
}
