import { useState, useEffect } from "react";
import styled from "styled-components";
import NumberType from "../NumberType";
import { atlassianRestService } from "../../../Service/AtlassianRestService";

const EmptyText = styled.span`
  color: rgb(107, 119, 140);
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
`;

const NumberPropertyView = ({ property, initialValue, pageId }) => {
  const [locale, setLocale] = useState();
  const [pageVersion, setPageVersion] = useState();
  window.AP.user.getLocale(function (locale) {
    setLocale(locale.replace(/_/g, "-"));
  });
  useEffect(() => {
    (async () => {
      const pageById = await atlassianRestService.getPageById(pageId);
      if (property.data?.showCurrentPageVersion) {
        setPageVersion(pageById?.version?.number);
      }
    })();
  }, [pageVersion]);
  if (property.data?.showCurrentPageVersion) {
    return <span style={{ display: "inline-block", marginLeft: "5px", fontSize: "14px" }}>{pageVersion}</span>;
  }
  if (initialValue) {
    if (property?.data?.type === NumberType?.CURRENCY) {
      return (
        <span
          style={{ display: "inline-block", marginLeft: "5px", fontSize: "14px" }}
          data-sortable={true}
          data-raw-value={Intl.NumberFormat(locale, {
            style: property.data.type,
            currency: property.data.currency,
          }).format(initialValue.replace(/,/g, "."))}
        >
          {Intl.NumberFormat(locale, {
            style: property.data.type,
            currency: property.data.currency,
          }).format(initialValue.replace(/,/g, "."))}
        </span>
      );
    } else if (property?.data?.type === NumberType.PERCENT) {
      if (initialValue.includes(",")) {
        initialValue = initialValue.replace(/,/g, ".");
      }

      return (
        <span
          style={{ display: "inline-block", marginLeft: "5px", fontSize: "14px" }}
          data-sortable={true}
          data-raw-value={Intl.NumberFormat(locale, {
            style: property.data.type,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(initialValue / 100)}
        >
          {Intl.NumberFormat(locale, {
            style: property.data.type,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(initialValue / 100)}
        </span>
      );
    } else if (property?.data?.type === NumberType.COMMA) {
      return (
        <span
          style={{ display: "inline-block", marginLeft: "5px", fontSize: "14px" }}
          data-sortable={true}
          data-raw-value={initialValue.includes(",") ? initialValue : initialValue + ",00"}
        >
          {initialValue.includes(",") ? initialValue : initialValue + ",00"}
        </span>
      );
    }
    return (
      <span
        style={{ display: "inline-block", marginLeft: "5px", fontSize: "14px" }}
        data-sortable={true}
        data-raw-value={initialValue.includes(",") ? initialValue.replace(/,/g, ".") : initialValue}
      >
        {initialValue.includes(",") ? initialValue.replace(/,/g, ".") : initialValue}
      </span>
    );
  }

  if (property?.data?.description) {
    return (
      <EmptyText data-sortable={true} data-raw-value="">
        {property?.data?.description}
      </EmptyText>
    );
  }
  return (
    <EmptyText data-sortable={true} data-raw-value="">
      Enter text here
    </EmptyText>
  );
};

export default NumberPropertyView;
