import React from "react";
import "./AddSaveEntry.scss";

const AddSaveEntry = ({ onToggle }) => {
  return (
    <div className="add-save-entry">
      <div onClick={onToggle}>
        <span>+</span> Add Entry
      </div>
    </div>
  );
};

export default AddSaveEntry;
