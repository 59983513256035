import BaseRestService from "./BaseRestService";
import { placeholderCloudRunUrl } from "../../cloud-run-config";

class PlaceholderService extends BaseRestService {
  placeholderPath = placeholderCloudRunUrl + "/api/placeholder";

  async createNewPlaceholder(placeholderSet, placeholder) {
    const url = `${this.placeholderPath}/${placeholderSet}`;
    return this.client.post(url, placeholder, this.buildJWTAuthAxiosConfig());
  }

  async editPlaceholder(placeholderSetId, placeholder) {
    const url = `${this.placeholderPath}/${placeholderSetId}/${placeholder.id}`;
    return this.client.put(url, placeholder, this.buildJWTAuthAxiosConfig());
  }

  async deletePlaceholder(placeholderSetId, id) {
    const url = `${this.placeholderPath}/${placeholderSetId}/${id}`;
    return this.client.delete(url, this.buildJWTAuthAxiosConfig());
  }

  async searchPlaceholder(query, kind) {
    const url = `${this.placeholderPath}/search/${kind}?query=${query}`;
    return this.client.get(url, this.buildJWTAuthAxiosConfig());
  }

  async getPlaceholder(placeholderSet, placeholderId) {
    const url = `${this.placeholderPath}/${placeholderSet}/${placeholderId}`;
    return this.client.get(url, this.buildJWTAuthAxiosConfig());
  }

  async getAllPlaceholdersOfSet(placeholderSetId) {
    const url = `${this.placeholderPath}/${placeholderSetId}`;
    return this.client.get(url, this.buildJWTAuthAxiosConfig());
  }
}

export const placeholderService = new PlaceholderService();
