import React from "react";
import { generateId } from "../../../../random-id-provider";
import { usePlaceholderStore } from "../../Store";
import { token } from "@atlaskit/tokens";

export const Tile = ({ kind, name, icon }) => {
  const { createPlaceholder } = usePlaceholderStore((state) => ({
    createPlaceholder: state.createPlaceholder,
  }));
  const handleClick = () => {
    createPlaceholder({ kind, lifecycle: "created", data: {}, tempId: generateId(10) });
  };

  return (
    <div className="Tile-container" onClick={handleClick} style={{ backgroundColor: token("elevation.surface.raised")}}>
      <span style={{ marginBottom: "8px" }}>{icon}</span>
      <div className="Tile-container--text" style={{ color: token("color.text")}}>{name}</div>
    </div>
  );
};
