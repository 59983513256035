/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { ModalFooter } from "@atlaskit/modal-dialog";
import Button, { ButtonGroup } from "@atlaskit/button";
import DocumentsIcon from "@atlaskit/icon/glyph/documents";
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle";
import Spinner from "@atlaskit/spinner";
import { token } from "@atlaskit/tokens";

const ActionButton = ({ iconBefore, label, link }) => {
  return (
    <Button
      css={css`
        span {
          font-size: 14px;
          font-weight: 500;
        }
      `}
      appearance="subtle-link"
      href={link}
      target="_blank"
      rel="nofollow"
      iconBefore={iconBefore}
    >
      {label}
    </Button>
  );
};

const CustomModalFooter = ({
  documentationURL,
  onSave = async () => {},
  onBack = undefined,
  primaryActionLabel = "Save",
  secondaryActionLabel = "Cancel",
  saveHidden,
  saveDisabled,
  boardViewSettings = null,
}) => {
  const [saving, setSaving] = useState(false);
  const saveMacroConfig = async () => {
    setSaving(true);
    window.AP.dialog.disableCloseOnSubmit();
    await onSave();
    window.AP.dialog.close();
    setSaving(false);
  };
  if (boardViewSettings) {
    return (
      <ModalFooter
        style={{
          padding: "5px",
          display: "flex",
          height: "40px",
        }}
      >
        <ButtonGroup>
          {!saveHidden && (
            <Button appearance="primary" onClick={saveMacroConfig} isDisabled={saving || saveDisabled}>
              {saving && <Spinner />}
              {!saving && <span>{primaryActionLabel}</span>}
            </Button>
          )}
          <Button
            appearance="link"
            onClick={() => {
              boardViewSettings.onCancel();
            }}
          >
            {secondaryActionLabel}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    );
  }

  return (
    <ModalFooter
      style={{
        padding: "5px",
        display: "flex",
        height: "40px",
        background: token("elevation.surface.overlay"),
      }}
    >
      <ButtonGroup>
        <ActionButton
          label="Support"
          iconBefore={<QuestionCircleIcon />}
          link="https://junovi.atlassian.net/servicedesk/customer/portal/1"
        />
        <ActionButton label="Documentation" iconBefore={<DocumentsIcon />} link={documentationURL} />
      </ButtonGroup>
      <ButtonGroup>
        {onBack ? (
          <Button appearance="link" onClick={onBack}>
            Back
          </Button>
        ) : null}
        {!saveHidden && (
          <Button appearance="primary" onClick={saveMacroConfig} isDisabled={saving || saveDisabled}>
            {saving && <Spinner />}
            {!saving && <span>{primaryActionLabel}</span>}
          </Button>
        )}
        <Button
          appearance="link"
          onClick={() => {
            window.AP.dialog.close();
          }}
        >
          {secondaryActionLabel}
        </Button>
      </ButtonGroup>
    </ModalFooter>
  );
};

export default CustomModalFooter;
