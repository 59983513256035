import { useEffect, useState } from "react";
import Button from "@atlaskit/button";
import LoadingButton from "@atlaskit/button/loading-button";
import { notify } from "../../../../Shared/notification-flag";
import { ConfirmSaveWhenDeletingModal } from "./ConfirmSaveWhenDeletingModal";
import { usePlaceholderStore, usePlaceholderPortalStore } from "../../../Store";

export const PortalActions = ({ groupIsChanged }) => {
  const [confirmSaveWhenDeletingModalIsVisible, setConfirmSaveWhenDeletingModalIsVisible] = useState(false);
  const { selectedPlaceholderSet, restricted } = usePlaceholderPortalStore((state) => ({
    selectedPlaceholderSet: state.selectedPlaceholderSet,
    restricted: state.restricted,
  }));
  const {
    isSavingPlaceholders,
    getAllPlaceholdersOfSet,
    saveButtonIsDisabled,
    saveWouldDeleteProperties,
    savePlaceholders,
    placeholders,
    areLabelsChanged,
    areSpacesChanged,
  } = usePlaceholderStore((state) => ({
    isSavingPlaceholders: state.isSavingPlaceholders,
    getAllPlaceholdersOfSet: state.getAllPlaceholdersOfSet,
    saveButtonIsDisabled: state.saveButtonIsDisabled,
    saveWouldDeleteProperties: state.saveWouldDeleteProperties,
    savePlaceholders: state.savePlaceholders,
    placeholders: state.placeholders,
    areLabelsChanged: state.areLabelsChanged,
    areSpacesChanged: state.areSpacesChanged,
  }));
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isAllowedChange, setIsAllowedChange] = useState(false);
  const confirmSaveWhenDeletingProperties = async () => {
    if (saveWouldDeleteProperties()) {
      setConfirmSaveWhenDeletingModalIsVisible(true);
    } else {
      handleSave();
    }
  };
  const handleSave = async () => {
    if (await savePlaceholders(selectedPlaceholderSet)) {
      notify("success", `${selectedPlaceholderSet.name} saved`);
    } else {
      notify("error", `an error occurred saving ${selectedPlaceholderSet.name}`);
    }
  };

  useEffect(() => {
    setTimeout(() => setIsAllowedChange(true), 1000);
  }, [groupIsChanged]);

  useEffect(() => {
    isAllowedChange && setIsSaveButtonDisabled(saveButtonIsDisabled());
  }, [placeholders, areLabelsChanged, areSpacesChanged]);

  return (
    <>
      {!restricted && (
        <>
          <ConfirmSaveWhenDeletingModal
            onClose={() => setConfirmSaveWhenDeletingModalIsVisible(false)}
            onConfirm={async () => await handleSave()}
            visible={confirmSaveWhenDeletingModalIsVisible}
          />
          <LoadingButton
            isDisabled={isSaveButtonDisabled}
            isLoading={isSavingPlaceholders}
            appearance="primary"
            onClick={confirmSaveWhenDeletingProperties}
          >
            Save changes
          </LoadingButton>
          <Button appearance="subtle-link" onClick={() => getAllPlaceholdersOfSet(selectedPlaceholderSet?.id, true)}>
            Discard
          </Button>
        </>
      )}
    </>
  );
};
