class AtlassianConfluencePageCache {
  constructor() {
    if (!AtlassianConfluencePageCache.instance) {
      AtlassianConfluencePageCache.instance = this;
      this.cache = {};
    }
    return AtlassianConfluencePageCache.instance;
  }

  get(pageId) {
    return this.cache[pageId];
  }

  putIfAbsent(pageId, page) {
    if (pageId in this.cache) {
      return;
    }
    this.cache[pageId] = page;
  }
}
  
  const atlassianConfluencePageCache = new AtlassianConfluencePageCache();
  
  export default atlassianConfluencePageCache;
  