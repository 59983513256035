import React, { useRef, useState } from "react";
import Button from "@atlaskit/button";
import InlineDialog from "@atlaskit/inline-dialog";
import Textfield from "@atlaskit/textfield";
import CheckIcon from "@atlaskit/icon/glyph/check";
import "./ColorFields.scss";

const gradientField =
  "https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80";
const regexForColor = "[a-fA-F0-9]+";

ColorFields.Pallete = ({
  option,
  inputRef,
  setColor,
  colorPalette,
  selectedColor,
  setSelectedColor,
  customColorInput,
  customColorField,
  setCustomColorField,
  setCustomColorInput,
}) => {
  return (
    <div style={{ display: "flex", height: "30px" }}>
      <div className="colorPaletteContainer">
        {colorPalette.map((colorField, idx) => {
          return (
            <div
              key={idx}
              className="colorPaletteContainer-colorField"
              style={{ backgroundColor: colorField.value }}
              onClick={() => {
                setSelectedColor(colorField.value);
                setColor(option, colorField.value);
              }}
            >
              {selectedColor === colorField.value && <CheckIcon primaryColor="#FFFFFF" />}
            </div>
          );
        })}
        {customColorField === "" ? (
          <img src={gradientField} className="colorPaletteContainer-colorField" onClick={inputRef.current?.focus} />
        ) : (
          <div
            className="colorPaletteContainer-colorField"
            style={{ background: customColorField }}
            onClick={() => {
              inputRef.current.focus();
              setSelectedColor(customColorField);
              setColor(option, customColorField);
            }}
          >
            {selectedColor === customColorField && <CheckIcon primaryColor="#FFFFFF" />}
          </div>
        )}
      </div>
      <Textfield
        placeholder="FFFFFF"
        value={customColorInput}
        ref={inputRef}
        maxLength="6"
        style={{ width: "95px" }}
        elemBeforeInput={<Textfield width="25" isDisabled value="#" maxLength={1} className="custom-color-input" />}
        onKeyDown={(e) => {
          if (e.key === "Enter" && e.target.value.match(regexForColor)) {
            const newValue = "#" + e.target.value;
            setCustomColorInput(e.target.value);
            setCustomColorField(newValue);
            setSelectedColor(newValue);
            setColor(option, newValue);
          }
          if (e.key === "Escape") {
            const lastValue = customColorField.substring(1);
            setCustomColorInput(lastValue);
          }
        }}
        onBlur={(e) => {
          if (e.target.value && e.target.value.match(regexForColor)) {
            const newValue = "#" + e.target.value;
            setCustomColorInput(e.target.value);
            setCustomColorField(newValue);
            setSelectedColor(newValue);
            setColor(option, newValue);
          }
        }}
        onChange={(e) => {
          const newValue = e.target.value;
          if (newValue.includes("#")) {
            const shownTextValue = newValue.replace("#", "");
            setCustomColorInput(shownTextValue);
          } else {
            setCustomColorInput(e.target.value);
          }
        }}
      />
    </div>
  );
};

export function ColorFields({ hexCode, colorPalette, option, options, setOptions}) {
  const inputRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(hexCode);
  const [customColorInput, setCustomColorInput] = useState("");
  const [customColorField, setCustomColorField] = useState("");


  const setColor = (option, color) => {
    const newOptions = options.map((o) => (o === option ? { ...o, color } : o));
    setOptions(newOptions);
  };

  return (
    <div style={{ display: "inline-flex" }}>
      <div className="colorFieldsContainer">
        <span className="colorPaletteContainer-colorField" style={{ background: selectedColor, display: "flex" }}>
          <InlineDialog
            onClose={() => setIsDialogOpen(false)}
            content={ColorFields.Pallete({
              option,
              inputRef,
              setColor,
              colorPalette,
              selectedColor,
              setSelectedColor,
              customColorInput,
              customColorField,
              setCustomColorField,
              setCustomColorInput,
            })}
            isOpen={isDialogOpen}
          >
            <Button style={{ height: "100%", width: "100%" }} onClick={() => setIsDialogOpen(!isDialogOpen)} />
          </InlineDialog>
        </span>
      </div>
    </div>
  );
}
