import { useEffect, useState } from "react";
import { SkeletonItem } from "@atlaskit/side-navigation";
import UserWithAvatar from "../../../UserWithAvatar";
import { atlassianRestService } from "../../../../Service/AtlassianRestService";
import { _toUserField } from "../../toUserField";


const UsernameWithOptionalAvatar = ({ accountID, prefix, withAvatar, before, after }) => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    (async () => {
      if (!accountID) {
        setLoading(false);
        setProfile({});
        return;
      }
      const result = await atlassianRestService.getUserByAccountId(accountID);
      setProfile(_toUserField(result));
      setLoading(false);
    })();
  }, [accountID]);

  if (!accountID) {
    return <span></span>;
  }

  return loading ? (
    <SkeletonItem width={200} isShimmering />
  ) : withAvatar ? (
    <div style={{ display: "flex" }}>
      <UserWithAvatar user={profile} prefix={prefix} beforeChild={before} afterChild={after} />
    </div>
  ) : (
    <span>{prefix + " " + profile.name}</span>
  );
};

export default UsernameWithOptionalAvatar;
