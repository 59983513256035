import { separatePrimaryId } from "./separate-primary-id";

const unassignedDroppableId = "structure-option0";
const getSourceData = (props, copyData) => {
  const { source } = props || {};
  const sourceIndex = source?.droppableId?.slice(-1);
  const sourceItemIndex = source?.index;

  const primaryId = copyData[sourceIndex]?.primaryIds?.splice(sourceItemIndex, 1)[0];
  const { recordId, entityId: pageId } = separatePrimaryId(primaryId);
  return { pageId, recordId };
};

const getDestinationData = (props, copyData) => {
  const { destination } = props || {};

  const destinationDataIndex = destination?.droppableId?.slice(-1);
  const destinationItemIndex = destination?.index;

  let valuesDestinationData = null;

  const isDestinationUnassigned = props?.destination?.droppableId === unassignedDroppableId;
  if (!isDestinationUnassigned) {
    valuesDestinationData = {
      label: copyData[destinationDataIndex].value,
      value: copyData[destinationDataIndex].value,
      color: copyData[destinationDataIndex].color,
    };
  }
  return { destinationItemIndex, destinationDataIndex, valuesDestinationData };
};
const addItemToDestinationArray = (props, copyData, pageId, recordId, destinationItemIndex, destinationDataIndex) => {
  const updatedData = [...copyData];
  updatedData[destinationDataIndex]?.primaryIds?.splice(destinationItemIndex, 0, recordId + pageId);

  return updatedData;
};
export const onDragEnd = async ({ props, storedData, groupId, selectedFieldId, saveAction }) => {
  let copyData = [...storedData];

  try {
    if (props.destination && props.source) {
      const { pageId, recordId } = getSourceData(props, copyData);
      const { valuesDestinationData, destinationItemIndex, destinationDataIndex } = getDestinationData(props, copyData);

      const valuesDestination = JSON.stringify({ [selectedFieldId]: valuesDestinationData });
      copyData = addItemToDestinationArray(props, copyData, pageId, recordId, destinationItemIndex, destinationDataIndex);

      const isSourceUnassigned = props?.source?.droppableId === unassignedDroppableId;
      const isDestinationUnassigned = props?.destination?.droppableId === unassignedDroppableId;
      const shouldSave = !(
        (isSourceUnassigned && isDestinationUnassigned) ||
        props?.source?.droppableId === props?.destination?.droppableId
      );

      if (shouldSave) {
        await saveAction({ pageId, groupId, recordId, valuesDestination });
      }
    }
  } catch (error) {
    console.error(`Error in onDragEnd: ${error}`);
  }
};
