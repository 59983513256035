import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { BoardCard } from "./StyledComponents";
import { LazyPageLink } from "./LazyPageLink";
import { filterPropertiesByPrimaryId } from "./filterPropertiesByPrimaryId";
import EditorHorizontalRuleIcon from "@atlaskit/icon/glyph/editor/horizontal-rule";
import AddIcon from "@atlaskit/icon/glyph/add";
import { separatePrimaryId } from "./separate-primary-id";



const CardList = ({ shouldDisableDrag, isEditView, properties = [], name, isExpanded, onOptionsChange, selectLabel, primaryIds }) => {
  const [expandedOptions, setExpandedOptions] = useState(Array(primaryIds?.length).fill(isExpanded));
  useEffect(() => {
    setExpandedOptions(Array(primaryIds?.length).fill(isExpanded));
  }, [isExpanded, primaryIds?.length]);

  useEffect(() => {
    const isOptionsChanged = JSON.stringify(expandedOptions) !== JSON.stringify(Array(primaryIds?.length).fill(isExpanded));

    if (isOptionsChanged) {
      onOptionsChange(expandedOptions);
    }
  }, [expandedOptions]);

  const toggleExpanded = (index, isExpanded) => {
    setExpandedOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = !isExpanded;
      return newOptions;
    });
  };
  const renderIcon = (index) => {
    const isExpanded = expandedOptions[index];
    return (
      <div
        style={{
          position: "absolute",
          right: "12px",
          top: "12px",
        }}
        onClick={() => toggleExpanded(index, isExpanded)}
      >
        {isExpanded ? <EditorHorizontalRuleIcon size="small" /> : <AddIcon size="small" />}
      </div>
    );
  };

  return (
    <div>
      {primaryIds?.map((primaryId, index) => {
        const { entityId: id } = separatePrimaryId(primaryId);
        return (
          <Draggable isDragDisabled={shouldDisableDrag} key={primaryId} draggableId={primaryId} index={index}>
            {(provided, snapshot) => {
              const isDragging = snapshot.isDragging;

              return (
                <>
                  <BoardCard
                    draggable={false}
                    style={{
                      backgroundColor: "transparent",
                      opacity: 0.4,
                      display: isDragging ? "block" : "none",
                    }}
                  >
                    <div style={{ opacity: 0.15, zIndex: "-100" }}>
                      <LazyPageLink pageId={id} />
                      {renderIcon(index)}
                      {properties?.length && expandedOptions[index]
                        ? filterPropertiesByPrimaryId(properties, primaryId, name, id, selectLabel)
                        : null}
                    </div>
                  </BoardCard>
                  <div key={index} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <BoardCard isEditView={isEditView} key={index + primaryId}>
                      <LazyPageLink pageId={id} />
                      {renderIcon(index)}
                      {properties?.length && expandedOptions[index]
                        ? filterPropertiesByPrimaryId(properties, primaryId, name, id, selectLabel)
                        : null}
                    </BoardCard>
                  </div>
                </>
              );
            }}
          </Draggable>
        );
      })}
    </div>
  );
};

export default CardList;
