/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useEffect, useRef } from "react";
import Button from "@atlaskit/button/standard-button";
import InlineDialog from "@atlaskit/inline-dialog";
import TableIcon from "@atlaskit/icon/glyph/table";
import MediaServicesGridIcon from "@atlaskit/icon/glyph/media-services/grid";
import { ExistingPropertyLiveFilter } from "./FilterFieldsForProperty";
import shallow from "zustand/shallow";
import { usePropertyGroupReportMacroStore, usePropertyGroupReportUIStore, usePropertyLiveFilterStore } from "./store";
import Spinner from "@atlaskit/spinner";
import { useQuery } from "react-query";
import { getPropertiesOfGroup } from "./api";
import { PropertyType } from "../../property-type";
import { ButtonGroup } from "@atlaskit/button";
import FilterIcon from "@atlaskit/icon/glyph/filter";
import Tooltip from "@atlaskit/tooltip";
import { ReportType } from "./report-type";
import { AnalyticsEvent } from "../../../analytics-events";
import { analytics } from "../../../analytics";

const ReportPropertyLiveFilter = () => {
  const { reportMacroId, group, setView } = usePropertyGroupReportMacroStore(
    (state) => ({
      group: state.selectedGroup,
      reportMacroId: state.reportMacroId,
      setView: state.setSelectedView,
    }),
    shallow
  );
  const { setPropertyLiveFiltersWithIndex, removePropertyLiveFilters, setPropertyLiveFiltersInLocalStorage } = usePropertyLiveFilterStore(
    (state) => ({
      setPropertyLiveFiltersWithIndex: state.setPropertyLiveFiltersWithIndex,
      removePropertyLiveFilters: state.removePropertyLiveFilters,
      setPropertyLiveFiltersInLocalStorage: state.setPropertyLiveFiltersInLocalStorage,
    })
  );
  const { setLoadedLiveFilter } = usePropertyGroupReportUIStore((state) => ({
    setLoadedLiveFilter: state.setLoadedLiveFilter,
  }));
  const ref = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDisabledApply, setIsDisabledApply] = useState();
  const [isDisabledClear, setIsDisabledClear] = useState();
  const [oldFilter, setOldFilter] = useState();
  const [allFilter, setAllFilter] = useState({});
  const [filterText, setFilterText] = useState("Filter");
  const { isLoading, data: availableProperties } = useQuery(["properties-of-group", { group }], getPropertiesOfGroup, {
    select(response) {
      return response.data.filter((d) => d.kind !== PropertyType.NUMBER).map((d) => ({ ...d, label: d.data.name, value: d.id }));
    },
  });
  const onDelete = () => {
    setFilterText("Filter");
    setOldFilter({});
    setAllFilter({});
    removePropertyLiveFilters(reportMacroId);
  };
  const onDeleteWithIndex = () => {
    removePropertyLiveFilters(reportMacroId);
  };
  const onUpdate = () => {
    onDeleteWithIndex();
    if (allFilter && Object.keys(allFilter).length > 0) {
      analytics.logEvent(AnalyticsEvent.LIVE_FILTER_USED, { LiveFilterUsed: true });
      const filterValues = Object.values(allFilter).filter((value) => (value ? value : undefined));
      setFilterText(filterValues.length + " filter" + (filterValues.length > 1 ? "s " : " ") + "applied");
    }
    const generatedKeyOfValue = Object.keys(allFilter);
    const valueOfFilter = Object.values(allFilter);
    if (generatedKeyOfValue.length > 0 && valueOfFilter.length > 0) {
      generatedKeyOfValue.map((key, idx) => {
        setPropertyLiveFiltersWithIndex(valueOfFilter[idx], key);
      });
    }
    setPropertyLiveFiltersInLocalStorage(reportMacroId, allFilter);
  };

  useEffect(() => {
    const tableElement = document.querySelector(".report-table");
    if (isDialogOpen) {
      setLoadedLiveFilter(true);
      setIsDisabledApply(true);
      if ((ref.current && ref.current.clientHeight > tableElement?.clientHeight) || document.body.scrollHeight < ref.current.clientHeight) {
        window.AP.resize("100%", `${(ref.current?.offsetHeight + 120).toString()}px`);
      } else if (tableElement && tableElement.clientHeight) {
        if (tableElement?.clientHeight < ref.current.clientHeight + 100) {
          window.AP.resize("100%", `${(ref.current?.offsetHeight + 120).toString()}px`);
        }
      }
    } else {
      setLoadedLiveFilter(false);
      window.AP.resize("100%", `${(tableElement?.clientHeight + 100).toString()}px`);
    }
  }, [isDialogOpen]);
  
  useEffect(() => {
    let propertyLiveFilterValues;
    try {
      const propertyLiveFiltersJson = reportMacroId
      ? localStorage.getItem(`property-report-live-filter-${reportMacroId}`)
      : localStorage.getItem("property-report-live-filter");
      propertyLiveFilterValues = JSON.parse(propertyLiveFiltersJson);
    } catch (e) {
      propertyLiveFilterValues = allFilter;
    }
    setOldFilter(propertyLiveFilterValues ? propertyLiveFilterValues : {});
    if (propertyLiveFilterValues && Object.keys(propertyLiveFilterValues).length > 0) {
      setFilterText(
        Object.keys(propertyLiveFilterValues).length +
          " filter" +
          (Object.keys(propertyLiveFilterValues).length > 1 ? "s " : " ") +
          "applied"
      );
      Object.keys(propertyLiveFilterValues).map((field, idx) => {
        setPropertyLiveFiltersWithIndex(Object.values(propertyLiveFilterValues)[idx], field);
      });
    } else {
      setIsDisabledClear(true);
    }
  }, []);
  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spinner />
      </div>
    );
  }

  return (
    <span>
      <div
        css={css`
          > div {
            padding: 16px;
            width: 362px;
            min-height: 250px;
            max-height: 100%;
          }
        `}
      >
        <InlineDialog
          onClose={() => {
            setIsDialogOpen(false);
          }}
          content={ContentOfInlineDialog(
            ref,
            availableProperties,
            oldFilter,
            reportMacroId,
            setAllFilter,
            onUpdate,
            onDelete,
            isDisabledApply,
            isDisabledClear,
            setIsDisabledApply,
            setIsDisabledClear,
            setIsDialogOpen
          )}
          isOpen={isDialogOpen}
        >
          <Button
            appearance="default"
            iconBefore={
              <Tooltip content={filterText}>
                <FilterIcon
                  size="medium"
                  css={css`
                     {
                      top: 1px;
                    }
                  `}
                />
              </Tooltip>
            }
            className="inline-dialog-button"
            onClick={() => setIsDialogOpen(isDialogOpen ? false : true)}
            style={{
              float: "right",
              fontSize: "14px",
              fontWeight: "500",
              marginTop: "8px",
              height: "40px",
              paddingTop: "3px",
              marginLeft: "5px",
              backgroundColor: "transparent",
            }}
          ></Button>
        </InlineDialog>
      </div>
      <Tooltip content="Card">
        <Button
          appearance="default"
          iconBefore={<MediaServicesGridIcon size="medium" />}
          onClick={() => setView(ReportType.CARD)}
          style={{
            float: "right",
            marginTop: "8px",
            height: "40px",
            paddingTop: "3px",
            marginLeft: "5px",
            backgroundColor: "transparent",
          }}
        />
      </Tooltip>
      <Tooltip content="Table">
        <Button
          appearance="default"
          iconBefore={<TableIcon size="medium" />}
          onClick={() => setView(ReportType.TABLE)}
          style={{
            float: "right",
            marginTop: "8px",
            height: "40px",
            paddingTop: "3px",
            marginLeft: "5px",
            backgroundColor: "transparent",
          }}
        />
      </Tooltip>
    </span>
  );
};

const ContentOfInlineDialog = (
  ref,
  availableProperties,
  oldFilter,
  reportMacroId,
  setAllFilter,
  onUpdate,
  onDelete,
  isDisabledApply,
  isDisabledClear,
  setIsDisabledApply,
  setIsDisabledClear,
  setIsDialogOpen
) => {
  return (
    <>
      <div
        ref={ref}
        className="property-inline-dialog"
        style={{
          display: "block",
          flexDirection: "inherit",
          width: "100%",
        }}
      >
        <table>
          {availableProperties &&
            availableProperties.map((key, index) => {
              return (
                <tr style={{ marginTop: "5px", width: "344px", display: "flex" }}>
                  <th
                    style={{
                      width: "130px",
                      marginTop: "10px",
                      marginBottom: "5px",
                      marginRight: "8px",
                      textAlign: "left",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#6B778C",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {key.label}
                  </th>
                  <th
                    style={{ width: "235px" }}
                    css={css`
                      > div {
                        padding: 0px;
                      }
                    `}
                  >
                    <ExistingPropertyLiveFilter
                      currentProperty={key}
                      setNewFilter={(addedFilter) => {
                        setIsDisabledApply(false);
                        setIsDisabledClear(false);
                        const mergeFilter = Object.assign(oldFilter, addedFilter);
                        setAllFilter(mergeFilter);
                      }}
                      reportMacroId={reportMacroId}
                    />
                  </th>
                </tr>
              );
            })}
        </table>
        <ButtonGroup>
          <Button
            appearance="primary"
            style={{
              fontSize: "14px",
              marginTop: "10px",
            }}
            isDisabled={(isDisabledApply && Object.values(oldFilter).length !== 0) || Object.values(oldFilter).length === 0}
            onClick={() => {
              setIsDialogOpen(false);
              onUpdate();
            }}
          >
            Apply
          </Button>
          <Button
            appearance="subtle"
            style={{
              fontSize: "14px",
              marginTop: "10px",
            }}
            isDisabled={isDisabledClear}
            onClick={() => {
              setIsDialogOpen(false);
              onDelete();
            }}
          >
            Clear
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
};
export default ReportPropertyLiveFilter;
