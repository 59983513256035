import React, { useEffect, useState } from "react";
import Form from "@atlaskit/form";
import { Checkbox } from "@atlaskit/checkbox";
import { FormFooter } from "@atlaskit/form/FormFooter";
import { CheckboxField as AtlassianCheckboxField } from "@atlaskit/form";
import { PanelTitle } from "../Fields/PanelTitle";
import { NameField } from "../Fields/NameField";
import { DescriptionField } from "../Fields/DescriptionField";
import { OptionsField, StoreTypes } from "../Fields/OptionsField";
import { usePlaceholderIcon } from "../usePlaceholderIcon";
import { PLACEHOLDER_KIND_MULTI_SELECT } from "../PlaceholderKinds";
import { CollapsibleContainer } from "../CollapsibleContainer";
import { DeleteProperty } from "../DeletePlaceholder";
import { PLACEHOLDER_EDITED } from "../../../../../../Lifecycles";
import { AnalyticsEvent } from "../../../../../../../analytics-events";
import { analytics } from "../../../../../../../analytics";
import { usePlaceholderStore } from "../../../../Store";
import { useLifecycleSetCollapsed } from "./useLifecycleSetCollapsed";

export const MultiSelectPlaceholder = ({ placeholder, onDropEndCbRef, key }) => {
  const { collapsed, setCollapsed } = useLifecycleSetCollapsed({ placeholder });
  const [isUppercaseMultiselect, setIsUppercaseMultiselect] = useState(placeholder.data.isUppercase);
  const { placeholders } = usePlaceholderStore((state) => ({
    placeholders: state.placeholders,
    changeOptions: state.changeOptions,
  }));

  return (
    <CollapsibleContainer
      collapsed={collapsed}
      Title={<PanelTitle placeholder={placeholder} />}
      onCollapseClick={(newValue) => setCollapsed(newValue)}
      Icon={usePlaceholderIcon(PLACEHOLDER_KIND_MULTI_SELECT, "small")}
    >
      <Form>
        {({ formProps }) => (
          <form {...formProps} name="select-placeholder-from-example">
            <NameField placeholder={placeholder} />
            <DescriptionField placeholder={placeholder} />
            <OptionsField
              placeholder={placeholder}
              storeType={StoreTypes.MULTI}
              key={key}
              onDropEndCbRef={onDropEndCbRef}
            />
            <div style={{ fontSize: "14px" }}>
              <AtlassianCheckboxField name="labelCaps" style={{ fontSize: "14px" }}>
                {({ fieldProps }) => (
                  <Checkbox
                    {...fieldProps}
                    isChecked={isUppercaseMultiselect}
                    label="Display options in uppercase"
                    style={{ fontSize: "14px" }}
                    onChange={(e) => {
                      setIsUppercaseMultiselect(!placeholder.data.isUppercase);
                      const p = placeholders.find((p) => p === placeholder);
                      if (p.lifecycle === undefined) {
                        p.lifecycle = PLACEHOLDER_EDITED;
                      }
                      p.data.isUppercase = !placeholder.data.isUppercase;
                      analytics.logEvent(AnalyticsEvent.IS_UPPERCASE_MULTISELECT, { isUppercaseMulti: !placeholder.data.isUppercase });
                    }}
                  />
                )}
              </AtlassianCheckboxField>
            </div>
            <FormFooter>
              <DeleteProperty property={placeholder} />
            </FormFooter>
          </form>
        )}
      </Form>
    </CollapsibleContainer>
  );
};
