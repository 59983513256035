import React, { useEffect, useState } from "react";
import Button from "@atlaskit/button";
import Field from "@atlaskit/form/Field";
import AddIcon from "@atlaskit/icon/glyph/add";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { OptionsListItem } from "./OptionsListItem";
import { usePlaceholderStore } from "../../../../../Store";
import { StoreTypes } from "./";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});

export const OptionsField = ({ placeholder, onDropEndCbRef, storeType = StoreTypes.SINGLE }) => {
  const { changeOptions } = usePlaceholderStore((state) => ({
    changeOptions: state.changeOptions,
  }));

  const [options, setOptions] = useState(
    placeholder.data?.options?.length > 0
      ? placeholder.data.options
      : [
          {
            value: "",
            isDefault: false,
          },
        ]
  );

  useEffect(() => {
    if (placeholder.data.options === undefined) {
      changeOptions(placeholder, options);
    }
    if (storeType === StoreTypes.SINGLE) {
      onDropEndCbRef.current[`options-list-${placeholder.id}`] = onDrag;
    }
    if (storeType === StoreTypes.MULTI) {
      onDropEndCbRef.current[`multiple-options-list-${placeholder.id}`] = onDrag;
    }
    changeOptions(placeholder, options);
  }, [options]);

  const onDrag = ({ source, destination }) => {
    if (!destination) {
      return;
    }
    const result = Array.from(options);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);
    setOptions(result);
  };
  const handleAddOption = () => {
    const canAddOption = options.length === 0 || options[options.length - 1].value !== "";
    if (canAddOption) {
      const newOptions = [...options, { value: "", isDefault: false }];
      setOptions(newOptions);
    }
  };

  return (
    <Field label="Options" name="options" defaultValue="">
      {({}) => (
        <div>
          <Droppable
            droppableId={storeType === StoreTypes.SINGLE ? `options-list-${placeholder.id}` : `multiple-options-list-${placeholder.id}`}
            type={`select-options`}
          >
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {options.map((option, index) => (
                  <Draggable
                    key={`select-option-${placeholder.id}-id:${index}`}
                    draggableId={`select-option-${placeholder.id}-id:${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <OptionsListItem
                          chosenStore={storeType}
                          availableOptions={options}
                          setOptions={setOptions}
                          addOption={handleAddOption}
                          option={option}
                          index={index}
                          key={`options-field-${index}`}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <Button onClick={() => handleAddOption()} appearance="subtle" style={{ fontSize: "14px" }} iconBefore={<AddIcon size="small" />}>
            Add option
          </Button>
        </div>
      )}
    </Field>
  );
};
