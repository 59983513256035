import create from "zustand";
import { analytics } from "../../../../analytics";
import { AnalyticsEvent } from "../../../../analytics-events";

import { placeholderSetService } from "../../../Service/PlaceholderSetService";

export const usePlaceholderPortalStore = create((set, get) => ({
  groups: [],
  selectedPlaceholderSet: null,
  initiallyFetched: false,
  validLicense: false,
  restricted: false,
  loadingPlaceholder: false,
  isContentDisplayAllowed: false,
  icon: "",
  setIcon: (icon) => set({ icon }),
  setIsContentDisplayAllowed: (isContentDisplayAllowed) => {
    set({ isContentDisplayAllowed });
  },
  setLoadingPlaceholders: (loadingPlaceholder) => {
    set({ loadingPlaceholder });
  },
  fetchPlaceholderSets: async (initiallySelectedGroup = undefined) => {
    try {
      const { data } = await placeholderSetService.getAllPlaceholderSets();
      data.sort((a, b) => (a.name > b.name ? 1 : -1));
      set({ groups: data });

      if (data.length > 0) {
        if (initiallySelectedGroup) {
          const foundIndex = data.findIndex((group) => group.id === initiallySelectedGroup);
          if (foundIndex > -1) {
            set({ selectedPlaceholderSet: data[foundIndex] });
          } else {
            set({ selectedPlaceholderSet: data[0] });
          }
        } else {
          const existingGroups = data.filter((group) => group.name !== "");
          set({ selectedPlaceholderSet: existingGroups[0], groups: existingGroups });
        }
      }

      await placeholderSetService.hasUserPermissionToEditPortal(data[0].id);
      set({ restricted: false });
      set({ initiallyFetched: true });
      analytics.logEvent(AnalyticsEvent.NUMBER_OF_PROPERTY_GROUPS, { size: data.length });
    } catch (error) {
      set({ restricted: true });
      set({ initiallyFetched: true });
    }
  },
  setSelectedPlaceholderSet: (settings) => {
    set({ selectedPlaceholderSet: settings });
  },
  setSelectedPlaceholderSet: (settings) => {
    set({ selectedPlaceholderSet: settings });
  },
  setRestriction: (isRestricted) => {
    set({ restricted: isRestricted });
  },
  setValidLicense: (valid) => {
    set({ validLicense: valid });
  },
  addPlaceholderSet: (placeholderSet) => {
    set((state) => ({
      groups: [...state.groups, placeholderSet],
      selectedPlaceholderSet: state.groups.find((s) => s.id === placeholderSet.id),
    }));
    analytics.logEvent(AnalyticsEvent.CREATED_PORTAL_GROUP, {});
  },
  updatePlaceholderSet: (id, placeholderSet) => {
    set((state) => ({
      groups: state.groups.map((set) => {
        if (set.id === id) {
          return { ...set, ...placeholderSet };
        }
        return set;
      }),
    }));
  },
  deletePlaceholderGroup: (id) => {
    set((state) => {
      const updatedGroups = state.groups.filter((s) => s.id !== id);
      const updatedSelectedPlaceholderSet =
        state.selectedPlaceholderSet?.id === id ? (updatedGroups.length > 0 ? updatedGroups[0] : null) : state.selectedPlaceholderSet;

      return {
        groups: updatedGroups,
        selectedPlaceholderSet: updatedSelectedPlaceholderSet,
      };
    });
  },
}));
