import React, { useState } from "react";
import Form from "@atlaskit/form";
import Tooltip from "@atlaskit/tooltip";
import Checkbox from "@atlaskit/checkbox";
import { FormFooter } from "@atlaskit/form/FormFooter";
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle";
import { CheckboxField as AtlassianCheckboxField } from "@atlaskit/form";
import { NameField } from "../Fields/NameField";
import { PanelTitle } from "../Fields/PanelTitle";
import { DeleteProperty } from "../DeletePlaceholder";
import { CheckboxField } from "../Fields/CheckboxField";
import { usePlaceholderIcon } from "../usePlaceholderIcon";
import { DescriptionField } from "../Fields/DescriptionField";
import { CollapsibleContainer } from "../CollapsibleContainer";
import { PLACEHOLDER_KIND_USER_MENTION } from "../PlaceholderKinds";
import { useLifecycleSetCollapsed } from "./useLifecycleSetCollapsed";
import { PLACEHOLDER_EDITED } from "../../../../../../Lifecycles";
import { analytics } from "../../../../../../../analytics";
import { AnalyticsEvent } from "../../../../../../../analytics-events";
import { usePlaceholderStore } from "../../../../Store";

UserMentionPlaceholderInPortal.singleUser = ({ placeholder, isPageCreator, isLastModifier }) => (
  <CheckboxField
    placeholder={placeholder}
    label="Restrict to a single person"
    name="singleUser"
    isPageMetadataChecked={isPageCreator || isLastModifier}
  />
);
UserMentionPlaceholderInPortal.displayAvatar = ({ placeholder, placeholders, isDisplayAvatarChecked, setIsDisplayAvatarChecked }) => (
  <AtlassianCheckboxField name="displayAvatar">
    {({ fieldProps }) => (
      <Checkbox
        {...fieldProps}
        isChecked={isDisplayAvatarChecked}
        label="Display avatar"
        onChange={(e) => {
          setIsDisplayAvatarChecked(!placeholder.data.displayAvatar);
          const p = placeholders.find((p) => p === placeholder);
          if (p.lifecycle === undefined) {
            p.lifecycle = PLACEHOLDER_EDITED;
          }
          p.data.displayAvatar = !placeholder.data.displayAvatar;
          analytics.logEvent(AnalyticsEvent.DISPLAY_AVATAR_USER, { isDisplayAvatar: !placeholder.data.displayAvatar });
        }}
      />
    )}
  </AtlassianCheckboxField>
);
UserMentionPlaceholderInPortal.metadataOptionsMessage = () => (
  <span style={{ display: "inline-flex" }}>
    <p style={{ color: "rgb(107, 119, 140)", display: "inline-block", marginLeft: "5px", fontSize: "14px" }}>Page metadata options</p>
    <span style={{ margin: "15px 0 0 3px" }}>
      <Tooltip content="Only one metadata can be displayed per field. If you choose one the other option will not be usable">
        <QuestionCircleIcon primaryColor="darkgrey" size="small" />
      </Tooltip>
    </span>
  </span>
);
UserMentionPlaceholderInPortal.displayCreator = ({
  placeholder,
  placeholders,
  isPageCreator,
  isLastModifier,
  setIsPageCreator,
  setIsLastModifier,
}) => (
  <AtlassianCheckboxField name="displayCreator">
    {({ fieldProps }) => (
      <Checkbox
        {...fieldProps}
        isChecked={isPageCreator}
        isDisabled={isLastModifier}
        style={isLastModifier ? { fontSize: "14px", cursor: "not-allowed" } : { fontSize: "14px" }}
        label="Display page creator"
        onChange={(e) => {
          setIsPageCreator(!placeholder.data.displayPageCreator);
          setIsLastModifier(false);
          const p = placeholders.find((p) => p === placeholder);

          if (p.lifecycle === undefined) {
            p.lifecycle = PLACEHOLDER_EDITED;
          }
          p.data.displayPageCreator = !placeholder.data.displayPageCreator;
          p.data.displayLastModifier = false;
        }}
      />
    )}
  </AtlassianCheckboxField>
);
UserMentionPlaceholderInPortal.displayLastModifier = ({
  placeholder,
  placeholders,
  isPageCreator,
  isLastModifier,
  setIsPageCreator,
  setIsLastModifier,
}) => (
  <AtlassianCheckboxField name="displayModifier">
    {({ fieldProps }) => (
      <Checkbox
        {...fieldProps}
        label="Display last modifier"
        isChecked={isLastModifier}
        isDisabled={isPageCreator}
        style={isPageCreator ? { fontSize: "14px", cursor: "not-allowed" } : { fontSize: "14px" }}
        onChange={(e) => {
          setIsPageCreator(false);
          setIsLastModifier(!placeholder.data.displayLastModifier);
          const p = placeholders.find((p) => p === placeholder);
          if (p.lifecycle === undefined) {
            p.lifecycle = PLACEHOLDER_EDITED;
          }
          p.data.displayLastModifier = !placeholder.data.displayLastModifier;
          p.data.displayPageCreator = false;
        }}
      />
    )}
  </AtlassianCheckboxField>
);

export function UserMentionPlaceholderInPortal({ placeholder }) {
  const { collapsed, setCollapsed } = useLifecycleSetCollapsed({ placeholder });
  const [isDisplayAvatarChecked, setIsDisplayAvatarChecked] = useState(
    placeholder.data.displayAvatar === undefined ? true : placeholder.data.displayAvatar
  );
  const [isPageCreator, setIsPageCreator] = useState(placeholder.data.displayPageCreator);
  const [isLastModifier, setIsLastModifier] = useState(placeholder.data.displayLastModifier);
  const { placeholders } = usePlaceholderStore((state) => ({
    placeholders: state.placeholders,
  }));

  return (
    <CollapsibleContainer
      Title={<PanelTitle placeholder={placeholder} />}
      Icon={usePlaceholderIcon(PLACEHOLDER_KIND_USER_MENTION, "small")}
      collapsed={collapsed}
      onCollapseClick={setCollapsed}
    >
      <Form>
        {({ formProps }) => (
          <form {...formProps} name="user-mention-placeholder">
            <NameField placeholder={placeholder} />
            <DescriptionField placeholder={placeholder} />
            <UserMentionPlaceholderInPortal.singleUser
              placeholder={placeholder}
              isPageCreator={isPageCreator}
              isLastModifier={isLastModifier}
            />
            <div style={{ fontSize: "14px" }}>
              <UserMentionPlaceholderInPortal.displayAvatar
                placeholder={placeholder}
                placeholders={placeholders}
                isDisplayAvatarChecked={isDisplayAvatarChecked}
                setIsDisplayAvatarChecked={setIsDisplayAvatarChecked}
              />
              <UserMentionPlaceholderInPortal.metadataOptionsMessage />
              <div style={{ display: "inline-flex" }}>
                <UserMentionPlaceholderInPortal.displayCreator
                  placeholder={placeholder}
                  placeholders={placeholders}
                  isPageCreator={isPageCreator}
                  isLastModifier={isLastModifier}
                  setIsPageCreator={setIsPageCreator}
                  setIsLastModifier={setIsLastModifier}
                />
                <UserMentionPlaceholderInPortal.displayLastModifier
                  placeholder={placeholder}
                  placeholders={placeholders}
                  isPageCreator={isPageCreator}
                  isLastModifier={isLastModifier}
                  setIsPageCreator={setIsPageCreator}
                  setIsLastModifier={setIsLastModifier}
                />
              </div>
            </div>
            <FormFooter>
              <DeleteProperty property={placeholder} />
            </FormFooter>
          </form>
        )}
      </Form>
    </CollapsibleContainer>
  );
}
