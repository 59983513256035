import { useEffect, useState } from "react";
import "./card.css";
import SectionMessage from "@atlaskit/section-message";
import Card from "./Card";
import Button from "@atlaskit/button";
import Spinner from "@atlaskit/spinner";
import PropertyBasedOnKind from "./PropertyBasedOnKind";
import { usePropertyGroupReportMacroStore, usePropertyLiveFilterStore } from "./store";
import { usePropertyGroupReportFormStore } from "./property-group-report-form-store";
import shallow from "zustand/shallow";
import { useQuery } from "react-query";
import { getPaginatedReport, getReport } from "./api";
import { ReportSortOrder } from "./report-sort-order";
import { generateId } from "../../../v2/id-generator";

const PropertyGroupReportCard = ({ preview = false }) => {
  const { setInitialValues, setValues } = usePropertyGroupReportFormStore((state) => ({
    values: state.values,
    initialValues: state.initialValues,
    setValues: state.setValues,
    setInitialValues: state.setInitialValues,
  }));
  const { propertyLiveFilters } = usePropertyLiveFilterStore((state) => ({
    propertyLiveFilters: state.propertyLiveFilters,
  }));
  const {
    group,
    selectedColumns,
    propertyFilters,
    pagesUnder,
    currentPageId,
    labels,
    spaces,
    defaultSortColumn,
    numberOfItems,
    defaultSortOrder,
  } = usePropertyGroupReportMacroStore(
    (state) => ({
      group: state.selectedGroup,
      selectedColumns: state.selectedColumns,
      defaultSortOrder: state.defaultSortOrder,
      defaultSortColumn: state.defaultSortColumn,
      labels: state.labels,
      pagesUnder: state.pagesUnder,
      currentPageId: state.currentPageId,
      spaces: state.selectedSpaces,
      propertyFilters: state.propertyFilters,
      numberOfItems: state.numberOfItems,
    }),
    shallow
  );

  const [records, setRecords] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [resolvedProperties, setResolvedProperties] = useState([]);
  const [pages, setPages] = useState([]);
  const [reportLength, setReportLength] = useState(numberOfItems);
  const [cardsRendered, setCardsRendered] = useState(false);
  const allPropertyFilters = { ...propertyFilters, ...propertyLiveFilters };
  const { isLoading, isFetchingNextPage, refetch, isFetching, data } = useQuery(
    [
      "group-report",
      {
        group,
        labels,
        spaces,
        pagesUnder: pagesUnder === "current-page" ? currentPageId : pagesUnder,
        filters: allPropertyFilters,
        limit: numberOfItems,
      },
    ],
    getReport,
    {
      retry: 0,
      select: (response) => {
        const pages = [response];
        return { pages, pageParams: [response?.pageParams] };
      },
    }
  );

  useEffect(() => {
    if (cardsRendered) {
      sortRowsByProperty(defaultSortColumn);
    }
  }, [cardsRendered]);

  useEffect(() => {
    refetch();
  }, [group, labels, spaces]);

  useEffect(() => {
    if (!defaultSortColumn || isLoading || isFetching || records.length === 0) {
      return;
    }

    const cardWrapperElement = document.querySelector(".grid-view");

    const mutationObserver = new MutationObserver((entries) => {
      if (records.length === document.querySelectorAll(".grid-view > div").length) {
        if (!cardsRendered) {
          setCardsRendered(true);
          mutationObserver.disconnect();
        }
      }
    });
    if (!cardsRendered) {
      mutationObserver.observe(cardWrapperElement, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }
  }, [isLoading, isFetching, records]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const newRecords = buildRecords({
      resolvedProperties: data.pages[0].data.resolvedProperties,
      report: data.pages.map((p) => p.data.report).flat(),
    });
    setHasNextPage(data?.pages[0]?.data?.allEntitiesMatchingFilters.length > newRecords.length);
    setResolvedProperties(data?.pages[0]?.data?.resolvedProperties);
    setPages(data?.pages[0]?.data?.allEntitiesMatchingFilters);
    if (!isLoading && !isFetching) {
      setRecords(newRecords);
    }
  }, [isLoading, isFetching, selectedColumns]);

  useEffect(() => {
    if (defaultSortColumn) {
      sortRowsByProperty(defaultSortColumn, defaultSortOrder);
    }
  }, [defaultSortColumn, defaultSortOrder]);

  const getMoreEntriesForReport = async () => {
    if (pages.length >= reportLength) {
      setReportLength(parseInt(reportLength) + parseInt(numberOfItems));
      const morePagesForReport = pages.slice(0, parseInt(reportLength) + parseInt(numberOfItems));
      const reportWithMoreEntries = await getPaginatedReport(group, resolvedProperties, morePagesForReport, numberOfItems);
      const moreRecords = buildRecords({
        resolvedProperties: reportWithMoreEntries?.data?.resolvedProperties,
        report: reportWithMoreEntries?.data?.report,
      });
      if (!isLoading && !isFetching) {
        setRecords(moreRecords);
      }
    }
  };

  const sortRowsByProperty = (propertyKey) => {
    const getVal = (cardElement) => {
      const sortableElement = cardElement.querySelector(`[data-property-key='${propertyKey}']`).querySelector(`[data-sortable=true]`);
      return sortableElement.dataset.rawValue;
    };
    const cards = Array.from(document.querySelectorAll(".grid-view .property-card"));
    if (cards.length === 0) {
      return;
    }
    cards.sort((a, b) => (getVal(a) || "").localeCompare(getVal(b) || ""));
    if (defaultSortOrder === ReportSortOrder.DESC) {
      cards.reverse();
    }
    const sortedRows = [];
    cards.forEach((row) => {
      const sortID = row.dataset.sortIdentifier;
      sortedRows.push(records.find((r) => r.sortIdentifier === sortID));
    });
    setRecords(sortedRows);
  };

  const buildRecords = (groupReport) => {
    const propertyValues = [];
    const valuesGroupedByRecordId = {};
    groupReport.report.map((recordsForEntity) => {
      const entityId = recordsForEntity.entityId;
      valuesGroupedByRecordId[entityId] = {};
      Object.keys(recordsForEntity.records).forEach((recordId) => {
        const recordForEntity = recordsForEntity.records[recordId];
        const recordWithResolvedValues = {
          entityId,
          entries: [],
          sortIdentifier: generateId(),
        };
        const keys = selectedColumns.length > 0 ? selectedColumns : Object.keys(recordForEntity);
        recordWithResolvedValues.entries = keys.map((propertyKey) => {
          const currentProperty = recordForEntity[propertyKey];
          return {
            key: propertyKey,
            info: groupReport.resolvedProperties[propertyKey],
            content: <PropertyBasedOnKind currentProperty={currentProperty} pageId={entityId} recordId={recordId} disableEdit />,
            value: currentProperty.value,
          };
        });
        recordWithResolvedValues.entries.forEach((entry) => {
          if (!(recordId in valuesGroupedByRecordId[entityId])) {
            valuesGroupedByRecordId[entityId][recordId] = {};
          }
          valuesGroupedByRecordId[entityId][recordId][entry.key] = entry.value;
        });
        propertyValues.push(recordWithResolvedValues);
      });
    });
    setValues(valuesGroupedByRecordId);
    setInitialValues(valuesGroupedByRecordId);
    return propertyValues;
  };

  if ((isLoading || isFetching) && !isFetchingNextPage) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner size={"large"} />
      </div>
    );
  }

  if (data.pages.length === 0 || data.pages[0].data.report.length === 0) {
    return (
      <div className="property-report-view">
        <SectionMessage
          style={{
            width: "100%",
          }}
        >
          <p>No records to display yet.</p>
        </SectionMessage>
      </div>
    );
  }

  return (
    <div className="property-report-view">
      <>
        <div className={`grid-view ${preview ? "card-preview" : ""}`}>
          {records.map((record, index) => {
            return <Card key={index} entityId={record.entityId} entries={record.entries} sortIdentifier={record.sortIdentifier} />;
          })}
        </div>
        {isFetchingNextPage && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner size={"small"} />
          </div>
        )}

        {hasNextPage && (
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Button appearance="link" onClick={() => getMoreEntriesForReport()}>
              Show more
            </Button>
          </div>
        )}
      </>
    </div>
  );
};
export default PropertyGroupReportCard;
