import { useEffect, useState } from "react";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import { AsyncSelect } from "@atlaskit/select";
import { usePropertyGroupReportUIStore } from "../../PropertyReportMacro/store";
import { token } from "@atlaskit/tokens";

function mapPage(page) {
  if (!page) {
    return null;
  }
  if (page?.content) {
    return {
      contentId: page?.content?.id,
      type: page?.content?.type,
      name: page?.content?.title,
      space: { name: page?.content?.space?.name },
      spaceKey: page?.content?.space?.key,
      spaceId: page?.content?.space?.id,
      excerpt: page?.excerpt,
      url: `${window.AP._hostOrigin}/wiki${page.url}`,
      createdBy: page?.content?.history?.createdBy?.displayName,
      lastModified: page?.friendlyLastModified ?? "",
      label: page?.content?.title,
      value: page?.content?.id,
    };
  }
  if (page?.id) {
    return {
      contentId: page?.id,
      type: page?.type,
      name: page?.title,
      space: { name: page?.space?.name },
      spaceKey: page?.space?.key,
      spaceId: page?.space?.id,
      excerpt: "",
      url: page?.["_links"]?.webui,
      createdBy: page?.history?.createdBy?.displayName,
      lastModified: page?.friendlyLastModified ?? "",
      label: page?.title,
      value: page?.id,
    };
  }
  return {
    label: page.title,
    value: page.id,
  };
}

const ConfluencePagePropertyEdit = ({ property, pageId, initialValue, onUpdate, placeholder = "Filter by page name" }) => {
  const [selectedPage, setSelectedPage] = useState([]);
  const defaultValue = property.data?.defaultPage?.id;
  if (defaultValue) {
    placeholder = "Choose a page or the default page will be selected";
  }
  const { loadedLiveFilter } = usePropertyGroupReportUIStore((state) => ({
    loadedLiveFilter: state.loadedLiveFilter,
  }));
  useEffect(async () => {
    if (Array.isArray(initialValue)) {
      initialValue.forEach((pageId) => {
        atlassianRestService
          .getPageById(pageId)
          .then((data) => {
            return mapPage(data);
          })
          .then((page) => {
            const pageExists = selectedPage.some((page) => page.value === pageId);
            if (!pageExists) {
              setSelectedPage((prev) => [...prev, page]);
            }
          });
      });
      return;
    } else if (initialValue) {
      atlassianRestService.getPageById(initialValue).then(mapPage).then(setSelectedPage);
    }
    if (Array.isArray(property.data?.displayCurrentPage)) {
      property.data?.displayCurrentPage.forEach((pageId) => {
        atlassianRestService
          .getPageById(pageId)
          .then(mapPage)
          .then((page) => {
            const pageExists = selectedPage.some((page) => page.value === pageId);
            if (!pageExists) {
              setSelectedPage((prev) => [...prev, page]);
            }
          });
      });
      return;
    } else if (property.data?.displayCurrentPage) {
      atlassianRestService.getPageById(pageId).then(mapPage).then(setSelectedPage);
    }
  }, [initialValue]);

  const getPageIdsByNames = (data, searchLabels) => {
    const result = [];
    data.labelsOfPages.map((item) => {
      const found = searchLabels.includes(item.name);
      if (found) {
        result.push(data.pageId);
      }
    });
    return result;
  };

  const loadOptions = async (query) => {
    let labels = [];
    if (property?.data?.labels) {
      labels = Object.values(property?.data?.labels).flat();
    }
    let spaces = [];
    if (property?.data?.spaces) {
      spaces = Object.values(property?.data?.spaces).flat();
    }
    const foundOptions = (await atlassianRestService.searchForPage({ query, labels })).results.map(mapPage);
    const labelsOfOptions = await atlassianRestService.getPageLabelsByPageId(foundOptions);

    if (foundOptions?.length > 0 && loadedLiveFilter) {
      window.AP.events.emit("trigger_resize_livefilter", [".confluence-page-property"]);
    }
    if (labels.length) {
      const matchedOptions = [];
      const foundPageLabels = labelsOfOptions.map((labelsWithPageId) => {
        if (labelsWithPageId?.labelsOfPages?.length > 0) {
          return getPageIdsByNames(labelsWithPageId, labels);
        }
      });
      const filteredPageIds = foundPageLabels.flat().filter(Boolean);
      foundOptions.map((option) => {
        if (filteredPageIds.length > 1) {
          filteredPageIds.map((pageId) => {
            if (pageId === option.contentId) {
              matchedOptions.push(option);
            }
          });
        } else if (filteredPageIds.length === 1 && filteredPageIds[0] === option.contentId) {
          matchedOptions.push(option);
        }
      });
      return matchedOptions;
    }
    if (spaces.length) {
      return foundOptions.filter((page) => {
        return spaces.includes(page.spaceKey);
      });
    }
    return foundOptions;
  };
  if (property.data?.displayCurrentPage) {
    return <p style={{ fontSize: "14px", marginLeft: "2px" }}>Property will display the current page</p>;
  }
  return (
    <AsyncSelect
      className="confluence-page-property"
      placeholder={placeholder}
      autoFocus={loadedLiveFilter}
      maxMenuHeight={150}
      isClearable
      openMenuOnFocus
      isMulti
      styles={{
        control: (base) => ({
          ...base,
          backgroundColor: `${token("utility.elevation.surface.current")}`,
          borderColor: `${token("color.border.input")}`,
          boxShadow: `${token("elevation.shadow.overflow")}`,
        }),
        placeholder: (base) => ({ ...base, fontSize: "14px", fontWeight: "400" }),
        singleValue: (base) => ({ ...base, fontSize: "14px", fontWeight: "400" }),
        menuList: (base) => ({ ...base, fontSize: "14px", fontWeight: "400", textAlign: "left", paddingTop: "0px" }),
      }}
      onChange={(e) => {
        if (e !== undefined || e?.contentId !== undefined) {
          setSelectedPage(e);
          loadedLiveFilter ? onUpdate(e) : onUpdate(e?.map((page) => page?.contentId));
        } else {
          setSelectedPage([]);
          onUpdate([]);
        }
      }}
      value={selectedPage}
      loadOptions={loadOptions}
    />
  );
};
export default ConfluencePagePropertyEdit;
