import TextField from "@atlaskit/textfield";
import { usePropertyGroupReportMacroStore } from "./store";

const PAGINATION_SIZE = 25;

const NumberOfItemsPagination = () => {
  const { numberOfItems, setNumberOfItems } = usePropertyGroupReportMacroStore((state) => ({
    numberOfItems: state.numberOfItems,
    setNumberOfItems: state.setNumberOfItems,
  }));

  const handleChange = (e) => {
    setNumberOfItems(e.target.value);
  };

  return (
    <div>
      <TextField
        value={numberOfItems}
        placeholder=""
        type="number"
        onChange={handleChange}
        onBlur={(e) => {
          const value = parseInt(e.target.value) || PAGINATION_SIZE;
          if (value > 50) {
            setNumberOfItems(PAGINATION_SIZE);
            return;
          }
        }}
      />
    </div>
  );
};

export default NumberOfItemsPagination;
