import React from "react";

export const PanelTitle = ({ placeholder }) => {
  if (placeholder.data.name === undefined) {
    return <span></span>;
  }
  if (placeholder.lifecycle === "deleted") {
    return <span style={{ textDecoration: "line-through", color: "red" }}>{placeholder.data.name}</span>;
  }
  return <span>{placeholder.data.name}</span>;
};
