import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { createNewPage } from "../PropertyVisualizationMacro/api";
import { notify } from "../Shared/notification-flag";

const useSaveQueue = ({
  groupId,
  onDeleteEntry,
  rowsInfo,
  createCardInputsRef,
  onNameEmpty,
  onLoadersTrigger,
  createCardInputs,
  valuesPerOrdinalNumber,
  onRecordsUpdate,
  isSaveQueryRegistered,
}) => {
  const [saveQueueEffectTrigger, setSaveQueueEffectTrigger] = useState(false);
  const saveQueue = useRef([]);

  useEffect(() => {
    if (saveQueue.current.length > 0 && !isSaveQueryRegistered.current) {
      isSaveQueryRegistered.current = true;
      onSaveQueryLengthChange();
    }
  }, [saveQueueEffectTrigger]);

  const onSaveQueryLengthChange = async () => {
    while (saveQueue.current?.length > 0) {
      const { idx: oldIdx, name } = saveQueue.current.shift();
      const newIdx = createCardInputsRef.current.findIndex((cardInput) => cardInput === name);
      await onSave(name, newIdx, oldIdx);
    }
    isSaveQueryRegistered.current = false;
  };

  const handeSaveToQueue = (idx) => {
    const name = createCardInputs[idx];
    if (!name) {
      onNameEmpty(idx);
      return;
    }
    onLoadersTrigger(idx, true);
    if (!saveQueue.current || saveQueue.current?.length === 0 || !Array.isArray(saveQueue.current)) {
      saveQueue.current = [{ idx, name }];
    } else {
      saveQueue.current.push({ idx, name });
    }

    setSaveQueueEffectTrigger(!saveQueueEffectTrigger);
    return;
  };

  const onSave = async (cardName, idx, oldIdx) => {
    createCardInputsRef.current = createCardInputsRef.current.slice(0, idx).concat(createCardInputsRef.current.slice(idx + 1));
    const recordId = uuidv4();

    const { confluence: context } = await window.AP.context.getContext();
    const spaceId = context.space.id;
    const spaceKey = context.space.key;
    const parentId = context.content.id;

    const values = valuesPerOrdinalNumber[oldIdx];
    const stringifiedValues = JSON.stringify(values, true);

    try {
      const response = await createNewPage(spaceId, parentId, recordId, groupId, cardName, stringifiedValues, spaceKey);
      if (response.id) {
        onRecordsUpdate(groupId, values, response, recordId);
        notify("success", `${cardName} added`);
      } else {
        if (
          response.message === "com.atlassian.confluence.api.service.exceptions.BadRequestException: A page with this title already exists"
        ) {
          notify("error", "A page with this title already exists");
        } else {
          notify("error", response.message);
        }
      }
    } catch (error) {
      notify("error", error.message);
    } finally {
      onDeleteEntry(idx, false);
      return true;
    }
  };

  const onSaveAll = () => {
    rowsInfo.forEach((_, idx) => {
      const name = createCardInputsRef.current[idx];
      if (!name) {
        onNameEmpty(idx);
        return;
      }

      onLoadersTrigger(idx, true);
      saveQueue.current.push({ idx, name });
    });
    setSaveQueueEffectTrigger(!saveQueueEffectTrigger);
  };

  return { handeSaveToQueue, onSaveAll, isSaveQueryRegistered };
};

export default useSaveQueue;
