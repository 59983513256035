import { useEffect, useState } from "react";
import { atlassianRestService } from "../../Service/AtlassianRestService";
import { getSpaceByKey } from "./api";
import { AsyncSelect } from "@atlaskit/select";
import { usePropertyGroupReportMacroStore } from "./store";
import { SelectFieldStyles } from "../Shared/SelectFieldStyles";
import { AnalyticsEvent } from "../../../analytics-events";
import { analytics } from "../../../analytics";
import { token } from "@atlaskit/tokens";


function mapSpace(space) {
  return {
    icon: space.icon,
    name: space.name,
    spaceKey: space.key,
    type: "space",
    label: space.name,
    value: space.key,
    url: `${window.AP._hostOrigin}/wiki/spaces/${space.key}/overview`,
  };
}

const ReportSpaceFilter = () => {
  const { spaces, setSpaces, toggleSpaceChange, spaceChanged } = usePropertyGroupReportMacroStore((state) => ({
    spaces: state.selectedSpaces,
    setSpaces: state.setSelectedSpaces,
    toggleSpaceChange: state.toggleSpaceChange,
  }));
  const [selectedSpaces, setSelectedSpaces] = useState([]);

  const loadSpaces = async (query) => {
    if (!query || query.trim().length === 0) {
      return [];
    }
    const results = (await atlassianRestService.searchForSpace(query)).results.map(({ space }) => {
      return { label: space.name, value: space.key };
    });
    return results;
  };

  useEffect(() => {
    if ((spaces ?? []).length > 0) {
      const promises = [];
      for (const spaceKey of spaces) {
        promises.push(getSpaceByKey(spaceKey));
      }
      Promise.all(promises)
        .then((results) => {
          setSelectedSpaces(results.map((r) => mapSpace(JSON.parse(r.body))));
        })
        .catch((e) => {
          // Handle errors here
        });
    }
  }, []);

  return (
    <AsyncSelect
      placeholder="Filter by space"
      isClearable
      isSearchable
      isMulti
      styles={SelectFieldStyles && { control: (base) => ({ ...base, backgroundColor: token("elevation.surface.overlay"), fontSize: "14px" }) }}
      onChange={(updatedSpaces) => {
        setSelectedSpaces(updatedSpaces);
        const spaceKeys = updatedSpaces.map((space) => space.value);
        setSpaces(spaceKeys);
        toggleSpaceChange();
        analytics.logEvent(AnalyticsEvent.SPACE_FILTER_USED, { LiveFilterUsed: true });
      }}
      value={selectedSpaces}
      cacheOptions
      defaultOptions
      loadOptions={loadSpaces}
    />
  );
};

export default ReportSpaceFilter;
