import { useEffect } from "react";
import shallow from "zustand/shallow";
import { usePropertyVisualizationStore } from "../property-visualization-store";
import { ChartVariant } from "./defaults";
import SystemPropertiesStructureVisualization from "./SystemPropertiesStructureVisualization";
import StructureVisualization from "./StructureVisualization";
import PropertyVisualizationSidebar from "./PropertyVisualizationSidebar";
import VisualizationEmptyState from "./VisualizationEmptyState";
import { ChartStrategy } from "./chart-strategy";

const StructureVisualizationEditWrapper = (props) => {
  const { rootConfig, config, updateConfig } = usePropertyVisualizationStore(
    (state) => ({
      rootConfig: state.config,
      config: state.config.args,
      updateConfig: state.updateConfig,
    }),
    shallow
  );

  useEffect(() => {
    if (!rootConfig.args) {
      updateConfig({
        strategy: ChartStrategy.PROPERTY_GROUP,
        data: {
          group: undefined,
          variant: ChartVariant.STRUCTURE,
          args: {},
        },
      });
    }
  }, []);

  if (!rootConfig.args) {
    return <></>;
  }

  return (
    <>
      <PropertyVisualizationSidebar />
      <div style={{ width: "100%", overflow: "scroll" }}>
        {config?.strategy === ChartStrategy.PROPERTY_GROUP &&
          config?.data?.group &&
          config?.data?.args?.primary &&
          config?.data?.args?.secondary && <StructureVisualization />}
        {config?.strategy === ChartStrategy.PROPERTY_GROUP &&
          (!config?.data?.group || !config?.data?.args?.primary || !config?.data?.args?.secondary) && (
            <VisualizationEmptyState description="Make sure to select a property group on the left side and configure the relation of the properties." />
          )}
        {config?.strategy === ChartStrategy.SYSTEM && config.data.args.assignments?.length <= 1 && (
          <VisualizationEmptyState description="Make sure to select a user on the left side and configure relations by adding other users or spaces." />
        )}
        {config?.strategy === ChartStrategy.SYSTEM && <SystemPropertiesStructureVisualization isEdit />}
      </div>
    </>
  );
};

export default StructureVisualizationEditWrapper;
