import { useEffect, useState } from "react";
import { LoadingState } from "../LoadingState";
import { usePropertyGroupChangelogStore } from "./PropertyGroupLogStore";
import Button from "@atlaskit/button";
import { atlassianRestService } from "../../Service/AtlassianRestService";
import { FormattedDate } from "../FormattedDate";
import ArrowRightIcon from "@atlaskit/icon/glyph/arrow-right";
import { LazyUserMention } from "../../../v2/Components/PropertyReportMacro/LazyUserMention";
import shallow from "zustand/shallow";
import SelectOptions from "../PropertyGroupMacro/view/SelectOptions";
import ModalWrapper from "../../CustomModal/ModalWrapper";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import { LazyPageLink } from "../PropertyReportMacro/LazyPageLink";
import styled from "styled-components";
import { token } from "@atlaskit/tokens";

const PropertyGroupLog = (props) => {
  const [loading, setLoading] = useState(true);
  const [recordId, setRecordId] = useState(undefined);
  const [entityId, setEntityId] = useState(undefined);
  const [loadingMore, setLoadingMore] = useState(false);

  const { loadMore, entries, allLoaded } = usePropertyGroupChangelogStore(
    (state) => ({
      loadMore: state.loadMore,
      entries: state.entries,
      allLoaded: state.allLoaded,
    }),
    shallow
  );

  useEffect(() => {
    const atlassianConnectContentElement = document.getElementsByClassName("ac-content")[0];
    if (atlassianConnectContentElement) {
      atlassianConnectContentElement.style.height = "100vh";
    }
  }, []);

  useEffect(() => {
    window.AP.theming.initializeTheming();
  }, []);

  useEffect(() => {
    window.AP.require("dialog", function (dialog) {
      dialog.getCustomData((data) => {
        if (data.recordId && data.entityId) {
          setRecordId(data.recordId);
          setEntityId(data.entityId);
          loadMore(data.entityId, data.recordId).then(() => {
            setLoading(false);
          });
        }
      });
    });
  }, [props]);

  const handleLoadMore = async () => {
    setLoadingMore(true);
    await loadMore(entityId, recordId);
    setLoadingMore(false);
  };

  if (loading) {
    return <LoadingState />;
  }

  return (
    <ModalWrapper>
      <CustomModalHeader title={"Changelog"}></CustomModalHeader>
      <div
        style={{
          padding: "20px",
          backgroundColor: token("utility.elevation.surface.current"),
          maxHeight: "500px",
          overflow: "scroll",
        }}
      >
        {entries.map((entry) => {
          return <LogEntry entry={entry} pageId={entityId} />;
        })}
        {allLoaded && <span></span>}
        {loadingMore && (
          <Button appearance="link" isDisabled style={{ paddingLeft: "0px" }}>
            Loading...
          </Button>
        )}
        {!loadingMore && !allLoaded && (
          <Button appearance="link" onClick={handleLoadMore} style={{ paddingLeft: "0px" }}>
            Show more
          </Button>
        )}
      </div>
      <CustomModalFooter documentationURL="https://junovi.atlassian.net/wiki/x/IQDkfw" secondaryActionLabel="Close" saveHidden />
    </ModalWrapper>
  );
};

const LogEntry = (props) => {
  const [, setLoading] = useState(true);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    (async () => {
      if (!props.entry.createdBy) {
        setLoading(false);
        setProfile({});
        return;
      }
      const result = await atlassianRestService.getUserByAccountId(props.entry.createdBy);
      setProfile(_toUserField(result));
      setLoading(false);
    })();
  }, [props.entry.createdBy]);

  return (
    <div style={{ padding: "10px 0px" }}>
      <table>
        <tr>
          <td style={{ width: "40px" }}>
            <span
              role="img"
              style={{
                marginRight: "5px",
                backgroundImage: "url(" + profile.avatarUrl + ")",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                borderRadius: "50%",
                width: "36px",
                height: "36px",
                display: "flex",
              }}
            ></span>
          </td>
          <td>
            <EntryLogHeader user={profile} entry={props.entry} pageId={props.pageId} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "4px",
              }}
            >
              <div
                style={{
                  maxWidth: "50%",
                }}
              >
                {props.entry.fromValue ? (
                  <LogValue value={props.entry.fromValue} propertyKind={props.entry.propertyKind} />
                ) : (
                  <span
                    style={{
                      color: "#42526E",
                      backgroundColor: "#DFE1E6",
                      borderRadius: "3px",
                      cursor: "default",
                      display: "flex",
                      lineHeight: "0.8",
                      padding: "4px",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    None
                  </span>
                )}
              </div>
              <div
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                <ArrowRightIcon />
              </div>
              <div
                style={{
                  maxWidth: "50%",
                }}
              >
                <LogValue value={props.entry.toValue} propertyKind={props.entry.propertyKind} />
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
};

const LogValue = ({ value, propertyKind }) => {
  if (propertyKind === "SELECT" && value) {
    return <SelectOptions values={[value]} single />;
  }
  if (propertyKind === "CONFLUENCEPAGE" && value) {
    return <LazyPageLink key={value} pageId={value}></LazyPageLink>;
  }
  if (propertyKind === "MULTI-SELECT" && value) {
    if (value.length === 0) {
      return (
        <span
          style={{
            color: "#42526E",
            backgroundColor: "#DFE1E6",
            borderRadius: "3px",
            cursor: "default",
            display: "flex",
            lineHeight: "0.8",
            padding: "4px",
            fontSize: "14px",
            fontWeight: "700",
          }}
        >
          Field set to empty
        </span>
      );
    }
    return (
      <SelectOptionsChangelogWrapper>
        <SelectOptions values={value} />
      </SelectOptionsChangelogWrapper>
    );
  }
  if (propertyKind === "USER-MENTION" && value) {
    if (value.length === 0) {
      return (
        <span
          style={{
            color: "#42526E",
            backgroundColor: "#DFE1E6",
            borderRadius: "3px",
            cursor: "default",
            display: "flex",
            lineHeight: "0.8",
            padding: "4px",
            fontSize: "14px",
            fontWeight: "700",
          }}
        >
          Field set to empty
        </span>
      );
    }
    return <LazyUserMention accountIDs={value} />;
  }
  if (propertyKind === "DATE" && value) {
    return (
      <span
        style={{
          background: "rgba(9, 30, 66, 0.08)",
          borderRadius: "6px",
          padding: "2px 4px",
          inlineSize: "max-content",
          whiteSpace: "nowrap",
        }}
      >
        {value}
      </span>
    );
  }
  return <span>{value}</span>;
};

const SelectOptionsChangelogWrapper = styled.div`
  .multiselect-property-value {
    flex-flow: column wrap !important;
  }
`;

const EntryLogHeader = ({ entry, user, pageId }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <span
          style={{
            fontWeight: "600",
            marginLeft: "2px",
          }}
        >
          {user.name}&nbsp;
        </span>
        <span>changed&nbsp;</span>
        <span
          style={{
            fontWeight: "600",
          }}
        >
          {entry.lastDisplayName}&nbsp;
        </span>
        <span>on&nbsp;</span>
        <FormattedDate seconds={entry.created._seconds} nanoseconds={entry.created._nanoseconds} inChangelog />

        {entry?.version ? (
          <>
            <div
              style={{
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <ArrowRightIcon />
            </div>
            <a href={`${window.AP._hostOrigin}/wiki/pages/viewpage.action?pageId=${pageId}&pageVersion=${entry.version}`} target="_blank">
              page v{entry.version}
            </a>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

function _toUserField(user) {
  return {
    avatarUrl: atlassianRestService.getProfilePictureUrl(user.profilePicture.path),
    id: user.accountId,
    name: user.publicName,
    type: "user",
  };
}

export default PropertyGroupLog;
