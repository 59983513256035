import { atlassianRestService } from "../../Service/AtlassianRestService";
export function _toUserField(user) {
  return {
    avatarUrl: atlassianRestService.getProfilePictureUrl(user.profilePicture.path),
    id: user.accountId,
    name: user.publicName,
    type: "user",
    label: user.publicName,
    value: user.accountId,
  };
}
