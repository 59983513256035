import { useState } from "react";
import BoardVisualizationContainer from "./BoardVisualizationContainer";
import ViewWrapperTopBar from "../Shared/ViewWrapperTopBar";
import { ViewerType } from "../../viewer-type";
import { usePropertyVisualizationStore } from "../property-visualization-store";

const ThumbnailViewer = ({ children }) => {
  const { rootConfig } = usePropertyVisualizationStore((state) => ({
    rootConfig: state.config,
  }));

  return (
    <div>
      <div>
        <ViewWrapperTopBar
          onlyFullScreen
          customData={{
            id: ViewerType.BOARD,
            args: {
              config: rootConfig,
            },
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          border: "1px solid #cecece",
          overflowY: "hidden",
        }}
      >
        {children}
      </div>
    </div>
  );
};

const BoardVisualizationViewWrapper = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div style={{ width: "100%" }}>
      <ThumbnailViewer loading={loading}>
        <BoardVisualizationContainer
          onLoaded={() => {
            setLoading(false);
          }}
        />
      </ThumbnailViewer>
    </div>
  );
};

export default BoardVisualizationViewWrapper;
