import { useEffect, useState } from "react";
import { AsyncSelect } from "@atlaskit/select";
import Spinner from "@atlaskit/spinner";
import { SelectFieldStyles } from "../SelectFieldStyles";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import { usePlaceholderPortalStore } from "../Store";
import { _toUserField } from "../toUserField";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";

function mapValues(value) {
  return {
    type: "group",
    id: value.id,
    label: value.name,
    value: value.id,
  };
}

export const UserOrGroupPicker = ({ onChangeRestriction, isNewProperty = false, isPermissionsDialogOpen = false, activRestrictions }) => {
  const [selectedPermission, setSelectedPermission] = useState();
  const { selectedPlaceholderSet } = usePlaceholderPortalStore((state) => ({
    selectedPlaceholderSet: state.selectedPlaceholderSet,
  }));

  useEffect(() => {
    (async () => {
      if (isNewProperty) {
        setSelectedPermission([]);
      } else {
        const { userOwners: userRestriction, groupOwners: groupRestriction } = selectedPlaceholderSet;
        const restrictions = [];
        if (groupRestriction && groupRestriction.length > 0) {
          const groupPromise = groupRestriction.map((groupId) => atlassianRestService.getGroupById(groupId));
          const responseOfGroups = await Promise.all(groupPromise);
          responseOfGroups.map((group) => restrictions.push({ label: group.name, value: group.id, type: "group", id: group.id }));
        }
        if (userRestriction && userRestriction.length > 0) {
          const userPromise = userRestriction.map((userId) => atlassianRestService.getUserByAccountId(userId));
          const responseOfUser = await Promise.all(userPromise);
          responseOfUser.map((user) =>
            restrictions.push({ label: user.displayName, value: user.accountId, type: "user", id: user.accountId })
          );
        }
        restrictions.forEach((item) => {
          if (!item.avatarUrl) {
            atlassianRestService.getUserByAccountId(item.id).then(async (result) => {
              if (result?.profilePicture) {
                item.avatarUrl = await atlassianRestService.getProfilePictureUrl(result.profilePicture.path);
              }
            });
          }
        });
        if (isPermissionsDialogOpen) {
          activRestrictions(restrictions);
          setSelectedPermission([]);
        } else {
          setSelectedPermission(restrictions);
        }
      }
    })();
  }, [selectedPlaceholderSet]);


  const loadOptions = async (searchQuery) => {
    if (searchQuery.trim().length === 0) {
      return [];
    }
    const [users, groups] = await Promise.all([
      atlassianRestService.findUsersByName(searchQuery),
      atlassianRestService.getGroupsByName(searchQuery),
    ]);
    const allOptionsAsync = [
      ...users.results.filter(({ user }) => user.accountType === "atlassian").map(({ user }) => _toUserField(user)),
      ...groups.results.map((group) => mapValues(group)),
    ];
    const allOptions = await Promise.all(allOptionsAsync);
    return allOptions;
  };

  if (!selectedPermission) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spinner size="small" />
      </div>
    );
  }

  const formatOptionLabel = ({ label, avatarUrl }) => {
    if (avatarUrl) {
      return (
        <span style={{ display: "flex", alignItems: "center" }}>
          <span
            role="img"
            style={{
              backgroundImage: "url(" + avatarUrl + ")",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: "50%",
              width: "23px",
              height: "23px",
              display: "flex",
              marginRight: "5px",
              display: "inline-flex",
              alignItems: "center",
            }}
          ></span>
          <span>{label}</span>
        </span>
      );
    } else {
      return (
        <span style={{ display: "inline-flex", alignItems: "center" }}>
          <PeopleGroupIcon primaryColor="darkgrey" size="medium" />
          <span style={{ marginLeft: "5px" }}>{label}</span>
        </span>
      );
    }
  };

  return (
    <AsyncSelect
      isMulti
      placeholder="Select users or groups"
      styles={SelectFieldStyles}
      formatOptionLabel={formatOptionLabel}
      onChange={(permission) => {
        if (permission !== undefined) {
          let userPermission = permission.filter((value) => value.type === "user").map((pickedValue) => pickedValue.id);
          let groupPermission = permission.filter((value) => value.type === "group").map((pickedValue) => pickedValue.id);
          onChangeRestriction({ userPermission, groupPermission });
          setSelectedPermission(permission);
        } else {
          setSelectedPermission(undefined);
        }
      }}
      value={selectedPermission}
      loadOptions={loadOptions}
    />
  );
};
