import { useEffect, useState } from "react";
import UserPicker from "@atlaskit/user-picker";
import Form from "@atlaskit/form";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import { token } from "@atlaskit/tokens";

function mapUser(user) {
  return {
    avatarUrl: atlassianRestService.getProfilePictureUrl(user.profilePicture.path),
    id: user.accountId,
    name: user.publicName,
    type: "user",
    label: user.publicName,
    value: user.accountId,
  };
}

const UserPropertyEdit = ({ property, initialValue, autoFocus, onUpdate, isEditing }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if ((initialValue ?? []).length > 0) {
      const promises = [];
      for (const accountId of initialValue) {
        promises.push(atlassianRestService.getUserByAccountId(accountId));
      }
      Promise.all(promises)
        .then((results) => {
          setSelectedUsers(results.map((r) => mapUser(r)));
        })
        .catch((e) => {
          // Handle errors here
        });
    }
  }, [initialValue]);

  const loadOptions = async (query) => {
    if (query.trim().length === 0) {
      return [];
    }
    return (await atlassianRestService.findUsersByName(query)).results
      .filter(({ user }) => user.accountType === "atlassian")
      .map(({ user }) => mapUser(user));
  };
  if (property.data?.displayPageCreator && !isEditing) {
    return <p style={{ fontSize: "14px", marginLeft: "2px" }}>Property will display the page creator</p>;
  }
  if (property.data?.displayLastModifier && !isEditing) {
    return <p style={{ fontSize: "14px", marginLeft: "2px" }}>Displays the latest modifier of the page</p>;
  }
  return (
    <div>
      <Form name="userpicker">
        {({ fieldProps, options }) => (
          <UserPicker
            autoFocus={autoFocus}
            menuPosition="fixed"
            placeholder={property?.data?.description}
            isMulti={!property.data?.checkboxes?.includes("singleUser")}
            addMoreMessage=""
            options={options}
            onChange={(e) => {
              if (e === undefined) {
                setSelectedUsers([]);
                onUpdate([]);
                return;
              }
              if (!property.data?.checkboxes?.includes("singleUser")) {
                setSelectedUsers(e);
                onUpdate(e.map((u) => u.id));
                return;
              }
              setSelectedUsers([e]);
              onUpdate([e.id]);
            }}
            value={selectedUsers}
            loadOptions={loadOptions}
            styles={{
              control: (styles) => ({
                ...styles,
                backgroundColor: `${token("utility.elevation.surface.current")}`,
                borderColor: `${token("color.border.input")}`,
                boxShadow: `${token("elevation.shadow.overflow")}`,
                fontSize: "14px",
                width: "inherit",
                fontWeight: "normal",
              }),
              menuPortal: (styles) => ({
                ...styles,
                position: "initial",
              }),
              menu: (styles) => ({
                ...styles,
                width: "100%",
                marginTop: "0px",
              }),
              menuList: (styles) => ({
                ...styles,
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "normal",
              }),
            }}
          />
        )}
      </Form>
    </div>
  );
};

export default UserPropertyEdit;
