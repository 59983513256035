import React, { useState } from "react";
import Button from "@atlaskit/button";
import { ConfirmDeletingPropertyModal } from "./";
import { usePlaceholderStore } from "../../../../Store";

export const DeleteProperty = ({ property }) => {
  const [confirmDeletingPropertyIsVisible, setConfirmDeletingPropertyIsVisible] = useState(false);
  const { deleteDirectlyNewProperty, deletePlaceholder } = usePlaceholderStore((state) => ({
    deleteDirectlyNewProperty: state.deleteDirectlyNewProperty,
    deletePlaceholder: state.deletePlaceholder,
  }));

  const handleDelete = () => {
    if (property.lifecycle === "created") {
      deleteDirectlyNewProperty(property);
      return;
    }
    deletePlaceholder(property);
  };
  const handleClose = () => {
    setConfirmDeletingPropertyIsVisible(false);
  };
  const handleConfirm = async () => {
    handleDelete();
  };
  const handleOpen = () => {
    setConfirmDeletingPropertyIsVisible(true);
  };

  return (
    <>
      <ConfirmDeletingPropertyModal onClose={handleClose} onConfirm={handleConfirm} visible={confirmDeletingPropertyIsVisible} />
      <Button onClick={handleOpen}>Delete</Button>
    </>
  );
};
