import { useEffect, useState } from "react";
import Button from "@atlaskit/button";
import PeopleIcon from "@atlaskit/icon/glyph/people";
import EmptyState from "@atlaskit/empty-state";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from "@atlaskit/modal-dialog";
import { atlassianRestService } from "../../../../../Service/AtlassianRestService";
import NoRestrictionsApplied from "../../../NoRestrictionsApplied.png";
import { _toUserField } from "../../../toUserField";
import styled from "styled-components";

const ListContainer = styled.div`
  max-height: 500px;
  overflow: overlay;
  &::-webkit-scrollbar {
    display: none;
  }
  &:hover::-webkit-scrollbar {
    display: inline-block;
    height: 8px;
    width: 9px;
    background-color: #f4f5f7;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    width: 9px;
    background-color: #c1c1c1
    border-radius: 10px;
  }
  `;

const PermissionList = ({ listPermission }) => {
  const [, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [displayName, setDisplayName] = useState("");
  const avatarStyle = {
    backgroundImage: `url(${profile.avatarUrl})`,
  };

  useEffect(() => {
    (async () => {
      const userResult = await atlassianRestService.getUserByAccountId(listPermission);
      const groupResult = await atlassianRestService.getGroupById(listPermission);
      if (userResult) {
        setDisplayName(userResult.publicName);
        setProfile(_toUserField(userResult));
      }
      if (groupResult) {
        setDisplayName(groupResult.name);
      }
      setLoading(false);
    })();
  }, [listPermission]);

  return (
    <td>
      <td>
        <td style={{ width: "40px" }}>
          {profile.avatarUrl ? <span role="img" className="permissionList-avatar" style={avatarStyle}></span> : <PeopleIcon size="large" />}
        </td>
        <td style={{ width: "400px" }}>{displayName}</td>
      </td>
    </td>
  );
};
const PermissionDialog = ({ onClose, visible, groupOwners, userOwners, isUserRestricted, openDrawer }) => {
  const [activPermissions, setActivPermissions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [noRestrictionsApplied, setNoRestrictionsApplied] = useState(false);

  useEffect(() => {
    const permissions = [...(groupOwners ?? []), ...(userOwners ?? [])];
    setNoRestrictionsApplied(permissions.length === 0);
    setActivPermissions(permissions);
    setLoaded(true);
  }, [groupOwners, userOwners]);

  return (
    visible && (
      <ModalTransition>
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle>Restrictions</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <ListContainer>
              {noRestrictionsApplied ? (
                <EmptyState
                  description="No restrictions are applied. Everybody can edit this Property Group."
                  imageUrl={NoRestrictionsApplied}
                />
              ) : (
                <table>
                  {loaded &&
                    activPermissions.map((permission) => (
                      <tr key={permission}>
                        <PermissionList listPermission={permission} />
                      </tr>
                    ))}
                </table>
              )}
            </ListContainer>
          </ModalBody>
          <ModalFooter style={isUserRestricted ? { display: "flex", justifyContent: "flex-end" } : { display: "flex" }}>
            {!isUserRestricted && (
              <Button
                appearance="link"
                className="permissionDialog-editButton"
                onClick={() => {
                  openDrawer();
                  onClose();
                }}
              >
                Edit permissions in the sidebar
              </Button>
            )}
            <Button appearance="subtle" onClick={onClose} style={{ display: "flex", justifyContent: "flex-end" }}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </ModalTransition>
    )
  );
};

export default PermissionDialog;
