import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "@atlaskit/checkbox";
import Form, { HelperMessage } from "@atlaskit/form";
import { FormFooter } from "@atlaskit/form/FormFooter";
import { CheckboxField as AtlassianCheckboxField } from "@atlaskit/form";
import { NameField } from "../Fields/NameField";
import { PanelTitle } from "../Fields/PanelTitle";
import { DeleteProperty } from "../DeletePlaceholder";
import DefaultPageField from "../Fields/DefaultPageField";
import { usePlaceholderIcon } from "../usePlaceholderIcon";
import { PageFiltersField } from "../Fields/PageFiltersField";
import { DescriptionField } from "../Fields/DescriptionField";
import { CollapsibleContainer } from "../CollapsibleContainer";
import { useLifecycleSetCollapsed } from "./useLifecycleSetCollapsed";
import { PLACEHOLDER_KIND_CONFLUENCE_PAGE } from "../PlaceholderKinds";
import { AnalyticsEvent } from "../../../../../../../analytics-events";
import { analytics } from "../../../../../../../analytics";
import { PLACEHOLDER_EDITED } from "../../../../../../Lifecycles";
import { atlassianRestService } from "../../../../../../Service/AtlassianRestService";
import { usePlaceholderStore } from "../../../../Store";
import "./ConfluencePagePlaceholderInPortal.scss";

ConfluencePagePlaceholderInPortal.propTypes = {
  placeholder: PropTypes.object.isRequired,
};
export function ConfluencePagePlaceholderInPortal({ placeholder, triggered }) {
  const [placeholderUniqueId, setPlaceholderUniqueId] = useState(placeholder.id + placeholder.data.name);
  const { collapsed, setCollapsed } = useLifecycleSetCollapsed({ placeholder });
  const [showCurrentPage, setShowCurrentPage] = useState(placeholder.data.displayCurrentPage);
  const [selectedPage, setSelectedPage] = useState(placeholder.data?.defaultPage);
  const [inputState, setInputState] = useState("");
  const [fetchedPages, setFetchedPages] = useState([]);
  const { placeholders, labels, spaces, setAreLabelsChanged, setAreSpacesChanged } = usePlaceholderStore((state) => ({
    placeholders: state.placeholders,
    labels: !state.areLabelsChanged ? placeholder.data.labels : state.labels,
    spaces: !state.areSpacesChanged ? placeholder.data.spaces : state.spaces,
    setAreLabelsChanged: state.setAreLabelsChanged,
    setAreSpacesChanged: state.setAreSpacesChanged,
  }));

  useEffect(() => {
    setPlaceholderUniqueId(placeholder.id + placeholder.data.name);
  }, [placeholder.data.name, triggered]);

  useEffect(async () => {
    let labelValues = [];
    if (labels) {
      labelValues = Object.values(labels)?.flat();
    }
    const { results } = await atlassianRestService.searchForPage({ query: inputState, limit: 25, labelValues });
    const pages = results.map(({ content }) => content);
    if (pages[0]?.id) {
      const sortedPages = pages?.sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();
        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0;
      });
      const filteredPagesBySpaces = sortedPages.filter((page) => {
        if (spaces?.[placeholderUniqueId]?.length === 0) return true;
        return spaces?.[placeholderUniqueId]?.includes(page.space?.key);
      });
      setFetchedPages(filteredPagesBySpaces);
    }
  }, [inputState, spaces, labels, placeholderUniqueId]);

  useEffect(() => {
    const p = placeholders.find((p) => p === placeholder);
    p.data.labels = labels;
    p.data.spaces = spaces;
  }, [labels, spaces]);

  const onPageChange = (newPage) => {
    setSelectedPage(newPage);
  };
  const onInputChange = (newInputState) => {
    setInputState(newInputState);
  };

  const onChangeCurrentPage = (e) => {
    setShowCurrentPage(!placeholder.data.displayCurrentPage);
    const p = placeholders.find((p) => p === placeholder);
    p.data.displayCurrentPage = !placeholder.data.displayCurrentPage;
    analytics.logEvent(AnalyticsEvent.DISPLAY_CURRENT_PAGE, {
      isShowCurrentPage: !placeholder.data.displayCurrentPage,
    });
  };

  return (
    <CollapsibleContainer
      Title={<PanelTitle placeholder={placeholder} />}
      Icon={usePlaceholderIcon(PLACEHOLDER_KIND_CONFLUENCE_PAGE, "small")}
      collapsed={collapsed}
      onCollapseClick={(newValue) => setCollapsed(newValue)}
    >
      <Form>
        {({ formProps }) => (
          <form {...formProps} name="page-placeholder-from-example">
            <NameField placeholder={placeholder} />
            <DescriptionField placeholder={placeholder} />
            <div style={{ fontSize: "14px" }}>
              <PageFiltersField
                placeholder={placeholder}
                uniqueId={placeholderUniqueId}
                onLabelsChange={() => setAreLabelsChanged(true)}
                onSpacesChange={() => setAreSpacesChanged(true)}
              />
              <DefaultPageField
                fetchedPages={fetchedPages}
                placeholder={placeholder}
                showCurrentPage={showCurrentPage}
                onInputChange={onInputChange}
                onPageChange={onPageChange}
              />
              <AtlassianCheckboxField name="ShowCurrentPage" style={{ fontSize: "14px" }}>
                {({ fieldProps }) => {
                  const p = placeholders.find((p) => p === placeholder) || "";
                  if (p.lifecycle === undefined) {
                    p.lifecycle = PLACEHOLDER_EDITED;
                  }
                  return (
                    <Checkbox
                      {...fieldProps}
                      isDisabled={!!selectedPage}
                      isChecked={showCurrentPage}
                      label="Display current page"
                      onChange={onChangeCurrentPage}
                    />
                  );
                }}
              </AtlassianCheckboxField>
              <HelperMessage>
                This is an automation to display the current page where the Property Group is embedded. By using this feature the field will
                not be editable for users visiting the page
              </HelperMessage>
            </div>
            <FormFooter>
              <DeleteProperty property={placeholder} />
            </FormFooter>
          </form>
        )}
      </Form>
    </CollapsibleContainer>
  );
}
