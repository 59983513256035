import React, { useEffect, useState, Fragment } from "react";
import Textfield from "@atlaskit/textfield";
import Button from "@atlaskit/button";
import Drawer from "@atlaskit/drawer";
import { BPCIcon } from "../../Icon/BPCIcon";
import { IconPicker } from "../../Icon/IconPicker";
import { UserOrGroupPicker } from "./UserOrGroupPicker";
import { AnalyticsEvent } from "../../../../analytics-events";
import { analytics } from "../../../../analytics";
import { FieldsDescriptors, FieldTypes } from "../field-types";
import { renderField } from "../render-field";
import { placeholderSetService } from "../../../Service/PlaceholderSetService";
import { usePlaceholderPortalStore } from "../Store";

const FieldNames = [FieldTypes.ICON, FieldTypes.NAME, FieldTypes.DESCRIPTION, FieldTypes.RESTRICTIONS];

export const EditPlaceholderSetModal = (props) => {
  const { onClose, visible } = props;
  const [description, setDescription] = useState("");
  const [groupOwners, setGroupOwners] = useState("");
  const [userOwners, setUserOwners] = useState("");
  const [iconPickerModalVisible, setIconPickerModalVisible] = useState(false);
  const [icon, setIcon] = useState(props.icon);
  const [name, setName] = useState(props.name);
  const { updatePlaceholderSet } = usePlaceholderPortalStore((state) => ({
    updatePlaceholderSet: state.updatePlaceholderSet,
  }));

  useEffect(() => {
    setIcon(props.icon);
    setName(props.name);
    setDescription(props.description);
    setGroupOwners(props.groupOwners);
    setUserOwners(props.userOwners);
  }, [props]);

  const handleOnIconPickerSubmit = (iconId) => {
    setIcon(iconId);
    setIconPickerModalVisible(false);
  };
  const handleEdit = async () => {
    const placeholderSet = {
      name,
      description,
      icon,
      groupOwners,
      userOwners,
    };
    const { data, status } = await placeholderSetService.editPlaceholderSet(props.id, placeholderSet);
    if (status !== 200) {
      window.AP.flag.create({
        title: "Error",
        body: ` an error occurred updating ${name}`,
        type: "error",
      });
    } else {
      updatePlaceholderSet(props.id, data);
      analytics.logEvent(AnalyticsEvent.EDITED_PROPERTY_GROUP_IN_PORTAL, {});
      window.AP.flag.create({
        title: "Success",
        body: `${name} updated`,
        type: "success",
        close: "auto",
      });
    }
    onClose();
  };

  const FieldsChildren = {
    [FieldTypes.ICON]: () => (
      <Button spacing="none" appearance="subtle" onClick={() => setIconPickerModalVisible(true)}>
        <span>
          <BPCIcon icon={icon} />
        </span>
      </Button>
    ),
    [FieldTypes.NAME]: ({ fieldProps }, props) => (
      <Textfield
        {...fieldProps}
        {...props}
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter property group name"
      />
    ),
    [FieldTypes.DESCRIPTION]: ({ fieldProps }, props) => (
      <Textfield
        {...fieldProps}
        {...props}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Enter a group description"
      />
    ),
    [FieldTypes.RESTRICTIONS]: ({ fieldProps }, props) => (
      <UserOrGroupPicker
        {...fieldProps}
        {...props}
        initialvalue={groupOwners || userOwners}
        placeholder="Type a user name or group"
        onChangeRestriction={(e) => {
          setGroupOwners(e.groupPermission);
          setUserOwners(e.userPermission);
        }}
      />
    ),
  };

  return (
    <div>
      {visible && (
        <Drawer onClose={() => onClose()} isOpen={visible} width="narrow">
          <div style={{ paddingRight: "15px" }}>
            <p style={{ fontSize: "20px", fontWeight: "500", marginTop: "8px" }}>Edit property group</p>
            <div>
              <IconPicker
                onClose={() => setIconPickerModalVisible(false)}
                onSubmit={handleOnIconPickerSubmit}
                visible={iconPickerModalVisible}
              />
              {FieldNames.map((field) => {
                return renderField({
                  fieldDescriptor: FieldsDescriptors[field],
                  renderChildren: FieldsChildren[field],
                  aditionalDiv: field === FieldTypes.ICON,
                });
              })}
            </div>
            <span
              style={{
                textAlign: "center",
                position: "fixed",
                bottom: "0",
                height: "80px",
              }}
            >
              <Button appearance="primary" onClick={() => handleEdit()} style={{ marginRight: "5px" }}>
                Save
              </Button>
              <Button appearance="default" onClick={() => onClose()}>
                Close
              </Button>
            </span>
          </div>
        </Drawer>
      )}
    </div>
  );
};
