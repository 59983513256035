export const AnalyticsEvent = {
  NUMBER_OF_PROPERTY_GROUPS: "number_of_property_groups",
  PROPERTY_GROUP_CREATED_UPDATED: "property_group_created_updated",
  NUMBER_OF_PROPERTIES_IN_GROUP: "number_of_properties_in_group",
  PROPERTY_PORTAL_CREATE_GROUP_MODAL: "property_portal_create_group_modal",
  CREATED_PORTAL_GROUP: "created_portal_group",
  PORTAL_LOADED: "portal_loaded",
  ADDED_PROPERTY_GROUP_IN_PORTAL: "added_property_group_in_portal",
  EDITED_PROPERTY_GROUP_IN_PORTAL: "edited_property_group_in_portal",
  SAVED_CHANGES_IN_PROPERTY_PORTAL: "saved_changes_in_property_portal",
  NUMBER_OF_OPTIONS_IN_SELECT: "number_of_options_in_select",
  GROUP_MACRO_LOADED: "group_macro_loaded",
  GROUP_MACRO_UPDATED_IN_VIEWMODE: "group_macro_updated_in_viewmode",
  GROUP_REPORT_CREATED_UPDATED: "group_report_created_updated",
  GROUP_REPORT_LOADED: "group_report_loaded",
  VISUALIZATION_MACRO_LOADED: "visualization_macro_loaded",
  VISUALIZATION_MACRO_CREATED_UPDATED: "visualization_macro_created_updated",
  NUMBER_OF_RESULTS_IN_REPORT: "number_of_results_in_report",
  REPORT_LAYOUT: "report_layout",
  VALUE_UPDATED_IN_DATABASE: "value_updated_in_database",
  IS_EXPIRED_DATE: "is_expired_date",
  IS_UPPERCASE_SELECT: "is_uppercase_select",
  IS_UPPERCASE_MULTISELECT: "is_uppercase_multiselect",
  DISPLAY_AVATAR_USER: "display_avatar_user",
  DISPLAY_CURRENT_PAGE: "display_current_page",
  RESTRICT_USER_TO_A_SINGLE_PERSON: "restrict_user_to_a_single_person",
  LABEL_FILTER_USED: "label_filter_used",
  SPACE_FILTER_USED: "space_filter_used",
  PROPERTY_FILTER_USED: "property_filter_used",
  PAGESUNDER_FILTER_USED: "pagesunder_filter_used",
  VIEW_TYPE: "view_type",
  LIVE_FILTER_USED: "live_filter_used",
  ENRICHMENT_STRATEGY_USED: "enrichment_strategy_used",
  SHOW_PAGE_VERSION_USED:"show_page_version_used",
};
