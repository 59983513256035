import { useEffect, useState } from "react";
import TextField from "@atlaskit/textfield";
import { usePropertyGroupReportUIStore } from "../../PropertyReportMacro/store";
import { FieldStyles } from "../../Shared/FieldThemingStyles";

const TextPropertyEdit = ({ property, initialValue, onUpdate, autoFocus = false }) => {
  const [value, setValue] = useState(initialValue);
  const { loadedLiveFilter } = usePropertyGroupReportUIStore((state) => ({
    loadedLiveFilter: state.loadedLiveFilter,
  }));

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);
  return (
    <TextField
      autoFocus={autoFocus}
      value={value}
      style={FieldStyles}
      onChange={(e) => {
        const newValue = e.target.value;
        setValue(newValue);
        if (!loadedLiveFilter) {
          onUpdate(newValue);
        }
      }}
      onBlur={(event) => {
        if (loadedLiveFilter) {
          onUpdate(value);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          if (loadedLiveFilter) {
            onUpdate(value);
          }
        }
      }}
      placeholder={property?.data?.description}
    />
  );
};

export default TextPropertyEdit;
