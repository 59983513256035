import { placeholderCloudRunUrl, placeholderSetRunUrl } from "../../../cloud-run-config";
import httpClient from "../../http-client";

export async function createNewPage(spaceId, parentId, recordId, groupId, title, values, spaceKey) {
  const url = `${placeholderCloudRunUrl}/api/atlassian-connect/confluence-cloud/createNewPage`;
  const data = {
    spaceId,
    parentId,
    recordId,
    groupId,
    title,
    values,
    spaceKey,
  };
  const response = await httpClient.post(url, data);

  return response.data;
}
