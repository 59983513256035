import React from "react";

const UserWithAvatar = ({ user, prefix = "", styles, beforeChild, afterChild }) => {
  return (
    <>
      <span
        role="img"
        style={{
          backgroundImage: "url(" + user.avatarUrl + ")",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "50%",
          width: "25px",
          height: "25px",
          display: "flex",
        }}
      ></span>
      <span style={{ marginLeft: "10px", ...styles }}>
        <span style={{ display: "flex", flexDirection: "column" }}>
          {beforeChild ? beforeChild : <></>}
          <span>
            {prefix + " " + user.name}
            {afterChild}
          </span>
        </span>
      </span>
    </>
  );
};

export default UserWithAvatar;
