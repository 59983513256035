import React, { useState } from "react";
import { usePropertyGroupReportMacroStore } from "./store";
import Select from "@atlaskit/select";
import shallow from "zustand/shallow";
import { SelectFieldStyles } from "../Shared/SelectFieldStyles";
import { addEntriesTypes } from "./addEntriesTypes";

const AddEntriesTypePicker = (props) => {
  const { addEntriesType, setAddEntriesType } = usePropertyGroupReportMacroStore(
    (state) => ({
      addEntriesType: state.addEntriesType,
      setAddEntriesType: state.setAddEntriesType,
    }),
    shallow
  );

  const options = [
    { label: addEntriesTypes.INLINE, value: addEntriesTypes.INLINE },
    { label: addEntriesTypes.DIALOG, value: addEntriesTypes.DIALOG },
    { label: addEntriesTypes.DISABLED, value: addEntriesTypes.DISABLED },
  ];
  const [selected, setSelected] = useState(options.find((item) => item.value === addEntriesType));

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        <Select
          options={options}
          isClearable={false}
          styles={SelectFieldStyles}
          onChange={(newType) => {
            setAddEntriesType(newType.value);
            setSelected(newType);
          }}
          placeholder="Select property"
          value={selected}
        />
      </div>
    </div>
  );
};
export default AddEntriesTypePicker;
