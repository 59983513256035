import { token } from "@atlaskit/tokens";
import { useEffect } from "react";
import styled from "styled-components";

const TableHover = styled.tr`
  &:hover {
    background-color: ${token("elevation.surface.hovered")} !important;
  }
  > td:hover {
    background-color: ${token("elevation.surface.pressed")} !important;
  }
`;

const TableRow = ({ entries, sortIdentifier }) => {
  useEffect(() => {
    window.AP.theming.initializeTheming();
  }, []);
  return (
    <TableHover data-sort-identifier={sortIdentifier}>
      {entries?.map((entry, index) => {
        return (
          <td key={index} data-property-key={entry?.key} data-property-type={entry?.info?.kind} className="property-cell">
            {entry.content}
          </td>
        );
      })}
    </TableHover>
  );
};
export default TableRow;
