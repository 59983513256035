import { useEffect, useState } from "react";
import { atlassianRestService } from "../../../Service/AtlassianRestService";

const UserMention = ({ profiles, displayUserProfilePicture = false }) => {
  const [profilePictureUrl, setProfilePictureUrl] = useState();

  useEffect(() => {
    (async () => {
      if (displayUserProfilePicture) {
        const profilePicturePath = await atlassianRestService.getProfilePictureUrl(profiles[0].profilePicture?.path);
        setProfilePictureUrl(profilePicturePath);
      }
    })();
  }, [profiles]);

  return (
    <div
      style={{ display: "flex", flexFlow: "row wrap", fontSize: "14px", marginLeft: "5px" }}
      data-sortable={true}
      data-raw-value={profiles.map((p, index) => p?.name || p?.publicName).join()}
    >
      {profiles.map((p, index) => {
        if (displayUserProfilePicture) {
          p.avatarUrl = profilePictureUrl;
        }
        return (
          <span key={p?.id || p?.accountId} style={{ display: "flex", alignItems: "center", whiteSpace: "pre-line" }}>
            <span
              role="img"
              style={{
                backgroundImage: "url(" + p.avatarUrl + ")",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                display: "flex",
                margin: "2px",
                minWidth: "24px",
              }}
            ></span>
            <div style={{ marginLeft: "4px" }}>
              {profiles.length > 1 && index !== profiles.length - 1 ? (
                <span style={{ fontSize: "14px" }}>{p?.name || p?.displayName},</span>
              ) : (
                p?.name || p?.displayName
              )}
            </div>
          </span>
        );
      })}
    </div>
  );
};

export default UserMention;
