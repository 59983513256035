import { useEffect, useState } from "react";
import { SkeletonItem } from "@atlaskit/side-navigation";
import Page16Icon from "@atlaskit/icon-object/glyph/page/16";
import { atlassianRestService } from "../../Service/AtlassianRestService";
export const LazyPageLink = ({ pageId }) => {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        const pageResponse = await atlassianRestService.getPageById(pageId);
        if (!pageResponse) {
          setIsError(true);
          return;
        }
        setPageData({ title: pageResponse.title, url: pageResponse._links.base + pageResponse._links.webui });
        setLoading(false);
      } catch (e) {}
    })();
  }, [pageId]);
  if (isError) {
    return <span>Select a value</span>;
  }
  return <>{loading ? <SkeletonItem width={200} isShimmering /> : <PageLink data={pageData} />}</>;
};

const PageLink = ({ data }) => {
  return (
    <a
      className="page-link"
      href={data.url}
      target="_parent"
      rel="noopener nofollow noreferrer"
      data-sortable={true}
      data-raw-value={data.title}
    >
      <Page16Icon styles={{ padding: "2px" }} />
      <span>{data.title}</span>
    </a>
  );
};
