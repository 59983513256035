/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import TextField from "@atlaskit/textfield";
import NumberType from "../NumberType";
import { HelperMessage } from "@atlaskit/form";
import { token } from "@atlaskit/tokens";

const popularCurrency = {
  EUR: "€",
  USD: "$",
};

const NumberPropertyEdit = ({ property, initialValue, autoFocus = false, onUpdate }) => {
  const [value, setValue] = useState("");
  const [valueWithComma, setValueWithComma] = useState("");
  const [valueOfPattern, setValueOfPattern] = useState();
  const [valueRegex, setValueRegex] = useState();
  const [locale, setLocale] = useState();
  useEffect(() => {
    if (initialValue) {
      setValueWithComma(initialValue.includes(",") ? initialValue.replace(/,/g, ".") : initialValue);
    }
    setValue(initialValue);

    window.AP.user.getLocale(function (locale) {
      setLocale(locale.replace(/_/g, "-"));
    });
    if (property.data.type === NumberType.CURRENCY) {
      formatOfCurrency(property.data.currency);
    } else {
      formats(property.data.type);
    }
  }, [initialValue]);

  const formats = (formatValue) => {
    setValueRegex(/^[0-9,.]*$/);
    if (formatValue === "percent") {
      setValueOfPattern("%");
    } else if (formatValue === "number") {
      setValueRegex(/^[0-9.]*$/);
      if (initialValue && initialValue.includes(",")) {
        setValue(initialValue.replace(/,/g, "."));
      }
    }
  };
  const formatForPercent = () => {
    if (property.data.type === NumberType.PERCENT) {
      return Number(valueWithComma / 100).toLocaleString(locale, {
        style: property.data.type,
        minimumFractionDigits: 2,
      });
    }
    if (property.data.type === NumberType.COMMA) {
      return value.includes(",") ? value : value + ",00";
    }
    if (property.data.type === NumberType.CURRENCY) {
      return Intl.NumberFormat(locale, {
        style: property.data.type,
        currency: property.data.currency,
      }).format(value.replace(/,/g, "."));
    }
    return value;
  };
  const formatOfCurrency = (formatValue) => {
    setValueRegex(/^[\d,.]+$/);
    setValueOfPattern(popularCurrency[formatValue] || formatValue);
  };

  if (property.data?.showCurrentPageVersion) {
    return <p style={{ fontSize: "14px", marginLeft: "2px" }}>Displays the page version</p>;
  }
  return (
    <>
      <TextField
        css={css`
          position: relative;
          &:before {
            position: absolute;
            top: 8.5px;
            left: 5px;
            content: "${valueOfPattern}";
            font-size: 16px;
            font-weight: 300;
          }
          > input {
            padding-left: ${Object.values(popularCurrency).includes(valueOfPattern) || property.data.type === NumberType.PERCENT
              ? "24px !important;"
              : property.data.type !== NumberType.CURRENCY
              ? "5px !important;"
              : "45px !important;"};
          }
        `}
        style={{
          backgroundColor: `${token("utility.elevation.surface.current")}`,
          borderColor: `${token("color.border.input")}`,
          boxShadow: `${token("elevation.shadow.overflow")}`,
        }}
        autoFocus={autoFocus}
        value={value}
        onChange={(number) => {
          const numberInput = number.target.value;
          if (numberInput.match(valueRegex)) {
            setValue(numberInput);
            setValueWithComma(numberInput.replace(/,/g, "."));
            onUpdate(numberInput);
          }
          if (property.data.type === NumberType.PERCENT && numberInput.includes(",")) {
            setValueWithComma(numberInput.replace(/,/g, "."));
          }
          if (!numberInput) {
            setValue(numberInput);
            onUpdate(numberInput);
          }
        }}
      />
      {value ? <HelperMessage>Preview: {formatForPercent()}</HelperMessage> : <></>}
    </>
  );
};

export default NumberPropertyEdit;
