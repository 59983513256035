import { PropertyType } from "../../../property-type";
import TextPropertyView from "../../PropertyGroupMacro/view/TextPropertyView";
import UrlPropertyView from "../../PropertyGroupMacro/view/UrlPropertyView";
import DatePropertyView from "../../PropertyGroupMacro/view/DatePropertyView";
import SelectPropertyView from "../../PropertyGroupMacro/view/SelectPropertyView";
import MultiSelectPropertyView from "../../PropertyGroupMacro/view/MultiSelectPropertyView";
import UserPropertyView from "../../PropertyGroupMacro/view/UserPropertyView";
import EmailPropertyView from "../../PropertyGroupMacro/view/EmailPropertyView";
import PhonePropertyView from "../../PropertyGroupMacro/view/PhonePropertyView";
import NumberPropertyView from "../../PropertyGroupMacro/view/NumberPropertyView";
import styled from "styled-components";
import { HeadingItem } from "@atlaskit/side-navigation";

const PropertyRow = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: 4px 13px 8px 7px;
`;
const IconContainer = styled.span`
  textoverflow: ellipsis;
  fontsize: 14px;
  lineheight: 1.3333;
  fontweight: 600;
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  alignitems: center;
  padding: 0 5px;
  margin-top: 5px;
  > * {
    margin: 0 3px 3px 0;
  }
  * {
    margin: 0 !important;
  }
`;

const getPropertyByKind = (property, primaryId, idx, pageId, name) => {
  const { kind, entityId, unassignedId, value } = property;
  const key = primaryId + idx;
  const initialValue = value ?? undefined;

  switch (kind) {
    case PropertyType.TEXT:
      return <TextPropertyView key={key} property={property} initialValue={initialValue?.label ?? initialValue} />;
    case PropertyType.URL:
      return <UrlPropertyView key={key} property={property} initialValue={initialValue?.label ?? initialValue} />;
    case PropertyType.DATE:
      return <DatePropertyView key={key} displayMode pageId={entityId || unassignedId} property={property} initialValue={initialValue} />;
    case PropertyType.SELECT:
      return <SelectPropertyView key={key} property={property} initialValue={initialValue} name={name} />;
    case PropertyType.MULTISELECT:
      return <MultiSelectPropertyView key={key} property={property} initialValue={initialValue} />;
    case PropertyType.USER:
      return <UserPropertyView key={key} property={property} pageId={pageId} initialValue={initialValue} />;
    case PropertyType.EMAIL:
      return <EmailPropertyView key={key} property={property} initialValue={initialValue?.label ?? initialValue} />;
    case PropertyType.PHONE:
      return <PhonePropertyView key={key} property={property} initialValue={initialValue} />;
    case PropertyType.NUMBER:
      return <NumberPropertyView property={property} initialValue={initialValue} />;
    default:
      return null;
  }
};

export const filterPropertiesByPrimaryId = (properties, primaryId, name, pageId, selectLabel) => {
  const filteredProps = properties?.filter((property) => property?.primaryId === primaryId);

  const renderProps = filteredProps?.map((property, idx) => {
    try {
      const label = property.label;
      const propertyByKind = getPropertyByKind(property, primaryId, idx, pageId, selectLabel === label && name);

      return (
        <div style={{ marginLeft: "5px" }} key={`${primaryId}-${idx}`}>
          <IconContainer>
            <HeadingItem cssFn={() => ({ paddingLeft: 0 })}>{label}</HeadingItem>
          </IconContainer>

          <PropertyRow>{propertyByKind}</PropertyRow>
        </div>
      );
    } catch (error) {
      console.error(error);
      return null;
    }
  });

  return <div>{renderProps}</div>;
};
