import { PLACEHOLDER_EDITED } from "../../../Lifecycles";
import { AnalyticsEvent } from "../../../../analytics-events";
import { analytics } from "../../../../analytics";
import create from "zustand";
import { placeholderService } from "../../../Service/PlaceholderService";

export const usePlaceholderStore = create((set, get) => ({
  placeholders: [],
  isSavingPlaceholders: false,
  spaces: {},
  labels: {},
  areLabelsChanged: false,
  areSpacesChanged: false,
  setAreLabelsChanged: (areLabelsChanged) => set(() => ({ areLabelsChanged })),
  setAreSpacesChanged: (areSpacesChanged) => set(() => ({ areSpacesChanged })),
  setSpaces: (spaces) => set(() => ({ spaces })),
  setLabels: (labels) => set(() => ({ labels })),
  removeSpaces: () => set(() => ({ spaces: {} })),
  removeLabels: () => set(() => ({ labels: {} })),
  setSpacesWithIndex: (spaces, idx) => set(() => ({ spaces: { ...get().spaces, [idx]: spaces } })),
  setLabelsWithIndex: (labels, idx) => set(() => ({ labels: { ...get().labels, [idx]: labels } })),
  createPlaceholder: async (placeholder) => {
    set((state) => ({
      placeholders: [
        ...state.placeholders,
        {
          ...placeholder,
          data: { name: "" },
          position: state.placeholders.length > 0 ? state.placeholders[state.placeholders.length - 1].position + 1 : 1,
        },
      ],
    }));
  },
  getAllPlaceholdersOfSet: async (placeholderSetId) => {
    try {
      const { data, status } = await placeholderService.getAllPlaceholdersOfSet(placeholderSetId);
      if (status === 200) {
        const updatedPlaceholders = data.map((p) => {
          if (p.kind === "MULTI-SELECT") {
            return {
              ...p,
              data: {
                ...p.data,
                options: p.data.options.map((o) => ({ color: o.color, isDefault: o.isDefault || false, value: o.value || "" })),
              },
            };
          }
          return p;
        });

        set({ placeholders: updatedPlaceholders });
        analytics.logEvent(AnalyticsEvent.NUMBER_OF_PROPERTIES_IN_GROUP, { groupId: placeholderSetId, size: updatedPlaceholders.length });
      } else {
        set({ placeholders: [] });
      }
    } catch (error) {
      set({ placeholders: [] });
    }
  },
  saveButtonIsDisabled: () => {
    const placeholders = get().placeholders;
    if (placeholders.some((p) => p.lifecycle !== undefined && p.data.name.trim().length === 0)) {
      return true;
    }
    return !placeholders.find((p) => p.lifecycle !== undefined);
  },
  saveWouldDeleteProperties: () => {
    const placeholders = get().placeholders;
    return placeholders.some((p) => p.lifecycle === "deleted");
  },
  savePlaceholders: async (selectedPlaceholderSet) => {
    set({ isSavingPlaceholders: true });

    const createOperations = get()
      .placeholders.filter((p) => p.lifecycle === "created")
      .map(async (createdPlaceholder) => {
        analytics.logEvent(AnalyticsEvent.ADDED_PROPERTY_GROUP_IN_PORTAL, { type: createdPlaceholder.kind });

        if (["MULTI-SELECT", "SELECT"].includes(createdPlaceholder.kind)) {
          analytics.logEvent(AnalyticsEvent.NUMBER_OF_OPTIONS_IN_SELECT, { size: createdPlaceholder.data.options.length });
        }

        const placeholderResponse = await placeholderService.createNewPlaceholder(selectedPlaceholderSet.id, {
          kind: createdPlaceholder.kind,
          data: createdPlaceholder.data,
          position: createdPlaceholder.position,
          id: createdPlaceholder.id,
        });

        createdPlaceholder.id = placeholderResponse.data.id;
      });

    const editOperations = get()
      .placeholders.filter((p) => p.lifecycle === "edited")
      .map(async ({ id, kind, data, position }) => {
        analytics.logEvent(AnalyticsEvent.SAVED_CHANGES_IN_PROPERTY_PORTAL, { type: kind });
        return placeholderService.editPlaceholder(selectedPlaceholderSet.id, {
          id,
          kind,
          data,
          position,
        });
      });

    const placeholdersWithDeleteFlag = get().placeholders.filter((p) => p.lifecycle === "deleted");
    const deleteOperations = placeholdersWithDeleteFlag.map(({ id }) =>
      placeholderService.deletePlaceholder(selectedPlaceholderSet.id, id)
    );

    try {
      await Promise.all([...createOperations, ...editOperations, ...deleteOperations]);

      set((state) => {
        const updatedPlaceholders = state.placeholders.map((p) => {
          if (p.lifecycle !== undefined) {
            p.lifecycle = undefined;
          }
          return p;
        });

        const filteredPlaceholders = updatedPlaceholders.filter(
          (p) => !placeholdersWithDeleteFlag.some((deletedPlaceholder) => deletedPlaceholder.id === p.id)
        );

        return { placeholders: filteredPlaceholders };
      });

      return true;
    } catch (error) {
      return false;
    } finally {
      set({ isSavingPlaceholders: false });
    }
  },
  deletePlaceholder: (placeholder) => {
    const placeholders = get().placeholders;
    const updatedPlaceholders = placeholders.map((p) => {
      if (p === placeholder) {
        return { ...p, lifecycle: "deleted" };
      }
      return p;
    });
    set({ placeholders: updatedPlaceholders });
  },
  deleteDirectlyNewProperty: (property) => {
    set((state) => {
      const updatedPlaceholders = state.placeholders.filter((p) => p !== property);
      return { placeholders: updatedPlaceholders };
    });
  },
  changeName: (placeholder, name) => {
    set((state) => {
      const updatedPlaceholders = state.placeholders.map((p) => {
        if (p === placeholder) {
          if (p.lifecycle === undefined) {
            p.lifecycle = PLACEHOLDER_EDITED;
          }
          return { ...p, data: { ...p.data, name } };
        }
        return p;
      });
      return { placeholders: updatedPlaceholders };
    });
  },
  changeDescription: (placeholder, description) => {
    set((state) => {
      const updatedPlaceholders = state.placeholders.map((p) => {
        if (p === placeholder) {
          if (p.lifecycle === undefined) {
            p.lifecycle = PLACEHOLDER_EDITED;
          }
          return { ...p, data: { ...p.data, description } };
        }
        return p;
      });
      return { placeholders: updatedPlaceholders };
    });
  },
  changeDefaultPage: (placeholder, defaultPage) => {
    set((state) => {
      const updatedPlaceholders = state.placeholders.map((p) => {
        if (p === placeholder) {
          if (p.lifecycle === undefined) {
            p.lifecycle = PLACEHOLDER_EDITED;
          }
          return { ...p, data: { ...p.data, defaultPage } };
        }
        return p;
      });
      return { placeholders: updatedPlaceholders };
    });
  },
  changeCountry: (placeholder, country) => {
    set((state) => {
      const updatedPlaceholders = state.placeholders.map((p) => {
        if (p === placeholder) {
          if (p.lifecycle === undefined) {
            p.lifecycle = PLACEHOLDER_EDITED;
          }
          return { ...p, data: { ...p.data, country } };
        }
        return p;
      });
      return { placeholders: updatedPlaceholders };
    });
  },
  changeNumberType: (placeholder, type) => {
    set((state) => {
      const updatedPlaceholders = state.placeholders.map((p) => {
        if (p === placeholder) {
          if (p.lifecycle === undefined) {
            p.lifecycle = PLACEHOLDER_EDITED;
          }
          return { ...p, data: { ...p.data, type } };
        }
        return p;
      });
      return { placeholders: updatedPlaceholders };
    });
  },
  changeCurrency: (placeholder, currency) => {
    set((state) => {
      const updatedPlaceholders = state.placeholders.map((p) => {
        if (p === placeholder) {
          if (p.lifecycle === undefined) {
            p.lifecycle = PLACEHOLDER_EDITED;
          }
          return { ...p, data: { ...p.data, currency } };
        }
        return p;
      });
      return { placeholders: updatedPlaceholders };
    });
  },
  changeOptions: (placeholder, options) => {
    set((state) => {
      const updatedPlaceholders = state.placeholders.map((p) => {
        if (!("id" in placeholder)) {
          if (p.tempId === placeholder.tempId) {
            if (p.lifecycle === undefined) {
              p.lifecycle = PLACEHOLDER_EDITED;
            }
            return { ...p, data: { ...p.data, options } };
          }
        } else if (p.id === placeholder.id) {
          if (p.lifecycle === undefined) {
            p.lifecycle = PLACEHOLDER_EDITED;
          }
          return { ...p, data: { ...p.data, options } };
        }
        return p;
      });
      return { placeholders: updatedPlaceholders };
    });
  },
  changeCheckboxes: (placeholder, checkboxName, reset = false) => {
    set((state) => {
      const updatedPlaceholders = state.placeholders.map((p) => {
        if (p === placeholder) {
          if (p.lifecycle === undefined) {
            p.lifecycle = PLACEHOLDER_EDITED;
          }
          if (reset === true) {
            return { ...p, data: { ...p.data, checkboxes: [] } };
          }
          const checkbox = p.data?.checkboxes?.find((c) => c === checkboxName);
          if (checkbox === undefined) {
            const checkboxes = p.data?.checkboxes ? [...p.data.checkboxes, checkboxName] : [checkboxName];
            return { ...p, data: { ...p.data, checkboxes } };
          } else {
            const checkboxes = p.data?.checkboxes?.filter((c) => c !== checkboxName);
            return { ...p, data: { ...p.data, checkboxes } };
          }
        }
        return p;
      });
      return { placeholders: updatedPlaceholders };
    });
  },
  onOrderChange: ({ source, destination }) => {
    set((state) => {
      if (!destination) {
        return state;
      }

      const result = Array.from(state.placeholders);
      result.forEach((element, index) => {
        element.position = index;
      });
      const movedElement = result[source.index];
      const [removed] = result.splice(source.index, 1);
      result.splice(destination.index, 0, removed);

      if (source.index === destination.index) {
        return { placeholders: result };
      }
      if (source.index < destination.index) {
        // moved down
        for (let i = source.index; i <= destination.index; i++) {
          result[i].position -= 1;
          if (result[i].lifecycle === undefined) {
            result[i].lifecycle = PLACEHOLDER_EDITED;
          }
        }
        movedElement.position = destination.index;
      }
      if (source.index > destination.index) {
        // moved up
        for (let i = destination.index; i <= source.index; i++) {
          result[i].position += 1;
          if (result[i].lifecycle === undefined) {
            result[i].lifecycle = PLACEHOLDER_EDITED;
          }
        }
        movedElement.position = destination.index;
      }

      return { placeholders: result };
    });
  },
}));
