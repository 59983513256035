import create from "zustand";

export const useBoardStore = create((set, get) => ({
  selectedGroup: {},
  setSelectedGroup: (group) => set((state) => ({ selectedGroup: group })),
  selectedProperties: [],
  setSelectedProperties: (properties) => set((state) => ({ selectedProperties: properties })),
  boardHeight: null,
  setBoardHeight: (height) => set((state) => ({ boardHeight: height })),
  isCreateCardOpened: false,
  setIsCreateCardOpened: (isOpened) => set((state) => ({ isCreateCardOpened: isOpened })),
}));

