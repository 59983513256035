export const HeightSettings = {
  fullScreenView: "calc(100vh - 65px)",
  editView: "74.5vh",
  defaultView: "unset",
};
export const ViewType = {
  fullScreenView: "fullScreenView",
  editView: "editView",
  defaultView: "defaultView",
};

