import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "@atlaskit/form";
import { Checkbox } from "@atlaskit/checkbox";
import { HelperMessage } from "@atlaskit/form";
import { FormFooter } from "@atlaskit/form/FormFooter";
import { CheckboxField as AtlassianCheckboxField } from "@atlaskit/form";
import { usePlaceholderIcon } from "../usePlaceholderIcon";
import { PLACEHOLDER_KIND_NUMBER } from "../PlaceholderKinds";
import { NameField } from "../Fields/NameField";
import { PanelTitle } from "../Fields/PanelTitle";
import { DeleteProperty } from "../DeletePlaceholder";
import { DescriptionField } from "../Fields/DescriptionField";
import { CollapsibleContainer } from "../CollapsibleContainer";
import { FormatSelectionField } from "../Fields/FormatSelectionField";
import { PLACEHOLDER_EDITED } from "../../../../../../Lifecycles";
import { analytics } from "../../../../../../../analytics";
import { AnalyticsEvent } from "../../../../../../../analytics-events";
import { usePlaceholderStore } from "../../../../Store";
import { useLifecycleSetCollapsed } from "./useLifecycleSetCollapsed";
import styled from "styled-components";
export const DisplayPageVersionContainer = styled.div`
  > * {
    font-size: 14px !important;
  }
`;

NumberPlaceholderInPortal.propTypes = {
  placeholder: PropTypes.object.isRequired,
};
export function NumberPlaceholderInPortal({ placeholder }) {
  const { collapsed, setCollapsed } = useLifecycleSetCollapsed({ placeholder });
  const [showCurrentPageVersion, setShowCurrentPageVersion] = useState(placeholder.data.showCurrentPageVersion);
  const { placeholders } = usePlaceholderStore((state) => ({
    placeholders: state.placeholders,
  }));

  const handleCollapseClick = (newValue) => {
    setCollapsed(newValue);
  };
  const handleCheckboxChange = (e) => {
    setShowCurrentPageVersion(!placeholder.data.showCurrentPageVersion);
    const p = placeholders.find((p) => p === placeholder);
    if (p.lifecycle === undefined) p.lifecycle = PLACEHOLDER_EDITED;
    p.data.showCurrentPageVersion = !placeholder.data.showCurrentPageVersion;
    if (p.data.showCurrentPageVersion) p.data.type = "NUMBER";
    analytics.logEvent(AnalyticsEvent.SHOW_PAGE_VERSION_USED, {
      showCurrentPageVersion: !placeholder.data.showCurrentPageVersion,
    });
  };
  const renderCheckboxWithFieldProps = ({ fieldProps }) => (
    <Checkbox {...fieldProps} isChecked={showCurrentPageVersion} label="Display page version" onChange={handleCheckboxChange} />
  );

  return (
    <CollapsibleContainer
      collapsed={collapsed}
      onCollapseClick={handleCollapseClick}
      Title={<PanelTitle placeholder={placeholder} />}
      Icon={usePlaceholderIcon(PLACEHOLDER_KIND_NUMBER, "small")}
    >
      <Form>
        {({ formProps }) => (
          <form {...formProps} name="number-placeholder">
            <NameField placeholder={placeholder} />
            <DescriptionField placeholder={placeholder} />
            {!showCurrentPageVersion && <FormatSelectionField placeholder={placeholder} />}
            <DisplayPageVersionContainer>
              <AtlassianCheckboxField name="showCurrentPageVersion">{renderCheckboxWithFieldProps}</AtlassianCheckboxField>
            </DisplayPageVersionContainer>
            <HelperMessage style={{ fontsize: "14px" }}>
              This is an automation to display the current page version where the Property Group is embedded. By using this feature the
              field will not be editable for users visiting the page
            </HelperMessage>
            <FormFooter>
              <DeleteProperty property={placeholder} />
            </FormFooter>
          </form>
        )}
      </Form>
    </CollapsibleContainer>
  );
}
