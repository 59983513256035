import { placeholderCloudRunUrl, placeholderSetRunUrl } from "../../../cloud-run-config";
import httpClient from "../../http-client";

export async function getPropertyGroups() {
  const url = placeholderSetRunUrl + "/api/sets";
  return httpClient.get(url);
}

export async function getRecord({ pageId, groupId, recordId, isTemplate }) {
  const url = placeholderCloudRunUrl + "/api/records/properties-record";
  return httpClient.get(url, { params: { pageId, groupId, recordId, isTemplate } });
}

export async function getRecordWithQuery({ queryKey }) {
  const [_key, { pageId, groupId, recordId, isTemplate }] = queryKey;
  const url = placeholderCloudRunUrl + "/api/records/properties-record";
  return httpClient.get(url, { params: { pageId, groupId, recordId, isTemplate } });
}

export async function getPropertiesOfGroup({ queryKey }) {
  const [_key, { group }] = queryKey;
  const url = `${placeholderCloudRunUrl}/api/placeholder/${group}`;
  return httpClient.get(url);
}
