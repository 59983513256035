import { useEffect, useState } from "react";
import { SkeletonItem } from "@atlaskit/side-navigation";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import styled from "styled-components";
import { FieldIcons } from "../../FieldIcons";
import { convertUnicodeToEmoji } from "./unicode-to-emoji";
import Linkify from "linkify-react";
import { token } from "@atlaskit/tokens";

const LinkDiv = styled.a`
  position: relative;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 27px;
  display: flex;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
`;
const HeadingTitle = styled.span`
  display: inline-block;
  overflowwrap: anywhere;
  color: ${token("color.text")};
  &:hover {
    text-decoration: underline;
  }
`;
const HeadingIcon = styled.div`
  color: #2d88ff;
  ${(props) => (props.emoji ? `transform: scale(1.75)` : `transform: scale(1.35, 1.09)`)};
  ${(props) => (props.emoji ? `margin-right: 9px` : `margin-right: 2px`)};
  ${(props) => (props.emoji ? `margin-left: 6px` : `margin-left: 2px`)};
`;

const PageLink = ({ data, pageId }) => {
  const icon = FieldIcons("CONFLUENCEPAGE");
  const [emoji, setEmoji] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const response = await atlassianRestService.getIconOfContent(pageId);
        setEmoji(response?.value);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <LinkDiv href={data.url} target="_parent" rel="noopener nofollow noreferrer" data-sortable={true} data-raw-value={data.title}>
      <HeadingIcon emoji={emoji}>{emoji ? convertUnicodeToEmoji(emoji) : icon}</HeadingIcon>
      <Linkify tagName="span" options={{ target: "_blank" }} data-sortable={true} data-raw-value={data.title}>
        <HeadingTitle>{data.title}</HeadingTitle>
      </Linkify>
    </LinkDiv>
  );
};

const LazyPageLink = ({ pageId }) => {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const pageResponse = await atlassianRestService.getPageById(pageId);
        if (!pageResponse) {
          setIsError(true);
          return;
        }
        setPageData({ title: pageResponse.title, url: pageResponse._links.base + pageResponse._links.webui });
        setLoading(false);
      } catch (e) {}
    })();
  }, [pageId]);
  if (isError) {
    return <span>Select a value</span>;
  }
  return <>{loading ? <SkeletonItem width={200} isShimmering /> : <PageLink data={pageData} pageId={pageId} />}</>;
};

export { LazyPageLink };
