import create from "zustand";
import { generateId } from "../../../v2/id-generator";
import { ReportSortOrder } from "./report-sort-order";
import { ReportType } from "./report-type";
import setObject from "lodash.set";

export const usePropertyGroupReportMacroStore = create((set, get) => ({
  loaded: false,
  selectedGroup: undefined,
  reportMacroId: undefined,
  availableGroups: undefined,
  selectedColumns: [],
  labels: {},
  labelsLogic: [],
  propertyFilters: {},
  selectedSpaces: [],
  selectedView: ReportType.TABLE,
  defaultSortColumn: undefined,
  defaultSortOrder: ReportSortOrder.ASC,
  numberOfItems: 25,
  pagesUnder: undefined,
  currentPageId: undefined,
  addEntriesType: undefined,
  spaceChanged: false,
  toggleSpaceChange: () => set(() => ({ spaceChanged: !get().spaceChanged })),
  setLabelsLogic: (labelsLogic) => set(() => ({ labelsLogic })),
  setAddEntriesType: (type) => set((state) => ({ addEntriesType: type })),
  setGroup: (group) => set((state) => ({ selectedGroup: group })),
  setReportMacroId: (reportId) => set((state) => ({ reportMacroId: reportId })),
  setAvailableGroups: (groups) => set((state) => ({ availableGroups: groups })),
  setLoaded: (loaded) => set((state) => ({ loaded })),
  setSelectedColumns: (columns) => set((state) => ({ selectedColumns: columns })),
  setLabels: (labels) => set((state) => ({ labels: labels })),
  setLabelsWithIndex: (labels, idx) => set((state) => ({ labels: { ...get().labels, [idx]: labels } })),
  removeLabelsWithIndex: (idx) =>
    set((state) => {
      const updatedLabels = { ...get().labels };
      delete updatedLabels[idx];
      return { labels: updatedLabels };
    }),
  setPropertyFilters: (filters) => set((state) => ({ propertyFilters: filters })),
  setPropertyFiltersWithIndex: (filters, idx) => {
    if (!idx) {
      return;
    }
    const updatedValues = setObject({ ...get().propertyFilters }, [idx], filters);
    return set((state) => ({ propertyFilters: updatedValues }));
  },
  removePropertyFiltersWithIndex: (idx) => {
    set((state) => {
      const updatedFilters = { ...get().propertyFilters };
      delete updatedFilters[idx];
      if (Object.keys(updatedFilters).length === 0) {
        const newId = generateId();
        return { propertyFilters: { [newId]: {} } };
      }
      return { propertyFilters: updatedFilters };
    });
  },
  setSelectedSpaces: (spaces) => set((state) => ({ selectedSpaces: spaces })),
  setPagesUnder: (page) => set((state) => ({ pagesUnder: page })),
  setCurrentPageId: (currentPageId) => set((state) => ({ currentPageId })),
  setSelectedView: (view) => set((state) => ({ selectedView: view || ReportType.TABLE })),
  setNumberOfItems: (number) => set((state) => ({ numberOfItems: number })),
  setDefaultSortColumn: (propertyKey) => set((state) => ({ defaultSortColumn: propertyKey })),
  setDefaultSortOrder: (order) => set((state) => ({ defaultSortOrder: order })),
}));
export const usePropertyLiveFilterStore = create((set, get) => ({
  propertyLiveFilters: {},
  reportMacroIdForLiveFilter: undefined,
  setReportMacroIdForLiveFilter: (reportId) => set((state) => ({ reportMacroIdForLiveFilter: reportId })),
  setPropertyLiveFilters: (liveFilters) =>
    set((state) => {
      const addKeyToFilter = { [generateId()]: liveFilters };
      return set((state) => ({ propertyLiveFilters: addKeyToFilter }));
    }),
  setPropertyLiveFiltersWithIndex: (liveFilters, idx) => {
    if (!idx) {
      return;
    }
    const filterKeyValue = { [idx]: liveFilters };
    const storeFilter = { ...get().propertyLiveFilters };
    let updatedValues;
    const addKeyToFilter = { [generateId()]: filterKeyValue };
    if (Object.keys(storeFilter).length > 0) {
      updatedValues = { ...storeFilter, ...addKeyToFilter };
    } else {
      updatedValues = addKeyToFilter;
    }
    return set((state) => ({ propertyLiveFilters: updatedValues }));
  },
  setPropertyLiveFiltersInLocalStorage: (reportMacroId, allFilter) =>
    set((state) => {
      try {
        reportMacroId
          ? localStorage.setItem(`property-report-live-filter-${reportMacroId}`, JSON.stringify(allFilter))
          : localStorage.setItem("property-report-live-filter", JSON.stringify(allFilter));
      } catch (e) {}
    }),
  removePropertyLiveFilters: (reportMacroId) => {
    set((state) => {
      try {
        reportMacroId
          ? localStorage.removeItem(`property-report-live-filter-${reportMacroId}`)
          : localStorage.removeItem("property-report-live-filter");
        const newId = generateId();
        return { propertyLiveFilters: { [newId]: {} } };
      } catch (error) {
        return set((state) => ({ propertyLiveFilters: {} }));
      }
    });
  },
}));
export const usePropertyGroupReportUIStore = create((set, get) => ({
  loadedLiveFilter: false,
  setLoadedLiveFilter: (loadedLiveFilter) => set((state) => ({ loadedLiveFilter })),
}));
