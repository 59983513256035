export const insertNoDuplicates = (arr, value, valueType) => {
  if (valueType === "object") {
    const found = arr.find((item) => item.value === value.value);
    if (!found) {
      arr.push(value);
    }
    return;
  }
  if (!arr?.includes(value)) {
    arr.push(value);
    return;
  }
};
