import BoardIcon from "@atlaskit/icon/glyph/board";
import styled from "styled-components";

const IconWrapper = styled.div`
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  > span {
    width: 100%;
    height: 100%;
    > svg {
      width: 100%;
      color: #5b0575;
      height: 100%;
      opacity: 0.5;
    }
  }
`;

const VisualizationEmptyState = ({ description }) => {
  return (
    <div
      style={{
        maxWidth: "465px",
        margin: "0 auto",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconWrapper>
        <BoardIcon />
      </IconWrapper>
      <div
        style={{
          fontSize: "1.42em",
          marginBottom: "10px",
        }}
      >
        You haven't configured your board yet
      </div>
      <div
        style={{
          fontSize: "16px",
        }}
      >
        {description}
      </div>
    </div>
  );
};

export default VisualizationEmptyState;
