import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { ViewerType } from "./viewer-type";
import ScrollContainer from "react-indiana-drag-scroll";
import { ChartStrategy } from "./PropertyVisualizationMacro/OrgChart/chart-strategy";
import StructureVisualization from "./PropertyVisualizationMacro/OrgChart/StructureVisualization";
import SystemPropertiesStructureVisualization from "./PropertyVisualizationMacro/OrgChart/SystemPropertiesStructureVisualization";
import BoardVisualizationContainer from "./PropertyVisualizationMacro/Board/BoardVisualizationContainer";
import { ViewType } from "./PropertyVisualizationMacro/Board/BoardViewType";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Viewer = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    window.AP.require("dialog", function (dialog) {
      dialog.getCustomData((customData) => {
        setData(customData);
        window.AP.events.emit(`viewer_${customData.id}`, JSON.stringify(customData));
        setLoading(false);
      });
    });
  }, []);

  useEffect(() => {
    window.AP.events.on(`viewer_${ViewerType.VISUALIZATION_CHART}`, function (args) {
      setData(JSON.parse(args));
    });
  }, []);

  if (loading) {
    return <></>;
  }

  if (data.id === ViewerType.VISUALIZATION_CHART) {
    return (
      <QueryClientProvider client={queryClient}>
        <ScrollContainer>
          {data.args.config.args.strategy === ChartStrategy.PROPERTY_GROUP && (
            <StructureVisualization
              predefinedStructure={data.args.config.args}
              onLoaded={() => {
                setLoading(false);
              }}
            />
          )}
          {data.args.config.args.strategy === ChartStrategy.SYSTEM && (
            <SystemPropertiesStructureVisualization
              isEdit={false}
              predefinedStructure={data.args.config.args}
              onLoaded={() => {
                setLoading(false);
              }}
            />
          )}
        </ScrollContainer>
      </QueryClientProvider>
    );
  }

  if (data.id === ViewerType.BOARD) {
    return (
      <QueryClientProvider client={queryClient}>
        <BoardVisualizationContainer
          viewType={ViewType.fullScreenView}
          predefinedStructure={data.args.config}
          onLoaded={() => {
            setLoading(false);
          }}
        />
      </QueryClientProvider>
    );
  }

  return <div></div>;
};

export default Viewer;
