import Select, { components } from "@atlaskit/select";
import Field from "@atlaskit/form/Field";
import { useEffect } from "react";
import { useState } from "react";
import { SelectFieldStyles } from "../Shared/SelectFieldStyles";

const SelectWithCustomOptions = ({ initialValue, onUpdate, options, label, placeholder, iconBefore = false }) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (initialValue) {
      const foundOption = options.find((p) => p.value === initialValue);
      if (foundOption) {
        setSelected(foundOption);
      }
    }
  }, [initialValue]);

  const { Option, SingleValue } = components;
  const IconOption = (props) => (
    <Option {...props}>
      <div
        style={{
          display: "flex",
        }}
      >
        {iconBefore && props.data.iconBefore}
        <div style={{ marginLeft: iconBefore ? "5px" : "initial", lineHeight: "1" }}>{props.data.label}</div>
      </div>
    </Option>
  );

  const IconSingleValue = (props) => (
    <SingleValue {...props}>
      <div
        style={{
          display: "flex",
        }}
      >
        {iconBefore && props.data.iconBefore}
        <div style={{ marginLeft: iconBefore ? "5px" : "initial" }}>{props.data.label}</div>
      </div>
    </SingleValue>
  );

  return (
    <>
      <Field name="select-with-custom-options-field" label={label}>
        {({ fieldProps }) => {
          return (
            <Select
              styles={SelectFieldStyles}
              placeholder={placeholder}
              options={options}
              components={{ Option: IconOption, SingleValue: IconSingleValue }}
              value={selected}
              onChange={(newOption) => {
                onUpdate(newOption.value);
              }}
            />
          );
        }}
      </Field>
    </>
  );
};

export default SelectWithCustomOptions;
