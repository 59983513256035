import { PortalContainer } from "../PlaceholderPortalStyles";
import EmptyPortal from "./EmptyPortal";
import { Sidebar } from "./Sidebar";
import { SelectedPlaceholderSet } from "./SelectedPlaceholderSet/SelectedPlaceholderSet";
import { Variables } from "./Variables/Variables";
import PromotionBanner from "../PromotionBanner";
import { useEffect } from "react";

const Portal = ({ selectedPlaceholderSet, groups, allowedAccess, onChangeGroup, onAddNewPlaceholder, groupIsChanged }) => {
  useEffect(() => {
    window.AP.theming.initializeTheming();
  }, []);
  if ((selectedPlaceholderSet === null || !selectedPlaceholderSet?.name) && groups?.length === 0) {
    return <EmptyPortal setAddPlaceholderSetModalVisible={onAddNewPlaceholder}/>;
  }
  return (
    <PortalContainer>
      <div>
        <Sidebar onChangeGroup={onChangeGroup} onAddNewPlaceholder={onAddNewPlaceholder} />
      </div>
      <div style={{ flex: "1", width: "100%", overflow: "hidden", minWidth: "610px", position: "relative" }}>
        <PromotionBanner />
        <SelectedPlaceholderSet allowedAccess={allowedAccess} groupIsChanged={groupIsChanged} />
      </div>
      {allowedAccess && <Variables />}
    </PortalContainer>
  );
};

export default Portal;
