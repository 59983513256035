import { useEffect, useState } from "react";
import { usePropertyGroupReportMacroStore } from "./store";
import Spinner from "@atlaskit/spinner";
import Button from "@atlaskit/button";
import { HelperMessage } from "@atlaskit/form";
import { NewPropertyFilter, ExistingPropertyFilter } from "./FilterFieldsForProperty";
import { useQuery } from "react-query";
import { getPropertiesOfGroup } from "./api";
import { generateId } from "../../../v2/id-generator";
import { PropertyType } from "../../property-type";
import { AnalyticsEvent } from "../../../analytics-events";
import { analytics } from "../../../analytics";

const ReportPropertyFilter = ({ isEditing = false }) => {
  const { group, propertyFilters, setPropertyFiltersWithIndex, removePropertyFiltersWithIndex } = usePropertyGroupReportMacroStore(
    (state) => ({
      group: state.selectedGroup,
      propertyFilters: state.propertyFilters,
      setPropertyFiltersWithIndex: state.setPropertyFiltersWithIndex,
      removePropertyFiltersWithIndex: state.removePropertyFiltersWithIndex,
    })
  );
  useEffect(() => {
    if (!propertyFilters || Object.keys(propertyFilters).length === 0) {
      const propertyFilterIdx = generateId();
      setPropertyFiltersWithIndex({}, propertyFilterIdx);
    }
  }, [propertyFilters]);
  const addPropertyFilterToStore = () => {
    analytics.logEvent(AnalyticsEvent.PROPERTY_FILTER_USED, { propertyFilterUsed: true });
    setPropertyFiltersWithIndex({}, generateId());
  };
  const [, setAllProperties] = useState([]);

  const { isLoading, data: availableProperties } = useQuery(["properties-of-group", { group }], getPropertiesOfGroup, {
    select(response) {
      return response.data.filter((d) => d.kind !== PropertyType.NUMBER).map((d) => ({ ...d, label: d.data.name, value: d.id }));
    },
    onSuccess: async (response) => {
      setAllProperties(response);
    },
  });
  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <Button
        appearance="link"
        onClick={() => {
          addPropertyFilterToStore();
        }}
        style={{
          fontSize: "12px",
          padding: "0",
          float: "right",
          lineHeight: "1.8",
          height: "15px",
        }}
      >
        Add
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {Object.keys(propertyFilters).map((key, index) => {
          return (
            <div style={{ marginTop: "5px" }}>
              {index > 0 && (
                <h6
                  style={{
                    width: "100%",
                    textAlign: "center",
                    lineHeight: "0.1em",
                    margin: "5px 0 10px",
                    fontWeight: "500",
                    color: "#6B778C",
                  }}
                >
                  AND
                </h6>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {Object.keys(propertyFilters[key]).length === 0 && (
                  <NewPropertyFilter isEditing={isEditing} targetIdx={key} availableProperties={availableProperties} />
                )}
                {Object.keys(propertyFilters[key]).length > 0 && (
                  <ExistingPropertyFilter
                    isEditing={isEditing}
                    targetIdx={key}
                    availableProperties={availableProperties}
                    propertyFilter={propertyFilters}
                  />
                )}
              </div>
              {index === Object.keys(propertyFilters).length - 1 ? <HelperMessage>Return items with these values</HelperMessage> : <></>}

              {(Object.keys(propertyFilters[key]).length > 0 || Object.keys(propertyFilters).length > 1) && (
                <>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    <Button
                      appearance="link"
                      onClick={() => {
                        removePropertyFiltersWithIndex(key);
                      }}
                      style={{
                        fontSize: "12px",
                        padding: "0",
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ReportPropertyFilter;
