export const FieldTypes = {
  ICON: "Icon",
  NAME: "Name",
  DESCRIPTION: "Description",
  RESTRICTIONS: "Restrictions",
};
export const FieldsDescriptors = {
  [FieldTypes.ICON]: { label: "Icon", name: "icon" },
  [FieldTypes.NAME]: { label: "Name", name: "name" },
  [FieldTypes.DESCRIPTION]: { label: "Description", name: "description" },
  [FieldTypes.RESTRICTIONS]: { label: "Restrictions", name: "restrictions" },
};
