import { useEffect, useState } from "react";
import { AsyncSelect, components } from "@atlaskit/select";
import { atlassianRestService } from "../../Service/AtlassianRestService";
import { usePropertyGroupReportMacroStore } from "./store";
import { AnalyticsEvent } from "../../../analytics-events";
import { analytics } from "../../../analytics";
import { getPagesInSpace, getSpaceByKey } from "./api";
import { token } from "@atlaskit/tokens";


function _toPageField(page) {
  return {
    contentType: page.content?.type || page.type,
    contentId: page.id || page.content?.id,
    spaceIconPath: page.content?.space?.icon?.path || page.space?.icon?.path,
    space: page.content?.space?.name || page.space.name,
    label: page.title,
    value: page.id || page.content?.id,
  };
}

const ParentPagePicker = ({}) => {
  const [selectedPage, setSelectedPage] = useState(undefined);
  const { pagesUnder, setPagesUnder, spaceChanged, selectedSpaces, currentPageId } = usePropertyGroupReportMacroStore((state) => ({
    pagesUnder: state.pagesUnder,
    setPagesUnder: state.setPagesUnder,
    spaceChanged: state.spaceChanged,
    selectedSpaces: state.selectedSpaces,
    currentPageId: state.currentPageId,
  }));

 useEffect(() => {
   let contains;

   const checkSpaces = async () => {
     try {
       // Use Promise.all to wait for all asynchronous operations to complete
       await Promise.all(
         selectedSpaces.map(async (s) => {
           const spaceUnparsed = await getSpaceByKey(s);
           const spaceData = JSON.parse(spaceUnparsed?.body);
           const spaceName = spaceData.name;

           const pagesUnderSpaceUnparsed = await getPagesInSpace(spaceData.id);
           const pagesUnderSpace = JSON.parse(pagesUnderSpaceUnparsed?.body).results;

           if (!contains) {
             contains = spaceName === selectedPage?.space;
           }
           if (!contains) {
             contains = pagesUnderSpace.find((p) => p.id === currentPageId) !== undefined;
           }
         })
       );
     } catch (error) {
       setPagesUnder(null);
       setSelectedPage(null);
       console.log(error);
     } finally {
       if (contains) return;
       setPagesUnder(null);
       setSelectedPage(null);
     }
   };

   checkSpaces();
 }, [spaceChanged]);


  useEffect(() => {
    if (pagesUnder) {
      if (pagesUnder === "current-page") {
        setSelectedPage({ label: "Current page", value: "current-page" });
        setPagesUnder("current-page");
      } else {
        atlassianRestService.getPageById(pagesUnder).then((result) => {
          setSelectedPage(_toPageField(result));
        });
      }
    }
  }, []);

  const loadOptions = async (query) => {
    return (await atlassianRestService.searchForPage({ query })).results.map((page) => {
      return _toPageField(page);
    });
  };
  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={[{ label: "Current page", value: "current-page" }]}
      placeholder="Search page"
      onChange={(e) => {
        if (e) {
          setSelectedPage(e);
          if (e.value === "current-page") {
            setPagesUnder("current-page");
          } else {
            setPagesUnder(e.contentId);
          }
          analytics.logEvent(AnalyticsEvent.PAGESUNDER_FILTER_USED, { isPagesUnderFilter: true });
        } else {
          setSelectedPage(null);
          setPagesUnder(null);
        }
      }}
      components={{
        Option: singleOption,
      }}
      isClearable
      value={selectedPage}
      loadOptions={loadOptions}
      styles={{
        control: (styles) => ({
          ...styles,
          backgroundColor: token("elevation.surface.overlay"),
          fontSize: "14px",
          width: "inherit",
        }),
        menu: (styles) => ({
          ...styles,
          width: "100%",
        }),
        singleValue: (styles) => ({
          ...styles,
          lineHeight: "18px",
          fontSize: "14px",
        }),
      }}
    />
  );
};

const singleOption = (props) => {
  const { space } = props?.data;
  const { label } = props;
  return (
    <components.Option {...props}>
      {!space && label}
      {space && (
        <div
          style={{
            display: "flex",
          }}
        >
          <div>
            <img
              style={{ marginTop: "3px", marginRight: "10px", borderRadius: "2px", borderColor: "white" }}
              src={`${window.AP._hostOrigin}/wiki/${props.data.spaceIconPath}`}
              height="24"
              width="24"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: "14px" }}>{label}</div>
            <div style={{ marginTop: "3px", fontSize: "12px" }}>{space}</div>
          </div>
        </div>
      )}
    </components.Option>
  );
};

export default ParentPagePicker;
