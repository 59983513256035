import { propertyRecordsService } from "../../Service/PropertyRecordsService";
import create from "zustand";

export const usePropertyGroupChangelogStore = create((set, get) => ({
  entries: [],
  startAfter: "",
  allLoaded: false,
  loadMore: async (pageId, recordId) => {
    const { data } = await propertyRecordsService.getLogForRecord(pageId, recordId, get().startAfter);
    return set((state) => ({ entries: [...get().entries, ...data.results], startAfter: data.next, allLoaded: data.allLoaded }));
  },
}));
