export const FormattedDate = ({ seconds, nanoseconds, styles = {}, inChangelog = false }) => {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const getDateAsString = () => {
    const date = new Date(seconds * 1000 + nanoseconds / 1000000);
    const monthIndex = date.getMonth();
    const monthName = months[monthIndex];
    const baseDate = `${monthName} ${date.getDate()}, ${date.getFullYear()}`;
    return inChangelog === true ? `${baseDate} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}` : `${baseDate}`;
  };
  return <span style={styles}>{getDateAsString()}</span>;
};

export const FormatDateString = ({ day, month, year }) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return months[month] + " " + day + ", " + year;
};

