import { PropertyType } from "../../property-type";
import DatePropertyEdit from "../PropertyGroupMacro/editor/DatePropertyEdit";
import MultiSelectPropertyEdit from "../PropertyGroupMacro/editor/MultiSelectPropertyEdit";
import SelectPropertyEdit from "../PropertyGroupMacro/editor/SelectPropertyEdit";
import TextPropertyEdit from "../PropertyGroupMacro/editor/TextPropertyEdit";
import UserPropertyEdit from "../PropertyGroupMacro/editor/UserPropertyEdit";
import DatePropertyView from "../PropertyGroupMacro/view/DatePropertyView";
import InlineEditing from "../PropertyGroupMacro/view/InlineEditing";
import MultiSelectPropertyView from "../PropertyGroupMacro/view/MultiSelectPropertyView";
import SelectPropertyView from "../PropertyGroupMacro/view/SelectPropertyView";
import TextPropertyView from "../PropertyGroupMacro/view/TextPropertyView";
import { propertyRecordsService } from "../../Service/PropertyRecordsService";
import UserPropertyView from "../PropertyGroupMacro/view/UserPropertyView";
import { usePropertyGroupReportMacroStore } from "./store";
import shallow from "zustand/shallow";
import { usePropertyGroupReportFormStore } from "./property-group-report-form-store";
import ConfluencePagePropertyView from "../PropertyGroupMacro/view/ConfluencePagePropertyView";
import ConfluencePagePropertyEdit from "../PropertyGroupMacro/editor/ConfluencePagePropertyEdit";
import EmailPropertyView from "../PropertyGroupMacro/view/EmailPropertyView";
import EmailPropertyEdit from "../PropertyGroupMacro/editor/EmailPropertyEdit";
import UrlPropertyView from "../PropertyGroupMacro/view/UrlPropertyView";
import UrlPropertyEdit from "../PropertyGroupMacro/editor/UrlPropertyEdit";
import PhonePropertyView from "../PropertyGroupMacro/view/PhonePropertyView";
import PhonePropertyEdit from "../PropertyGroupMacro/editor/PhonePropertyEdit";
import NumberPropertyView from "../PropertyGroupMacro/view/NumberPropertyView";
import NumberPropertyEdit from "../PropertyGroupMacro/editor/NumberPropertyEdit";
import { atlassianRestService } from "../../Service/AtlassianRestService";

const PropertyBasedOnKind = ({ currentProperty, pageId, recordId, disableEdit = false, viewStyles = {} }) => {
  const { group } = usePropertyGroupReportMacroStore(
    (state) => ({
      group: state.selectedGroup,
    }),
    shallow
  );

  const { values, setInitialValues, initialValues, updateValue, addContentIdNotAllowedToEdit, contentIdsNotAllowedToEdit } =
    usePropertyGroupReportFormStore((state) => ({
      values: state.values,
      initialValues: state.initialValues,
      updateValue: state.updateValue,
      setInitialValues: state.setInitialValues,
      addContentIdNotAllowedToEdit: state.addContentIdNotAllowedToEdit,
      contentIdsNotAllowedToEdit: state.contentIdsNotAllowedToEdit,
    }));

  const onSave = async ({ pageId, recordId, propertyId }) => {
    try {
      const pageResponse = await atlassianRestService.getPageById(pageId);
      await propertyRecordsService.createOrUpdateRecord(
        pageId,
        group,
        recordId,
        JSON.stringify(values[pageId][recordId]),
        pageResponse.version.number
      );
      setInitialValues(pageId, recordId, values[pageId][recordId]);
    } catch (error) {
      if (error.response && error.response.status === 403 && error.response.data.code === "NO_EDIT_PERMISSIONS") {
        addContentIdNotAllowedToEdit(pageId);
        updateValue({ value: initialValues[pageId]?.[recordId]?.[propertyId], propertyId, recordId, pageId });
        window.AP.flag.create({
          title: `You have no permission to edit record.`,
          type: "error",
          close: "auto",
        });
      }
    }
  };

  const onCancel = ({ pageId, recordId, propertyId }) => {
    updateValue({ value: initialValues[pageId]?.[recordId]?.[propertyId], propertyId, recordId, pageId });
  };

  return (
    <>
      {currentProperty.kind === PropertyType.TEXT && (
        <InlineEditing
          viewStyles={viewStyles}
          disableEdit={disableEdit || contentIdsNotAllowedToEdit.includes(pageId)}
          onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
          onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
          View={
            <TextPropertyView
              key={currentProperty.id}
              property={currentProperty}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
          Edit={
            <TextPropertyEdit
              autoFocus
              property={currentProperty}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
              onUpdate={(newValue) => {
                updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
              }}
            />
          }
        />
      )}
      {currentProperty.kind === PropertyType.URL && (
        <InlineEditing
          viewStyles={viewStyles}
          disableEdit={disableEdit || contentIdsNotAllowedToEdit.includes(pageId)}
          onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
          onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
          View={
            <UrlPropertyView
              key={currentProperty.id}
              property={currentProperty}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
          Edit={
            <UrlPropertyEdit
              autoFocus
              property={currentProperty}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
              onUpdate={(newValue) => {
                updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
              }}
            />
          }
        />
      )}
      {currentProperty.kind === PropertyType.DATE && (
        <InlineEditing
          disableEdit={
            disableEdit ||
            contentIdsNotAllowedToEdit.includes(pageId) ||
            currentProperty.data.displayLastModified ||
            currentProperty.data.displayCreationDate
          }
          onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
          onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
          View={
            <DatePropertyView
              key={currentProperty.id}
              displayMode
              pageId={pageId}
              property={currentProperty}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
          Edit={
            <DatePropertyEdit
              autoFocus
              property={currentProperty}
              onUpdate={(newValue) => {
                updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
              }}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
        />
      )}
      {currentProperty.kind === PropertyType.SELECT && (
        <InlineEditing
          disableEdit={disableEdit || contentIdsNotAllowedToEdit.includes(pageId)}
          onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
          onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
          View={
            <SelectPropertyView
              key={currentProperty.id}
              property={currentProperty}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
          Edit={
            <SelectPropertyEdit
              autoFocus
              property={currentProperty}
              onUpdate={(newValue) => {
                updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
              }}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
        />
      )}
      {currentProperty.kind === PropertyType.MULTISELECT && (
        <InlineEditing
          disableEdit={disableEdit || contentIdsNotAllowedToEdit.includes(pageId)}
          onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
          onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
          View={
            <MultiSelectPropertyView
              key={currentProperty.id}
              property={currentProperty}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
          Edit={
            <MultiSelectPropertyEdit
              autoFocus
              property={currentProperty}
              onUpdate={(newValue) => {
                updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
              }}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
        />
      )}
      {currentProperty.kind === PropertyType.USER && (
        <InlineEditing
          disableEdit={
            disableEdit ||
            contentIdsNotAllowedToEdit.includes(pageId) ||
            currentProperty.data.displayPageCreator ||
            currentProperty.data.displayLastModifier
          }
          onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
          onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
          View={
            <UserPropertyView
              displayMode
              key={currentProperty.id}
              property={currentProperty}
              pageId={pageId}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
          Edit={
            <UserPropertyEdit
              autoFocus
              property={currentProperty}
              onUpdate={(newValue) => {
                updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
              }}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
        />
      )}
      {currentProperty.kind === PropertyType.CONFLUENCEPAGE && (
        <InlineEditing
          disableEdit={disableEdit || contentIdsNotAllowedToEdit.includes(pageId) || currentProperty.data.displayCurrentPage}
          onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
          onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
          View={
            <ConfluencePagePropertyView
              displayMode
              pageId={pageId}
              property={currentProperty}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
          Edit={
            <ConfluencePagePropertyEdit
              autoFocus
              property={currentProperty}
              onUpdate={(newValue) => {
                updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
              }}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
        />
      )}
      {currentProperty.kind === PropertyType.EMAIL && (
        <InlineEditing
          disableEdit={disableEdit || contentIdsNotAllowedToEdit.includes(pageId)}
          onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
          onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
          View={
            <EmailPropertyView
              key={currentProperty.id}
              property={currentProperty}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
          Edit={
            <EmailPropertyEdit
              autoFocus
              property={currentProperty}
              onUpdate={(newValue) => {
                updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
              }}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
        />
      )}
      {currentProperty.kind === PropertyType.PHONE && (
        <InlineEditing
          disableEdit={disableEdit || contentIdsNotAllowedToEdit.includes(pageId)}
          onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
          onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
          View={
            <PhonePropertyView
              key={currentProperty.id}
              property={currentProperty}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
          Edit={
            <PhonePropertyEdit
              autoFocus
              property={currentProperty}
              onUpdate={(newValue) => {
                updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
              }}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
        />
      )}
      {currentProperty.kind === PropertyType.NUMBER && (
        <InlineEditing
          disableEdit={
            disableEdit || 
            contentIdsNotAllowedToEdit.includes(pageId) ||
            currentProperty.data.showCurrentPageVersion 
          }
          onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
          onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
          View={
            <NumberPropertyView property={currentProperty} initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined} pageId = {pageId} />
          }
          Edit={
            <NumberPropertyEdit
              autoFocus
              property={currentProperty}
              onUpdate={(newValue) => {
                updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
              }}
              initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
            />
          }
        />
      )}
    </>
  );
};

export default PropertyBasedOnKind;
