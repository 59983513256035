import { useEffect, useState } from "react";
import { HelperMessage } from "@atlaskit/form";
import { CreatableSelect } from "@atlaskit/select";
import { SelectFieldStyles } from "../../../../../../Shared/SelectFieldStyles";
import { AnalyticsEvent } from "../../../../../../../../analytics-events";
import { analytics } from "../../../../../../../../analytics";
import { usePlaceholderStore } from "../../../../../Store";

const createOption = (label) => ({
  label: label,
  value: label,
});

const labelFilterStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const createSelectStyle = (SelectFieldStyles) => {
  return (
    SelectFieldStyles && {
      control: (base) => ({
        ...base,
        backgroundColor: "#FFFFFF",
        fontSize: "14px",
      }),
    }
  );
};

const LabelPicker = ({ selectedLabels, onLabelsChange, saveLabel }) => {
  const handleBlur = (addedLabel) => {
    const addedLabelValue = addedLabel.target.value;
    saveLabel(addedLabelValue);
  };

  return (
    <CreatableSelect
      isMulti
      isClearable
      styles={createSelectStyle(SelectFieldStyles)}
      placeholder="Filter by label"
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Menu: () => null,
      }}
      onBlur={handleBlur}
      formatCreateLabel={() => null}
      onChange={onLabelsChange}
      onCreateOption={saveLabel}
      value={selectedLabels}
    />
  );
};
export const LabelFilter = ({ placeholder, onLabelsChange: triggerLabelsChange, uniqueId }) => {
  const { labels, setLabelsWithIndex, areLabelsChanged, areSpacesChanged, setLabels } = usePlaceholderStore((state) => ({
    labels: placeholder.data.labels?.[uniqueId]?.length ? placeholder.data.labels : state.labels,
    setLabelsWithIndex: state.setLabelsWithIndex,
    removeLabels: state.removeLabels,
    areLabelsChanged: state.areLabelsChanged,
    setAreLabelsChanged: state.setAreLabelsChanged,
    setLabels: state.setLabels,
  }));
  const [selectedLabels, setSelectedLabels] = useState([]);

  useEffect(() => {
    setLabelsWithIndex(selectedLabels, uniqueId);
  }, [selectedLabels, uniqueId, areLabelsChanged, areSpacesChanged]);

  useEffect(() => {
    setSelectedLabels(labels?.[uniqueId] ?? []);
  }, [uniqueId]);

  useEffect(() => {
    if (placeholder && placeholder.data && placeholder.data.labels) {
      setLabels(placeholder.data.labels);
    }
  }, [placeholder]);

  const onLabelsChange = (updatedLabels) => {
    setSelectedLabels(updatedLabels?.map((labelFromStore) => labelFromStore.label) ?? []);
    triggerLabelsChange();
  };

  const saveLabel = (newLabel) => {
    if (!newLabel) return null;
    let labelsToAdd = [...selectedLabels];

    if (newLabel.includes(" ")) {
      const newLabels = newLabel.split(/(\s+)/).filter((e) => e.trim().length > 0);
      labelsToAdd.push(...newLabels);
    } else {
      labelsToAdd.push(newLabel);
    }
    labelsToAdd.length && analytics.logEvent(AnalyticsEvent.LABEL_FILTER_USED, { label_filter_used: true });

    setSelectedLabels(labelsToAdd);
    triggerLabelsChange();
  };

  return (
    <div style={labelFilterStyle}>
      <div style={{ marginTop: "5px" }}>
        <LabelPicker
          selectedLabels={selectedLabels?.map((labelFromStore) => createOption(labelFromStore)) ?? []}
          onLabelsChange={onLabelsChange}
          saveLabel={saveLabel}
        />
        <HelperMessage>Returns pages with these labels</HelperMessage>
      </div>
    </div>
  );
};
