import React from "react";
import PropertyGroupForm from "./PropertyGroupForm";

const PropertyGroupFormInline = ({ reportView = false, updateValue, values, data, pageId, recordId, group, ordinalNumber }) => {
  return (
    <PropertyGroupForm
      shortVersion
      reportView={reportView}
      updateValue={updateValue}
      values={values}
      data={data}
      pageId={pageId}
      recordId={recordId}
      group={group}
      ordinalNumber={ordinalNumber}
    />
  );
};

export default PropertyGroupFormInline;
