import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import shallow from "zustand/shallow";
import { loadDataFromMacro } from "../../macro-service";
import { usePropertyVisualizationStore } from "./property-visualization-store";
import ModalWrapper from "../../CustomModal/ModalWrapper";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModalContent from "../../CustomModal/CustomModalContent";
import { VisualizationType } from "./visualization-variants";
import StructureVisualizationEditWrapper from "./OrgChart/StructureVisualizationEditWrapper";
import BoardVisualizationEditWrapper from "./Board/BoardVisualizationEditWrapper";

import TypeOverview from "./TypeOverview";
import { analytics } from "../../../analytics";
import { AnalyticsEvent } from "../../../analytics-events";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const PropertyVisualizationMacroEditor = (props) => {
  const { config, setConfig } = usePropertyVisualizationStore(
    (state) => ({
      config: state.config,
      setConfig: state.setConfig,
    }),
    shallow
  );

  const onBack = () => {
    setConfig({
      type: undefined,
      args: undefined,
    });
  };

  useEffect(() => {
    (async () => {
      const macroData = await loadDataFromMacro();
      try {
        const parsedConfig = JSON.parse(macroData.config);
        setConfig(parsedConfig);
      } catch (e) {
        setConfig({
          type: undefined,
          args: undefined,
        });
      }
    })();
  }, []);
  useEffect(() => {
    window.AP.theming.initializeTheming();
  }, []);


  const saveMacro = async () => {
    analytics.logEvent(AnalyticsEvent.VISUALIZATION_MACRO_CREATED_UPDATED, {
      configuration: JSON.stringify({ ...config }),
    });
    window.AP.confluence.saveMacro({
      modified: Date.now(),
      config: JSON.stringify({ ...config }),
    });
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ModalWrapper>
        <CustomModalHeader title={"Property Group Visualization"}></CustomModalHeader>
        <CustomModalContent>
          {!config.type && <TypeOverview />}
          {config.type === VisualizationType.ORG_CHART && <StructureVisualizationEditWrapper />}
          {config.type === VisualizationType.BOARD && <BoardVisualizationEditWrapper />}
        </CustomModalContent>
        <CustomModalFooter
          saveHidden={config.type ? false : true}
          onSave={saveMacro}
          onBack={config.type ? onBack : undefined}
          documentationURL="https://junovi.atlassian.net/wiki/x/NADefw"
        ></CustomModalFooter>
      </ModalWrapper>
    </QueryClientProvider>
  );
};

export default PropertyVisualizationMacroEditor;
