import { useEffect, useState } from "react";
import styled from "styled-components";
import TextArea from "@atlaskit/textarea";
import { urlBasicRegex } from "../../url-sanitizer";
import { ErrorMessage } from "@atlaskit/form";
import { usePropertyValidationStore } from "../store";
import { FieldStyles } from "../../Shared/FieldThemingStyles";
const MultipleTextarea = styled.div`
  > textarea {
    height: 36px !important;
  }
`;
const UrlPropertyEdit = ({ property, initialValue, autoFocus = false, onUpdate }) => {
  const [value, setValue] = useState(initialValue);
  const { validValue, setValidValue } = usePropertyValidationStore((state) => ({
    validValue: state.validValue,
    setValidValue: state.setValidValue,
  }));
  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
    setValidValue(true);
  }, [initialValue]);
  function validateValue(value) {
    const isUrlValid = urlBasicRegex.test(value);
    setValidValue(isUrlValid);
  }
  function updateValue(value) {
    if (validValue) {
      onUpdate(value);
      setValue(value);
    }
    if (!value) {
      setValue("");
      onUpdate("");
      setValidValue(true);
    }
  }
  return (
    <div>
      <MultipleTextarea>
        <TextArea
          autoFocus={autoFocus}
          value={value}
          placeholder="Add one or multiple links and separate them with comma"
          onChange={(e) => {
            const newValue = e.target.value;
            setValue(newValue);
            validateValue(newValue);
            updateValue(newValue);
          }}
          style={(FieldStyles, { fontSize: "14px" })}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              validateValue(event.target.value);
              updateValue(event.target.value);
              event.preventDefault();
            }
          }}
          onBlur={(e) => {
            validateValue(e.target.value);
            updateValue(e.target.value);
          }}
        />
      </MultipleTextarea>
      {validValue === false ? (
        <ErrorMessage>No valid url. If you are using multiple links separate every link with a comma</ErrorMessage>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UrlPropertyEdit;
