import { ChartStrategy } from "./chart-strategy";

export const ChartVariant = {
  STRUCTURE: "structure",
};

export const DEFAULT_ORG_CHART = {
  strategy: ChartStrategy.PROPERTY_GROUP,
  data: {
    group: undefined,
    variant: ChartVariant.STRUCTURE,
    args: {},
  },
};
