import Field from "@atlaskit/form/Field";
import { useEffect, useState } from "react";
import { getCountries, getCountryCallingCode } from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import Select from "@atlaskit/select";
import PropTypes from "prop-types";
import { SelectFieldStyles } from "../../../../SelectFieldStyles";
import { usePlaceholderStore } from "../../../../Store";

CountrySelectionField.propTypes = {
  placeholder: PropTypes.object.isRequired,
};
export function CountrySelectionField({ placeholder }) {
  const [country, setCountry] = useState();

  const { changeCountry } = usePlaceholderStore((state) => ({
    changeCountry: state.changeCountry,
  }));

  useEffect(() => {
    if (placeholder.data) {
      const countryValue = placeholder.data.country;
      setCountry(
        getCountries()
          .map((country) => ({
            label: en[country] + " +" + getCountryCallingCode(country),
            value: en[country] + " +" + getCountryCallingCode(country),
            initials: country,
          }))
          .find((country) => country.value === countryValue)
      );
    }
    placeholder.data.country === undefined && changeCountry(placeholder, []);
  }, []);

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry || undefined);
    changeCountry(placeholder, selectedCountry.initials);
  };

  const options = getCountries().map((country) => ({
    label: en[country] + " +" + getCountryCallingCode(country),
    value: en[country] + " +" + getCountryCallingCode(country),
    initials: country,
  }));

  return (
    <Field label="Default country" name="country" defaultValue="" isRequired>
      {({ fieldProps }) => (
        <Select
          {...fieldProps}
          maxMenuHeight={110}
          placeholder="Select a default country"
          value={country}
          menuPlacement="auto"
          styles={SelectFieldStyles}
          options={options}
          onChange={handleCountryChange}
        />
      )}
    </Field>
  );
}
