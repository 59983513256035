import React from "react";
import Button from "@atlaskit/button";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";

export const ConfirmSaveWhenDeletingModal = (props) => {
  const { onClose, visible, onConfirm } = props;

  const handleConfirm = async () => {
    onConfirm();
    onClose();
  };
  const style = {
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: "5px",
    },
  };

  return (
    <ModalTransition>
      {visible && (
        <Modal height="192px" width="400px" heading={`Confirm save`} onClose={onClose}>
          <div>
            <p>You are going to delete Properties after save. Continue?</p>
            <div style={style.buttonContainer}>
              <Button onClick={handleConfirm} appearance="danger">
                Continue
              </Button>
              <Button style={{ marginLeft: "10px" }} onClick={onClose}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};
