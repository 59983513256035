import { Field } from "@atlaskit/form";
export const renderField = ({ fieldDescriptor, renderChildren, customProps = {}, aditionalDiv = false, isRequired = false }) => (
  <Field {...fieldDescriptor} isRequired={isRequired}>
    {({ fieldProps, error, meta: { valid } }) =>
      aditionalDiv ? (
        <div>{renderChildren({ fieldProps, error, valid, ...customProps })}</div>
      ) : (
        <>{renderChildren({ fieldProps, error, valid, ...customProps })}</>
      )
    }
  </Field>
);
