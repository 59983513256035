import React, { useEffect, useRef } from "react";
import Field from "@atlaskit/form/Field";
import Textfield from "@atlaskit/textfield";
import PropTypes from "prop-types";
import { usePlaceholderStore } from "../../../../Store";

NameField.propTypes = {
  placeholder: PropTypes.object.isRequired,
};
export function NameField({ placeholder, ref }) {
  const nameRef = useRef(null);
  const { placeholders, changeName } = usePlaceholderStore((state) => ({
    placeholders: state.placeholders,
    changeName: state.changeName,
  }));

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  return (
    <Field label="Name" name="name" defaultValue="" isRequired>
      {({ fieldProps }) => (
        <Textfield
          ref={nameRef}
          {...fieldProps}
          value={placeholders.find((p) => p === placeholder)?.data.name || ""}
          onChange={(e) => changeName(placeholder, e.target.value)}
          placeholder="Enter property name"
        />
      )}
    </Field>
  );
}
