import create from "zustand";

export const useChartVisualizationStore = create((set, get) => ({
  config: {},
  availableGroups: undefined,
  setConfig: (data) => set((state) => ({ config: data })),
  updateConfig: (update) => set((state) => ({ config: { ...state.config, args: { ...state.config.args, ...update } } })),
  setAvailableGroups: (groups) => set((state) => ({ availableGroups: groups })),
}));

export const useSystemPropertiesUIStore = create((set, get) => ({
  selected: undefined,
  setSelected: (data) => set((state) => ({ selected: data })),
}));
