const OPTIONS_COLOR_PALETTE = [
  { label: "Grey", value: "#DFE1E6" },
  { label: "Blue", value: "#DEEBFF" },
  { label: "Green", value: "#E3FCEF" },
  { label: "Yellow", value: "#FFF0B3" },
  { label: "Red", value: "#FFEBE6" },
  { label: "Purple", value: "#EAE6FF" },
];

export default OPTIONS_COLOR_PALETTE;
