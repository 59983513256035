export const initResizeObserver = () => {
  let { scrollHeight } = document.body;
  const mutationObserver = new MutationObserver((entries) => {
    if (scrollHeight !== document.body.scrollHeight) {
      scrollHeight = document.body.scrollHeight;
      if (window.AP && window.AP.resize) {
        let height = scrollHeight + 10;
        window.AP.resize("100%", `${height.toString()}px`);
        window.AP.sizeToParent();
      }
    }
  });
  mutationObserver.observe(document.body, {
    attributes: true,
    childList: true,
    subtree: true,
  });

  const resizeObserver = new ResizeObserver((entries) => {
    const dialogElement = document.querySelector(".property-inline-dialog");
    if (window.AP && window.AP.resize) {
      if(!dialogElement){
        window.AP.resize("100%", `${(document.body.scrollHeight + 10).toString()}px`);
        window.AP.sizeToParent();
      }
    }
  });
  resizeObserver.observe(document.body);

  window.AP.events.on("trigger_resize", (args) => {
    const selector = args[0];
    if (selector) {
      const element = document.querySelector(selector);
      if (element) {
        const newHeight = document.body.scrollHeight + 10 + element.offsetHeight;
        window.AP.resize("100%", `${newHeight.toString()}px`);
      }
    }
  });

  window.AP.events.on("trigger_resize_livefilter", (args) => {
    const selector = args[0];
    if (selector) {
      const element = document.querySelector(selector);
      if (element) {
        const tableElement = document.querySelector(".report-table");
        const dialogElement = document.querySelector(".property-inline-dialog");
        if (tableElement?.clientHeight < dialogElement?.clientHeight + 100) {
          setTimeout(() => {
            if (element.getBoundingClientRect().bottom + 200 > dialogElement.getBoundingClientRect().bottom) {
              window.AP.resize("100%", `${(dialogElement.getBoundingClientRect().bottom + element.scrollHeight).toString()}px`);
            }
          }, 150);
        }
      }
    }
  });
};

export const onSelectMenuClosed = () => {
  const tableElement = document.querySelector(".report-table");
  const dialogElement = document.querySelector(".property-inline-dialog");
  if (tableElement && dialogElement && tableElement?.clientHeight < dialogElement?.clientHeight + 100) {
    window.AP.resize("100%", `${(dialogElement.getBoundingClientRect().bottom + 60).toString()}px`);
  }
};
