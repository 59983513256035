/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { NavigationContent, SideNavigation, HeadingItem, LinkItem } from "@atlaskit/side-navigation";
import { Field, HelperMessage } from "@atlaskit/form";
import ReportGroupPicker from "./ReportGroupPicker";
import ReportLabelFilter from "./ReportLabelFilter";
import ReportSpaceFilter from "./ReportSpaceFilter";
import EditorOpenIcon from "@atlaskit/icon/glyph/editor/open";
import ReportDisplayOptions from "./ReportDisplayOptions";
import ReportPropertyFilter from "./ReportPropertyFilter";
import ParentPagePicker from "./ParentPagePicker";
import { token } from "@atlaskit/tokens";

const ReportEditorSidebar = (props) => {
  return (
    <div
      className="content-sidebar"
      style={{
        height: "100%",
        width: "28em",
        borderRight: "1px solid #cccccc",
      }}
    >
      <SideNavigation>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: token("utility.elevation.surface.current"),
          }}
        >
          <NavigationContent
            css={css`
              margin: 0;
              margin-left: 20px;
              margin-right: 20px;
            `}
          >
            <div style={{ margin: "0", marginLeft: "15px", marginRight: "20px" }}>
              <Field label="Property Group" name="select group" defaultValue="">
                {({}) => (
                  <>
                    <ReportGroupPicker />
                    <HelperMessage>Select the property group you want to generate a report for</HelperMessage>
                  </>
                )}
              </Field>
              <HeadingItem cssFn={() => ({ paddingLeft: 0 })}>Filters</HeadingItem>
              <div style={{ padding: "10px 20px", backgroundColor: token("elevation.surface.overlay.hovered") }}>
                <Field label="Property Value(s)" name="filter properties" defaultValue="">
                  {() => (
                    <>
                      <ReportPropertyFilter isEditing />
                    </>
                  )}
                </Field>
                <Field label="Label(s)" name="filter labels" defaultValue="">
                  {() => (
                    <>
                      <ReportLabelFilter />
                    </>
                  )}
                </Field>
                <Field label="Space(s)" name="filter spaces" defaultValue="">
                  {() => (
                    <>
                      <ReportSpaceFilter />
                      <HelperMessage>Returns items from these spaces</HelperMessage>
                    </>
                  )}
                </Field>
                <Field label="Pages under" name="pages under" defaultValue="">
                  {() => (
                    <>
                      <ParentPagePicker />
                      <HelperMessage>Only include pages under specific page</HelperMessage>
                    </>
                  )}
                </Field>
              </div>
              <ReportDisplayOptions />
              <br />
            </div>
          </NavigationContent>
          <LinkItem
            iconAfter={<EditorOpenIcon />}
            target="_blank"
            rel="nofollow noopener"
            href={`${window.AP._hostOrigin}/wiki/plugins/servlet/ac/net.junovi.confluence.properties/properties-overview`}
          >
            Go to portal
          </LinkItem>
        </div>
      </SideNavigation>
    </div>
  );
};

export default ReportEditorSidebar;
