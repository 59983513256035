import React, { useEffect, useRef, useState } from "react";
import PropertyGroupFormWrapper from "./PropertyGroupFormWrapper";
import { getPropertiesOfGroup } from "../PropertyGroupMacro/api";
import { useQuery } from "react-query";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import { CreateCardClosed, CreateCardOpened, Cover } from "./StyledComponents";
import { usePropertyFormStore } from "../PropertyGroupMacro/editor/property-form-store";
import { useOnClickOutside } from "../PropertyVisualizationMacro/Board/useOnClickOutside";
import { v4 as uuidv4 } from "uuid";
import { useBoardStore } from "../PropertyVisualizationMacro/Board/board-store";
import { createNewPage } from "./api";
import Select from "@atlaskit/select";
import { SelectFieldStyles } from "./SelectFieldStyles";
import { atlassianRestService } from "../../Service/AtlassianRestService";
import AddSaveEntry from "./AddSaveEntry";
import { notify } from "./notification-flag";
import Tooltip from "@atlaskit/tooltip";
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle";
import styled from "styled-components";
import "./CreateCard.scss";
import { token } from "@atlaskit/tokens";

const InputWrapper = styled.div`
  width: 100%;
  > input {
    background-color: ${token("utility.elevation.surface.current")};
    box-shadow: ${token("elevation.shadow.overflow")};
    border: 1px solid !important;
    border-color: ${token("color.border.input")} !important;
  }
`;

const CreateCard = ({
  name,
  groupId,
  isEditView,
  onCreateNewPage,
  isUnassigned,
  isPickable = "enabled",
  onComputedHeight = () => {},
  isEntry = false,
}) => {
  const { isLoading, data: groupData } = useQuery(["properties-of-group", { group: groupId }], getPropertiesOfGroup, { staleTime: 0 });
  const { values: propertyFormValues, setValues: setPropertyFormValues } = usePropertyFormStore((state) => ({
    values: state.values,
    setValues: state.setValues,
  }));
  const { setIsCreateCardOpened: setOpenGlobally } = useBoardStore((state) => ({
    isCreateCardOpened: state.isCreateCardOpened,
    setIsCreateCardOpened: state.setIsCreateCardOpened,
  }));
  const [isCreateCardOpened, setIsCreateCardOpened] = useState(false);
  const [inputState, setInputState] = useState("");
  const [selectedPage, setSelectedPage] = useState(undefined);
  const [parentPages, setParentPages] = useState([]);

  const createCardOpenedRef = useRef(null);
  const createCardInputRef = useRef(null);
  const [createCardName, setCreateCardName] = useState("");

  const toggleIsCreateCardOpened = () => setIsCreateCardOpened((prevState) => !prevState);
  useOnClickOutside(createCardOpenedRef, toggleIsCreateCardOpened);

  useEffect(() => {
    setOpenGlobally(isCreateCardOpened);
    createCardInputRef.current?.focus();
    const numberOfFileds = groupData?.data?.length;
    const minFiledsHeight = 130 * numberOfFileds;
    const dialogHeight = 500 + minFiledsHeight;
    isCreateCardOpened ? onComputedHeight(dialogHeight) : onComputedHeight(0);
  }, [isCreateCardOpened]);

  useEffect(async () => {
    const { results } = await atlassianRestService.searchForPage({ query: inputState, limit: 25 });
    const pages = results.map(({ content }) => content);
    if (pages[0]?.id) {
      const sortedPages = pages?.sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      setParentPages(sortedPages);
    } else {
      console.log("could not get pages");
    }
  }, [inputState]);

  const onCancel = () => {
    setPropertyFormValues({});
    toggleIsCreateCardOpened();
  };

  const onSave = async () => {
    const { confluence: context } = await window.AP.context.getContext();
    const recordId = uuidv4();
    const spaceId = context.space.id;
    const spaceKey = context.space.key;
    const parentId = selectedPage?.id ?? context.content.id;

    const values = propertyFormValues;
    const stringifiedValues = JSON.stringify(values, true);

    if (createCardName !== "") {
      const cardName = createCardName;
      try {
        const response = await createNewPage(spaceId, parentId, recordId, groupId, cardName, stringifiedValues, spaceKey);
        if (response.id) {
          isEntry ? onCreateNewPage(groupId, values, response, recordId) : onCreateNewPage();
          setPropertyFormValues({});
          notify("success", `${cardName} added`);
        } else {
          onCreateNewPage();
          notify("error", response.message);
        }
      } catch (error) {
        notify("error", error.message);
      } finally {
        toggleIsCreateCardOpened();
        return true;
      }
    } else {
      notify("error", "Page name cannot be empty");
    }
  };

  const renderCreateCardOpened = () => (
    <>
      <Cover />
      <CreateCardOpened className="create-card" innerRef={createCardOpenedRef} placeholder="Create page">
        <div style={{ fontWeight: 700 }}>{isEntry ? "Add Entry" : "Create page"}</div>

        {isPickable === "disabled" ? (
          <div style={{ display: "none" }}></div>
        ) : (
          <div className="input-or-select-container" style={{ marginBottom: "16px" }}>
            <span className="input-or-select-container-names">
              Choose a parent page
              <span className="parent-page-tooltip">
                <Tooltip content="Leave empty to select the current page">
                  <QuestionCircleIcon primaryColor="darkgrey" size="small" />
                </Tooltip>
              </span>
            </span>
            <Select
              className="create-card-select"
              isClearable
              styles={SelectFieldStyles}
              value={selectedPage}
              options={parentPages?.map((page) => {
                return { value: page.title, label: page.title, id: page.id };
              })}
              onInputChange={(newInputState) => {
                setInputState(newInputState);
              }}
              onChange={(newOption) => {
                setSelectedPage(newOption);
              }}
              placeholder={isPickable === "enabled" ? "Select a parent page" : "disabled in the edit mode"}
            ></Select>
          </div>
        )}
        <div className="input-or-select-container">
          <span className="input-or-select-container-names">
            Enter the page name <span style={{ color: "red" }}> *</span>
          </span>
          <InputWrapper>
            <input
              className="create-card-input"
              innerRef={createCardInputRef}
              onChange={(e) => {
                setCreateCardName(e.target.value);
              }}
              value={createCardName}
            />
          </InputWrapper>
        </div>
        {groupData && <PropertyGroupFormWrapper groupData={groupData} selectPropertyName={name || null} />}
        <CustomModalFooter
          onSave={onSave}
          saveDisabled={!groupId}
          documentationURL="https://junovi.atlassian.net/wiki/x/IQDkfw"
          boardViewSettings={{
            onCancel,
          }}
        />
      </CreateCardOpened>
    </>
  );

  const renderCreateCardClosed = () => (
    <div className={`${isUnassigned ? "create-card-unassigend" : "create-card-entity"}`}>
      <CreateCardClosed isEntity={!isUnassigned} onClick={toggleIsCreateCardOpened}>
        + Create
      </CreateCardClosed>
    </div>
  );

  const renderPlaceholder = () =>
    isEntry ? (
      <AddSaveEntry onToggle={toggleIsCreateCardOpened} />
    ) : (
      <div className={`${isUnassigned ? "placeholder-unassigend" : "placeholder-entity"}`}>
        <CreateCardClosed onClick={toggleIsCreateCardOpened}>+ Create</CreateCardClosed>
      </div>
    );

  if (isEditView) {
    return null;
  }

  return (
    <>
      {isCreateCardOpened && groupData && renderCreateCardOpened()}
      {renderCreateCardClosed()}
      {renderPlaceholder()}
    </>
  );
};

export default CreateCard;
