import { useState } from "react";
import Select from "@atlaskit/select";
import Spinner from "@atlaskit/spinner";

import { usePropertyGroupReportMacroStore } from "./store";
import { useQuery } from "react-query";
import { getPropertiesOfGroup } from "./api";
import { SelectFieldStyles } from "../Shared/SelectFieldStyles";

const SelectedColumnsPicker = (props) => {
  const { group, selectedColumns, setSelectedColumns } = usePropertyGroupReportMacroStore((state) => ({
    group: state.selectedGroup,
    selectedColumns: state.selectedColumns,
    setSelectedColumns: state.setSelectedColumns,
  }));

  const [selected, setSelected] = useState([]);

  const { isLoading, data: availableProperties } = useQuery(["properties-of-group", { group }], getPropertiesOfGroup, {
    select(response) {
      return response.data.map((d) => ({ label: d.data.name, value: d.id }));
    },
    onSuccess: async (response) => {
      if (selectedColumns.length === 0) {
        setSelected(response);
        return;
      }
      const updatedSelectedColumns = selectedColumns.filter((c) => response.some((property) => property.value === c));
      setSelectedColumns(updatedSelectedColumns);
      setSelected(updatedSelectedColumns.map((c) => ({ value: c, label: response.find((property) => property.value === c).label })));
    },
  });

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spinner />
      </div>
    );
  }

  return (
    <Select
      isMulti
      isClearable
      isSearchable
      styles={SelectFieldStyles}
      closeMenuOnSelect={false}
      options={availableProperties}
      maxMenuHeight={100}
      onChange={(newColumns) => {
        if (!newColumns) {
          setSelectedColumns([]);
          setSelected([]);
          return;
        }
        if (newColumns.length === availableProperties.length) {
          setSelected(newColumns);
          setSelectedColumns([]);
          return;
        }
        setSelected(newColumns);
        setSelectedColumns(newColumns.map((c) => c.value));
      }}
      placeholder="Select properties to show"
      value={selected}
    />
  );
};

export default SelectedColumnsPicker;
