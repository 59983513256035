import React from "react";

const AddSaveEntry = ({ rowsInfo, onAddEntry, onSaveAll }) => {
  return (
    <div className="add-save-entry">
      <div onClick={() => onAddEntry(rowsInfo?.length - 1 ?? 0)} style={{ cursor: "pointer" }}>
        <span>+</span> Add Entry
      </div>
      {rowsInfo.length > 1 && (
        <div onClick={onSaveAll} style={{ cursor: "pointer" }}>
          <span>Save All</span>
        </div>
      )}
    </div>
  );
};

export default AddSaveEntry;
