import React from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";

export const ConfirmDeletingPropertyModal = (props) => {
  const { onClose, visible, onConfirm, name } = props;

  const handleConfirm = async () => {
    onConfirm();
    onClose();
  };

  return (
    <ModalTransition>
      {visible && (
        <Modal height="192px" width="400px" heading={`Delete ${name}?`} onClose={() => onClose()}>
          <div>
            <p>{`You cannot restore the property as it is permanently deleted`}</p>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "5px" }}>
              <Button onClick={handleConfirm} appearance="danger">
                Continue
              </Button>
              <Button style={{ marginLeft: "10px" }} onClick={onClose}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};
