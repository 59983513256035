import { usePropertyVisualizationStore } from "./property-visualization-store";
import { VisualizationType } from "./visualization-variants";
import shallow from "zustand/shallow";
import styled from "styled-components";
import OverviewCard from "./OverviewCard";
import BoardIcon from "@atlaskit/icon/glyph/board";
import DepartmentIcon from "@atlaskit/icon/glyph/department";
import { token } from "@atlaskit/tokens";


const Page = styled.div`
  margin: 1rem 2rem 1rem 2rem;
  width: 100%;
  boxsizing: inherit;
  letter-spacing: normal;
  background-color: ${token("utility.elevation.surface.current")}
`;
const CardContainer = styled.div`
  margin: 0 auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  background-color: ${token("elevation.surface.hovered")}
  font-weight: 500;
`;

const TypeOverview = (props) => {
  const { chooseType } = usePropertyVisualizationStore(
    (state) => ({
      chooseType: state.chooseType,
    }),
    shallow
  );

  const handleTypeChoose = (selectedType) => {
    chooseType(selectedType);
  };

  const Org_Chart_Quotes = ["Visualize your data", " with connections and dependencies"];
  const Org_Chart_Descriptions = [
    "The Chart View allows you to see or define depending structures",
    " based on your individual properties or on system properties.",
  ];
  const Board_Quotes = ["Visualize your data", " in structured boards"];
  const Board_Descriptions = [
    "The Board View displays pages with a specific property group on a board layout.",
    " You choose a property group and a property field to get a board view with your data as result.",
  ];

  return (
    <Page>
      <h3 style={{ margin: "1rem 0 6rem 0", color: token("color.text"), textAlign: "center", letterSpacing: "1.5px", fontSize: "1.5rem" }}>
        Select your visualization type
      </h3>
      <CardContainer>
        <OverviewCard
          icon={<DepartmentIcon />}
          headerName={"OrgChart"}
          headerQuotes={Org_Chart_Quotes}
          descriptions={Org_Chart_Descriptions}
          visualizationType={VisualizationType.ORG_CHART}
          handleTypeChoose={handleTypeChoose}
        />
        <OverviewCard
          icon={<BoardIcon />}
          headerName={"Board View"}
          headerQuotes={Board_Quotes}
          descriptions={Board_Descriptions}
          visualizationType={VisualizationType.BOARD}
          handleTypeChoose={handleTypeChoose}
        />
      </CardContainer>
    </Page>
  );
};

export default TypeOverview;
