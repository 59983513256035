import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@atlaskit/button";
import DragHandlerIcon from "@atlaskit/icon/glyph/drag-handler";
import { ColorFields } from "../ColorFields/ColorFields";
import OPTIONS_COLOR_PALETTE from "../ColorFields/OptionsPalette";
import { generateId } from "../../../../../../../random-id-provider";
import { OptionsListContainer, OptionsListInput, OptionsListPTag } from "./";

OptionsListItem.propTypes = {
  option: PropTypes.object.isRequired,
};

export function OptionsListItem({ option, addOption, setOptions, availableOptions, index, chosenStore }) {
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    if (!option.color) {
      const color = OPTIONS_COLOR_PALETTE[index >= OPTIONS_COLOR_PALETTE.length ? index % OPTIONS_COLOR_PALETTE.length : index].value;
      const newOptions = availableOptions.map((o) => (o === option ? { ...o, color } : o));
      setOptions(newOptions);
    }
  }, [index]);

  const handleOnKeyDown = (e) => {
    e.keyCode === 13 && addOption();
  };
  const handleDeleteOption = () => {
    const canDeleteOption = availableOptions.length > 1;
    if (canDeleteOption) {
      const newOptions = availableOptions.filter((o) => o !== option);
      setOptions(newOptions);
    }
  };
  const handleChangeValue = (option, value) => {
    const newOptions = availableOptions.map((o) => (o === option ? { ...o, value } : o));
    setOptions(newOptions);
  };
  const handleDefault = (option) => {
      const newOptions = availableOptions.map((o) =>
        o.value === option.value && o.isDefault !== option.isDefault
          ? { ...o, isDefault: option.isDefault }
          : { ...o, isDefault: o.isDefault ? true : false }
      );
      setOptions(newOptions);
  };
  const handleClearDefault = () => {
      const newOptions = availableOptions.map((o) => ({ ...o, isDefault: false }));
      setOptions(newOptions);
  };
  const onSetDefaultClick = () => handleDefault({ ...option, isDefault: true });
  const onClearDefaultClick = () => handleClearDefault();
  return (
    <OptionsListContainer isHovered={isHovered} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <DragHandlerIcon />
      <ColorFields
        chosenStore={chosenStore}
        hexCode={option.color}
        colorPalette={OPTIONS_COLOR_PALETTE}
        options={availableOptions}
        setOptions={setOptions}
        option={option}
        key={generateId(5)}
      />
      <OptionsListInput
        placeholder={`Option ${availableOptions.indexOf(option) + 1}`}
        autoFocus
        onBlur={() => {
          option.value === "" && handleDeleteOption(option);
        }}
        onChange={(e) => handleChangeValue(option, e.target.value)}
        onKeyDown={handleOnKeyDown}
        value={option.value}
      />
      {isHovered && !option.isDefault && (
        <Button onClick={onSetDefaultClick} appearance={"link"} style={{ fontSize: "14px" }}>
          Set as default
        </Button>
      )}
      {availableOptions.find((defaultValue) => defaultValue.isDefault === true && defaultValue.value === option.value) && (
        <OptionsListPTag>Default</OptionsListPTag>
      )}
      {isHovered && option.isDefault && (
        <Button onClick={onClearDefaultClick} appearance={"link"}>
          Clear default
        </Button>
      )}
    </OptionsListContainer>
  );
}
