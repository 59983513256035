import { useEffect } from "react";
import EmptyState from "@atlaskit/empty-state";
import CustomModalHeader from "../CustomModal/CustomModalHeader";
import CustomModalFooter from "../CustomModal/CustomModalFooter";
import CustomModalContent from "../CustomModal/CustomModalContent";
import styled from "styled-components";

const InvalidLicenseWrapper = styled.div`
  height: 100vh;
  width: 45vw;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const InvalidLicenseContentWrapper = styled.div`
  > div {
    margin: 0 auto;
    max-width: 550px;
    p {
      margin-bottom: 0;
    }
  }
`;

const InvalidLicenseContent = ({ title = "" }) => {
  return (
    <InvalidLicenseContentWrapper>
      <EmptyState
        header={title}
        description="You don’t have a valid license for the Properties app. Please activate a free trial license in the Confluence administration or purchase a license through the Atlassian Marketplace. Don't worry: You won't be losing any data."
        renderImage={() => <img alt="platform" src="images/platform.png" style={{ width: "180px", height: "200px" }} />}
        width="wide"
      />
    </InvalidLicenseContentWrapper>
  );
};

export const FreeVersionInvalidEditor = (props) => {
  return (
    <>
      <InvalidLicenseWrapper>
        <CustomModalHeader title="Please activate a free trial"></CustomModalHeader>
        <div
          style={{
            background: "white",
            padding: "50px 10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomModalContent>
            <InvalidLicenseContent />
          </CustomModalContent>
        </div>
        <CustomModalFooter saveHidden secondaryActionLabel="Cancel"></CustomModalFooter>
      </InvalidLicenseWrapper>
    </>
  );
};

export const FreeVersionInvalidView = (props) => {
  useEffect(() => {
    var ro = new ResizeObserver((entries) => {
      if (window.AP && window.AP.resize) {
        window.AP.resize("100%", `${(document.body.scrollHeight + 10).toString()}px`);
        window.AP.sizeToParent();
      }
    });
    ro.observe(document.body);
  }, []);

  return (
    <>
      <InvalidLicenseContent title="Please activate a free trial" />
    </>
  );
};
