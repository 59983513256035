import { forwardRef, useEffect, useState } from "react";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import { useDrag, useDrop } from "react-dnd";

function mapSpace(space) {
  return {
    icon: space.icon,
    name: space.name,
    spaceKey: space.key,
    type: "space",
    id: space.key,
    label: space.name,
    value: space.key,
  };
}

export const DraggableDroppableLazyLoadingSpace = ({ spaceKey, selected, onClick = () => {}, onDrop = () => {} }) => {
  const [, drop] = useDrop(() => ({
    accept: ["user", "space"],
    drop: () => ({ id: spaceKey }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [, drag] = useDrag({
    type: "space",
    item: () => {
      return { id: spaceKey, type: "space" };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  function attachRef(el) {
    drag(el);
    drop(el);
  }

  return <LazyLoadingSpace ref={attachRef} spaceKey={spaceKey} selected={selected} onClick={onClick} />;
};

const LazyLoadingSpace = forwardRef(({ spaceKey, selected = false, onClick = () => {} }, ref) => {
  const [loading, setLoading] = useState(true);
  const [space, setSpace] = useState(undefined);

  useEffect(() => {
    (async () => {
      if (!spaceKey) {
        setLoading(false);
        return;
      }

      const result = await atlassianRestService.getSpaceByKey(spaceKey);
      setSpace(mapSpace(result));
      setLoading(false);
    })();
  }, [spaceKey]);

  if (loading || !space) {
    return <></>;
  }

  return (
    <div
      ref={ref}
      onClick={onClick}
      style={{
        width: "150px",
        margin: "0 auto",
        border: selected ? "1px solid #f3f7ff" : "1px solid #ccc",
        padding: "10px",
        cursor: "pointer",
      }}
    >
      <img
        alt="space-icon"
        src={`${window.AP._hostOrigin}/wiki/${space.icon.path}`}
        style={{
          borderRadius: "10px",
          width: "40px",
          height: "40px",
        }}
      />
      <div
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontSize: "14px",
        }}
      >
        {space.name}
      </div>
    </div>
  );
});

export default LazyLoadingSpace;
