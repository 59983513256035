import FONT_COLOR_PALETTE from "../../FontColorPalette";

const hsl = require("hsl-to-hex");
const Color = require("color");

export const fontColorAccordingToHex = (hexColor) => {
  if (!hexColor) {
    return "#000000";
  }
  const color = Color(hexColor);
  switch (hexColor) {
    case "#DFE1E6":
      return FONT_COLOR_PALETTE.find((font) => font.label === "Grey").value;
    case "#DEEBFF":
      return FONT_COLOR_PALETTE.find((font) => font.label === "Blue").value;
    case "#E3FCEF":
      return FONT_COLOR_PALETTE.find((font) => font.label === "Green").value;
    case "#FFF0B3":
      return FONT_COLOR_PALETTE.find((font) => font.label === "Yellow").value;
    case "#FFEBE6":
      return FONT_COLOR_PALETTE.find((font) => font.label === "Red").value;
    case "#EAE6FF":
      return FONT_COLOR_PALETTE.find((font) => font.label === "Purple").value;
    case "#0d9be2":
      return "#FFFFFF";
  }
  if (color.isDark()) {
    const lightColor = color.lightness(97);
    const hue = lightColor.color[0];
    const saturation = lightColor.color[1];
    const luminosity = lightColor.color[2];
    return hsl(hue, saturation, luminosity);
  }
  return color.darken(0.7);
};
