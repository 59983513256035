import { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";

const InputText = styled.div`
  .PhoneInput {
    margin-left: 5px;
  }
  ,
  .PhoneInputInput {
    height: 40px;
    font-size: 14px;
  }
`;
const PhonePropertyEdit = ({ property, initialValue, autoFocus = false, onUpdate }) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);
  return (
    <InputText>
      <PhoneInput
        defaultCountry={property.data.country}
        value={value}
        onChange={(number) => {
          setValue(number);
          onUpdate(number);
        }}
      />
    </InputText>
  );
};

export default PhonePropertyEdit;
