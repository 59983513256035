import { useState } from "react";
import { useQuery } from "react-query";
import { getAllPropertyGroups } from "../api";
import { SelectFieldStyles } from "../../Shared/SelectFieldStyles";
import Spinner from "@atlaskit/spinner";
import Select from "@atlaskit/select";

const PropertyGroupPicker = ({ initialValue, onUpdate }) => {
  const [selectedOption, setSelectedOption] = useState();
  const { isLoading, data } = useQuery(["property-groups", {}], getAllPropertyGroups, {
    select(response) {
      const filteredData = response.data.filter((d) => d.name !== "");
      const filteredGroupOptions = filteredData.map((d) => ({ ...d, id: d.id, label: d.name }));
      filteredGroupOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
      return filteredGroupOptions;
    },

    onSuccess: async (response) => {
      if (initialValue) {
        const resolvedGroup = response.find((o) => o.id === initialValue);
        if (resolvedGroup) {
          const option = { id: resolvedGroup.id, value: resolvedGroup.id, label: resolvedGroup.label };
          setSelectedOption(option);
          onUpdate(option);
        }
      }
    },
  });

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spinner />
      </div>
    );
  }

  return (
    <Select
      isClearable={false}
      value={selectedOption || initialValue}
      styles={SelectFieldStyles}
      options={data}
      getOptionValue={(option) => option.label}
      maxMenuHeight={205}
      onChange={(newOption) => {
        setSelectedOption(newOption);
        onUpdate(newOption);
      }}
      placeholder="Select a group"
    />
  );
};

export default PropertyGroupPicker;
