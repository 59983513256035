import { useEffect } from "react";
import Button from "@atlaskit/button";
import shallow from "zustand/shallow";
import { QueryClient, QueryClientProvider } from "react-query";
import { loadDataFromMacro } from "../../macro-service";
import { usePropertyGroupStore } from "./store";
import { usePropertyFormStore } from "./editor/property-form-store";
import Spinner from "@atlaskit/spinner";
import { uuidv4 } from "../../../v2/id-generator";
import { getRecord } from "./api";
import PropertyGroupMacroEditorSidebar from "./editor/PropertyGroupMacroEditorSidebar";
import PropertyGroupFormWrapper from "../Shared/PropertyGroupFormWrapper";
import EmptyState from "@atlaskit/empty-state";
import { NO_PROPERTY_GROUP_SELECTED_OR_EMPTY } from "../../../v2/empty-states-resources";
import { initResizeObserver } from "../../resize-service";
import ModalWrapper from "../../CustomModal/ModalWrapper";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModalContent from "../../CustomModal/CustomModalContent";
import { AnalyticsEvent } from "../../../analytics-events";
import { propertyRecordsService } from "../../Service/PropertyRecordsService";
import { analytics } from "../../../analytics";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const PropertyGroupMacroEditor = (props) => {
  const { values, setInitialValues, setValues, isTemplate, setIsTemplate } = usePropertyFormStore((state) => ({
    values: state.values,
    setInitialValues: state.setInitialValues,
    setValues: state.setValues,
    setIsTemplate: state.setIsTemplate,
    isTemplate: state.isTemplate,
  }));

  const { loaded, pageId, recordId, group, setData, setGroup, setRecordId, setPageId, setEntityId } = usePropertyGroupStore(
    (state) => ({
      loaded: state.loaded,
      setData: state.setData,
      setRecordId: state.setRecordId,
      setPageId: state.setPageId,
      setGroup: state.setGroup,
      setEntityId: state.setEntityId,
      group: state.selectedGroup,
      pageId: state.pageId,
      recordId: state.recordId,
    }),
    shallow
  );

  const saveMacro = async () => {
    const context = await window.AP.context.getContext();
    await propertyRecordsService.createOrUpdateRecord(
      pageId,
      group,
      recordId,
      values ? JSON.stringify(values[pageId][recordId], true) : {},
      context?.confluence?.content?.version,
      !isTemplate,
      isTemplate
    );
    analytics.logEvent(AnalyticsEvent.PROPERTY_GROUP_CREATED_UPDATED, {
      entityId: pageId,
      groupId: group,
    });
    window.AP.confluence.saveMacro({
      entityId: isTemplate ? null : pageId,
      recordId: recordId,
      groupId: group,
      modified: Date.now(),
      isTemplate: isTemplate,
    });
  };

  const initializeNewMacro = ({ pageId }) => {
    const newRecordId = uuidv4();
    setRecordId(newRecordId);
    setEntityId(pageId);
    setPageId(pageId);
    setInitialValues(pageId, newRecordId, {});
    setValues({ [pageId]: { [newRecordId]: {} } });
  };

  const initializeExistingMacro = async ({ pageId, groupId, recordId, isTemplate }) => {
    setGroup(groupId);
    setPageId(pageId);
    setEntityId(pageId);
    setRecordId(recordId);
    const { data } = await getRecord({ pageId, groupId, recordId, isTemplate });
    const values =
      data.draft && "values" in data.draft && data.draft.values && Object.keys(data.draft.values).length > 0
        ? data.draft.values
        : data.values;
    setValues({ [pageId]: { [recordId]: values } });
    setInitialValues(pageId, recordId, values);
  };

  useEffect(() => {
    (async () => {
      const macroData = await loadDataFromMacro();
      const context = await window.AP.context.getContext();
      let macroInsideTemplate = false;
      window.AP.navigator.getLocation(async (location) => {
        const pageId = context.confluence?.content?.id;
        if (location.context.contentType === "page" || location.context.contentType === "blogpost") {
          setIsTemplate(false);
        } else {
          setIsTemplate(true);
          macroInsideTemplate = true;
        }
        if (!macroData) {
          initializeNewMacro({ pageId });
          return;
        }
        const groupId = macroData.groupId;
        const recordId = macroData.recordId;
        if (!groupId || !recordId) {
          initializeNewMacro({ pageId });
        } else {
          initializeExistingMacro({ pageId, groupId, recordId, isTemplate: macroInsideTemplate });
        }
      });
      setData(macroData);
      initResizeObserver();
    })();
  }, []);
  useEffect(() => {
    window.AP.theming.initializeTheming();
  }, []);


  if (!loaded) {
    return <Spinner />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ModalWrapper>
        <CustomModalHeader title={"Property Group"}></CustomModalHeader>
        <CustomModalContent>
          <PropertyGroupMacroEditorSidebar />
          {group && <PropertyGroupFormWrapper key={group} />}
          {!group && (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <EmptyState
                header="You haven't selected any property group yet"
                description="Property groups are collections of properties that act as form fields. You can manage them in the portal."
                imageUrl={NO_PROPERTY_GROUP_SELECTED_OR_EMPTY}
                primaryAction={
                  <Button
                    appearance="primary"
                    href={`${window.AP._hostOrigin}/wiki/plugins/servlet/ac/net.junovi.confluence.properties/properties-overview`}
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    Go to portal
                  </Button>
                }
              />
            </div>
          )}
        </CustomModalContent>
        <CustomModalFooter onSave={saveMacro} saveDisabled={!group} documentationURL="https://junovi.atlassian.net/wiki/x/IQDkfw" />
      </ModalWrapper>
    </QueryClientProvider>
  );
};

export default PropertyGroupMacroEditor;
