import styled from "styled-components";
import { LazyPageLink } from "../../PropertyReportMacro/LazyPageLink";
import { MultiPageSelect } from "./MultiPageSelect";

const EmptySelect = styled.span`
  color: rgb(107, 119, 140);
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
`;

const ConfluencePagePropertyView = ({ property, pageId, displayMode, initialValue: inheritedValue }) => {
  const defaultValue = property.data?.defaultPage?.id;
  const initialValue = !inheritedValue ? defaultValue : inheritedValue;
  if (property?.data?.displayCurrentPage) {
    if (!displayMode) {
      return (
        <EmptySelect data-sortable={true} data-raw-value="">
          Property will display the current page
        </EmptySelect>
      );
    } else {
      return <LazyPageLink key={pageId} pageId={pageId}></LazyPageLink>;
    }
  }

  if (Array.isArray(initialValue)) {
    if (initialValue?.length > 0) {
      return <MultiPageSelect key={initialValue} pageIds={initialValue}></MultiPageSelect>;
    }
  } else if (initialValue) {
    return <LazyPageLink key={initialValue} pageId={initialValue}></LazyPageLink>;
  }
  if (property?.data?.description) {
    return (
      <EmptySelect data-sortable={true} data-raw-value="">
        {property?.data?.description}
      </EmptySelect>
    );
  }
  return (
    <EmptySelect data-sortable={true} data-raw-value="">
      Select a value
    </EmptySelect>
  );
};

export default ConfluencePagePropertyView;
