import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { Tree, TreeNode } from "react-organizational-chart";
import FlatToNested from "flat-to-nested";
import { getRecordsForGroup } from "../api";

import styled from "styled-components";
import LazyLoadingUser from "./LazyLoadingUser";
import { usePropertyVisualizationStore } from "../property-visualization-store";
import { v4 as uuidv4 } from "uuid";
import { PropertyType } from "../../../property-type";

const TreeWrapper = styled.div`
  > ul > li > ul {
    &::before {
      visibility: hidden;
    }
  }
`;

const NodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: ${({ selected }) => (selected ? "1px solid #f3f7ff" : "1px solid #cccc")};
  border-radius: 7px;
  margin-bottom: 5px;
  margin: 0 auto;
  width: 150px;
  cursor: pointer;
`;

function isObject(value) {
  return typeof value === "object" && !Array.isArray(value) && !null;
}

const getType = (value) => {
  if (isObject(value)) {
    return PropertyType.SELECT;
  }
  return PropertyType.USER;
};

function updateStructure({ structure, primaryObject, secondaryObject }) {
  const { value: primaryValue, type: primaryType, id: primaryId } = primaryObject;
  const { value: secondaryValue, type: secondaryType, id: secondaryId } = secondaryObject;

  const existingSecondary = structure.find((v) => v.id === secondaryId && v.parent === "0");
  if (existingSecondary) {
    // Update the parent of the existing secondary object
    existingSecondary.parent = primaryValue;
  } else if (!structure.find((v) => v.value === secondaryValue && v.parent === primaryId)) {
    // Add the secondary object to the structure if it doesn't exist
    structure.push({ value: secondaryValue, parent: primaryId, type: secondaryType, id: secondaryId });
  }
  if (!structure.find((v) => v.value === primaryValue && v.parent === "0")) {
    // Add the primary object to the structure if it doesn't exist
    structure.push({ value: primaryValue, parent: "0", type: primaryType, id: primaryId });
  }
}

function Node({ o, parent, type }) {
  const isRoot = parent === undefined;
  const T = parent
    ? TreeNode
    : ({ children }) => (
        <TreeWrapper className="tree-wrapper">
          <Tree lineWidth={"2px"} lineColor={"#bbc"} lineBorderRadius={"12px"}>
            {children}
          </Tree>
        </TreeWrapper>
      );

  const labelOptions = {
    ROOT: <span></span>,
    [PropertyType.SELECT]: <NodeWrapper key={uuidv4()}>{o.value}</NodeWrapper>,
    [PropertyType.USER]: (
      <LazyLoadingUser
        onClick={() => {
          window.open(`${window.AP._hostOrigin}/wiki/people/${o.value}`, "_blank");
        }}
        key={uuidv4()}
        accountId={o.id}
      />
    ),
  };
  const getLabel = () => {
    return isRoot ? labelOptions.ROOT : labelOptions[o.type];
  };

  const getNode = () => {
    return (
      <T label={getLabel()}>
        {(o.children || []).map((c) => (
          <Node o={c} parent={o} key={uuidv4()} />
        ))}
      </T>
    );
  };

  return getNode();
}

const StructureVisualization = ({ onLoaded = () => {}, predefinedStructure = undefined }) => {
  const config = usePropertyVisualizationStore((state) => state.config.args) ?? predefinedStructure;

  const structureLengthRef = useRef(0);

  const { isLoading, data } = useQuery(["records-of-group", { group: config?.data?.group }], getRecordsForGroup, {
    enabled: !!config?.data?.group && !!config?.data?.args?.primary && !!config?.data?.args?.secondary,
    select(response) {
      const structure = [{ id: "0", parent: undefined }];
      const validRecords = response.data.filter((record) => !record.deleted);

      validRecords.forEach((record) => {
        const primaryValue = record?.values?.[config.data.args.primary];
        const secondaryValue = record?.values?.[config.data.args.secondary];
        const isValidValue = (value) => !(!value || value.length === 0);

        if (!isValidValue(primaryValue) || !isValidValue(secondaryValue)) {
          return;
        }

        updateStructure({
          structure,
          primaryObject:
            getType(primaryValue) === PropertyType.SELECT
              ? { value: primaryValue.value, type: PropertyType.SELECT, id: primaryValue.value }
              : { value: primaryValue[0], type: PropertyType.USER, id: primaryValue[0] },
          secondaryObject:
            getType(secondaryValue) === PropertyType.SELECT
              ? { value: secondaryValue.value, type: PropertyType.SELECT, id: secondaryValue.value }
              : { value: secondaryValue[0], type: PropertyType.USER, id: secondaryValue[0] },
        });
      });
      structureLengthRef.current = structure.length;

      return new FlatToNested().convert(JSON.parse(JSON.stringify(structure)));
    },
  });

  useEffect(() => {
    let counter = 0;

    const mutationObserver = new MutationObserver((entries) => {
      counter += 1;
      if (counter === structureLengthRef.current - 1) {
        const pseudoRoot = document.querySelector(".tree-wrapper");
        if (pseudoRoot) {
          onLoaded();
          mutationObserver.disconnect();
        }
      }
    });
    mutationObserver.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }, [data]);

  if (isLoading || !data) {
    return <></>;
  }

  return <Node o={data}></Node>;
};

export default StructureVisualization;
