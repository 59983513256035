import { useEffect, useState } from "react";
import TextField from "@atlaskit/textfield";
import { ErrorMessage } from "@atlaskit/form";
import { token } from "@atlaskit/tokens";

const emailBasicRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

const EmailPropertyEdit = ({ property, initialValue, autoFocus = false, onUpdate }) => {
  const [value, setValue] = useState(initialValue);
  const [isValid, setIsValid] = useState();

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  function validateMail(value) {
    return value && value.match(emailBasicRegex);
  }

  return (
    <div>
      <TextField
        autoFocus={autoFocus}
        value={value}
        onChange={(e) => {
          const newValue = e.target.value;
          setValue(newValue);
          validateMail(newValue) ? setIsValid(true) : setIsValid(false);
        }}
        style={{
          backgroundColor: `${token("utility.elevation.surface.current")}`,
          borderColor: `${token("color.border.input")}`,
          boxShadow: `${token("elevation.shadow.overflow")}`,
        }}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            if (validateMail(value)) {
              setIsValid(true);
              setValue(value);
              onUpdate(value);
            } else {
              setIsValid(false);
              onUpdate("");
              setValue("");
            }
          }
        }}
        onBlur={(e) => {
          if (validateMail(value)) {
            setIsValid(true);
            setValue(value);
            onUpdate(value);
          } else {
            setIsValid(false);
            onUpdate("");
            setValue("");
          }
        }}
        placeholder={property?.data?.description}
      />
      {isValid === false ? <ErrorMessage>No valid Email</ErrorMessage> : <></>}
    </div>
  );
};

export default EmailPropertyEdit;
