import { useEffect } from "react";
import shallow from "zustand/shallow";
import { QueryClient, QueryClientProvider } from "react-query";
import { loadDataFromMacro } from "../../macro-service";
import { usePropertyAddEntriesPickerStore, usePropertyGroupReportMacroStore, usePropertyLiveFilterStore } from "./store";
import Spinner from "@atlaskit/spinner";
import { safelyParseJson } from "../../safely-parse-json";
import { generateId } from "../../../v2/id-generator";
import { AnalyticsEvent } from "../../../analytics-events";
import { analytics } from "../../../analytics";
import PropertyGroupReportTable from "./PropertyGroupReportTable";
import { initResizeObserver } from "../../resize-service";
import { ReportType } from "./report-type";
import PropertyGroupReportCard from "./PropertyGroupReportCard";
import ReportPropertyLiveFilter from "./ReportPropertyLiveFilter";
import { atlassianRestService } from "../../Service/AtlassianRestService";
import { useContextStore } from "../context-store";
import { token } from "@atlaskit/tokens";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const PropertyGroupReportMacro = (props) => {
  const {
    loaded,
    setGroup,
    group,
    view,
    setNumberOfItems,
    setLoaded,
    setSelectedColumns,
    setReportMacroId,
    setLabels,
    setLabelsLogic,
    setPagesUnder,
    setCurrentPageId,
    setPropertyFilters,
    setSelectedSpaces,
    setSelectedView,
    setDefaultSortColumn,
    setDefaultSortOrder,
    addEntriesType,
    setAddEntriesType,
  } = usePropertyGroupReportMacroStore(
    (state) => ({
      loaded: state.loaded,
      group: state.selectedGroup,
      view: state.selectedView,
      setNumberOfItems: state.setNumberOfItems,
      selectedColumns: state.selectedColumns,
      defaultSortColumn: state.defaultSortColumn,
      defaultSortOrder: state.defaultSortOrder,
      labels: state.labels,
      spaces: state.selectedSpaces,
      pagesUnder: state.pagesUnder,
      setPagesUnder: state.setPagesUnder,
      setCurrentPageId: state.setCurrentPageId,
      setPropertyFilters: state.setPropertyFilters,
      setGroup: state.setGroup,
      setReportMacroId: state.setReportMacroId,
      setLoaded: state.setLoaded,
      setSelectedColumns: state.setSelectedColumns,
      setLabels: state.setLabels,
      setLabelsLogic: state.setLabelsLogic,
      setSelectedSpaces: state.setSelectedSpaces,
      setSelectedView: state.setSelectedView,
      setDefaultSortColumn: state.setDefaultSortColumn,
      setDefaultSortOrder: state.setDefaultSortOrder,
      addEntriesType: state.addEntriesType,
      setAddEntriesType: state.setAddEntriesType,
    }),
    shallow
  );
  const { setPropertyLiveFilters } = usePropertyLiveFilterStore((state) => ({
    setPropertyLiveFilters: state.setPropertyLiveFilters,
  }));
  const { setAccountId } = useContextStore(
    (state) => ({
      setAccountId: state.setAccountId,
    }),
    shallow
  );

  useEffect(() => {
    (async () => {
      const macroData = await loadDataFromMacro();
      if (!macroData) {
        setLoaded(true);
        return;
      }
      window.AP.navigator.getLocation(async (location) => {
        const pageId = location.context.contentId;
        setCurrentPageId(pageId);
        setGroup(macroData.groupId);
        setSelectedView(macroData.selectedView);
        setDefaultSortColumn(macroData.defaultSortColumn);
        setDefaultSortOrder(macroData.defaultSortOrder);
        setNumberOfItems(macroData.numberOfItems || 25);
        setPagesUnder(macroData.pagesUnder);
        setReportMacroId(macroData.reportMacroId);
        setSelectedColumns(safelyParseJson(macroData.selectedColumns, []));
        setSelectedSpaces(safelyParseJson(macroData.selectedSpaces, []));
        setPropertyFilters(safelyParseJson(macroData.propertyFilters, {}));
        setPropertyLiveFilters(safelyParseJson(macroData.propertyLiveFilters, {}));
        const parsedLabels = safelyParseJson(macroData.labels, []);
        setLabels(Array.isArray(parsedLabels) ? { [generateId()]: parsedLabels } : parsedLabels);
        setLabelsLogic(safelyParseJson(macroData.labelsLogic, []));
        const currentUser = await atlassianRestService.getCurrentUser();
        if (currentUser && currentUser.type === "known") {
          setAccountId(currentUser.accountId);
        }
        setLoaded(true);
        setAddEntriesType(macroData.addEntriesType ?? addEntriesType);
      });
    })();
  }, []);

  useEffect(() => {
    initResizeObserver();
  }, []);

  useEffect(() => {
    window.AP.theming.initializeTheming();
    analytics.logEvent(AnalyticsEvent.GROUP_REPORT_LOADED, {});
  }, []);

  if (!loaded) {
    return (
      <div style={{ backgroundColor: token("utility.elevation.surface.current") }}>
        <Spinner />
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div
        style={
          view === ReportType.TABLE || !view
            ? { display: "flow-root", width: "fit-content", backgroundColor: token("utility.elevation.surface.current") }
            : { display: "grid" }
        }
      >
        <div>
          <ReportPropertyLiveFilter />
        </div>
        {(view === undefined || view === ReportType.TABLE) && <PropertyGroupReportTable groupId={group} />}
        {view === ReportType.CARD && <PropertyGroupReportCard />}
      </div>
    </QueryClientProvider>
  );
};

export default PropertyGroupReportMacro;
