import React, { useEffect, useState, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  UrlPlaceholderInPortal,
  DatePlaceholderInPortal,
  EmailPlaceholderInPortal,
  PhonePlaceholderInPortal,
  NumberPlaceholderInPortal,
  StringPlaceholderInPortal,
  UserMentionPlaceholderInPortal,
  ConfluencePagePlaceholderInPortal,
  SelectPlaceholderInPortal,
  MultiSelectPlaceholder,
} from "./Placeholders";
import { LoadingState } from "../../../LoadingState";
import { PlaceholderKindMatcher } from "./PlaceholderKindMatcher";
import { NO_PROPERTY_GROUP_SELECTED_OR_EMPTY } from "../../../empty-states-resources";
import { usePlaceholderStore, usePlaceholderPortalStore } from "../../../Store";
import "./PlaceholderList.scss";

const getItemStyle = (_, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});
PlaceholderList.Loading = () => (
  <div className="placeholderList-loading">
    <LoadingState />
  </div>
);
PlaceholderList.Empty = () => (
  <div className="placeholderList-empty">
    <img width="50%" src={NO_PROPERTY_GROUP_SELECTED_OR_EMPTY}></img>
    <div>Create a new property by choosing a field type in the right sidebar</div>
  </div>
);
PlaceholderList.ShowList = ({ onDropEndCbRef, onOrderChange, placeholders, trigger, triggered }) => {
  return (
    <div style={{ paddingBottom: "150px" }}>
      <h3>Properties</h3>
      <DragDropContext
        onDragEnd={({ source, destination, payload }) => {
          if (!destination) return;
          if (destination.droppableId !== "placeholder-list" && source.droppableId) {
            onDropEndCbRef.current?.[source.droppableId]({ source, destination });
            return;
          }
          onOrderChange({ source, destination, payload });
          trigger();
        }}
      >
        <Droppable droppableId="placeholder-list" type="properties">
          {(provided, snapshot) => (
            <div key={"placeholder-list"} {...provided.droppableProps} ref={provided.innerRef}>
              {placeholders.map((p, idx) => (
                <Draggable key={`property-entry-id:${idx}`} draggableId={`id:${idx}`} index={idx} payload={{ name: p.data.name, id: p.id }}>
                  {(provided, snapshot) => (
                    <div
                      key={`property-entry-id:${idx}`}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <div key={idx}>
                        <PlaceholderKindMatcher
                          kind={p.kind}
                          urlPlaceholder={<UrlPlaceholderInPortal placeholder={p} />}
                          datePlaceholder={<DatePlaceholderInPortal placeholder={p} />}
                          emailPlaceholder={<EmailPlaceholderInPortal placeholder={p} />}
                          phonePlaceholder={<PhonePlaceholderInPortal placeholder={p} />}
                          numberPlaceholder={<NumberPlaceholderInPortal placeholder={p} />}
                          stringPlaceholder={<StringPlaceholderInPortal placeholder={p} />}
                          userMentionPlaceholder={<UserMentionPlaceholderInPortal placeholder={p} />}
                          confluencePagePlaceholder={
                            <ConfluencePagePlaceholderInPortal placeholder={p} key={p.id} triggered={triggered} />
                          }
                          selectPlaceholder={
                            <SelectPlaceholderInPortal placeholder={p} onDropEndCbRef={onDropEndCbRef} key={p.id} />
                          }
                          multiSelectPlaceholder={
                            <MultiSelectPlaceholder placeholder={p} onDropEndCbRef={onDropEndCbRef} key={p.id} />
                          }
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export function PlaceholderList(props) {
  const onDropEndCbRef = useRef({});
  const [triggered, setTriggered] = useState(false);
  const { selectedPlaceholderSet, loadingPlaceholder, setLoadingPlaceholders, isContentDisplayAllowed } = usePlaceholderPortalStore(
    (state) => ({
      selectedPlaceholderSet: state.selectedPlaceholderSet,
      loadingPlaceholder: state.loadingPlaceholder,
      setLoadingPlaceholders: state.setLoadingPlaceholders,
      isContentDisplayAllowed: state.isContentDisplayAllowed,
    })
  );
  const { placeholders, getAllPlaceholdersOfSet, onOrderChange } = usePlaceholderStore((state) => ({
    placeholders: state.placeholders,
    onOrderChange: state.onOrderChange,
    getAllPlaceholdersOfSet: state.getAllPlaceholdersOfSet,
  }));
  const isEmptyList = placeholders.length === 0;
  const trigger = () => {
    setTriggered(!triggered);
  };

  useEffect(() => {
    (async () => {
      setLoadingPlaceholders(true);
      await getAllPlaceholdersOfSet(selectedPlaceholderSet?.id);
      isContentDisplayAllowed && setLoadingPlaceholders(false);
    })();
  }, [selectedPlaceholderSet, isContentDisplayAllowed]);

  const shouldLoad = loadingPlaceholder || !isContentDisplayAllowed;
  return (
    (shouldLoad && <PlaceholderList.Loading />) ||
    (isEmptyList && <PlaceholderList.Empty />) ||
    (isContentDisplayAllowed && (
      <PlaceholderList.ShowList
        onDropEndCbRef={onDropEndCbRef}
        onOrderChange={onOrderChange}
        placeholders={placeholders}
        getItemStyle={props.getItemStyle}
        trigger={trigger}
        triggered={triggered}
      />
    ))
  );
}
