import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { IntlProvider } from "react-intl-next";

import "./index.css";

ReactDOM.render(
  <React.Fragment>
    <IntlProvider locale="en">
      <App />
    </IntlProvider>
  </React.Fragment>,

  document.getElementById("properties-confluence")
);
