import styled from "styled-components";
import { token } from "@atlaskit/tokens";

const CustomModalContent = styled.div`
  display: flex;
  height: calc(100% - 90px);
  background-color: ${token("utility.elevation.surface.current")}
`;

export default CustomModalContent;
