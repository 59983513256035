import PropTypes from "prop-types";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import ChevronRightIcon from "@atlaskit/icon/glyph/chevron-right";
import { Container } from "./CollapsibleContainerStyle";

CollapsibleContainer.propTypes = {
  Icon: PropTypes.object.isRequired,
  Title: PropTypes.object.isRequired,
  collapsed: PropTypes.bool.isRequired,
};
export function CollapsibleContainer(props) {
  const collapsed = props.collapsed;
  return (
    <Container collapsed={collapsed}>
      <div className="header" onClick={() => props.onCollapseClick(!collapsed)}>
        <span style={{ padding: "10px" }}>{props.Icon}</span>
        <span style={{ padding: "10px", fontSize: "14px" }}>{props.Title}</span>
        <span style={{ marginLeft: "auto" }}>
          {!collapsed && <ChevronDownIcon />}
          {collapsed && <ChevronRightIcon />}
        </span>
      </div>
      <div className="content">
        <div className="sliding-child">
          <div>{props.children}</div>
        </div>
      </div>
    </Container>
  );
}
