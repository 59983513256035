import { notify } from "../Shared/notification-flag";

const useManageRowsData = ({
  setRowsInfo,
  setCreateCardInputs,
  createCardInputsRef,
  isSaveQueryRegistered,
  deleteValuesPerOrdinalNumber,
  onKeySwitcher,
}) => {
  const onLoadersTrigger = (idx, isWaiting) => {
    setRowsInfo((prev) => {
      let newRowLoadingRows = [...prev];
      if (newRowLoadingRows?.[idx]) {
        newRowLoadingRows[idx].loading = isWaiting;
      }
      return newRowLoadingRows;
    });
  };
  const onNameEmpty = (idx) => {
    setRowsInfo((prev) => {
      let newRowLoadingRows = [...prev];
      newRowLoadingRows[idx].isNameEmpty = true;
      notify("error", "Name cannot be empty");

      return newRowLoadingRows;
    });
  };
  const onAddEntry = (idx = false) => {
    setRowsInfo((prev) => {
      const newState = [...prev];
      const newRowSettings = { loading: false };

      if (!prev.length || idx === prev.length - 1) {
        newState.push(newRowSettings);
      } else if (idx !== false) {
        newState.splice(idx, 0, newRowSettings);
      }

      return newState;
    });
  };
  const onStopSpinner = (idx) => {
    onLoadersTrigger(idx, false);
    isSaveQueryRegistered.current = false;
  };
  const onDeleteEntry = (idx, shouldUpdateRef = true) => {
    deleteValuesPerOrdinalNumber(idx);
    if (shouldUpdateRef) {
      createCardInputsRef.current = createCardInputsRef.current.slice(0, idx).concat(createCardInputsRef.current.slice(idx + 1));
    }
    setCreateCardInputs((prev) => prev.slice(0, idx).concat(prev.slice(idx + 1)));
    setRowsInfo((prev) => prev.slice(0, idx).concat(prev.slice(idx + 1)));
    onKeySwitcher();
  };
  return {
    onAddEntry,
    onStopSpinner,
    onDeleteEntry,
    onNameEmpty,
    onLoadersTrigger,
  };
};

export default useManageRowsData;
