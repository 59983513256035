import React, { useEffect, useState } from "react";
import Tooltip from "@atlaskit/tooltip";
import { Checkbox } from "@atlaskit/checkbox";
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle";
import { CheckboxField as AtlassianCheckboxField } from "@atlaskit/form";
import { analytics } from "../../../../../../../analytics";
import { AnalyticsEvent } from "../../../../../../../analytics-events";
import { usePlaceholderStore } from "../../../../Store";

const checkboxStyle = (isPageMetadataChecked) =>
  isPageMetadataChecked
    ? { fontSize: "14px", display: "inline-flex", cursor: "not-allowed" }
    : { fontSize: "14px", display: "inline-flex" };

export const CheckboxField = ({ placeholder, name, label, isPageMetadataChecked = false }) => {
  const [isRestrictedToSingleUser, setIsRestrictedToSingleUser] = useState(placeholder.data?.checkboxes?.includes("singleUser"));
  const { changeCheckboxes } = usePlaceholderStore((state) => ({
    changeCheckboxes: state.changeCheckboxes,
  }));

  useEffect(() => {
    placeholder.data.checkboxes === undefined &&
      placeholder.data.isUppercase === undefined &&
      placeholder.data.isExpiredDate &&
      changeCheckboxes(placeholder, null, true);
  }, []);

  useEffect(() => {
    if (isPageMetadataChecked) {
      setIsRestrictedToSingleUser(false);
      changeCheckboxes(placeholder, "singleUser");
    }
  }, [isPageMetadataChecked]);

  const handleChange = (e) => {
    setIsRestrictedToSingleUser(!placeholder.data?.checkboxes?.includes("singleUser"));
    changeCheckboxes(placeholder, e.target.name);
    analytics.logEvent(AnalyticsEvent, { isRestrictedToSinglePerson: !placeholder.data.checkedOption });
  };

  return (
    <div style={checkboxStyle(isPageMetadataChecked)}>
      <AtlassianCheckboxField name={name} style={{ fontSize: "14px" }}>
        {({ fieldProps }) => (
          <Checkbox
            {...fieldProps}
            isChecked={isRestrictedToSingleUser}
            label={label}
            isDisabled={isPageMetadataChecked}
            onChange={handleChange}
          />
        )}
      </AtlassianCheckboxField>
      <span style={{ marginTop: "13px" }}>
        <Tooltip content="This option is not available if page metadata fields are displayed">
          <QuestionCircleIcon primaryColor="darkgrey" size="small" />
        </Tooltip>
      </span>
    </div>
  );
};
