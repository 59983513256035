import React from "react";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";
import { notify } from "../../Shared/notification-flag";
import { placeholderSetService } from "../../../Service/PlaceholderSetService";
import { usePlaceholderPortalStore } from "../Store";

export const DeletePlaceholderSetModal = ({ onClose, visible, id, name }) => {
  const deletePlaceholderGroup = usePlaceholderPortalStore((state) => state.deletePlaceholderGroup);

  const handleDelete = async () => {
    try {
      const { status } = await placeholderSetService.deletePlaceholderSet(id);
      if (status !== 200) {
        notify("error", `An error occurred deleting ${name}`);
      } else {
        deletePlaceholderGroup(id);
        notify("success", `${name} deleted`);
      }
      onClose();
    } catch (e) {
      if (e.response?.status === 403) {
        onClose();
      }
      notify("error", `An error occurred deleting ${name}`);
    }
  };

  return (
    <ModalTransition>
      {visible && (
        <Modal height="192px" width="400px" heading={`Delete ${name}`} onClose={onClose}>
          <div>
            <p>This is a permanent action. Are you sure you want to do this?</p>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "5px" }}>
              <Button onClick={handleDelete} appearance="danger">
                Delete
              </Button>
              <Button style={{ marginLeft: "10px" }} onClick={onClose}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};
