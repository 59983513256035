import {
  PLACEHOLDER_KIND_DATE,
  PLACEHOLDER_KIND_MULTI_SELECT,
  PLACEHOLDER_KIND_SELECT,
  PLACEHOLDER_KIND_STRING,
  PLACEHOLDER_KIND_USER_MENTION,
  PLACEHOLDER_KIND_CONFLUENCE_PAGE,
  PLACEHOLDER_KIND_EMAIL,
  PLACEHOLDER_KIND_URL,
  PLACEHOLDER_KIND_PHONE,
  PLACEHOLDER_KIND_NUMBER,
} from "./PlaceholderKinds";
import PropTypes from "prop-types";
import React from "react";
export const PlaceholderKindMatcher = ({
  kind,
  stringPlaceholder,
  selectPlaceholder,
  multiSelectPlaceholder,
  datePlaceholder,
  userMentionPlaceholder,
  confluencePagePlaceholder,
  urlPlaceholder,
  emailPlaceholder,
  phonePlaceholder,
  numberPlaceholder,
}) => {
  return (
    <>
      {kind === PLACEHOLDER_KIND_SELECT && selectPlaceholder}
      {kind === PLACEHOLDER_KIND_STRING && stringPlaceholder}
      {kind === PLACEHOLDER_KIND_MULTI_SELECT && multiSelectPlaceholder}
      {kind === PLACEHOLDER_KIND_DATE && datePlaceholder}
      {kind === PLACEHOLDER_KIND_USER_MENTION && userMentionPlaceholder}
      {kind === PLACEHOLDER_KIND_CONFLUENCE_PAGE && confluencePagePlaceholder}
      {kind === PLACEHOLDER_KIND_URL && urlPlaceholder}
      {kind === PLACEHOLDER_KIND_EMAIL && emailPlaceholder}
      {kind === PLACEHOLDER_KIND_PHONE && phonePlaceholder}
      {kind === PLACEHOLDER_KIND_NUMBER && numberPlaceholder}
    </>
  );
};

PlaceholderKindMatcher.propTypes = {
  kind: PropTypes.string.isRequired,
  stringPlaceholder: PropTypes.element.isRequired,
  selectPlaceholder: PropTypes.element.isRequired,
  multiSelectPlaceholder: PropTypes.element.isRequired,
  datePlaceholder: PropTypes.element.isRequired,
  userMentionPlaceholder: PropTypes.element.isRequired,
  confluencePagePlaceholder: PropTypes.element.isRequired,
  emailPlaceholder: PropTypes.element.isRequired,
  urlPlaceholder: PropTypes.element.isRequired,
  phonePlaceholder: PropTypes.element.isRequired,
  numberPlaceholder: PropTypes.element.isRequired,
};
