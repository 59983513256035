import React from "react";
import { token } from "@atlaskit/tokens";
import styled from "styled-components";


export const IconContainer = styled.div`
    padding: 5px;
    &:hover {
        background: ${token("elevation.surface.hovered")};
        cursor: pointer;
    }
`

export const Icon = styled.span`
    background-image: ${props => "url(" + getURLPathForIcon(props.icon) + ")"};
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    width: ${props => props.size + "px"};
    height: ${props => props.size + "px"};
    display: flex;
`


export const BPCIcon = ({ icon, size = 32, onClick }) => {
    return (
        <IconContainer onClick={onClick}>
            <Icon icon={icon} size={size} />
        </IconContainer>
    )
}

const getURLPathForIcon = (name = "ava1") => {
    if (name !== undefined && name.trim().length === 0) {
        name = "ava1";
    }
    return `/icons/propertygroups/${name}.svg`
}
