import React, { useEffect, useState } from "react";
import PropertyGroupForm from "./PropertyGroupForm";

const PropertyGroupFormDialog = ({ selectPropertyName = null, data, values, updateValue, pageId, recordId, group }) => {
  useEffect(() => {
    if (selectPropertyName && data.data) {
      const filteredData = data.data.filter((obj) => {
        return obj.data && obj?.data?.options && obj?.data?.options?.some((option) => option.value === selectPropertyName);
      });
      if (filteredData?.length !== 0 && !values[pageId]?.[recordId]?.[filteredData[0].id]) {
        updateValue({ value: selectPropertyName, pageId, propertyId: filteredData[0].id, recordId });
      }
    }
  }, []);
  return (
    <PropertyGroupForm
      shortVersion
      data={data}
      group={group}
      pageId={pageId}
      recordId={recordId}
      updateValue={updateValue}
      values={values}
      selectPropertyName={selectPropertyName}
    />
  );
};

export default PropertyGroupFormDialog;
