import styled from "styled-components";
import { token } from "@atlaskit/tokens";

export const Container = styled.div`
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
  background-color: ${token("elevation.surface.overlay.hovered")};
  margin-bottom: 5px;
  .header {
    display: flex;
    align-items: center;
    padding: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    ${(props) =>
      props.collapsed &&
      `
            &:hover {
                background-color: ${token("elevation.surface.hovered")};
            }
        `};
  }
  .content {
    padding-left: 50px;
    padding-right: 40px;
    .sliding-child {
      ${(props) =>
        !props.collapsed &&
        `
                padding-bottom: 20px;
            `};
      ${(props) =>
        props.collapsed &&
        `
                div:first-child {
                    display: none;
                }
            `};
    }
  }
`;
