import { ModalHeader } from "@atlaskit/modal-dialog";

const CustomModalHeader = ({ title }) => {
  return (
    <ModalHeader
      style={{
        padding: 0,
        height: "50px",
        minHeight: "50px",
        backgroundColor: "#520575",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          color: "white",
          fontSize: "1em",
          paddingLeft: "20px",
          flex: 1,
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {title}
      </div>
    </ModalHeader>
  );
};

export default CustomModalHeader;
