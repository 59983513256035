import React from "react";
import { useQuery } from "react-query";
import shallow from "zustand/shallow";
import { getPropertiesOfGroup } from "../PropertyGroupMacro/api";
import { usePropertyGroupStore } from "../PropertyGroupMacro/store";
import Spinner from "@atlaskit/spinner";
import { usePropertyFormStore } from "../PropertyGroupMacro/editor/property-form-store";
import PropertyGroupForm from "./PropertyGroupForm";
import PropertyGroupFormInline from "./PropertyGroupFormInline";
import PropertyGroupFormDialog from "./PropertyGroupFormDialog";

function PropertyGroupFormWrapper({
  groupData = null,
  selectPropertyName = null,
  reportView = false,
  updateValuesPerOrdinalNumber = false,
  initialValues = null,
  ordinalNumber = -1,
}) {
  const { group, recordId, pageId } = usePropertyGroupStore(
    (state) => ({
      group: state.selectedGroup,
      recordId: state.recordId,
      pageId: state.pageId,
    }),
    shallow
  );

  let { values, updateValue } = usePropertyFormStore(
    (state) => ({
      values: state.values,
      updateValue: state.updateValue,
    }),
    shallow
  );

  const { isLoading, data } = useQuery(["properties-of-group", { group }], getPropertiesOfGroup);

  if (isLoading) {
    return <Spinner />;
  }

  if (updateValuesPerOrdinalNumber && groupData) {
    return (
      <PropertyGroupFormInline
        data={groupData}
        group={group}
        pageId={pageId}
        recordId={recordId}
        reportView={reportView}
        updateValue={updateValuesPerOrdinalNumber.bind(null, ordinalNumber)}
        ordinalNumber={ordinalNumber}
        values={initialValues}
      />
    );
  }
  if (selectPropertyName && groupData) {
    return (
      <PropertyGroupFormDialog
        data={groupData}
        group={group}
        pageId={pageId}
        recordId={recordId}
        updateValue={updateValue}
        values={values}
        selectPropertyName={selectPropertyName}
      />
    );
  }

  return <PropertyGroupForm data={data} group={group} pageId={pageId} recordId={recordId} updateValue={updateValue} values={values} />;
}

export default PropertyGroupFormWrapper;
