import { useState } from "react";
import UserPicker from "@atlaskit/user-picker";
import Form from "@atlaskit/form";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import Button from "@atlaskit/button";
import { usePropertyVisualizationStore } from "../property-visualization-store";

function mapUser(user) {
  return {
    avatarUrl: atlassianRestService.getProfilePictureUrl(user?.profilePicture.path),
    id: user.accountId,
    name: user.publicName,
    type: "user",
    label: user.publicName,
    value: user.accountId,
  };
}

const SystemPropertyUserPicker = ({ isMulti, onUpdate, label = "User", placeholder = "Search user..." }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { config } = usePropertyVisualizationStore((state) => ({
    config: state.config.args,
  }));

  const loadOptions = async (query) => {
    if (query.trim().length === 0) {
      return [];
    }
    const usersByName = await atlassianRestService.findUsersByName(query);
    return usersByName.results.filter(({ user }) => user.accountType === "atlassian").map(({ user }) => mapUser(user));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <div style={{ flex: 1 }}>
        <Form name="userpicker">
          {({ fieldProps, options }) => (
            <UserPicker
              menuPosition="fixed"
              placeholder={placeholder}
              isMulti={isMulti}
              addMoreMessage=""
              options={options}
              onChange={(e, action) => {
                if (e === undefined) {
                  setSelectedUsers([]);
                  return;
                }
                if (isMulti) {
                  setSelectedUsers(e);
                  return;
                }
                setSelectedUsers([e]);
              }}
              value={selectedUsers}
              loadOptions={loadOptions}
              styles={{
                control: (styles) => ({
                  ...styles,
                  backgroundColor: "#FFFFFF",
                  fontSize: "14px",
                  width: "inherit",
                  fontWeight: "normal",
                }),
                menuPortal: (styles) => ({
                  ...styles,
                  position: "initial",
                }),
                menu: (styles) => ({
                  ...styles,
                  width: "100%",
                  marginTop: "0px",
                }),
                menuList: (styles) => ({
                  ...styles,
                  textAlign: "left",
                  fontSize: "14px",
                  fontWeight: "normal",
                }),
              }}
            />
          )}
        </Form>
      </div>

      <div style={{ marginLeft: "5px" }}>
        <Button
          appearance="primary"
          onClick={() => {
            if (!selectedUsers || selectedUsers.length === 0) {
              return;
            }
            const currentlyAddedPeople = config?.data?.args?.people ?? [];
            const filteredUsers = selectedUsers.filter((u) => !currentlyAddedPeople.includes(u));
            onUpdate(currentlyAddedPeople, filteredUsers);
            setSelectedUsers([]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default SystemPropertyUserPicker;
