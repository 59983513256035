import { useEffect, useState } from "react";
import Select, { CheckboxSelect } from "@atlaskit/select";
import { fontColorAccordingToHex } from "../font-color-according-to-hex";
import { usePropertyGroupReportUIStore } from "../../PropertyReportMacro/store";
import { onSelectMenuClosed } from "../../../resize-service";
import { token } from "@atlaskit/tokens";

const MultiSelectPropertyEdit = ({ property, initialValue, autoFocus, onUpdate }) => {
  const [selected, setSelected] = useState();
  const { loadedLiveFilter } = usePropertyGroupReportUIStore((state) => ({
    loadedLiveFilter: state.loadedLiveFilter,
  }));

  useEffect(() => {
    if (initialValue) {
      setSelected(initialValue);
    }
  }, [initialValue]);

  if (loadedLiveFilter) {
    return (
      <CheckboxSelect
        className="checkbox-multi-select"
        autoFocus
        placeholder={"Search..."}
        isSearchable
        backspaceRemovesValue
        openMenuOnFocus
        styles={{
          control: (base) => ({
            ...base,
            backgroundColor: `${token("utility.elevation.surface.current")}`,
            borderColor: `${token("color.border.input")}`,
            boxShadow: `${token("elevation.shadow.overflow")}`,
            fontSize: "14px",
          }),
          indicatorsContainer: (base) => ({ ...base, display: "none" }),
          placeholder: (base) => ({ ...base, fontSize: "14px", fontWeight: "400" }),
          singleValue: (base) => ({
            ...base,
            fontSize: "14px",
            fontWeight: "400",
            textTransform: property.data.isUppercase ? "uppercase" : "initial",
          }),
          menu: (base) => ({ ...base, marginTop: "0px", borderRadius: "0px" }),
          menuList: (base) => ({ ...base, fontSize: "14px", fontWeight: "400", paddingTop: "0px", textAlign: "left" }),
          multiValue: (styles, { data }) => {
            return {
              ...styles,
              display: "none",
            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: fontColorAccordingToHex(data.color),
            textTransform: property.data.isUppercase ? "uppercase" : "initial",
          }),
        }}
        onMenuOpen={() => {
          if (loadedLiveFilter) {
            window.AP.events.emit("trigger_resize_livefilter", [".checkbox-multi-select"]);
          }
        }}
        onMenuClose={() => {
          if (loadedLiveFilter) {
            onSelectMenuClosed();
          }
        }}
        onFocus={() => {}}
        options={property.data.options.map(toSelectField)}
        onChange={(newOption) => {
          setSelected(newOption);
          onUpdate(newOption);
        }}
        value={selected}
      />
    );
  }
  return (
    <Select
      isMulti
      isClearable
      openMenuOnFocus={autoFocus}
      closeMenuOnSelect={false}
      isSearchable={false}
      styles={{
        placeholder: (base) => ({ ...base, fontSize: "14px", fontWeight: "400" }),
        singleValue: (base) => ({ ...base, fontSize: "14px", fontWeight: "400" }),
        control: (base) => ({
          ...base,
          backgroundColor: `${token("utility.elevation.surface.current")}`,
          borderColor: `${token("color.border.input")}`,
          boxShadow: `${token("elevation.shadow.overflow")}`,
        }),
        menuList: (base) => ({ ...base, fontSize: "14px", fontWeight: "400" }),
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor: data.color,
            fontSize: "12px",
            fontWeight: "700",
            textTransform: property.data.isUppercase ? "uppercase" : "initial",
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: fontColorAccordingToHex(data.color),
        }),
      }}
      value={selected}
      options={property.data.options.map(toSelectField)}
      onChange={(newOption) => {
        if (!newOption) {
          newOption = [{ value: "" }];
        }
        setSelected(newOption);
        onUpdate(newOption);
      }}
      placeholder={property?.data?.description || "Select a value"}
    ></Select>
  );
};

const toSelectField = ({ value, color }) => {
  return {
    label: value,
    value,
    color,
  };
};

export default MultiSelectPropertyEdit;
