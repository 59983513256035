import styled from "styled-components";

const EmptyText = styled.span`
  color: rgb(107, 119, 140);
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
`;

const PhonePropertyView = ({ property, initialValue }) => {
  if (initialValue) {
    return (
      <a
        href={`tel:${initialValue}`}
        style={{ display: "inline-block", overflowWrap: "anywhere", marginLeft: "5px", fontSize: "14px" }}
        onClick={(event) => event.stopPropagation()}
        data-sortable={true}
        data-raw-value={initialValue}
      >
        {initialValue}
      </a>
    );
  }

  if (property?.data?.description) {
    return (
      <EmptyText data-sortable={true} data-raw-value="">
        {property?.data?.description}
      </EmptyText>
    );
  }
  return (
    <EmptyText data-sortable={true} data-raw-value="">
      Enter text here
    </EmptyText>
  );
};

export default PhonePropertyView;
