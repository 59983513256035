import { useRef, useState } from "react";
import { ButtonItem, NavigationContent, Section, SideNavigation, LinkItem, NavigationFooter } from "@atlaskit/side-navigation";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";
import EditFilledIcon from "@atlaskit/icon/glyph/edit-filled";
import { useQuery } from "react-query";
import Spinner from "@atlaskit/spinner";

import { getPropertyGroups } from "../api";
import shallow from "zustand/shallow";
import { usePropertyGroupStore } from "../store";
import { BPCIcon } from "../../Icon/BPCIcon";
import { usePropertyFormStore } from "./property-form-store";
import Textfield from "@atlaskit/textfield";

const PropertyGroupMacroEditorSidebar = (props) => {
  const { group, setGroup } = usePropertyGroupStore(
    (state) => ({
      setGroup: state.setGroup,
      group: state.selectedGroup,
    }),
    shallow
  );

  const { hasChanged, setHasChanged, initialValues, setValues } = usePropertyFormStore((state) => ({
    setHasChanged: state.setHasChanged,
    hasChanged: state.hasChanged,
    initialValues: state.initialValues,
    setValues: state.setValues,
  }));

  const [confirmSwitchingPropertyGroupModalIsVisible, setConfirmSwitchingPropertyGroupModalIsVisible] = useState(false);
  const currentItem = useRef(null);
  const { isLoading, data } = useQuery("property-groups", getPropertyGroups);

  const [filteredData, setFilteredData] = useState(null);

  if (isLoading) {
    return <Spinner />;
  }

  const handleSwitchingPropertyGroup = () => {
    setValues(initialValues);
    setGroup(currentItem.current.id);
    setHasChanged(false);
  };

  const handleOnChangeInput = (textInput) => {
    const filterData = data.data.filter((item) => {
      return item.name.toLowerCase().includes(textInput.trim().toLowerCase());
    });

    const regFilter = new RegExp(`^${textInput}`, "gi");
    const sortData = filterData.sort((a, b) => {
      const aMatch = regFilter.test(a.name) ? 1 : -1;
      const bMatch = regFilter.test(b.name) ? 1 : -1;
      return bMatch - aMatch;
    });

    setFilteredData(sortData);
  };

  return (
    <>
      <div
        className="content-sidebar"
        style={{
          height: "100%",
          borderRight: "1px solid #cccccc",
        }}
      >
        <ConfirmSwitchingPropertyGroupModal
          onClose={() => {
            setConfirmSwitchingPropertyGroupModalIsVisible(false);
          }}
          onConfirm={(item) => {
            handleSwitchingPropertyGroup(item);
          }}
          visible={confirmSwitchingPropertyGroupModalIsVisible}
        />
        <SideNavigation>
          <div
            style={{
              height: "100%",
              backgroundColor: "var(--ds-surface,#FAFBFC)",
            }}
          >
            <NavigationContent>
              <Section hasSeparator>
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Textfield
                    onChange={(evt) => {
                      handleOnChangeInput(evt.target.value);
                    }}
                    placeholder="Search..."
                  />
                </div>
                {(filteredData !== null ? filteredData : data.data).map((item, idx) => (
                  <ButtonItem
                    isSelected={item.id === group}
                    iconBefore={<BPCIcon icon={item.icon} size="25" />}
                    key={idx}
                    onClick={() => {
                      currentItem.current = item;
                      if (hasChanged) {
                        setConfirmSwitchingPropertyGroupModalIsVisible(true);
                      } else {
                        handleSwitchingPropertyGroup();
                      }
                    }}
                  >
                    {item.name}
                  </ButtonItem>
                ))}
              </Section>
            </NavigationContent>
            <NavigationFooter>
              <Section>
                <LinkItem
                  target="_blank"
                  href={`${window.AP._hostOrigin}/wiki/plugins/servlet/ac/net.junovi.confluence.properties/properties-overview`}
                  iconBefore={<EditFilledIcon />}
                  cssFn={(defaultStyles) => {
                    return {
                      ...defaultStyles,
                      textDecoration: "none",
                    };
                  }}
                >
                  Edit or create property groups
                </LinkItem>
              </Section>
            </NavigationFooter>
          </div>
        </SideNavigation>
      </div>
    </>
  );
};

const ConfirmSwitchingPropertyGroupModal = (props) => {
  const { onClose, visible, onConfirm } = props;

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <ModalTransition>
      {visible && (
        <Modal height="192px" width="400px" heading={`Switch property group?`} onClose={() => onClose()}>
          <div>
            <p>{`Are you sure. All changes will be discarded.`}</p>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "5px" }}>
              <Button onClick={handleConfirm} appearance="danger">
                Continue
              </Button>
              <Button style={{ marginLeft: "10px" }} onClick={onClose}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default PropertyGroupMacroEditorSidebar;
