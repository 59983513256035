import { usePropertyGroupReportMacroStore } from "./store";
import TableIcon from "@atlaskit/icon/glyph/table";
import MediaServicesGridIcon from "@atlaskit/icon/glyph/media-services/grid";
import { ReportType } from "./report-type";
import SelectWithCustomOptions from "./SelectWithCustomOptions";

const ReportViewModePicker = (props) => {
  const { view, setView } = usePropertyGroupReportMacroStore((state) => ({
    view: state.selectedView,
    setView: state.setSelectedView,
  }));

  return (
    <SelectWithCustomOptions
      iconBefore
      initialValue={view}
      options={[
        { label: "Table", value: ReportType.TABLE, iconBefore: <TableIcon size="small" /> },
        { label: "Card", value: ReportType.CARD, iconBefore: <MediaServicesGridIcon size="small" /> },
      ]}
      label="View type"
      onUpdate={(newVariant) => {
        setView(newVariant);
      }}
    />
  );
};

export default ReportViewModePicker;
