import mixpanel from "mixpanel-browser";

class Analytics {
  constructor() {
    mixpanel.init("c205e20b1269e89cef53353bea3cbc2b", { debug: true, ip: false });
  }
  logEvent(name, payload = {}) {
    if (!name) {
      throw new Error("can not log analytics data without name");
    }
    try {
      mixpanel.track(name, { host: window.AP._hostOrigin, ...payload });
    } catch (e) {}
  }
}

export const analytics = new Analytics();
