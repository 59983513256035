import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Field from "@atlaskit/form/Field";
import Select from "@atlaskit/select";
import { SelectFieldStyles } from "../../../../SelectFieldStyles";
import { usePlaceholderStore } from "../../../../Store";

CurrencySelectionField.propTypes = {
  placeholder: PropTypes.object.isRequired,
};
export function CurrencySelectionField({ placeholder }) {
  const currency = require("currency-codes");
  const [currencyNotes, setCurrencyNotes] = useState();
  const { changeCurrency } = usePlaceholderStore((state) => ({
    changeCurrency: state.changeCurrency,
  }));

  useEffect(() => {
    if (placeholder.data) {
      const selectedCurrency = currency.data.find((currentCurrency) => currentCurrency.code === placeholder.data.currency);
      selectedCurrency && setCurrencyNotes({ label: selectedCurrency.currency, value: selectedCurrency.code });
    }
  }, []);

  const formatCurrencyNotes = (currencyNote) => ({
    label: currencyNote.currency,
    value: currencyNote.code,
  });

  const handleCurrencyChange = (selectedCurrency) => {
    setCurrencyNotes(selectedCurrency);
    changeCurrency(placeholder, selectedCurrency.value);
  };

  return (
    <Field label="Default currency" name="currencyNote" defaultValue="">
      {({ fieldProps }) => (
        <div>
          <Select
            {...fieldProps}
            maxMenuHeight={110}
            placeholder="Select a default currency"
            styles={SelectFieldStyles}
            value={currencyNotes}
            options={currency.data.map(formatCurrencyNotes)}
            onChange={handleCurrencyChange}
          />
        </div>
      )}
    </Field>
  );
}
