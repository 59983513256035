/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useEffect } from "react";
import Button from "@atlaskit/button/standard-button";
import InlineDialog from "@atlaskit/inline-dialog";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import { FieldsForFiltering } from "./FilterFieldsForProperty";
import { atlassianRestService } from "../../Service/AtlassianRestService";
import { PropertyType } from "../../property-type";
export const ValuesForLiveFilter = ({ currentProperty, propertyFilter, propertyId, onUpdate }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [userPropertyId, setUserPropertyId] = useState(false);
  const [textValue, setTextValue] = useState("All");
  const [valueBeforeApply, setValueBeforeApply] = useState(propertyFilter);
  useEffect(() => {
    (async () => {
      if (currentProperty.kind === PropertyType.USER) {
        if (Object.values(propertyFilter)[0]) {
          setUserPropertyId(Object.values(propertyFilter)[0]);
        }
      }
      if (Object.values(propertyFilter)[0]) {
        const currentFilters = Object.values(propertyFilter)[0];
        setTextValue(currentFilters);
        if (currentProperty.kind === PropertyType.CONFLUENCEPAGE && currentFilters) {
          const pageFilter = await atlassianRestService.getPageById(currentFilters);
          setTextValue(pageFilter?.title);
        }
        if (currentFilters[0]?.label || currentFilters?.label) {
          setTextValue(currentFilters[0]?.label || currentFilters?.label);
          if (currentFilters.length > 1) {
            setTextValue(currentFilters[0].label + " and " + (currentFilters.length - 1) + " more");
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (userPropertyId) {
        if (userPropertyId.length > 1) {
          const userByAccountId = await atlassianRestService.getUserByAccountId(userPropertyId[0]);
          setTextValue(userByAccountId.publicName + " and " + (userPropertyId.length - 1) + " more");
        } else {
          const userByAccountId = await atlassianRestService.getUserByAccountId(userPropertyId);
          if (userPropertyId.length === 0) {
            setTextValue("All");
          } else {
            setTextValue(userByAccountId.publicName);
          }
        }
      }
    })();
  }, [userPropertyId]);

  useEffect(() => {
    const dialogElement = document.querySelector(".property-inline-dialog");
    const tableElement = document.querySelector(".report-table");
    if (!isDialogOpen && tableElement?.clientHeight < dialogElement?.clientHeight + 100) {
      window.AP.resize("100%", `${(dialogElement.getBoundingClientRect().bottom + 60).toString()}px`);
    }
  }, [isDialogOpen]);
  const contentOfInlineProperty = (
    <div style={{ padding: "0px" }} className="property-edit-dropdown">
      <FieldsForFiltering
        currentProperty={currentProperty}
        propertyFilter={valueBeforeApply}
        propertyId={propertyId}
        onUpdate={(newValue) => {
          if (newValue) {
            setTextValue(newValue);
            if (newValue?.type === "user") {
              setUserPropertyId(newValue.value);
            } else {
              if (newValue[0]?.label || newValue?.label) {
                setTextValue(newValue[0]?.label || newValue?.label);
                if (newValue.length > 1) {
                  setTextValue(newValue[0].label + " and " + (newValue.length - 1) + " more");
                }
              }
            }
          } else {
            setTextValue("All");
          }
          setValueBeforeApply({ [propertyId]: newValue });
          onUpdate(newValue);
        }}
        liveFilter={true}
      />
    </div>
  );
  return (
    <InlineDialog
      onClose={() => {
        setIsDialogOpen(false);
      }}
      content={contentOfInlineProperty}
      isOpen={isDialogOpen}
      css={css`
        padding: 0px;
      `}
    >
      <Button
        appearance="default"
        onClick={() => setIsDialogOpen(isDialogOpen ? false : true)}
        style={{
          fontSize: "14px",
          width: "235px",
          textAlign: "left",
          fontWeight: "600",
          color: "#42526E !important",
          height: "40px",
          lineHeight: "40px",
        }}
        iconAfter={<HipchatChevronDownIcon size="small" />}
      >
        {textValue}
      </Button>
    </InlineDialog>
  );
};
