import styled from "styled-components";
import ChevronRightLargeIcon from "@atlaskit/icon/glyph/chevron-right-large";
import { token } from "@atlaskit/tokens";

const ButtonCard = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  height: 180px;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  &:hover {
    background-color: ${token("elevation.surface.hovered")};
  }
  &:active {
    background-color: ${token("elevation.surface")};
  }
  box-shadow: -0.3px 1px 2.5px 0.2px rgba(126, 156, 164, 0.7);
  border-radius: 4px;
  margin-bottom: 1rem;
  @media (max-width: 800px) {
    height: 150px;
  }
  @media (max-width: 600px) {
    height: 120px;
    width: 130%;
    transform: translateX(-20%);
  }
  cursor: pointer;
`;
const ImageContainer = styled.div`
  height: 100%;
  width: 220px;
  min-width: 120px;
  background-color: #f4f5f7;
  display: flex;
  jusitfy-content: center;
  align-items: center;
  text-align: center;
`;
const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  margin: 0 auto;
  > span {
    width: 100%;
    height: 100%;
    > svg {
      width: 100%;
      color: #5b0575;
      height: 100%;
      opacity: 0.5;
    }
  }
`;
const TextContainer = styled.div`
  position: relative;
  height: 80%;
  width: 80%;
  max-width: 80%;
  display: flex;
  align-items: center;
  jusitfy-content: center;
  * {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 1110px) {
    transform: scale(0.95);
  }
  @media (max-width: 1000px) {
    transform: scale(0.9);
    width: 85%;
  }
  @media (max-width: 900px) {
    transform: scale(0.85);
  }
  @media (max-width: 800px) {
    transform: scale(0.8);
    width: 90%;
  }
  @media (max-width: 700px) {
    transform: scale(0.75);
  }
`;

const ElementsStyler = styled.div`
  letter-spacing: normal;
  max-width: 80%;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  padding: 2rem 2.5rem 0 2rem;
  * {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 1000px) {
    padding: 1.5rem 1.5rem 0 1rem;
    width: 90%;
    .forHighWidth {
      display: none;
    }
  }
  @media (max-width: 900px) {
    padding: 1rem 1rem 0 0.5rem;
    width: 95%;
  }
  @media (max-width: 800px) {
    padding: 1rem 0.5rem 0 0.2rem;
  }
  @media (max-width: 700px) {
    padding: 1.5rem 1.5rem 0 1rem;
    width: 90%;
    .forMediumWidth {
      display: none;
    }
  }
  @media (max-width: 500px) {
    padding: 0.8rem 1rem 0 0.8rem;
  }
`;
const IconRow = styled.div`
  position: absolute;
  top: 50%;
  right: -0.5rem;
  width: 10%;
  font-size: 1rem;
`;

const OverviewCard = ({ handleTypeChoose, visualizationType, icon, headerName, headerQuotes, descriptions }) => {
  return (
    <ButtonCard
      onClick={() => {
        handleTypeChoose(visualizationType);
      }}
    >
      <ImageContainer>
        <IconWrapper>{icon}</IconWrapper>
      </ImageContainer>
      <TextContainer>
        <ElementsStyler>
          <h3>{headerName}</h3>
          <p style={{ fontSize: "14px" }}>
            <>
              {headerQuotes.map((quote, idx) => {
                return (
                  <span className={idx === 0 ? "" : "forHighWidth"} key={idx}>
                    {quote}
                  </span>
                );
              })}
            </>
          </p>
          <br />
          <p>
            <>
              {descriptions.map((quote, idx) => {
                return (
                  <span className={idx === 0 ? "forMediumWidth" : "forHighWidth"} key={idx}>
                    {quote}
                  </span>
                );
              })}
            </>
          </p>
        </ElementsStyler>
        <IconRow>
          <ChevronRightLargeIcon />
        </IconRow>
      </TextContainer>
    </ButtonCard>
  );
};

export default OverviewCard;
