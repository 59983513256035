import { useEffect, useState } from "react";
import styled from "styled-components";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import { FormatDateString } from "../../FormattedDate";

const EmptyText = styled.span`
  color: rgb(107, 119, 140);
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
`;

const DatePropertyView = ({ property, initialValue, pageId, displayMode }) => {
  const [isExpiredDate, setIsExpiredDate] = useState(false);
  const [displayLastModified, setDisplayLastModified] = useState("");
  const [displayCreationDate, setDisplayCreationDate] = useState("");

  useEffect(() => {
    if (initialValue) {
      const date = new Date();
      const initialDate = new Date(initialValue);
      setIsExpiredDate(date > initialDate && property.data?.isExpiredDate);
    }
  }, [initialValue]);
  useEffect(async () => {
    const pageById = await atlassianRestService.getPageById(pageId);
    if (property.data?.displayLastModified) {
      const dateOfLastModifiedPage = pageById?.version?.when.slice(0, 10);
      setDisplayLastModified(dateOfLastModifiedPage);
    }
    if (property.data?.displayCreationDate) {
      const dateOfCreation = pageById?.history?.createdDate.slice(0, 10);
      setDisplayCreationDate(dateOfCreation);
    }
  }, []);

  if (!displayMode) {
    if (property.data?.displayLastModified) {
      return (
        <p style={{ color: "rgb(107, 119, 140)", display: "inline-block", marginLeft: "5px", fontSize: "14px" }}>
          Displays the last modified date
        </p>
      );
    } else if (property.data?.displayCreationDate) {
      return (
        <p style={{ color: "rgb(107, 119, 140)", display: "inline-block", marginLeft: "5px", fontSize: "14px" }}>
          Displays the page creation date
        </p>
      );
    }
  }

  if (initialValue || displayCreationDate || displayLastModified) {
    let dateOfProperty = "";
    if (initialValue) {
      dateOfProperty = initialValue?.value ?? initialValue;
    }
    if (property.data?.displayLastModified) {
      dateOfProperty = displayLastModified;
    } else if (property.data?.displayCreationDate) {
      dateOfProperty = displayCreationDate;
    }
    const dateFormat = dateOfProperty?.split("-");
    return (
      <span
        data-sortable={true}
        data-raw-value={dateOfProperty}
        style={{
          background: isExpiredDate ? "#FFEBE6" : "#DFE1E6",
          color: isExpiredDate ? "#BF2600" : "#42526E",
          borderRadius: "3px",
          padding: "2px 4px",
          inlineSize: "max-content",
          marginLeft: "5px",
          whiteSpace: "nowrap",
          fontSize: "14px",
        }}
      >
        <FormatDateString day={dateFormat?.[2]} month={dateFormat?.[1] - 1} year={dateFormat?.[0]} />
      </span>
    );
  }

  if (property?.data?.description) {
    return (
      <EmptyText data-sortable={true} data-raw-value="">
        {property?.data?.description}
      </EmptyText>
    );
  }
  return (
    <EmptyText data-sortable={true} data-raw-value="">
      No date specified
    </EmptyText>
  );
};

export default DatePropertyView;
