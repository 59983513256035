import React, { useState, useEffect } from "react";
import Form from "@atlaskit/form";
import Tooltip from "@atlaskit/tooltip";
import { Checkbox } from "@atlaskit/checkbox";
import { FormFooter } from "@atlaskit/form/FormFooter";
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle";
import { CheckboxField as AtlassianCheckboxField } from "@atlaskit/form";
import { usePlaceholderIcon } from "../usePlaceholderIcon";
import { PanelTitle } from "../Fields/PanelTitle";
import { NameField } from "../Fields/NameField";
import { DescriptionField } from "../Fields/DescriptionField";
import { CollapsibleContainer } from "../CollapsibleContainer";
import { DeleteProperty } from "../DeletePlaceholder";
import { useLifecycleSetCollapsed } from "./useLifecycleSetCollapsed";
import { PLACEHOLDER_KIND_DATE } from "../PlaceholderKinds";
import { PLACEHOLDER_EDITED } from "../../../../../../Lifecycles";
import { AnalyticsEvent } from "../../../../../../../analytics-events";
import { analytics } from "../../../../../../../analytics";
import { usePlaceholderStore } from "../../../../Store";

const renderCheckboxField = ({ name, label, isDisabled = false, isChecked = false, onChangeCB }) => (
  <AtlassianCheckboxField name={name}>
    {({ fieldProps }) => (
      <Checkbox
        {...fieldProps}
        label={label}
        isChecked={isChecked}
        isDisabled={isDisabled}
        onChange={onChangeCB}
        style={isDisabled ? { fontSize: "14px", cursor: "not-allowed" } : { fontSize: "14px" }}
      />
    )}
  </AtlassianCheckboxField>
);
const renderTooltip = ({ title, content }) => (
  <span style={{ display: "inline-flex" }}>
    <p style={{ color: "rgb(107, 119, 140)", display: "inline-block", marginLeft: "5px", fontSize: "14px" }}>{title}</p>
    <span style={{ marginTop: "15px", marginLeft: "3px" }}>
      <Tooltip content={content}>
        <QuestionCircleIcon primaryColor="darkgrey" size="small" />
      </Tooltip>
    </span>
  </span>
);

export const DatePlaceholderInPortal = ({ placeholder }) => {
  const { collapsed, setCollapsed } = useLifecycleSetCollapsed({ placeholder });
  const [isExpiredDate, setIsExpiredDate] = useState(placeholder.data.isExpiredDate);
  const [isRecentlyModified, setIsRecentlyModified] = useState(placeholder.data.displayLastModified);
  const [isCreationDate, setIsCreationDate] = useState(placeholder.data.displayCreationDate);
  const PanelIcon = usePlaceholderIcon(PLACEHOLDER_KIND_DATE, "small");
  const { editPlaceholder } = usePlaceholderStore((state) => ({
    editPlaceholder: state.placeholders.find((p) => p === placeholder),
  }));
  editPlaceholder.lifecycle === undefined && (editPlaceholder.lifecycle = PLACEHOLDER_EDITED);

  const onChangeHighlightDateExpiredCheckbox = (e) => {
    setIsExpiredDate(!placeholder.data.isExpiredDate);
    editPlaceholder.data.isExpiredDate = !placeholder.data.isExpiredDate;
    analytics.logEvent(AnalyticsEvent.IS_EXPIRED_DATE, { isExpiredDate: !placeholder.data.isExpiredDate });
  };
  const onChangeDisplayLastModifiedCheckbox = (e) => {
    setIsCreationDate(false);
    setIsRecentlyModified(!placeholder.data.displayLastModified);
    editPlaceholder.data.displayLastModified = !placeholder.data.displayLastModified;
    editPlaceholder.data.displayCreationDate = false;
  };
  const onChangeDisplayCreationDateCheckbox = (e) => {
    setIsRecentlyModified(false);
    setIsCreationDate(!placeholder.data.displayCreationDate);
    editPlaceholder.data.displayCreationDate = !placeholder.data.displayCreationDate;
    editPlaceholder.data.displayLastModified = false;
  };
  return (
    <CollapsibleContainer
      collapsed={collapsed}
      Title={<PanelTitle placeholder={placeholder} />}
      Icon={PanelIcon}
      onCollapseClick={setCollapsed}
    >
      <Form>
        {({ formProps }) => (
          <form {...formProps} name="date-placeholder-from-example">
            <NameField placeholder={placeholder} />
            <DescriptionField placeholder={placeholder} />
            <div style={{ fontSize: "14px" }}>
              {renderCheckboxField({
                name: "DateExpired",
                label: "Highlight when date is expired",
                isChecked: isExpiredDate,
                onChangeCB: onChangeHighlightDateExpiredCheckbox,
              })}
              {renderTooltip({
                title: "Page metadata options",
                content: "Only one metadata can be displayed per field. If you choose one the other option will not be usable",
              })}
              <div style={{ display: "inline-flex" }}>
                {renderCheckboxField({
                  name: "displayLastModified",
                  label: "Display last modified date",
                  isDisabled: isCreationDate,
                  isChecked: isRecentlyModified,
                  onChangeCB: onChangeDisplayLastModifiedCheckbox,
                })}
                {renderCheckboxField({
                  name: "displayCreationDate",
                  label: "Display creation date",
                  isDisabled: isRecentlyModified,
                  isChecked: isCreationDate,
                  onChangeCB: onChangeDisplayCreationDateCheckbox,
                })}
              </div>
            </div>
            <FormFooter>
              <DeleteProperty property={placeholder} />
            </FormFooter>
          </form>
        )}
      </Form>
    </CollapsibleContainer>
  );
};
