import { useState, useRef } from "react";
import useManageRowsData from "./useManageRowsData";
import useSaveQueue from "./useSaveQueue";

const useCreateEntryMethods = ({ groupId, onRecordsUpdate }) => {
  const [rowsInfo, setRowsInfo] = useState([]);
  const createCardInputsRef = useRef([]);
  const [createCardInputs, setCreateCardInputs] = useState([]);
  const [valuesPerOrdinalNumber, setValuesPerOrdinalNumber] = useState([]);
  const isSaveQueryRegistered = useRef(false);
  const keyOptions = { option1: "KEY-V1", option2: "KEY-V2" };
  const [keySwitcher, setKeySwitcher] = useState(keyOptions.option1);

  const onKeySwitcher = () => {
    setKeySwitcher((prev) => {
      return prev === keyOptions.option1 ? keyOptions.option2 : keyOptions.option1;
    });
  };

  const handleInputChange = (evt, idx) => {
    if (rowsInfo[idx]?.isNameEmpty) {
      setRowsInfo((prev) => {
        let newRowLoadingRows = [...prev];
        newRowLoadingRows[idx].isNameEmpty = false;
        return newRowLoadingRows;
      });
    }
    createCardInputsRef.current[idx] = evt.target.value;
    setCreateCardInputs((prev) => {
      let newArr = [...prev];
      newArr[idx] = evt.target.value;
      return newArr;
    });
  };
  const updateValuesPerOrdinalNumber = (ordinalNumber, { value, propertyId = "" }) => {
    setValuesPerOrdinalNumber((state) => {
      let newValuesPerOrdinalNumber = [...state];
      if (JSON.stringify(value) === "{}") {
        newValuesPerOrdinalNumber[ordinalNumber] = {};
      } else {
        newValuesPerOrdinalNumber[ordinalNumber] = {
          ...newValuesPerOrdinalNumber[ordinalNumber],
          [propertyId]: value,
        };
      }
      return newValuesPerOrdinalNumber;
    });
  };
  const deleteValuesPerOrdinalNumber = (ordinalNumber) => {
    setValuesPerOrdinalNumber((state) => {
      let newValuesPerOrdinalNumber = [...state];
      newValuesPerOrdinalNumber = newValuesPerOrdinalNumber
        .slice(0, ordinalNumber)
        .concat(newValuesPerOrdinalNumber.slice(ordinalNumber + 1));
      return newValuesPerOrdinalNumber;
    });
  };

  const { onAddEntry, onStopSpinner, onDeleteEntry, onNameEmpty, onLoadersTrigger } = useManageRowsData({
    rowsInfo,
    setRowsInfo,
    createCardInputsRef,
    setCreateCardInputs,
    isSaveQueryRegistered,
    handleInputChange,
    valuesPerOrdinalNumber,
    updateValuesPerOrdinalNumber,
    deleteValuesPerOrdinalNumber,
    onKeySwitcher,
  });
  const { handeSaveToQueue, onSaveAll } = useSaveQueue({
    groupId,
    onDeleteEntry,
    rowsInfo,
    createCardInputsRef,
    onNameEmpty,
    onLoadersTrigger,
    createCardInputs,
    valuesPerOrdinalNumber,
    onRecordsUpdate,
    isSaveQueryRegistered,
  });

  return {
    valuesPerOrdinalNumber,
    updateValuesPerOrdinalNumber,
    onAddEntry,
    createCardInputs,
    rowsInfo,
    handleInputChange,
    onStopSpinner,
    onDeleteEntry,
    onSaveAll,
    handeSaveToQueue,
    keySwitcher,
  };
};

export default useCreateEntryMethods;
