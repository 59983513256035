const axios = require("axios");
export default class BaseRestService {
  constructor() {
    this.client = axios.create();
  }
  buildJWTAuthAxiosConfig() {
    const jwt = new URLSearchParams(window.location.search).get("jwt") || "";
    if (jwt === "") {
      console.warn(`Could not get JWT from URL ${window.location}! Backend will not accept occurring requests.`);
    }
    return { headers: { Authorization: `JWT ${jwt}` } };
  }
}
