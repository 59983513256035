import { useEffect } from "react";
import shallow from "zustand/shallow";
import { QueryClient, QueryClientProvider } from "react-query";
import { loadDataFromMacro } from "../../macro-service";
import { usePropertyAddEntriesPickerStore, usePropertyGroupReportMacroStore } from "./store";
import Button from "@atlaskit/button";
import Spinner from "@atlaskit/spinner";
import EmptyState from "@atlaskit/empty-state";
import ModalWrapper from "../../CustomModal/ModalWrapper";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModalContent from "../../CustomModal/CustomModalContent";
import { safelyParseJson } from "../../safely-parse-json";
import { generateId } from "../../../v2/id-generator";
import ReportEditorSidebar from "./ReportEditorSidebar";
import { AnalyticsEvent } from "../../../analytics-events";
import { analytics } from "../../../analytics";
import PropertyGroupReportTable from "./PropertyGroupReportTable";
import { ReportType } from "./report-type";
import PropertyGroupReportCard from "./PropertyGroupReportCard";
import { NO_PROPERTY_GROUP_SELECTED_OR_EMPTY } from "../../../v2/empty-states-resources";
import { token } from "@atlaskit/tokens";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const PropertyGroupReportMacroEditor = (props) => {
  const {
    loaded,
    group,
    view,
    selectedColumns,
    defaultSortColumn,
    defaultSortOrder,
    labels,
    labelsLogic,
    spaces,
    pagesUnder,
    numberOfItems,
    propertyFilters,
    reportMacroId,
    setPropertyFilters,
    setGroup,
    setLoaded,
    setSelectedColumns,
    setLabels,
    setLabelsLogic,
    setSelectedSpaces,
    setPagesUnder,
    setCurrentPageId,
    setSelectedView,
    setDefaultSortColumn,
    setDefaultSortOrder,
    setNumberOfItems,
    addEntriesType,
    setAddEntriesType,
  } = usePropertyGroupReportMacroStore(
    (state) => ({
      loaded: state.loaded,
      group: state.selectedGroup,
      view: state.selectedView,
      selectedColumns: state.selectedColumns,
      defaultSortColumn: state.defaultSortColumn,
      defaultSortOrder: state.defaultSortOrder,
      labels: state.labels,
      labelsLogic: state.labelsLogic,
      spaces: state.selectedSpaces,
      pagesUnder: state.pagesUnder,
      numberOfItems: state.numberOfItems,
      propertyFilters: state.propertyFilters,
      reportMacroId: state.reportMacroId,
      setPropertyFilters: state.setPropertyFilters,
      setGroup: state.setGroup,
      setLoaded: state.setLoaded,
      setSelectedColumns: state.setSelectedColumns,
      setLabels: state.setLabels,
      setLabelsLogic: state.setLabelsLogic,
      setSelectedSpaces: state.setSelectedSpaces,
      setSelectedView: state.setSelectedView,
      setDefaultSortColumn: state.setDefaultSortColumn,
      setPagesUnder: state.setPagesUnder,
      setCurrentPageId: state.setCurrentPageId,
      setDefaultSortOrder: state.setDefaultSortOrder,
      setNumberOfItems: state.setNumberOfItems,
      addEntriesType: state.addEntriesType,
      setAddEntriesType: state.setAddEntriesType,
    }),
    shallow
  );

  useEffect(() => {
    (async () => {
      const macroData = await loadDataFromMacro();
      if (!macroData) {
        setLoaded(true);
        return;
      }

      window.AP.navigator.getLocation(async (location) => {
        const pageId = location.context.contentId;
        setCurrentPageId(pageId);
        setGroup(macroData.groupId);
        setSelectedView(macroData.selectedView);
        setDefaultSortColumn(macroData.defaultSortColumn);
        setDefaultSortOrder(macroData.defaultSortOrder);
        setSelectedColumns(safelyParseJson(macroData.selectedColumns, []));
        setSelectedSpaces(safelyParseJson(macroData.selectedSpaces, []));
        setNumberOfItems(macroData.numberOfItems || 25);
        const parsedLabels = safelyParseJson(macroData.labels, []);
        setLabels(Array.isArray(parsedLabels) ? { [generateId()]: parsedLabels } : parsedLabels);
        setLabelsLogic(safelyParseJson(macroData.labelsLogic, []));
        setPropertyFilters(safelyParseJson(macroData.propertyFilters, {}));
        setPagesUnder(macroData.pagesUnder);
        setAddEntriesType(macroData.addEntriesType);
        setLoaded(true);
      });
    })();
  }, []);

  const saveMacro = async () => {
    analytics.logEvent(AnalyticsEvent.GROUP_REPORT_CREATED_UPDATED, {
      withAnd: labels.length,
      labelFilterUsed: labels.length > 0 && labels[0].length > 0,
      spaceFilterUsed: spaces.length > 0,
      propertyFilterUsed: Object.keys(propertyFilters).length > 0,
      selectedLayout: view,
    });
    analytics.logEvent(AnalyticsEvent.REPORT_LAYOUT, {
      layout: view,
    });
    window.AP.confluence.saveMacro({
      groupId: group,
      reportMacroId: reportMacroId ? reportMacroId : generateId(),
      selectedColumns: JSON.stringify(selectedColumns),
      labels: JSON.stringify(labels),
      labelsLogic: JSON.stringify(labelsLogic),
      selectedSpaces: JSON.stringify(spaces),
      selectedView: view,
      defaultSortColumn: defaultSortColumn,
      defaultSortOrder: defaultSortOrder,
      numberOfItems: numberOfItems,
      pagesUnder: pagesUnder,
      propertyFilters: JSON.stringify(propertyFilters),
      modified: Date.now(),
      addEntriesType: addEntriesType,
    });
  };

  if (!loaded) {
    return <Spinner />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ModalWrapper>
        <CustomModalHeader title={"Property Group Report"}></CustomModalHeader>
        <CustomModalContent>
          <ReportEditorSidebar />
          {group && (
            <div
              style={{
                overflow: "scroll",
                width: "100%",
                backgroundColor: token("elevation.surface.pressed"),
              }}
            >
              {(view === ReportType.TABLE || !view) && <PropertyGroupReportTable disableEdit />}
              {view === ReportType.CARD && <PropertyGroupReportCard preview />}
            </div>
          )}
          {!group && (
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <EmptyState
                header="You haven't selected any property group yet"
                description="Property groups are collections of properties that act as form fields. You can manage them in the portal."
                imageUrl={NO_PROPERTY_GROUP_SELECTED_OR_EMPTY}
                primaryAction={
                  <Button
                    appearance="primary"
                    href={`${window.AP._hostOrigin}/wiki/plugins/servlet/ac/net.junovi.confluence.properties/properties-overview`}
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    Go to portal
                  </Button>
                }
              />
            </div>
          )}
        </CustomModalContent>
        <CustomModalFooter onSave={saveMacro} saveDisabled={!group} documentationURL="https://junovi.atlassian.net/wiki/x/NADefw" />
      </ModalWrapper>
    </QueryClientProvider>
  );
};

export default PropertyGroupReportMacroEditor;
