import { useState } from "react";
import Textfield from "@atlaskit/textfield";
import Drawer from "@atlaskit/drawer";
import Button from "@atlaskit/button";
import { notify } from "../../Shared/notification-flag";
import { BPCIcon } from "../../Icon/BPCIcon";
import { IconPicker } from "../../Icon/IconPicker";
import { UserOrGroupPicker } from "./UserOrGroupPicker";
import { placeholderSetService } from "../../../Service/PlaceholderSetService";
import { usePlaceholderPortalStore } from "../Store";
import { FieldsDescriptors, FieldTypes } from "../field-types";
import { renderField } from "../render-field";

const FieldNames = [FieldTypes.ICON, FieldTypes.NAME, FieldTypes.DESCRIPTION, FieldTypes.RESTRICTIONS];

export const CreatePlaceholderSetModal = (props) => {
  const { onClose, visible } = props;
  const [icon, setIcon] = useState("ava1");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [groupOwners, setGroupOwners] = useState("");
  const [userOwners, setUserOwners] = useState("");
  const [iconPickerModalVisible, setIconPickerModalVisible] = useState(false);

  const { addPlaceholderSet } = usePlaceholderPortalStore((state) => ({
    addPlaceholderSet: state.addPlaceholderSet,
  }));

  const handleOnIconPickerSubmit = (iconId) => {
    setIcon(iconId);
    setIconPickerModalVisible(false);
  };
  const handleSubmit = async () => {
    const placeholderSet = {
      name,
      description,
      icon,
      groupOwners,
      userOwners,
    };
    if (!placeholderSet.name) {
      notify("error", "A name is required to create a property group");
      return;
    }
    try {
      const { data } = await placeholderSetService.createNewPlaceholderSet(placeholderSet);
      addPlaceholderSet(data);
      notify("success", `${name} added`);
    } catch (error) {
      notify("error", `An error occurred adding ${name}`);
    }
    setIcon("ava1");
    setName("");
    setDescription("");
    setGroupOwners("");
    setUserOwners("");
    onClose();
  };

  const FieldsChildren = {
    [FieldTypes.ICON]: () => (
      <Button spacing="none" appearance="subtle" onClick={() => setIconPickerModalVisible(true)}>
        <span>
          <BPCIcon icon={icon} />
        </span>
      </Button>
    ),
    [FieldTypes.NAME]: ({ fieldProps }, props) => (
      <Textfield
        {...fieldProps}
        {...props}
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter property group name"
      />
    ),
    [FieldTypes.DESCRIPTION]: ({ fieldProps }, props) => (
      <Textfield
        {...fieldProps}
        {...props}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Enter a group description"
      />
    ),
    [FieldTypes.RESTRICTIONS]: ({ fieldProps }, props) => (
      <UserOrGroupPicker
        {...fieldProps}
        {...props}
        initialvalue={groupOwners || userOwners}
        placeholder="Type a user name or group"
        onChangeRestriction={(e) => {
          setGroupOwners(e.groupPermission);
          setUserOwners(e.userPermission);
        }}
        isNewProperty
      />
    ),
  };

  return (
    <div>
      {visible && (
        <Drawer onClose={() => onClose()} isOpen={visible} width="narrow">
          <div style={{ paddingRight: "15px" }}>
            <p style={{ fontSize: "20px", fontWeight: "500", marginTop: "8px" }}>Create property group</p>
            <IconPicker
              onClose={() => setIconPickerModalVisible(false)}
              onSubmit={handleOnIconPickerSubmit}
              visible={iconPickerModalVisible}
            />
            <div>
              {FieldNames.map((field) => {
                return renderField({
                  fieldDescriptor: FieldsDescriptors[field],
                  renderChildren: FieldsChildren[field],
                  aditionalDiv: field === FieldTypes.ICON ? true : false,
                  isRequired: field === FieldTypes.NAME ? true : false,
                });
              })}
            </div>
            <span
              style={{
                textAlign: "center",
                position: "fixed",
                bottom: "0",
                height: "80px",
              }}
            >
              <Button appearance="primary" onClick={() => handleSubmit()} style={{ marginRight: "5px" }}>
                Create
              </Button>
              <Button appearance="default" onClick={() => onClose()}>
                Close
              </Button>
            </span>
          </div>
        </Drawer>
      )}
    </div>
  );
};
