import { PromotionBannerContainer } from "./PlaceholderPortalStyles";
import Banner from "@atlaskit/banner";
const PromotionBanner = () => {
  return (
    <PromotionBannerContainer>
      <Banner appearance="announcement">
        Do you enjoy working with Properties?{" "}
        <a
          href="https://marketplace.atlassian.com/apps/1224369/properties-custom-fields-metadata-databases-reports?hosting=cloud&tab=reviews"
          target="_blank"
          rel="nofollow"
        >
          Leave a review on the Atlassian Marketplace
        </a>{" "}
        and receive a discount on your app license.
      </Banner>
    </PromotionBannerContainer>
  );
};
export default PromotionBanner;
