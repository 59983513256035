import { insertNoDuplicates } from "./insertNoDuplicates";

const getIsAssigned = (record, selectedFieldId, mappedOption) => {
  return (
    record?.values[selectedFieldId]?.value &&
    record?.values[selectedFieldId]?.value === mappedOption?.value &&
    Object.keys(record?.values).includes(selectedFieldId)
  );
};
const getIsUnassigned = (record, selectedFieldId) => {
  return !record?.values[selectedFieldId];
};

export const getPopulatedOptions = ({ options, selectedFieldId, recordData, properties = [] }) => {
  const unassignedRow = {
    value: "unassigned",
    properties: [],
    primaryIds: [],
  };
  const passedRecordIds = [];
  const selectedProperties = { availableProperties: [] };

  try {
    const mappedOptions = options?.map((option) => {
      const mappedOption = { ...option };
      mappedOption.primaryIds = [];

      recordData = recordData.filter((record) => !record.deleted);
      recordData?.forEach((record) => {
        const isAssigned = getIsAssigned(record, selectedFieldId, mappedOption);
        const isUnassigned = getIsUnassigned(record, selectedFieldId);
        const isPassed = mappedOption.primaryIds.includes(record?.recordId + record?.entityId);

        if (record?.values) {
          if (isAssigned) {
            insertNoDuplicates(mappedOption.primaryIds, record.recordId + record.entityId);
            passedRecordIds.push(record.recordId);
          }
          if (isUnassigned && !isPassed) {
            insertNoDuplicates(unassignedRow.primaryIds, record.recordId + record.entityId);
          }
          properties.forEach((property) => {
            const propertyValue = record.values?.[property.value];
            const insertObject = {
              label: property.label,
              value: propertyValue,
              kind: property.kind,
              primaryId: record.recordId + record.entityId,
            };
            propertyValue && insertNoDuplicates(selectedProperties.availableProperties, insertObject, "object");
          });
        }
      });
      return mappedOption;
    });

    mappedOptions?.unshift(unassignedRow);
    mappedOptions?.push(selectedProperties);
    return { populatedOptions: mappedOptions };
  } catch (error) {
    console.log({ error });
    return { populatedOptions: [] };
  }
};
