/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Button from "@atlaskit/button";
import { NavigationContent, SideNavigation, HeadingItem } from "@atlaskit/side-navigation";
import { Field, HelperMessage } from "@atlaskit/form";
import PropertyGroupPicker from "../Shared/PropertyGroupPicker";
import { ChartStrategy } from "./chart-strategy";
import StructureVariantWrapper from "./StructureVariantWrapper";
import SystemPropertiesConfiguration from "./SystemPropertiesConfiguration";
import { ChartVariant } from "./defaults";
import { usePropertyVisualizationStore } from "../property-visualization-store";
import { AnalyticsEvent } from "../../../../analytics-events";
import { analytics } from "../../../../analytics";
import { token } from "@atlaskit/tokens";

const PropertyVisualizationSidebar = (props) => {
  const { config, updateConfig } = usePropertyVisualizationStore((state) => ({
    config: state.config.args,
    updateConfig: state.updateConfig,
  }));

  const onPropertyGroupUpdate = (group) => {
    if (group) {
      updateConfig({ ...config, data: { ...config.data, group: group.id } });
    }
  };

  return (
    <div
      className="content-sidebar"
      style={{
        height: "100%",
        width: "28em",
        borderRight: "1px solid #cccccc",
      }}
    >
      <SideNavigation>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: token("utility.elevation.surface.current"),
          }}
        >
          <NavigationContent
            css={css`
              margin: 0;
              margin-left: 20px;
              margin-right: 20px;
            `}
          >
            <div style={{ margin: "0", marginLeft: "15px", marginRight: "20px" }}>
              <Field label="Enrichment strategy" name="strategy" defaultValue="">
                {({}) => (
                  <div
                    style={{
                      display: "flex",
                      fontSize: "14px",
                    }}
                  >
                    <Button
                      isSelected={config.strategy === ChartStrategy.PROPERTY_GROUP}
                      onClick={() => {
                        updateConfig({ strategy: ChartStrategy.PROPERTY_GROUP });
                        analytics.logEvent(AnalyticsEvent.ENRICHMENT_STRATEGY_USED, { Enrichment_stragy: ChartStrategy.PROPERTY_GROUP });
                      }}
                    >
                      Property Group
                    </Button>
                    <Button
                      isSelected={config.strategy === ChartStrategy.SYSTEM}
                      onClick={() => {
                        updateConfig({ strategy: ChartStrategy.SYSTEM });
                        analytics.logEvent(AnalyticsEvent.ENRICHMENT_STRATEGY_USED, { Enrichment_stragy: ChartStrategy.SYSTEM });
                        if (!config?.data?.args?.assignments || config.data.args.assignments.length === 0) {
                          updateConfig({
                            data: {
                              ...config.data,
                              args: {
                                ...config.data.args,
                                assignments: [{ id: "0", parent: undefined, type: "user" }],
                              },
                            },
                          });
                        }
                      }}
                    >
                      System Properties
                    </Button>
                  </div>
                )}
              </Field>
              {config.strategy === ChartStrategy.PROPERTY_GROUP && (
                <>
                  <Field label="Property Group" name="select group" defaultValue="">
                    {({}) => (
                      <>
                        <PropertyGroupPicker initialValue={config?.data?.group} onUpdate={onPropertyGroupUpdate} />
                        <HelperMessage>Select the property group you want to generate visualizations for</HelperMessage>
                      </>
                    )}
                  </Field>
                  {config?.data?.group && (
                    <>
                      <HeadingItem cssFn={() => ({ paddingLeft: 0 })}>Configuration</HeadingItem>
                      <HelperMessage>
                        Select two properties to visualize the relation of the values. Currently only people properties restricted to a
                        single person and select properties are supported.
                      </HelperMessage>
                      {(!config.data?.variant || config.data.variant === ChartVariant.STRUCTURE) && <StructureVariantWrapper />}
                    </>
                  )}
                </>
              )}
              {config.strategy === ChartStrategy.SYSTEM && (
                <>
                  <HeadingItem cssFn={() => ({ paddingLeft: 0 })}>Configuration</HeadingItem>
                  {!config.data?.args?.people && !config.data?.args?.spaces && (
                    <HelperMessage>
                      <ol style={{ paddingLeft: "15px" }}>
                        <li>Enter a user name or space below. The user or space will be added on the preview on the right side.</li>
                        <li>Click on the user or space on preview. You can then assign more users or spaces to the selected item.</li>
                        <li>
                          After adding more than one user or space you can drag and drop them in the preview to change their relation to
                          each other.
                        </li>
                      </ol>
                    </HelperMessage>
                  )}
                  {(!config.data?.variant || config.data.variant === ChartVariant.STRUCTURE) && <SystemPropertiesConfiguration />}
                </>
              )}
            </div>
          </NavigationContent>
        </div>
      </SideNavigation>
    </div>
  );
};

export default PropertyVisualizationSidebar;
