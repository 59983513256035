import React, { Fragment } from "react";
import { SelectFieldStyles } from "../../../../../Shared/SelectFieldStyles";
import Select from "@atlaskit/select";
import Field from "@atlaskit/form/Field";
import Tooltip from "@atlaskit/tooltip";
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle";
import { usePlaceholderStore } from "../../../../Store";

const DefaultPageField = ({ placeholder, showCurrentPage, fetchedPages, onInputChange, onPageChange }) => {
  const { placeholders, changeDefaultPage } = usePlaceholderStore((state) => ({
    placeholders: state.placeholders,
    changeDefaultPage: state.changeDefaultPage,
  }));
  const p = placeholders.find((p) => p === placeholder) || "";

  const options = fetchedPages?.map((page) => ({
    value: page.title,
    label: page.title,
    id: page.id,
  }));

  const handleOptionChange = (newOption) => {
    changeDefaultPage(placeholder, newOption);
    onPageChange(newOption);
  };

  return (
    <Field label="Set default page" name="default page">
      {({ fieldProps }) => (
        <Fragment>
          <span style={{ display: "inline-flex" }}>
            <span style={{ transform: "translateY(2px)", marginLeft: "3px", marginTop: "15px", cursor: "pointer" }}>
              <Tooltip content="Only one metadata can be chosen per field. If you chose one the other option will not be usable. You can select default page, current page or skip those options">
                <QuestionCircleIcon primaryColor="darkgrey" size="small" />
              </Tooltip>
            </span>
          </span>
          <Select
            {...fieldProps}
            className="select-parent-page default-page-selector"
            isClearable
            isDisabled={!!showCurrentPage}
            styles={SelectFieldStyles}
            value={p.data.defaultPage}
            options={options}
            onInputChange={onInputChange}
            onChange={handleOptionChange}
            placeholder={"Select default page"}
          ></Select>
        </Fragment>
      )}
    </Field>
  );
};

export default DefaultPageField;
