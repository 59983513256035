import React, { useEffect } from "react";
import { VariablesGrid } from "./VariablesGrid";
import "./Variables.scss";
import { token } from "@atlaskit/tokens";

export const Variables = (props) => {
  useEffect(() => {
    window.AP.theming.initializeTheming();
  }, []);
  return (
    <div className="Variables-container" style={{ backgroundColor: token("elevation.surface.overlay.hovered")}}>
      <div className="Variables-container--subcontainer">
        <h3 className="Variables-container--title" style={{ color: token("color.text") }}>
          Properties
        </h3>
        <p className="Variables-container--subtitle" style={{ color: token("color.text") }}>
          Properties capture and display the information people need to carry out your project's work.
        </p>
      </div>
      <div className="Variables-container--subcontainer">
        <p className="Variables-container--title-two" style={{ color: token("color.text") }}>
          Add new property
        </p>
        <p className="Variables-container--subtitle" style={{ color: token("color.text") }}>
          Choose from the following field types:
        </p>
      </div>
      <div className="Variables-container--subcontainer">
        <VariablesGrid />
      </div>
    </div>
  );
};
