import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import shallow from "zustand/shallow";
import { getPropertiesOfGroup } from "../api";
import { PropertyType } from "../../../property-type";
import { usePropertyGroupStore } from "../store";
import Spinner from "@atlaskit/spinner";
import TextPropertyEdit from "../editor/TextPropertyEdit";
import TextPropertyView from "./TextPropertyView";
import InlineEditing from "./InlineEditing";
import { usePropertyFormStore } from "../editor/property-form-store";
import { propertyRecordsService } from "../../../Service/PropertyRecordsService";
import useResizeObserver from "use-resize-observer";
import DatePropertyView from "./DatePropertyView";
import DatePropertyEdit from "../editor/DatePropertyEdit";
import ConfluencePagePropertyView from "./ConfluencePagePropertyView";
import ConfluencePagePropertyEdit from "../editor/ConfluencePagePropertyEdit";
import SelectPropertyView from "./SelectPropertyView";
import SelectPropertyEdit from "../editor/SelectPropertyEdit";
import MultiSelectPropertyView from "./MultiSelectPropertyView";
import MultiSelectPropertyEdit from "../editor/MultiSelectPropertyEdit";
import UserPropertyEdit from "../editor/UserPropertyEdit";
import UserPropertyView from "./UserPropertyView";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import EmailPropertyView from "./EmailPropertyView";
import EmailPropertyEdit from "../editor/EmailPropertyEdit";
import UrlPropertyView from "./UrlPropertyView";
import UrlPropertyEdit from "../editor/UrlPropertyEdit";
import PhonePropertyView from "./PhonePropertyView";
import PhonePropertyEdit from "../editor/PhonePropertyEdit";
import NumberPropertyView from "./NumberPropertyView";
import NumberPropertyEdit from "../editor/NumberPropertyEdit";
import { FieldIcons } from "../../FieldIcons";
import { usePageStore } from "../../page-store";
import { AnalyticsEvent } from "../../../../analytics-events";
import { analytics } from "../../../../analytics";
import Tooltip from "@atlaskit/tooltip";
import { token } from "@atlaskit/tokens";

const PropertyFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${token("elevation.surface.raised")};
  width: 100%;
  margin-bottom: 8px;
`;

const PropertyRow = styled.div`
    width: 70%
    flex: 1 1 auto;
    display: flex; 
    align-items: center;
    >div:first-child {
        margin-top: 2px!important;
        margin-bottom: 2px;
    }
`;
const TooltipWrapper = styled.div`
  > div:first-child {
    display: flex;
    align-items: center;
  }
`;

const PropertyGroupTableView = () => {
  const [responsiveVariant, setResponsiveVariant] = useState(false);
  const [permissionToEditPage, setPermissionToEditPage] = useState(true);

  const { ref: propertyFormRef } = useResizeObserver({
    onResize: ({ width }) => {
      if (width < 500 && !responsiveVariant) {
        setResponsiveVariant(true);
      } else if (width >= 500 && responsiveVariant) {
        setResponsiveVariant(false);
      }
    },
  });

  const { group, recordId, pageId } = usePropertyGroupStore(
    (state) => ({
      group: state.selectedGroup,
      recordId: state.recordId,
      pageId: state.pageId,
    }),
    shallow
  );

  const { values, initialValues, updateValue, setInitialValues } = usePropertyFormStore(
    (state) => ({
      values: state.values,
      initialValues: state.initialValues,
      updateValue: state.updateValue,
      setInitialValues: state.setInitialValues,
    }),
    shallow
  );

  const { isDisplayMode } = usePageStore(
    (state) => ({
      isDisplayMode: state.isDisplayMode,
    }),
    shallow
  );

  useEffect(() => {
    (async () => {
      const context = await window.AP.context.getContext();
      const currentUser = await atlassianRestService.getCurrentUser();
      if (context?.confluence?.macro?.outputType === "display" || context?.confluence?.macro?.outputType === "preview") {
        const userCanEditPage = await atlassianRestService.userHasPermissionToEditPage(
          currentUser.accountId,
          context.confluence.content.id
        );
        setPermissionToEditPage(userCanEditPage);
      }
    })();
  }, []);

  const onSave = async ({ pageId, recordId }) => {
    analytics.logEvent(AnalyticsEvent.GROUP_MACRO_UPDATED_IN_VIEWMODE, {
      page: pageId,
      record: recordId,
      values: JSON.stringify(values[pageId][recordId]),
    });
    const context = await window.AP.context.getContext();
    await propertyRecordsService.createOrUpdateRecord(
      pageId,
      group,
      recordId,
      JSON.stringify(values[pageId][recordId]),
      context.confluence.content.version
    );
    setInitialValues(pageId, recordId, values[pageId][recordId]);
  };

  const onCancel = ({ pageId, recordId, propertyId }) => {
    updateValue({ value: initialValues[pageId]?.[recordId]?.[propertyId], propertyId, recordId, pageId });
  };

  const { isLoading, data } = useQuery(["properties-of-group", { group }], getPropertiesOfGroup);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PropertyFormWrapper innerRef={propertyFormRef}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {Object.keys(data.data).map((key, index) => {
          let currentProperty = data.data[key];
          return (
            <div
              key={index}
              style={{
                display: responsiveVariant ? "block" : "flex",
                width: "100%",
                maxWidth: "700px",
                height: "100%",
                paddingBottom: responsiveVariant ? "10px" : "inherit",
                paddingTop: responsiveVariant ? "5px" : "10px",
              }}
            >
              <span
                style={{
                  flex: "0 1 250px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  lineHeight: "1.3333",
                  fontWeight: "600",
                  marginTop: "2px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: responsiveVariant ? "5px" : "inherit",
                }}
              >
                <TooltipWrapper>
                  <Tooltip content={currentProperty?.data?.description}>
                    <span style={{ marginRight: "12px", top: "1px", display: "inline-block" }}>{FieldIcons(currentProperty.kind)}</span>
                    <span
                      style={{
                        wordWrap: "break-word",
                        width: "160px",
                      }}
                    >
                      {currentProperty.data.name}
                    </span>
                  </Tooltip>
                </TooltipWrapper>
              </span>
              <PropertyRow>
                {currentProperty.kind === PropertyType.TEXT && (
                  <InlineEditing
                    disableEdit={!permissionToEditPage}
                    onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
                    onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
                    View={
                      <TextPropertyView
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                      />
                    }
                    Edit={
                      <TextPropertyEdit
                        autoFocus
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                        onUpdate={(newValue) => {
                          updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
                        }}
                      />
                    }
                  />
                )}
                {currentProperty.kind === PropertyType.URL && (
                  <InlineEditing
                    disableEdit={!permissionToEditPage}
                    onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
                    onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
                    View={
                      <UrlPropertyView
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                      />
                    }
                    Edit={
                      <UrlPropertyEdit
                        autoFocus
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                        onUpdate={(newValue) => {
                          updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
                        }}
                      />
                    }
                  />
                )}
                {currentProperty.kind === PropertyType.CONFLUENCEPAGE && (
                  <InlineEditing
                    disableEdit={!permissionToEditPage || (isDisplayMode && currentProperty.data.displayCurrentPage)}
                    onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
                    onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
                    View={
                      <ConfluencePagePropertyView
                        displayMode={isDisplayMode}
                        pageId={pageId}
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                      />
                    }
                    Edit={
                      <ConfluencePagePropertyEdit
                        autoFocus
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                        onUpdate={(newValue) => {
                          updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
                        }}
                      />
                    }
                  />
                )}
                {currentProperty.kind === PropertyType.DATE && (
                  <InlineEditing
                    disableEdit={
                      !permissionToEditPage ||
                      (isDisplayMode && currentProperty.data.displayLastModified) ||
                      currentProperty.data.displayCreationDate
                    }
                    onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
                    onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
                    View={
                      <DatePropertyView
                        property={currentProperty}
                        displayMode={isDisplayMode}
                        pageId={pageId}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                      />
                    }
                    Edit={
                      <DatePropertyEdit
                        disableEdit={!permissionToEditPage}
                        autoFocus
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                        onUpdate={(newValue) => {
                          updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
                        }}
                      />
                    }
                  />
                )}
                {currentProperty.kind === PropertyType.SELECT && (
                  <InlineEditing
                    disableEdit={!permissionToEditPage}
                    onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
                    onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
                    View={
                      <SelectPropertyView
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                      />
                    }
                    Edit={
                      <SelectPropertyEdit
                        autoFocus
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                        onUpdate={(newValue) => {
                          updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
                        }}
                      />
                    }
                  />
                )}
                {currentProperty.kind === PropertyType.MULTISELECT && (
                  <InlineEditing
                    disableEdit={!permissionToEditPage}
                    onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
                    onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
                    View={
                      <MultiSelectPropertyView
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                      />
                    }
                    Edit={
                      <MultiSelectPropertyEdit
                        autoFocus
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                        onUpdate={(newValue) => {
                          updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
                        }}
                      />
                    }
                  />
                )}
                {currentProperty.kind === PropertyType.USER && (
                  <InlineEditing
                    disableEdit={
                      !permissionToEditPage ||
                      (isDisplayMode && currentProperty.data.displayPageCreator) ||
                      currentProperty.data.displayLastModifier
                    }
                    onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
                    onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
                    View={
                      <UserPropertyView
                        property={currentProperty}
                        displayMode={isDisplayMode}
                        pageId={pageId}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                      />
                    }
                    Edit={
                      <UserPropertyEdit
                        autoFocus
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                        onUpdate={(newValue) => {
                          updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
                        }}
                      />
                    }
                  />
                )}
                {currentProperty.kind === PropertyType.EMAIL && (
                  <InlineEditing
                    disableEdit={!permissionToEditPage}
                    onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
                    onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
                    View={
                      <EmailPropertyView
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                      />
                    }
                    Edit={
                      <EmailPropertyEdit
                        autoFocus
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                        onUpdate={(newValue) => {
                          updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
                        }}
                      />
                    }
                  />
                )}
                {currentProperty.kind === PropertyType.PHONE && (
                  <InlineEditing
                    disableEdit={!permissionToEditPage}
                    onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
                    onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
                    View={
                      <PhonePropertyView
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                      />
                    }
                    Edit={
                      <PhonePropertyEdit
                        autoFocus
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                        onUpdate={(newValue) => {
                          updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
                        }}
                      />
                    }
                  />
                )}
                {currentProperty.kind === PropertyType.NUMBER && (
                  <InlineEditing
                    disableEdit={!permissionToEditPage || currentProperty.data.showCurrentPageVersion}
                    onSave={() => onSave({ pageId, recordId, propertyId: currentProperty.id })}
                    onCancel={() => onCancel({ pageId, recordId, propertyId: currentProperty.id })}
                    View={
                      <NumberPropertyView
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                        pageId={pageId}
                      />
                    }
                    Edit={
                      <NumberPropertyEdit
                        autoFocus
                        property={currentProperty}
                        initialValue={values[pageId]?.[recordId]?.[currentProperty.id] ?? undefined}
                        onUpdate={(newValue) => {
                          updateValue({ value: newValue, pageId, propertyId: currentProperty.id, recordId });
                        }}
                      />
                    }
                  />
                )}
              </PropertyRow>
            </div>
          );
        })}
      </div>
    </PropertyFormWrapper>
  );
};

export default PropertyGroupTableView;
