import {
  ButtonItem,
  Footer,
  Header,
  NavigationContent,
  NavigationFooter,
  Section,
  LinkItem,
  SideNavigation,
} from "@atlaskit/side-navigation";
import AddIcon from "@atlaskit/icon/glyph/add";
import EditorHelpIcon from "@atlaskit/icon/glyph/editor/help";
import LightbulbIcon from "@atlaskit/icon/glyph/lightbulb";
import { BPCIcon } from "../../Icon/BPCIcon";
import { usePlaceholderPortalStore } from "../Store";
import { PortalPropertySidebar, PortalSidebarFooter, SidebarNavContainer } from "../PlaceholderPortalStyles";
import { token } from "@atlaskit/tokens";

const footerCssFn = (defaultStyles) => {
  return {
    ...defaultStyles,
    "& h2": {
      textAlign: "center",
    },
  };
};

const renderLinkItem = (href, text, IconComponent) => {
  return (
    <LinkItem
      target="_blank"
      href={href}
      iconBefore={<IconComponent />}
      cssFn={(defaultStyles) => {
        return {
          ...defaultStyles,
          textDecoration: "none",
        };
      }}
    >
      {text}
    </LinkItem>
  );
};
export const Sidebar = ({ onAddNewPlaceholder, onChangeGroup }) => {
  const { groups, selectedPlaceholderSet, setSelectedPlaceholderSet, validLicense } = usePlaceholderPortalStore((state) => ({
    groups: state.groups,
    selectedPlaceholderSet: state.selectedPlaceholderSet,
    setSelectedPlaceholderSet: state.setSelectedPlaceholderSet,
    validLicense: state.validLicense,
  }));
  const AddNewPlaceholderSetButton = () => (
    <ButtonItem
      iconBefore={<AddIcon label="" />}
      onClick={onAddNewPlaceholder}
      style={{ backgroundColor: token("elevation.surface.overlay.hovered") }}
    >
      New property group
    </ButtonItem>
  );

  return (
    <div style={{ width: "unset", height: window.innerHeight }}>
      <SidebarNavContainer>
        <SideNavigation label="project">
          <NavigationContent>
            <Section hasSeparator>
              <PortalPropertySidebar>
                <Header>Property Groups</Header>
                {groups.map((item, idx) => (
                  <ButtonItem
                    isSelected={selectedPlaceholderSet?.id === item.id}
                    iconBefore={<BPCIcon icon={item.icon} size="24" />}
                    key={idx}
                    onClick={() => {
                      setSelectedPlaceholderSet(item);
                      onChangeGroup();
                    }}
                  >
                    {item.name}
                  </ButtonItem>
                ))}
              </PortalPropertySidebar>
            </Section>
            <Section hasSeparator>{validLicense && AddNewPlaceholderSetButton()}</Section>
          </NavigationContent>
          <NavigationFooter>
            <PortalSidebarFooter>
              <Footer cssFn={footerCssFn}>
                <img width="40" height="40" src="/propertiesicon.jpg"></img>
              </Footer>
              <Section>
                {renderLinkItem("https://junovi.atlassian.net/servicedesk/customer/portals", "Get help", EditorHelpIcon)}
                {renderLinkItem("https://junovi.atlassian.net/wiki/x/FwDhfw", "Learn more", LightbulbIcon)}
              </Section>
            </PortalSidebarFooter>
          </NavigationFooter>
        </SideNavigation>
      </SidebarNavContainer>
    </div>
  );
};
