import styled from "styled-components";
import SelectOptions from "./SelectOptions";

const EmptySelect = styled.span`
  color: rgb(107, 119, 140);
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
`;
const MultiSelectPropertyView = ({ property, initialValue }) => {
  const optionsSource = property?.value || property?.data?.options;
  const getCorrectInitialValue = () => {
    const defaultOption = optionsSource?.filter((o) => o?.isDefault);
    if (initialValue) {
      const correctOption = initialValue?.filter((o) =>
        optionsSource?.map((p) => {
          if (p?.value === o?.value) {
            o.color = p?.color;
            return p;
          }
        })
      );
      return correctOption;
    }
    if (defaultOption) {
      return defaultOption;
    }
    return undefined;
  };

  const correctValue = getCorrectInitialValue();

  if (correctValue && correctValue?.length > 0) {
    return <SelectOptions values={getCorrectInitialValue()} property={property} />;
  }

  if (property?.data?.description) {
    return (
      <EmptySelect data-sortable={true} data-raw-value="">
        {property?.data?.description}
      </EmptySelect>
    );
  }
  return (
    <EmptySelect data-sortable={true} data-raw-value="">
      Select a value
    </EmptySelect>
  );
};

export default MultiSelectPropertyView;
