import styled from "styled-components";
import { token } from "@atlaskit/tokens";

const PlaceholderPortalContainer = styled.div`
  background-color: ${token("elevation.surface.overlay.pressed")};
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

const PortalContainer = styled.div`
  display: flex;
  height: 100vh;
  margin-right: 320px;
  flex-grow: 1;
  background-color: ${token("elevation.surface")};
`;
const PromotionBannerContainer = styled.div`
  height: 48px;
  display: flex;
  font-size: 14px;
  > div {
    flex: 1 1 0%;
  }
`;
const SidebarNavContainer = styled.div`
  > nav {
    background-color: ${token("elevation.surface.overlay.hovered")} !important;
    height: 100vh;
  }
`;
const EmptyPortalSidebarContainer = styled.div`
  display: flex;
  min-width: 412px;
  flex-grow: 1;
`;

const EmptyPortalPlaceholder = styled.div`
  height: 500px;
  width: 99%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
`;
const PortalPropertySidebar = styled.div`
  > div {
    background-color: ${token("elevation.surface.overlay.hovered")} !important;
  }
  > button:hover {
    background-color: ${token("elevation.surface.overlay.pressed")} !important;
  }
  > button {
    background-color: ${token("elevation.surface.overlay.hovered")} !important;
  }
`;
const PortalSidebarFooter = styled.div`
  > div {
    background-color: ${token("elevation.surface.overlay.hovered")} !important;
    > a {
      background-color: ${token("elevation.surface.overlay.hovered")} !important;
    }
    > a:hover {
      background-color: ${token("elevation.surface.overlay.pressed")} !important;
    }
  }
`;
export {
  PortalContainer,
  PromotionBannerContainer,
  SidebarNavContainer,
  EmptyPortalSidebarContainer,
  EmptyPortalPlaceholder,
  PlaceholderPortalContainer,
  PortalPropertySidebar,
  PortalSidebarFooter,
};
