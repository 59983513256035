import { useEffect } from "react";
import { useQuery } from "react-query";
import { getFieldFromGroup, getRecordsForGroup } from "../api";
import { getPopulatedOptions } from "./getPopulatedOptions";

const patchResponseObject = (obj) => {
  const values = obj?.values || {};

  const [idxOfStringValue, valueString] =
    Object.values(values)
      .filter((value) => typeof value === "string")
      .map((value) => [Object.values(values).indexOf(value), value])[0] || [];

  const key = Object.keys(values)[idxOfStringValue];

  const valueModified =
    idxOfStringValue !== undefined
      ? {
          label: valueString,
          value: valueString,
          color: "#DFE1E6",
        }
      : {};

  return {
    ...obj,
    entityId: String(obj.entityId),
    values: {
      ...values,
      ...(idxOfStringValue !== undefined && { [key]: valueModified }),
    },
  };
};

const useBoardData = ({ groupId, fieldId, selectedProperties, setStoredData }) => {
  const { isLoading: loadingSelectField, data: selectedField } = useQuery(["select-field", { groupId, fieldId }], getFieldFromGroup, {
    enabled: !!groupId && !!fieldId,
    select(response) {
      return response.data;
    },
  });

  const {
    isLoading: loadingGroupRecords,
    data: groupRecords,
    refetch,
  } = useQuery(["records-of-group", { group: groupId }], getRecordsForGroup, {
    enabled: !!selectedField,
    select(response) {
      response.data = response?.data?.map((obj) => {
        return patchResponseObject(obj);
      });
      try {
        const selectOptions = selectedField?.data?.options;
        const selectedFieldId = selectedField?.id;
        const { populatedOptions } = getPopulatedOptions({
          options: selectOptions,
          recordData: response?.data.filter((record) => !record.deleted),
          selectedFieldId,
          properties: selectedProperties,
        });
        return populatedOptions;
      } catch (error) {
        console.log({ error });
        return [];
      }
    },
  });

  useEffect(() => {
    if (groupRecords?.length) {
      setTimeout(() => {
        setStoredData(groupRecords);
      }, 100);
    }
  }, [groupRecords]);

  useEffect(() => {
    setStoredData(groupRecords);
  }, [selectedField, selectedProperties]);

  return {
    isLoading: loadingGroupRecords,
    selectFieldLoading: loadingSelectField,
    selectedField,
    data: groupRecords,
    refetch,
  };
};

export default useBoardData;
