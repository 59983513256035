import { useState, useEffect } from "react";
import styled from "styled-components";
import { atlassianRestService } from "../../../Service/AtlassianRestService";
import { useDrag, useDrop } from "react-dnd";
import { forwardRef } from "react";

const NodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: ${({ selected }) => (selected ? "1px solid #f3f7ff" : "1px solid #cccc")};
  border-radius: 7px;
  margin-bottom: 5px;
  margin: 0 auto;
  width: 150px;
  cursor: pointer;
`;

function mapUser(user) {
  return {
    avatarUrl: atlassianRestService.getProfilePictureUrl(user?.profilePicture.path),
    id: user?.accountId,
    name: user?.publicName,
    type: "user",
  };
}

const UserWithAvatar = ({ user }) => {
  return (
    <>
      <span
        role="img"
        style={{
          backgroundImage: "url(" + user.avatarUrl + ")",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "50%",
          width: "25px",
          height: "25px",
          display: "flex",
        }}
      ></span>
      <span style={{ marginLeft: "10px" }}>
        <span style={{ display: "flex", flexDirection: "column" }}>{user.name}</span>
      </span>
    </>
  );
};

export const DraggableDroppableLazyLoadingUser = ({ accountId, selected = false, onClick = () => {}, onDrop = () => {}, onDragStart }) => {
  const [, drop] = useDrop(() => ({
    accept: ["user", "space"],
    drop: () => ({ id: accountId }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [, drag] = useDrag({
    type: "user",
    item: () => {
      onDragStart();
      return { id: accountId, type: "user" };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDrop(item.id, dropResult.id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  function attachRef(el) {
    drag(el);
    drop(el);
  }

  return <LazyLoadingUser ref={attachRef} accountId={accountId} selected={selected} onClick={onClick} />;
};

const LazyLoadingUser = forwardRef(({ accountId, selected = false, onClick = () => {} }, ref) => {
  const [userInfo, setUserInfo] = useState(undefined);

  useEffect(() => {
    (async () => {
      const result = await atlassianRestService.getUserByAccountId(accountId);
      setUserInfo(mapUser(result));
    })();
  }, []);

  if (!userInfo) {
    return <NodeWrapper>Loading</NodeWrapper>;
  }

  return (
    <NodeWrapper innerRef={ref} onClick={onClick} selected={selected}>
      <UserWithAvatar user={userInfo} />
    </NodeWrapper>
  );
});

export default LazyLoadingUser;
