import create from "zustand";
import setObject from "lodash.set";

export const usePropertyFormStore = create((set, get) => ({
  values: {},
  initialValues: undefined,
  hasChanged: false,
  isTemplate: false,
  setHasChanged: (changed) => set((state) => ({ hasChanged: changed })),
  setIsTemplate: (isTemplate) =>
    set((state) => {
      if (typeof isTemplate === "string") {
        return { isTemplate: isTemplate === "true" };
      }
      return { isTemplate };
    }),
  updateValue: ({ value, pageId, propertyId, recordId }) => {
    let updatedValues = {};
    if (!pageId && !recordId) {
      updatedValues = setObject({ ...get().values }, [propertyId], value ?? "");
    } else {
      updatedValues = setObject({ ...get().values }, [pageId, recordId, propertyId], value ?? "");
    }
    return set((state) => ({ values: updatedValues, hasChanged: true }));
  },
  setValues: (newValues) => set((state) => ({ values: newValues })),
  setInitialValues: (entityId, recordId, values) => {
    return set((state) => ({
      initialValues: setObject({}, [entityId, recordId], { ...values }),
    }));
  },
}));
