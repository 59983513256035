import styled from "styled-components";
import { token } from "@atlaskit/tokens";

const OptionsListContainer = styled.div`
  display: flex;
  justify-content: space-around;
  border: solid;
  border-color: ${token("elevation.surface.raised")};
  border-radius: 3px;
  width: 100%;
  align-items: center;
  background-color: ${token("elevation.surface.overlay")};
  overflow: hidden;
  margin-bottom: 4px;
  height: 36px;
`;

const OptionsListPTag = styled.p`
  margin-right: 10px;
  font-size: 14px;
`;

const OptionsListInput = styled.input`
  border: none;
  background-color: ${token("elevation.surface.overlay")};
  outline: none;
  width: 100%;
`;

export { OptionsListContainer, OptionsListPTag, OptionsListInput };
