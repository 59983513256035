import { useState, useEffect } from "react";
import "./card.css";
import Page24Icon from "@atlaskit/icon-object/glyph/page/24";
import { SkeletonItem } from "@atlaskit/side-navigation";
import { atlassianRestService } from "../../Service/AtlassianRestService";
import { token } from "@atlaskit/tokens";

const Card = ({ entityId, entries, sortIdentifier }) => {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    (async () => {
      const pageResponse = await atlassianRestService.getPageById(entityId);
      setPageData({ title: pageResponse.title, url: pageResponse._links.base + pageResponse._links.webui });
      setLoading(false);
    })();
  }, [entityId]);

  return (
    <div>
      <a className="card-link" href={pageData.url} target="_parent" style={{ color: token("color.text") }}>
        <div className="property-card" data-sort-identifier={sortIdentifier}>
          <div className="property-card-grid-view" style={{ backgroundColor: token("elevation.surface.overlay") }}>
            {!loading && (
              <span role="img" aria-label="Page" className="page-icon">
                <Page24Icon />
                <span className="page-title">{pageData.title}</span>
              </span>
            )}
            {loading && <SkeletonItem width={200} isShimmering />}
            {entries.map((entry, index) => {
              return (
                <div data-property-key={entry.key}>
                  <div className="property-rows">
                    <span className="property-name">
                      <span>{entry.info.data.name}</span>
                    </span>
                  </div>
                  <div>
                    <span>{entry.content}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </a>
    </div>
  );
};
export default Card;
