import BaseRestService from "./BaseRestService";
import { placeholderSetRunUrl } from "../../cloud-run-config";

class PlaceholderSetService extends BaseRestService {
  placeholderSetPath = placeholderSetRunUrl + "/api/sets";

  async createNewPlaceholderSet(placeholderSet) {
    const url = `${this.placeholderSetPath}`;
    return this.client.post(url, placeholderSet, this.buildJWTAuthAxiosConfig());
  }

  async hasUserPermissionToEditPortal(placeholderSetId) {
    const url = `${this.placeholderSetPath}/${placeholderSetId}`;
    return this.client.get(url, this.buildJWTAuthAxiosConfig());
  }

  async editPlaceholderSet(placeholderSetId, placeholderSet) {
    const url = `${this.placeholderSetPath}/${placeholderSetId}`;
    return this.client.put(url, placeholderSet, this.buildJWTAuthAxiosConfig());
  }

  async getAllPlaceholderSets() {
    const url = `${this.placeholderSetPath}`;
    return this.client.get(url, this.buildJWTAuthAxiosConfig());
  }

  async deletePlaceholderSet(id) {
    const url = `${this.placeholderSetPath}/${id}`;
    return this.client.delete(url, this.buildJWTAuthAxiosConfig());
  }
}

export const placeholderSetService = new PlaceholderSetService();
