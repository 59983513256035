const invalidProtocolRegex = /^(%20|\s)*(javascript|data|vbscript)/im;
const ctrlCharactersRegex = /[^\x20-\x7EÀ-ž]/gim;
const urlSchemeRegex = /^([^:]+):/gm;
const relativeFirstCharacters = [".", "/"];

export const urlBasicRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/?.*$/;
function isRelativeUrlWithoutProtocol(url) {
  return relativeFirstCharacters.indexOf(url[0]) > -1;
}

export function sanitizeUrl(url, messageWhenInvalid = "about:blank") {
  if (!url || url.trim().length === 0) {
    return "";
  }

  const sanitizedUrl = url.replace(ctrlCharactersRegex, "").trim();

  if (isRelativeUrlWithoutProtocol(sanitizedUrl)) {
    return sanitizedUrl;
  }

  const urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);

  if (!urlSchemeParseResults) {
    return sanitizedUrl;
  }

  const urlScheme = urlSchemeParseResults[0];

  if (invalidProtocolRegex.test(urlScheme)) {
    return messageWhenInvalid;
  }

  return sanitizedUrl;
}
