import { useEffect, useState } from "react";
import { usePropertyGroupReportMacroStore } from "./store";
import { CreatableSelect } from "@atlaskit/select";
import { HelperMessage } from "@atlaskit/form";
import Button from "@atlaskit/button";
import { generateId } from "../../../v2/id-generator";
import { SelectFieldStyles } from "../Shared/SelectFieldStyles";
import { AnalyticsEvent } from "../../../analytics-events";
import { analytics } from "../../../analytics";
import { token } from "@atlaskit/tokens";

const ReportLabelFilter = (props) => {
  const { labels, removeLabelsWithIndex, setLabelsWithIndex, labelsLogic, setLabelsLogic } = usePropertyGroupReportMacroStore((state) => ({
    labels: state.labels,
    removeLabelsWithIndex: state.removeLabelsWithIndex,
    setLabelsWithIndex: state.setLabelsWithIndex,
    labelsLogic: state.labelsLogic,
    setLabelsLogic: state.setLabelsLogic,
  }));
  const [logicOperations, setLogicOperations] = useState([]);

  const toggleLogic = (idx) => {
    const updatedLogic = [...logicOperations];
    updatedLogic[idx] = updatedLogic[idx] === "AND" ? "OR" : "AND";
    setLogicOperations(updatedLogic);
  };

  useEffect(() => {
    if (!labels || Object.keys(labels).length === 0) {
      const labelIdx = generateId();
      setLabelsWithIndex([], labelIdx);
    }
    if (logicOperations.length === 0) {
      const extractedLogic = labelsLogic.filter((_, idx) => {
        return idx % 2 != 0;
      });
      setLogicOperations(extractedLogic);
    }
  }, []);

  useEffect(() => {
    const labelKeys = Object.keys(labels);
    const newLabelLogic = labelKeys.reduce((acc, curr, idx) => {
      acc.push(curr, logicOperations[idx] || "AND");

      return acc;
    }, []);
    if (labelKeys.length > 0) {
      setLabelsLogic(newLabelLogic);
    }
  }, [labels, logicOperations]);

  const addLabelsToStore = () => {
    const idx = generateId();
    setLabelsWithIndex([], idx);
    setLogicOperations([...logicOperations, "AND"]);
  };
  const removeLabelsFromStore = (idx, key, idxInLogicOperations) => {
    let newLogicOperations = [...logicOperations];
    newLogicOperations[idxInLogicOperations] = "AND";
    setLogicOperations(newLogicOperations.filter((_, i) => i !== idxInLogicOperations + 1));
    removeLabelsWithIndex(key);
  };

  return (
    <>
      <Button
        appearance="link"
        onClick={() => {
          addLabelsToStore();
        }}
        style={{
          fontSize: "12px",
          padding: "0",
          float: "right",
          lineHeight: "1.8",
          height: "15px",
        }}
      >
        Add
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {Object.keys(labels).map((key, index, arr) => {
          const idxInLogicOperations = logicOperations.indexOf(arr[index]);
          return (
            <div style={{ marginTop: "5px" }}>
              {index > 0 && (
                <h6
                  onClick={() => {
                    toggleLogic(index - 1);
                  }}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    lineHeight: "0.1em",
                    margin: "5px 0 10px",
                    fontWeight: "500",
                    color: "#6B778C",
                    cursor: "pointer",
                  }}
                >
                  {logicOperations[index - 1] === "OR" ? "OR" : "AND"}
                </h6>
              )}
              <></>
              <LabelPicker targetIdx={key} />
              {index === Object.keys(labels).length - 1 ? <HelperMessage>Returns pages with these labels</HelperMessage> : <></>}
              {index > 0 && (
                <Button
                  appearance="link"
                  onClick={() => removeLabelsFromStore(index, key, idxInLogicOperations)}
                  style={{
                    fontSize: "12px",
                    padding: "0",
                    float: "right",
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const LabelPicker = ({ targetIdx }) => {
  const { labels, setLabelsWithIndex } = usePropertyGroupReportMacroStore((state) => ({
    labels: state.labels,
    setLabelsWithIndex: state.setLabelsWithIndex,
  }));
  const [selectedLabels, setSelectedLabels] = useState([]);

  useEffect(() => {
    if (Object.keys(labels).length > 0) {
      setSelectedLabels(labels[targetIdx].map((labelFromStore) => ({ label: labelFromStore, value: labelFromStore })));
    }
  }, []);

  const createOption = (input) => ({
    label: input,
    value: input,
  });

  const saveLabel = (newLabel) => {
    const labelsToAdd = [...selectedLabels];
    if (newLabel === "") {
      return null;
    }
    if (newLabel.includes(" ")) {
      let newLabels = newLabel.split(/(\s+)/).filter((e) => e.trim().length > 0);
      labelsToAdd.push(...[newLabels.map((l) => createOption(l.toLowerCase()))]);
    } else {
      labelsToAdd.push(createOption(newLabel.toLowerCase()));
    }
    const optionLabels = labelsToAdd.map((element) => element.label);
    if (optionLabels.length > 0) {
      analytics.logEvent(AnalyticsEvent.LABEL_FILTER_USED, { label_filter_used: true });
    }
    setLabelsWithIndex(optionLabels, targetIdx);
    setSelectedLabels(labelsToAdd);
  };

  return (
    <CreatableSelect
      isMulti
      isClearable
      styles={SelectFieldStyles && { control: (base) => ({ ...base, backgroundColor: token("elevation.surface.overlay"), fontSize: "14px" }) }}
      placeholder="Filter by label"
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Menu: () => null,
      }}
      onBlur={(addedLabel) => {
        const addedLabelValue = addedLabel.target.value;
        saveLabel(addedLabelValue);
      }}
      formatCreateLabel={() => null}
      isValidNewOption={() => true}
      onChange={(updatedLabels) => {
        setSelectedLabels(updatedLabels);
        const storeUpdatedlabels = updatedLabels.map((l) => l.label);
        setLabelsWithIndex(storeUpdatedlabels, targetIdx);
      }}
      onCreateOption={(newLabel) => {
        saveLabel(newLabel);
      }}
      value={selectedLabels}
    />
  );
};

export default ReportLabelFilter;
