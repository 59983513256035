import BaseRestService from "./BaseRestService";
import { placeholderCloudRunUrl } from "../../cloud-run-config";

class PropertyRecordsService extends BaseRestService {
  placeholderPath = placeholderCloudRunUrl + "/api/records";
  reportPath = placeholderCloudRunUrl + "/api/reports";
  async createOrUpdateRecord(pageId, groupId, recordId, values, pageVersion, draft = false, isTemplate = false) {
    const url = `${this.placeholderPath}/properties-record`;
    const data = {
      pageId,
      recordId,
      groupId,
      values,
      pageVersion,
      draft,
      isTemplate,
    };
    return this.client.post(url, data, this.buildJWTAuthAxiosConfig());
  }

  async getRecord(pageId, groupId, recordId) {
    const url = `${this.placeholderPath}/properties-record`;
    return this.client.get(url, { params: { pageId, groupId, recordId }, ...this.buildJWTAuthAxiosConfig() });
  }

  async getReport(groupId, labels, spaces) {
    const url = `${this.reportPath}`;
    const allLabels = [];

    const labelsAsList = Array.isArray(labels);
    if (labelsAsList) {
      labels.forEach((labelGroup) => {
        allLabels.push(labelGroup.join(","));
      });
    } else {
      const labelGroups = Object.values(labels).filter((x) => x.length > 0);
      allLabels.push(...labelGroups.map((group) => group.join(",")));
    }
    return this.client.get(url, {
      params: { groupId, labels: allLabels.join(";"), spaces: spaces.join(",") },
      ...this.buildJWTAuthAxiosConfig(),
    });
  }

  async getLogForRecord(entityId, recordId, startAfter) {
    const url = `${this.placeholderPath}/properties-record/page/${entityId}/record/${recordId}/log?startAfter=${startAfter}`;
    return this.client.get(url, this.buildJWTAuthAxiosConfig());
  }
}

export const propertyRecordsService = new PropertyRecordsService();
