import Linkify from "linkify-react";
import styled from "styled-components";

const EmptyText = styled.span`
  color: rgb(107, 119, 140);
  display: inline-block;
  margin-left: 5px;
  font-size: 14px;
`;

const TextPropertyView = ({ property, initialValue }) => {
  const linkProps = {
    onClick: (event) => {
      event.stopPropagation();
    },
  };


  if (initialValue) {
    return (
      <Linkify tagName="span" options={{ attributes: linkProps, target: "_blank" }} data-sortable={true} data-raw-value={initialValue}>
        <span
          style={{
            display: "inline-block",
            overflowWrap: "anywhere",
            paddingRight: "2px",
            marginLeft: "5px",
            fontSize: "14px",
          }}
        >
          {initialValue}
        </span>
      </Linkify>
    );
  }

  if (property?.data?.description) {
    return (
      <EmptyText data-sortable={true} data-raw-value="">
        {property?.data?.description}
      </EmptyText>
    );
  }
  return (
    <EmptyText data-sortable={true} data-raw-value="">
      Enter text here
    </EmptyText>
  );
};

export default TextPropertyView;
