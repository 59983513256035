import { useState } from "react";
import { HeadingItem } from "@atlaskit/side-navigation";

import ReportViewModePicker from "./ReportViewModePicker";
import SelectedColumnsPicker from "./SelectedColumnsPicker";
import DefaultSortPicker from "./DefaultSortPicker";
import Button from "@atlaskit/button";
import { Field, HelperMessage } from "@atlaskit/form";
import NumberOfItemsPagination from "./NumberOfItemsPagination";
import AddEntriesTypePicker from "./AddEntriesTypePicker";

const ReportDisplayOptions = () => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <HeadingItem cssFn={() => ({ paddingLeft: 0, flex: 1 })}>Display Options</HeadingItem>
        <Button
          appearance="link"
          onClick={() => {
            setShowOptions(showOptions ? false : true);
          }}
          style={{
            fontSize: "12px",
            padding: "0",
            lineHeight: "1.5",
            height: "16px",
            marginTop: "10px",
          }}
        >
          {showOptions ? "Hide" : "Show"}
        </Button>
      </div>

      {showOptions && (
        <>
          <>
            <ReportViewModePicker />
            <HelperMessage> Choose a layout for the report</HelperMessage>
          </>
          <Field label="Displayed properties" name="select properties" defaultValue="">
            {({}) => (
              <>
                <SelectedColumnsPicker />
                <HelperMessage>Select which properties are to be displayed in the report</HelperMessage>
              </>
            )}
          </Field>
          <Field label="Sort by" name="select sort order" defaultValue="">
            {() => (
              <>
                <DefaultSortPicker />
                <HelperMessage>By default the pages will sort by last modified date of the page</HelperMessage>
              </>
            )}
          </Field>
          <Field label="Add Entries" name="select type of adding entries" defaultValue="">
            {() => (
              <>
                <AddEntriesTypePicker />
                <HelperMessage>By default the pages will add new entries using inline form</HelperMessage>
              </>
            )}
          </Field>
          <Field label="Limit" name="limit pagination" defaultValue="">
            {() => (
              <>
                <NumberOfItemsPagination />
                <HelperMessage>Set a limit how many records are shown. Maximum allowed number is 50.</HelperMessage>
              </>
            )}
          </Field>
        </>
      )}
    </>
  );
};

export default ReportDisplayOptions;
