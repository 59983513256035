import create from "zustand";
import setObject from "lodash.set";

export const usePropertyGroupReportFormStore = create((set, get) => ({
  values: {},
  initialValues: undefined,
  hasChanged: false,
  contentIdsNotAllowedToEdit: [],
  setHasChanged: (changed) => set((state) => ({ hasChanged: changed })),
  updateValue: ({ value, pageId, propertyId, recordId }) => {
    const updatedValues = setObject({ ...get().values }, [pageId, recordId, propertyId], value);
    return set((state) => ({ values: updatedValues, hasChanged: true }));
  },
  setValues: (newValues) => set((state) => ({ values: newValues })),
  addContentIdNotAllowedToEdit: (contentId) => {
    if (get().contentIdsNotAllowedToEdit.includes(contentId)) {
      return;
    }
    return set((state) => ({ contentIdsNotAllowedToEdit: [...get().contentIdsNotAllowedToEdit, contentId] }));
  },
  setInitialValues: (values) =>
    set((state) => ({
      initialValues: JSON.parse(JSON.stringify(values)),
    })),
}));
