/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import shallow from "zustand/shallow";
import { AnalyticsEvent } from "../../../analytics-events";
import { loadDataFromMacro } from "../../macro-service";
import { initResizeObserver } from "../../resize-service";
import { getRecordWithQuery } from "./api";
import { usePropertyFormStore } from "./editor/property-form-store";
import { usePropertyGroupStore } from "./store";
import Spinner from "@atlaskit/spinner";
import { propertyRecordsService } from "../../Service/PropertyRecordsService";
import EmptyState from "@atlaskit/empty-state";
import { NO_PROPERTY_GROUP_SELECTED_OR_EMPTY } from "../../../v2/empty-states-resources";
import PropertyGroupTableView from "./view/PropertyGroupTableView";
import { FormattedDate } from "../FormattedDate";
import Button from "@atlaskit/button";
import EmojiFrequentIcon from "@atlaskit/icon/glyph/emoji/frequent";
import { atlassianRestService } from "../../Service/AtlassianRestService";
import { useContextStore } from "../context-store";
import { usePageStore } from "../page-store";
import { analytics } from "../../../analytics";

const PropertyGroupMacro = ({}) => {
  const [isReadMode, setIsReadMode] = useState(false);

  const { pageId, recordId, group, entityId, setGroup, setRecordId, setPageId, setEntityId, setLastModified } = usePropertyGroupStore(
    (state) => ({
      loaded: state.loaded,
      setLoaded: state.setLoaded,
      setData: state.setData,
      setRecordId: state.setRecordId,
      setPageId: state.setPageId,
      setGroup: state.setGroup,
      setEntityId: state.setEntityId,
      group: state.selectedGroup,
      pageId: state.pageId,
      entityId: state.entityId,
      recordId: state.recordId,
      setLastModified: state.setLastModified,
    }),
    shallow
  );

  const { setIsDisplayMode } = usePageStore(
    (state) => ({
      setIsDisplayMode: state.setIsDisplayMode,
    }),
    shallow
  );

  const { setInitialValues, setValues, isTemplate, setIsTemplate } = usePropertyFormStore((state) => ({
    setValues: state.setValues,
    setInitialValues: state.setInitialValues,
    setIsTemplate: state.setIsTemplate,
    isTemplate: state.isTemplate,
  }));

  const { setAccountId } = useContextStore(
    (state) => ({
      setAccountId: state.setAccountId,
    }),
    shallow
  );

  useEffect(() => {
    initResizeObserver();
  }, []);

  useEffect(() => {
    if (pageId) {
      analytics.logEvent(AnalyticsEvent.GROUP_MACRO_LOADED, {
        pageIdOfGroup: pageId,
        selectedGroup: group,
        recordId: recordId,
        entityId: entityId,
      });
    }
  }, [pageId]);

  useEffect(() => {
    (async () => {
      const macroData = await loadDataFromMacro();
      const context = await window.AP.context.getContext();
      window.AP.navigator.getLocation(async (location) => {
        const pageId = context.confluence?.content?.id;
        if (location.context.contentType === "template") {
          setIsTemplate(true);
        } else {
          setIsTemplate(false);
        }
        if (macroData.groupId && macroData.recordId) {
          if (context?.confluence?.macro?.outputType === "display") {
            setIsReadMode(true);
            setIsDisplayMode(true);
          }
          setGroup(macroData.groupId);
          setEntityId(macroData.entityId);
          setRecordId(macroData.recordId);
          setPageId(pageId);
        }
        const currentUser = await atlassianRestService.getCurrentUser();
        if (currentUser && currentUser.type === "known") {
          setAccountId(currentUser.accountId);
        }
      });
    })();
  }, []);

  const { isIdle, isLoading, isError } = useQuery(
    ["property-record", { groupId: group, pageId: entityId || pageId, recordId, isTemplate }],
    getRecordWithQuery,
    {
      enabled: !!group && !!recordId,
      select(response) {
        return response.data;
      },
      onSuccess: async (response) => {
        if (
          response.draft &&
          "values" in response.draft &&
          response.draft.values &&
          Object.keys(response.draft.values).length > 0 &&
          !isReadMode
        ) {
          setValues({ [pageId]: { [recordId]: response.draft.values } });
          setInitialValues(pageId, recordId, response.draft.values);
        } else {
          if (response.values && Object.keys(response.values).length > 0 && !response.deleted) {
            setValues({ [pageId]: { [recordId]: response.values } });
            setInitialValues(pageId, recordId, response.values);
          }
        }
        if (response.lastModified) {
          setLastModified(response.lastModified, response.modifiedBy);
        }
        if (pageId !== entityId) {
          // macro was copied to another page
          const context = await window.AP.context.getContext();
          await propertyRecordsService.createOrUpdateRecord(
            pageId,
            group,
            recordId,
            JSON.stringify(response.values),
            context.confluence.content.version,
            true
          );
          setEntityId(pageId);
        }
      },
    }
  );

  if (isLoading || isIdle) {
    return <Spinner />;
  }

  if (!group || isError) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
      >
        <EmptyState
          description="There is no property group selected. Edit and configure one."
          imageUrl={NO_PROPERTY_GROUP_SELECTED_OR_EMPTY}
        />
      </div>
    );
  }

  return (
    <>
      <PropertyGroupTableView />
      {isReadMode && <Changelog />}
    </>
  );
};

const Changelog = () => {
  const { pageId, recordId, lastModified } = usePropertyGroupStore(
    (state) => ({
      pageId: state.pageId,
      recordId: state.recordId,
      lastModified: state.lastModified,
    }),
    shallow
  );

  const openLog = () => {
    window.AP.require("dialog", function (dialog) {
      dialog.create({
        chrome: false,
        key: "property-group-log-dialog",
        customData: {
          recordId: recordId,
          entityId: pageId,
        },
      });
      window.AP.dialog.getButton("submit").hide();
    });
  };

  if (!lastModified) {
    return <></>;
  }

  return (
    <div style={{ display: "flex" }}>
      <Button
        appearance="link"
        iconBefore={<EmojiFrequentIcon primaryColor="rgb(107, 119, 140)" size="small" />}
        onClick={openLog}
        css={css`
          padding-left: 0px;
          font-size: 12px;
          text-decoration: none;
          > span {
            margin: 0px;
          }
        `}
      >
        <span
          style={{
            color: "rgb(107, 119, 140)",
          }}
        >
          Updated{" "}
        </span>
        <FormattedDate
          styles={{
            color: "rgb(107, 119, 140)",
          }}
          seconds={lastModified._seconds}
          nanoseconds={lastModified._nanoseconds}
        />
      </Button>
    </div>
  );
};

export default PropertyGroupMacro;
