import { useQuery } from "react-query";
import { PropertyType } from "../../../property-type";
import { getPropertiesOfGroup } from "../api";
import CustomSelect from "../Shared/CustomSelect";
import Spinner from "@atlaskit/spinner";
import { usePropertyVisualizationStore } from "../property-visualization-store";

const BoardVariantWrapper = (props) => {
  const { config, updateConfig } = usePropertyVisualizationStore((state) => ({
    config: state.config.args,
    updateConfig: state.updateConfig,
  }));

  const { isLoading, data: availableProperties } = useQuery(["properties-of-group", { group: config.data.group }], getPropertiesOfGroup, {
    enabled: !!config.data.group,
    select: (response) =>
      response.data.filter((d) => d.kind === PropertyType.SELECT).map((d) => ({ ...d, label: d.data.name, value: d.id })),
  });

  const handleUpdateConfig = (value) =>
    updateConfig({
      data: { ...config.data, args: { ...config.data.args, id: value?.id, position: value?.position, label: value?.label } },
    });

  return isLoading ? (
    <div style={{ textAlign: "center" }}>
      <Spinner />
    </div>
  ) : (
    <CustomSelect
      isClearable={false}
      initialValue={config.data.args.id}
      options={availableProperties}
      onUpdate={handleUpdateConfig}
      label="Select"
      placeholder=""
    />
  );
};

export default BoardVariantWrapper;
