import { useEffect } from "react";
import Spinner from "@atlaskit/spinner";
import { Board } from "./StyledComponents";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import BoardLane from "./BoardLane";
import { onDragEnd } from "./onDragEnd";
import { HeightSettings } from "./BoardViewType";
import "./BoardVisualization.scss";
import { useBoardStore } from "./board-store";


const BoardVisualization = ({
  shouldDisableDragDrop = false,
  isLoading,
  selectFieldLoading,
  groupId,
  selectedField,
  onSave,
  storedData,
  viewType = "defaultView",
  expandState,
  selectLabel,
  onCreateNewPage,
  isPickable,
}) => {
  const { isCreateCardOpened } = useBoardStore((state) => ({
    isCreateCardOpened: state.isCreateCardOpened,
  }));

  const renderData = storedData?.slice(0, -1); //exclude last element
  const lastElement = storedData?.slice(-1)[0]; //take last element
  const properties = lastElement?.availableProperties || [];

  const handleDragEnd = (props) => {
    onDragEnd({
      props,
      storedData,
      groupId,
      selectedFieldId: selectedField?.id,
      saveAction: onSave,
    });
  };

  if (isLoading || selectFieldLoading || !storedData) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spinner />
      </div>
    );
  }
  const renderBoardLane = (option, idx) => {
    return (
      <Droppable isDropDisabled={shouldDisableDragDrop} droppableId={"structure-option" + idx} key={idx}>
        {(provided, snapshot) => (
          <div
            className="structure-option-container"
            key={idx}
            ref={(ref) => provided.innerRef(ref)}
            {...provided?.droppableProps}
            style={{
              cursor: snapshot.isDraggingOver ? "grabbing" : "default",
              minHeight: HeightSettings[viewType],
              ...provided?.droppableProps?.style,
            }}
          >
            <BoardLane
              index={idx}
              headQuote={option?.value}
              primaryIds={option?.primaryIds}
              properties={properties}
              isEditView={viewType === "editView"}
              shouldDisableDrag={shouldDisableDragDrop}
              isDraggingOver={snapshot.isDraggingOver}
              expandState={expandState}
              isPickable={isPickable}
              selectLabel={selectLabel}
              groupId={groupId}
              onCreateNewPage={onCreateNewPage}
              isUnassigned={idx === 0}
            />
          </div>
        )}
      </Droppable>
    );
  };

  return (
    <div style={{ width: "100vw" }}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Board minHeight={HeightSettings[viewType]}>{renderData?.map(renderBoardLane)}</Board>
      </DragDropContext>
    </div>
  );
};

export default BoardVisualization;
