import styled from "styled-components";
import { token } from "@atlaskit/tokens";

const Board = styled.div`
  min-height: ${(props) => props.minHeight};
  display: flex;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  &:hover::-webkit-scrollbar {
    display: inline-block;
    height: 8px;
    background-color: #f4f5f7;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1
    border-radius: 10px;
  }

  cursor: default;
  border-radius: 3px;
  `;
const BoardLane = styled.div`
min-height: ${(props) => props.minHeight};
  cursor: default;
  background-color: ${token("elevation.surface.raised")};
  display: flex;
  flex-direction: column;
  align-self: auto;
  flex: 1 1 auto;
  min-width: 260px;
  max-width: 260px;
  margin: 0.5rem;
  padding: 0.5rem 3px 0.5rem 3px;
  border-radius: 4px;
  &::-webkit-scrollbar {
    display: none;
  }
  &:hover::-webkit-scrollbar {
    display: block;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1
    border-radius: 10px;
  }
  border-radius: 4px;

`;

const HeadQuote = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  padding-left: 4px
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  display: flex;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-sizing: border-box;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  margin-bottom: 7px;
  position: relative;
`;

const CardsWrapper = styled.div`
  paddingbottom: "40px";
  height: 100%;
`;

const BoardCard = styled.div`
  min-width: 260px;
  min-height: 72px;
  background-color: ${token("elevation.surface.overlay")};
  &:hover {
    background-color: ${token("elevation.surface.hovered")};
  }
  &:active {
    background-color: ${token("elevation.surface.pressed")};
  }
  box-shadow: ${token("elevation.shadow.overflow")};
  border-radius: 3px;
  margin-bottom: 0.5rem;
  padding: 12px 0 12px 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  line-height: 20px;
  text-decoration-skip-ink: auto;
  font-weight: 400;
  display: block;
  font-size: 14px;
  cursor: ${(props) => (props.isEditView ? "pointer" : "grab")};
  * {
    cursor: pointer;
  }
  position: relative;
`;

export { Board, BoardLane, HeadQuote, CardsWrapper, BoardCard };
