import { useEffect, useState } from "react";
import { atlassianRestService } from "../../Service/AtlassianRestService";
import { SkeletonItem } from "@atlaskit/side-navigation";
import UserPropertyView from "../../../v2/Components/PropertyFieldViews/UserPropertyView";

export const LazyUserMention = ({ accountIDs }) => {
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState({});

  useEffect(() => {
    (async () => {
      if (!accountIDs) {
        setLoading(false);
        setProfiles([]);
        return;
      }
      const profilePromises = accountIDs.map((ai) => atlassianRestService.getUserByAccountId(ai));
      const result = await Promise.all(profilePromises);
      setProfiles(result.map(_toUserField));
      setLoading(false);
    })();
  }, [accountIDs]);

  if (!accountIDs) {
    return <span data-sortable={true} data-raw-value=""></span>;
  }

  <SkeletonItem width={200} isShimmering />;
  return loading ? (
    <>
      {accountIDs.map((id) => {
        return <SkeletonItem key={id} width={200} isShimmering />;
      })}
    </>
  ) : (
    <div className="property-user-value" style={{ display: "flex" }}>
      <UserPropertyView profiles={profiles} />
    </div>
  );
};

function _toUserField(user) {
  return {
    avatarUrl: atlassianRestService.getProfilePictureUrl(user.profilePicture.path),
    id: user.accountId,
    name: user.publicName,
    type: "user",
  };
}
