import Select from "@atlaskit/select";
import { SelectFieldStyles } from "../../Shared/SelectFieldStyles";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

export const Label = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #6b778c;
  margin-bottom: 5px;
`;

const CustomSelect = ({ initialValue, onUpdate, options, label, placeholder, isClearable = true }) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (initialValue) {
      const selected = options.find((p) => p.value === initialValue);
      if (selected) {
        setSelected(selected);
      }
    } else {
      setSelected();
    }
  }, [initialValue]);

  return (
    <div style={{ marginTop: "20px" }}>
      <Label>{label}</Label>
      <Select
        value={selected}
        options={options}
        isMulti={false}
        styles={SelectFieldStyles}
        placeholder={placeholder}
        isClearable={isClearable}
        onChange={(result) => {
          onUpdate(result);
        }}
      />
    </div>
  );
};

export default CustomSelect;
