import React from "react";
import { Tile } from "./Tile";
import {
  PLACEHOLDER_KIND_DATE,
  PLACEHOLDER_KIND_MULTI_SELECT,
  PLACEHOLDER_KIND_SELECT,
  PLACEHOLDER_KIND_STRING,
  PLACEHOLDER_KIND_USER_MENTION,
  PLACEHOLDER_KIND_CONFLUENCE_PAGE,
  PLACEHOLDER_KIND_EMAIL,
  PLACEHOLDER_KIND_URL,
  PLACEHOLDER_KIND_PHONE,
  PLACEHOLDER_KIND_NUMBER,
} from "../SelectedPlaceholderSet/PlaceholderList/PlaceholderKinds";
import { usePlaceholderIcon } from "../SelectedPlaceholderSet/PlaceholderList/usePlaceholderIcon";

export const VariablesGrid = (props) => {
  return (
    <div className="VariablesGrid-container">
      <Tile icon={usePlaceholderIcon(PLACEHOLDER_KIND_STRING, "medium")} name="Text" kind={PLACEHOLDER_KIND_STRING} />
      <Tile icon={usePlaceholderIcon(PLACEHOLDER_KIND_SELECT, "medium")} name="Select" kind={PLACEHOLDER_KIND_SELECT} />
      <Tile icon={usePlaceholderIcon(PLACEHOLDER_KIND_MULTI_SELECT, "large")} name="Multiselect" kind={PLACEHOLDER_KIND_MULTI_SELECT} />
      <Tile icon={usePlaceholderIcon(PLACEHOLDER_KIND_DATE, "medium")} name="Date" kind={PLACEHOLDER_KIND_DATE} />
      <Tile icon={usePlaceholderIcon(PLACEHOLDER_KIND_USER_MENTION, "medium")} name="People" kind={PLACEHOLDER_KIND_USER_MENTION} />
      <Tile icon={usePlaceholderIcon(PLACEHOLDER_KIND_CONFLUENCE_PAGE, "medium")} name="Page" kind={PLACEHOLDER_KIND_CONFLUENCE_PAGE} />
      <Tile icon={usePlaceholderIcon(PLACEHOLDER_KIND_EMAIL, "medium")} name="Email" kind={PLACEHOLDER_KIND_EMAIL} />
      <Tile icon={usePlaceholderIcon(PLACEHOLDER_KIND_URL, "medium")} name="URL" kind={PLACEHOLDER_KIND_URL} />
      <Tile icon={usePlaceholderIcon(PLACEHOLDER_KIND_PHONE, "medium")} name="Phone" kind={PLACEHOLDER_KIND_PHONE} />
      <Tile icon={usePlaceholderIcon(PLACEHOLDER_KIND_NUMBER, "medium")} name="Number" kind={PLACEHOLDER_KIND_NUMBER} />
    </div>
  );
};
