import { useState } from "react";
import { useQuery } from "react-query";
import { getAllPropertyGroups } from "./api";
import { usePropertyGroupReportMacroStore } from "./store";
import { SelectFieldStyles } from "../Shared/SelectFieldStyles";
import Spinner from "@atlaskit/spinner";
import Select from "@atlaskit/select";

const ReportGroupPicker = (props) => {
  const { group, setAvailableGroups, setGroup } = usePropertyGroupReportMacroStore((state) => ({
    group: state.selectedGroup,
    setGroup: state.setGroup,
    setAvailableGroups: state.setAvailableGroups,
  }));
  /*   const styles = {
    singleValue: (base) => ({ ...base, fontSize: "14px", fontWeight: "400" }),
    menuList: (base) => ({ ...base, fontSize: "14px", fontWeight: "400" }),
  }; */
  const [selectedOption, setSelectedOption] = useState(group);
  const { isLoading, data } = useQuery(["property-groups", {}], getAllPropertyGroups, {
    select(response) {
      const filteredData = response.data.filter((d) => d.name !== "");
      const filteredGroupOptions = filteredData.map((d) => ({ id: d.id, label: d.name }));
      filteredGroupOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
      return filteredGroupOptions;
    },
    onSuccess: async (response) => {
      setAvailableGroups(response);
      if (group) {
        const resolvedNameForGroup = response.find((o) => o.id === group)?.label;
        if (resolvedNameForGroup) {
          setSelectedOption({ id: group, value: group, label: resolvedNameForGroup });
        }
      }
    },
  });

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spinner />
      </div>
    );
  }

  return (
    <Select
      isClearable
      value={selectedOption}
      styles={SelectFieldStyles}
      options={data}
      getOptionValue={(option) => option.label}
      maxMenuHeight={205}
      onChange={(newOption) => {
        setSelectedOption(newOption);
        setGroup(newOption?.id);
      }}
      placeholder="Select a group"
    />
  );
};

export default ReportGroupPicker;
