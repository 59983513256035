import { useQuery } from "react-query";
import { PropertyType } from "../../../property-type";
import { getPropertiesOfGroup } from "../api";
import CustomSelect from "../Shared/CustomSelect";
import { usePropertyVisualizationStore } from "../property-visualization-store";

const StructureVariantWrapper = (props) => {
  const { config, updateConfig } = usePropertyVisualizationStore((state) => ({
    config: state.config.args,
    updateConfig: state.updateConfig,
  }));

  const { isLoading, data: availableProperties } = useQuery(["properties-of-group", { group: config.data.group }], getPropertiesOfGroup, {
    enabled: !!config.data.group,
    select(response) {
      return response.data
        .filter((d) => (d.kind === PropertyType.USER && d.data?.checkboxes?.includes("singleUser")) || d.kind === PropertyType.SELECT)
        .map((d) => ({ ...d, label: d.data.name, value: d.id }));
    },
  });

  if (isLoading) {
    return <></>;
  }

  return (
    <div>
      <CustomSelect
        initialValue={config.data.args.primary}
        options={availableProperties}
        onUpdate={(value) => {
          updateConfig({ data: { ...config.data, args: { ...config.data.args, primary: value?.id } } });
        }}
        label="Primary"
        placeholder=""
      />
      <CustomSelect
        initialValue={config.data.args.secondary}
        options={availableProperties}
        onUpdate={(value) => {
          updateConfig({ data: { ...config.data, args: { ...config.data.args, secondary: value?.id } } });
        }}
        label="Secondary"
        placeholder=""
      />
    </div>
  );
};

export default StructureVariantWrapper;
