import { useEffect, useState } from "react";
import { BoardLane as BoardLaneContainer, HeadQuote, CardsWrapper } from "./StyledComponents";
import CardList from "./CardList";
import EditorHorizontalRuleIcon from "@atlaskit/icon/glyph/editor/horizontal-rule";
import AddIcon from "@atlaskit/icon/glyph/add";
import CreateCard from "../../Shared/CreateCard";

const areAllBooleanValuesSame = (expandedOptions) => {
  if (!Array.isArray(expandedOptions)) {
    throw new TypeError("Expected an array of boolean values");
  }
  if (expandedOptions.length === 0) {
    return false;
  }
  if (Array.isArray(expandedOptions) && expandedOptions?.some((val) => typeof val !== "boolean")) {
    throw new TypeError("Expected an array of boolean values", expandedOptions);
  }
  const firstValue = expandedOptions[0];
  for (let i = 1; i < expandedOptions.length; i++) {
    if (expandedOptions[i] !== firstValue) {
      return false;
    }
  }
  return true;
};

const BoardLane = ({
  index,
  headQuote = "",
  primaryIds,
  isEditView,
  shouldDisableDrag,
  properties,
  expandState,
  selectLabel,
  groupId,
  onCreateNewPage,
  isPickable,
  isUnassigned,
}) => {
  const [isExpanded, setIsExpanded] = useState(expandState === "expanded");
  const [boardHeight, setBoardHeight] = useState(0);

  useEffect(() => {
    setIsExpanded(expandState === "expanded");
  }, [expandState]);

  const onComputedHeight = (computedHeight) => {
    setBoardHeight(computedHeight);
  };

  const toggleIsExpanded = () => setIsExpanded((prevState) => !prevState);

  const renderHeadQuote = () => <span style={{ fontWeight: "400", paddingLeft: "4px", cursor: "grabbing" }}>{primaryIds?.length}</span>;

  const renderIcon = () => (
    <div style={{ position: "absolute", right: "12px" }} onClick={toggleIsExpanded}>
      {isExpanded ? <EditorHorizontalRuleIcon size="small" /> : <AddIcon size="small" />}
    </div>
  );

  const renderCardList = (onOptionsChange, primaryIds) => {
    return (
      <div
        style={{
          minHeight: `${boardHeight ? boardHeight + "px" : "unset"}`,
        }}
      >
        {primaryIds?.length ? (
          <CardList
            name={headQuote}
            properties={properties}
            isEditView={isEditView}
            shouldDisableDrag={shouldDisableDrag}
            isExpanded={isExpanded}
            onOptionsChange={onOptionsChange}
            selectLabel={selectLabel}
            groupId={groupId}
            isUnassigned={isUnassigned}
            primaryIds={primaryIds}
            isPickable={isPickable}
          />
        ) : null}
        <CreateCard
          groupId={groupId}
          onCreateNewPage={onCreateNewPage}
          isEditView={isEditView}
          name={headQuote}
          isUnassigned={isUnassigned}
          isPickable={isPickable}
          onComputedHeight={onComputedHeight}
        />
      </div>
    );
  };

  const onOptionsChange = (expandedOptions) => {
    const allBooleanValuesAreSame = areAllBooleanValuesSame(expandedOptions);
    allBooleanValuesAreSame && toggleIsExpanded();
  };

  return (
    <BoardLaneContainer key={index} className="structure-option">
      <HeadQuote>
        {headQuote}
        {renderHeadQuote()}
        {renderIcon()}
      </HeadQuote>
      <CardsWrapper style={{ borderRadius: "4px" }}>{renderCardList(onOptionsChange, primaryIds)}</CardsWrapper>
    </BoardLaneContainer>
  );
};

export default BoardLane;
