import { useEffect, useRef, useState } from "react";
import SystemPropertiesStructureVisualization from "./SystemPropertiesStructureVisualization";
import StructureVisualization from "./StructureVisualization";
import ScrollContainer from "react-indiana-drag-scroll";

import { ChartStrategy } from "./chart-strategy";
import { usePropertyVisualizationStore } from "../property-visualization-store";
import ViewWrapperTopBar from "../Shared/ViewWrapperTopBar";
import { ViewerType } from "../../viewer-type";

const ThumbnailViewer = ({ children, loading }) => {
  const { rootConfig } = usePropertyVisualizationStore((state) => ({
    rootConfig: state.config,
  }));
  const childrenWrapperRef = useRef();
  const scrollContainerRef = useRef();
  const parentContainerRef = useRef();
  const scaleFactor = useRef(1);

  useEffect(() => {
    if (loading) {
      return;
    }

    const childHeight = childrenWrapperRef?.current?.offsetHeight > 0 ? childrenWrapperRef?.current?.offsetHeight : 200;
    const childWidth = childrenWrapperRef.current.scrollWidth;
    const parentWidth = parentContainerRef.current.offsetWidth;

    const newScaleFactor = childWidth > parentWidth ? parentWidth / childWidth : childWidth / parentWidth;
    scrollContainerRef.current.style.height = "250px";
    childrenWrapperRef.current.style.width = "0px";
    childrenWrapperRef.current.style.height = "0px";
    scaleFactor.current = newScaleFactor;
    childrenWrapperRef.current.style.transform = `scale(${newScaleFactor - 0.025})`;
  }, [loading]);
  return (
    <div>
      <div>
        <ViewWrapperTopBar
          onZoomIn={() => {
            scaleFactor.current += 0.1;
            childrenWrapperRef.current.style.transform = `scale(${scaleFactor.current - 0.025})`;
          }}
          onZoomOut={() => {
            scaleFactor.current -= 0.1;
            childrenWrapperRef.current.style.transform = `scale(${scaleFactor.current - 0.025})`;
          }}
          onFitContent={() => {
            const childWidth = childrenWrapperRef.current.scrollWidth;
            const parentWidth = parentContainerRef.current.offsetWidth;
            scaleFactor.current = parentWidth / childWidth;
            childrenWrapperRef.current.style.transform = `scale(${scaleFactor.current - 0.025})`;
          }}
          customData={{
            id: ViewerType.VISUALIZATION_CHART,
            args: {
              config: rootConfig,
            },
          }}
        />
      </div>
      <div
        ref={parentContainerRef}
        style={{
          border: "1px solid #cecece",
          padding: "10px",
          height: "250px",
        }}
      >
        <ScrollContainer
          className="structure-scroll-container"
          innerRef={scrollContainerRef}
          style={{
            height: "250px",
            position: "relative",
          }}
        >
          <div
            ref={childrenWrapperRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            {children}
          </div>
        </ScrollContainer>
      </div>
    </div>
  );
};

const StructureVisualizationViewWrapper = ({ predefinedStructure = undefined }) => {
  const config = usePropertyVisualizationStore((state) => state.config.args) ?? predefinedStructure;

  const [loading, setLoading] = useState(true);

  return (
    <div style={{ width: "100%" }}>
      <ThumbnailViewer loading={loading}>
        {config.strategy === ChartStrategy.PROPERTY_GROUP && (
          <StructureVisualization
            onLoaded={() => {
              setLoading(false);
            }}
          />
        )}
        {config.strategy === ChartStrategy.SYSTEM && (
          <SystemPropertiesStructureVisualization
            isEdit={false}
            predefinedStructure={predefinedStructure}
            onLoaded={() => {
              setLoading(false);
            }}
          />
        )}
      </ThumbnailViewer>
    </div>
  );
};

export default StructureVisualizationViewWrapper;
