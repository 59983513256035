import create from "zustand";

export const usePropertyGroupStore = create((set, get) => ({
  loaded: false,
  selectedGroup: undefined,
  data: {},
  initialValues: undefined,
  values: {},
  entityId: undefined,
  pageId: undefined,
  recordId: undefined,
  lastModified: undefined,
  lastModifiedBy: undefined,
  setLastModified: (date, by) => set((state) => ({ lastModified: date, lastModifiedBy: by })),
  setRecordId: (id) => set((state) => ({ recordId: id })),
  setPageId: (id) => set((state) => ({ pageId: id })),
  setEntityId: (id) => set((state) => ({ entityId: id })),
  setData: (payload) => set((state) => ({ data: payload, loaded: true })),
  setGroup: (group) => set((state) => ({ selectedGroup: group })),
  setLoaded: () => set((state) => ({ loaded: true })),
}));

export const usePropertyValidationStore = create((set, get) => ({
  validValue: true,
  setValidValue: (valid) => set((state) => ({ validValue: valid })),
}));
