const ALL_JS_URL = "https://connect-cdn.atl-paas.net/all.js";

export const initAtlassianScript = () => {
  return new Promise((resolve, reject) => {
    if (!contextIsIframe()) {
      reject("not loading scripts since its not the expected iframe");
    }
    loadScriptInHTMLHead(ALL_JS_URL, async () => {
      resolve(true);
    });
  });
};

const loadScriptInHTMLHead = (scriptURL, onScriptLoaded) => {
  const scriptElement = document.createElement("script");
  const urlParams = new URLSearchParams(window.location.search)
  scriptElement.onerror = (onError) => {
    throw new Error("Could not load script: " + onError.target.src);
  };
  scriptElement.src = scriptURL;
  urlParams.get("portal") ? scriptElement.setAttribute("data-options", "sizeToParent:true;margin:false") : scriptElement.setAttribute("data-options", "sizeToParent:false;margin:false");
  scriptElement.onload = onScriptLoaded;

  document.head.appendChild(scriptElement);
};

const contextIsIframe = () => {
  if (!window.self || !window.top) {
    return true;
  }
  return window.self !== window.top;
};
