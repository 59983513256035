import { useEffect, useState } from "react";
import SectionMessage from "@atlaskit/section-message";
import Portal from "./Portal/Portal";
import { LoadingState } from "../LoadingState";
import { getParameterByName } from "./getParameterByName";
import { PlaceholderPortalContainer } from "./PlaceholderPortalStyles";
import { CreatePlaceholderSetModal } from "./PlaceholderSetModal/CreatePlaceholderSetModal";
import { analytics } from "../../../analytics";
import { AnalyticsEvent } from "../../../analytics-events";
import { placeholderSetService } from "../../Service/PlaceholderSetService";
import { usePlaceholderPortalStore, usePlaceholderStore } from "./Store";
import { token } from "@atlaskit/tokens";

export const PlaceHolderPortal = (props) => {
  const [addPlaceholderSetModalVisible, setAddPlaceholderSetModalVisible] = useState(false);
  const [changedGroup, setChangedGroup] = useState(false);
  const {
    selectedPlaceholderSet,
    setValidLicense,
    fetchPlaceholderSets,
    setRestriction,
    validLicense,
    initiallyFetched,
    groups,
    restricted,
    setIsContentDisplayAllowed,
    loadingPlaceholder,
  } = usePlaceholderPortalStore((state) => ({
    selectedPlaceholderSet: state.selectedPlaceholderSet,
    setValidLicense: state.setValidLicense,
    fetchPlaceholderSets: state.fetchPlaceholderSets,
    setRestriction: state.setRestriction,
    validLicense: state.validLicense,
    initiallyFetched: state.initiallyFetched,
    groups: state.groups,
    restricted: state.restricted,
    setIsContentDisplayAllowed: state.setIsContentDisplayAllowed,
    loadingPlaceholder: state.loadingPlaceholder,
  }));
  const { removeSpaces, removeLabels, setAreLabelsChanged, setAreSpacesChanged } = usePlaceholderStore((state) => ({
    removeSpaces: state.removeSpaces,
    removeLabels: state.removeLabels,
    setAreLabelsChanged: state.setAreLabelsChanged,
    setAreSpacesChanged: state.setAreSpacesChanged,
  }));

  useEffect(() => {
    analytics.logEvent(AnalyticsEvent.PORTAL_LOADED, {});
    const initiallySelectedGroup = getParameterByName("selectedGroup");
    const validLicense = getParameterByName("validLicense");
    setValidLicense(validLicense === "true");
    (async () => {
      await fetchPlaceholderSets(initiallySelectedGroup);
    })();
  }, [props]);

  useEffect(() => {
    if (addPlaceholderSetModalVisible) {
      analytics.logEvent(AnalyticsEvent.PROPERTY_PORTAL_CREATE_GROUP_MODAL);
    }
  }, [addPlaceholderSetModalVisible]);

  useEffect(() => {
    const checkUserPermission = async () => {
      setIsContentDisplayAllowed(false);
      if (!loadingPlaceholder) {
        try {
          await placeholderSetService.hasUserPermissionToEditPortal(selectedPlaceholderSet.id);
          setRestriction(false);
        } catch (error) {
          setRestriction(true);
        } finally {
          setIsContentDisplayAllowed(true);
        }
      }
    };
    checkUserPermission();
  }, [changedGroup]);

  const toggleChangeGroup = () => {
    setChangedGroup(!changedGroup);
    setIsContentDisplayAllowed(false);
    setRestriction(true);
    removeSpaces();
    removeLabels();
    setAreLabelsChanged(false);
    setAreSpacesChanged(false);
  };
  const onAddNewPlaceholder = () => {
    return setAddPlaceholderSetModalVisible(true);
  };

  return (
    <PlaceholderPortalContainer>
      <CreatePlaceholderSetModal
        onClose={() => {
          setAddPlaceholderSetModalVisible(false);
        }}
        visible={addPlaceholderSetModalVisible}
      />
      {!initiallyFetched && <LoadingState />}
      {initiallyFetched && (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%", backgroundColor: token('utility.elevation.surface.current') }}
        >
          {!validLicense && (
            <SectionMessage appearance="warning">
              You don’t have a valid license for the <strong>Properties</strong> app. Please activate a trial license in the Confluence
              administration or purchase a license through the Atlassian Marketplace.
            </SectionMessage>
          )}
          <div>
            <Portal
              selectedPlaceholderSet={selectedPlaceholderSet}
              groups={groups}
              allowedAccess={validLicense && !restricted}
              onChangeGroup={toggleChangeGroup}
              onAddNewPlaceholder={onAddNewPlaceholder}
              groupIsChanged={changedGroup}
            />
          </div>
        </div>
      )}
    </PlaceholderPortalContainer>
  );
};
