import { useEffect, useState } from "react";

export const useLifecycleSetCollapsed = ({ placeholder }) => {
  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    placeholder.lifecycle === "created" && setCollapsed(false);
    placeholder.lifecycle === undefined && !collapsed && setCollapsed(true);
  }, [placeholder.lifecycle]);
  return {
    collapsed,
    setCollapsed,
  };
};
