import React from "react";

const UserPropertyView = ({ profiles }) => {
  return (
    <div style={{ display: "flex", flexFlow: "row wrap" }} data-sortable={true} data-raw-value={profiles.map((p, index) => p.name).join()}>
      {profiles.map((p, index) => {
        return (
          <span key={p.id} className="skip-margin" style={{ display: "flex", alignItems: "center", margin: "3px", whiteSpace: "pre-line" }}>
            <span
              role="img"
              style={{
                backgroundImage: "url(" + p.avatarUrl + ")",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                borderRadius: "50%",
                minWidth: "25px",
                width: "25px",
                height: "25px",
                display: "flex",
              }}
            ></span>
            <div style={{ marginLeft: "10px" }}>{profiles.length > 1 && index !== profiles.length-1 ? <span>{p.name},</span> : p.name}</div>
          </span>
        );
      })}
    </div>
  );
};

export default UserPropertyView;
