import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import shallow from "zustand/shallow";
import { loadDataFromMacro } from "../../macro-service";
import { initResizeObserver } from "../../resize-service";
import { usePropertyVisualizationStore } from "./property-visualization-store";
import { VisualizationType } from "./visualization-variants";
import StructureVisualizationViewWrapper from "./OrgChart/StructureVisualizationViewWrapper";
import BoardVisualizationViewWrapper from "./Board/BoardVisualizationViewWrapper";
import { AnalyticsEvent } from "../../../analytics-events";
import { analytics } from "../../../analytics";
import { token } from "@atlaskit/tokens";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const PropertyVisualizationMacro = (props) => {
  const { config, setConfig } = usePropertyVisualizationStore(
    (state) => ({
      config: state.config,
      setConfig: state.setConfig,
    }),
    shallow
  );

  useEffect(() => {
    (async () => {
      const macroData = await loadDataFromMacro();
      try {
        const parsedConfig = JSON.parse(macroData.config);
        setConfig(parsedConfig);
      } catch (e) {
        setConfig({
          type: VisualizationType.ORG_CHART,
          args: undefined,
        });
      }
    })();
  }, []);

  useEffect(() => {
    initResizeObserver();
  }, []);

  useEffect(() => {
    window.AP.theming.initializeTheming();
    analytics.logEvent(AnalyticsEvent.VISUALIZATION_MACRO_LOADED, {});
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div style={{ width: "100%", backgroundColor: token("utility.elevation.surface.current") }}>
        {config.type === VisualizationType.ORG_CHART && <StructureVisualizationViewWrapper />}
        {config.type === VisualizationType.BOARD && <BoardVisualizationViewWrapper />}
      </div>
    </QueryClientProvider>
  );
};

export default PropertyVisualizationMacro;
